import { Box } from "@material-ui/core";
import { TDataGridRenderer } from "@uk";
import ukColors from "@uk/ukColors";
import { toJS } from "mobx";

export const objectRenderer: TDataGridRenderer = (row: any, value: any) => {
    console.log('objectRenderer(), value = ', toJS(value));
  
    return (
      <table>
        <tbody>
          {value &&
            Object.keys(value).map((key) => {
              if (Array.isArray(value[key])) {
                // return value[key].map((subValue: any) => objectRenderer(undefined, subValue));
                return JSON.stringify(value[key]).replaceAll(',"', ', "');
                // replaceAll доступен только в последних версиях JS
                // return JSON.stringify(value[key]).replace(/,"/g, ', "');
              }
              return (
                <tr key={key}>
                  <td>
                    <Box color={ukColors.lightGrey} pr={1}>
                      {key}
                    </Box>
                  </td>
                  <td>
                    <Box>{value[key]}</Box>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };