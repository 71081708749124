import React, { useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus } from '@uk';

import { ILabel } from '../../types';

import { DialogLabelsBody } from './DialogLabelsBody';

export interface ILabelsDialogResult {
  recordIds: number[];
  mapRecordLabels?: Map<string, ILabel[]>;
  oldLabels: ILabel[];
  addLabels: ILabel[];
  delLabels: ILabel[];
  updateLabels: ILabel[];
  closeStatus: ECommonDialogCloseStatus;
}

interface IRecordDialogProps {
  isOpen: boolean;
  dialogResult: ILabelsDialogResult;
  closeDialog: (dialogResult: ILabelsDialogResult) => void;
}

const DialogChangeLabels: React.FC<IRecordDialogProps> = ({ isOpen, dialogResult, closeDialog }) => {
  const { t } = useTranslation();
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());

  const checkOkBtn = useCallback(() => {
    userDlgUiStoreRef.current.setOkEnabled(
      dialogResult.addLabels.length + dialogResult.updateLabels.length + dialogResult.delLabels.length > 0,
    );
  }, [dialogResult.addLabels.length, dialogResult.updateLabels.length, dialogResult.delLabels.length]);

  checkOkBtn();

  const handleClose = useCallback(
    (status: number) => {
      userDlgUiStoreRef.current.setPending(true);
      dialogResult.closeStatus = status;

      if (closeDialog) {
        closeDialog(dialogResult);
      }
      userDlgUiStoreRef.current.setPending(false);
    },
    [closeDialog, dialogResult],
  );

  return (
    <CommonDialog
      data-autotest="DialogChangeLabels"
      autotestId={'DialogChangeLabels'}
      title={'labelForm.title'}
      contentComponent={<DialogLabelsBody data={dialogResult} setOkEnabled={checkOkBtn} />}
      open={isOpen}
      onClose={handleClose}
      uiStore={userDlgUiStoreRef.current}
    />
  );
};

export default observer(DialogChangeLabels);
