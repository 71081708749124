import { toast } from 'react-toastify';
import { DataGridStore, IDataGridStoreData, IDataGridStoreState } from '@uk';
import { makeAutoObservable, runInAction } from 'mobx';

import { BackendService } from 'services';

import i18n from 'i18next';

import { gAPP_STORE } from './../../app/app-store';

import { IUser } from './i-user';
import { usersGridColumns } from './users-grid-columns';

import { BackendError, HandleErrorTranslatedMessage } from '@/services/types';


export class UsersStore {
  data: { users: IUser[] } = { users: [] };
  searchQuery = '';

  gridStore: DataGridStore<IUser>;
  gridCols = usersGridColumns;

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });

    const dataProvider = async (state: IDataGridStoreState): Promise<IDataGridStoreData<IUser>> => {
      await this.getAllImpl();

      return { data: this.data.users, total: this.data.users.length, hasNextPage: false };
    };

    this.gridStore = new DataGridStore<IUser>('UserSettings', this.gridCols, { dataProvider: dataProvider });
  }

  /**
   * Get all users
   */
  getAll = async () => {
    await this.gridStore.reload();
  };

  private getAllImpl = async () => {
    try {
      const data: IUser[] = await BackendService.get('user');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      runInAction(() => (this.data.users = data.sort((a: any, b: any) => (a.id === b.id ? 0 : a.id - b.id))));

      const groups = await gAPP_STORE.getGroupsStore().getUserGroupsNoError(this.data.users.map(user => user.id));
      if (groups) {
        const userGroupsIds = (gAPP_STORE.loginStore.user?.groups || []).map(g => g.id);

        this.data.users.forEach(user => {
          user.groups = groups[user.id];
          // Если текущий пользователь не супервизор, то оставляем только те группы, в которых он состоит
          if (!gAPP_STORE.loginStore.user?.isSupervisor && !gAPP_STORE.loginStore.user?.isAdmin) {
            user.groups = user.groups?.filter(g => userGroupsIds.includes(g.id));
          }
        });
      }
    } catch (e) {
      this.data.users = [];
      if (e instanceof BackendError && e.status === 401) {
        toast.error(i18n.t(e.statusMessage));
      } else {
        toast.error(`Server error: ${JSON.stringify(e)}`);
        throw e;
      }
    }
  };

  /**
   * Create new user
   */
  create = async (user: IUser, password: string): Promise<IUser | undefined> => {
    try {
      const newUserData = {
        // id: newUserNum,
        active: user.active,
        email: user.email,
        firstName: user.firstName,
        isAdmin: user.isAdmin,
        isSupervisor: user.isSupervisor,
        isEditor: user.isEditor,
        lastName: '',
        password: password,
        platform: user.platform,
      };

      const data: IUser = await BackendService.post('user', JSON.stringify(newUserData));

      return data;
    } catch (error) {
      if (error instanceof BackendError) {
        if(error.errorInfo?.description.includes('already exists') || (!error.errorInfo && error.status === 500)){
          toast.error(i18n.t('unicLogin'));
        } else if(error.errorInfo.reasonCode) {
          toast.error(HandleErrorTranslatedMessage(error));
        } else {
          toast.error(`Server error: ${JSON.stringify(error)}`);
        };
      } else {
        toast.error(`Server error: ${JSON.stringify(error)}`);
        throw error;
      }
    }
  };

  /**
   * Update user
   */
  update = async (user: IUser, oldValue: IUser) => {
    try {
      await BackendService.put('user', JSON.stringify(user));
    } catch (error) {
      if (error instanceof BackendError) {
        if(error.errorInfo.reasonCode){
          toast.error(HandleErrorTranslatedMessage(error));
        } else {
          toast.error(`Server error: ${JSON.stringify(error)}`);
        };
      } else {
        toast.error(`Server error: ${JSON.stringify(error)}`);
        throw error;
      }
    }
  };

  /**
   * Update user password
   */
  updatePassword = async (user: IUser, password: string) => {
    try {
      await BackendService.put(`user/${user.id.toString()}`, password);
    } catch (error) {
      toast.error(`Server error: ${JSON.stringify(error)}`);
      throw error;
    }
  };

  /**
   * Delete user
   */
  delete = async (user: IUser) => {
    try {
      await BackendService.delete(`user/delete/${user.id.toString()}`);
    } catch (error) {
      if (error instanceof BackendError) {
        if(error.errorInfo.reasonCode){
          toast.error(HandleErrorTranslatedMessage(error));
        } else {
          toast.error(`Server error: ${JSON.stringify(error)}`);
        };
      } else {
        toast.error(`Server error: ${JSON.stringify(error)}`);
        throw error;
      }
    }
  };

  getUserNameById = (id: number) => {
    const user = this.data.users.find(u => u.id === id);

    return user ? `${user.firstName}` : undefined;
  };
}
