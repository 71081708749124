import React, { useEffect, useRef } from 'react';

import { CircularProgress } from '@material-ui/core';

import { ERecordStatus, IRecord } from 'components/records';

import { DataGrid, IDataGridCol, TDataGridRenderer } from '@uk';

import { observer } from 'mobx-react';

import RecordsStatusLabel from '../records/records-status-label';
import { UncompletedRecordsStore } from '../records/uncomplete-records-store';

const statusRenderer: TDataGridRenderer = (row: IRecord[], value: ERecordStatus) => {
  return <RecordsStatusLabel status={value} />;
};

export const uncompletedGridColumns: IDataGridCol<IRecord>[] = [
  { id: 'status', name: 'records.status', width: 100, align: 'center', renderer: statusRenderer, noSort: true },
  { id: 'fileName', name: 'records.filename', width: 300, noSort: true },
];

const UploadPanelGrid = observer(() => {
  const uncompletedStore = useRef(new UncompletedRecordsStore());

  useEffect(() => {
    const w = uncompletedStore.current.gridStoreEventWrapper;
    uncompletedStore.current.uncompletedGridStore.reload();
    void w.subscribe();

    return () => void w.unsubscribe();
  }, []);

  if (uncompletedStore.current.uncompletedGridStore.isPending) {
    return <CircularProgress />;
  }

  return (
    <DataGrid
      noPaging
      id="UploadPanel"
      cols={uncompletedGridColumns}
      gridStore={uncompletedStore.current.uncompletedGridStore}
    />
  );
});

export default UploadPanelGrid;
