import { RefObject, useCallback, useMemo, useRef } from 'react';

import { debounce } from 'lodash';

import { SelectedByIndexLogic } from '@/stores/selectedByIndexLogic';
import { IWord } from '@/components/records';
import { isSelectPossible } from '@/components/records/text/phrase';

export const useIndexedSelection = (phraseIndex: number, phraseTokens: IWord[], ref: RefObject<HTMLDivElement>) => {
  const selectionEnableFlagRef = useRef(false);
  const mouseDown = useRef<number | null>(null);
  const selections = useRef(new SelectedByIndexLogic(phraseIndex, phraseTokens.length));
  if (phraseTokens.length !== selections.current.getIndexesCount()) {
    selections.current = new SelectedByIndexLogic(phraseIndex, phraseTokens.length);
  }

  const handleCopySelected = useCallback((e: KeyboardEvent) => {
    if (e.ctrlKey && e.code === 'KeyC') {
      const range = selections.current.getFromToOrdered();

        if (range) {
          e.preventDefault();

          const selectedPhrase = phraseTokens.slice(range[0], range[1] + 1);
          const copiedText = selectedPhrase.reduce(
            (phrase: string, word: IWord) => (phrase += ` ${word.text}${word.stop_sign || ''}`),
            '',
          );

          if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(copiedText);
          } else {
            const textArea = document.createElement('textarea');
            textArea.value = copiedText;
            textArea.style.position = 'absolute';
            textArea.style.left = '-9999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
              document.execCommand('copy');
            } catch (error) {
              console.error(error);
            } finally {
              textArea.remove();
              document.removeEventListener('keydown', handleCopySelected);
            }
          }
        }
      }
    },
    [phraseTokens],
  );

  const clear = useCallback(() => {
    mouseDown.current = null;
    selections.current.reset();
    selectionEnableFlagRef.current = false;
  }, []);

  const onSelectionMouseDown = useCallback((idx: number) => {
    mouseDown.current = idx;
  }, []);

  const selectionOver = useCallback((idx: number) => {
    if (mouseDown.current !== null) {
      if (!selectionEnableFlagRef.current) {
        selectionEnableFlagRef.current = true;
        selections.current.startSelectFrom(mouseDown.current, !isSelectPossible());
      } else {
        selections.current.selectTo(idx);
      }
    }
  }, []);

  const onSelectionEnd = useCallback(
    (idx?: number) => {
      if (mouseDown.current === null) return;
      mouseDown.current = null;

      if (selectionEnableFlagRef.current) {
        if (idx !== undefined) {
          selections.current.selectTo(idx);
        }
        selectionEnableFlagRef.current = false;
        selections.current.setSelectionFinished(true);
      }

      document.addEventListener('keydown', handleCopySelected);
    },
    [handleCopySelected],
  );

  const debouncedSelectionMove = useMemo(
    () =>
      debounce(
        tokenIndex => {
          selectionOver(tokenIndex);
        },
        50,
        { maxWait: 100 },
      ),
    [selectionOver],
  );

  return {
    selectionsStore: selections.current,
    onSelectionMouseDown,
    onSelectionEnd,
    selectionOver: debouncedSelectionMove,
    clear,
  };
};
