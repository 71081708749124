import { dateRenderer, IDataGridCol } from '@uk';

import { ITemplate } from './i-template';


export const templatesGridColumns: IDataGridCol<ITemplate>[] = [
  { id: 'id', name: 'id', width: 60, align: 'center', noSort: true },
  {
    id: 'modifyedDate',
    name: 'templates.modifyedDate',
    width: 200,
    renderer: dateRenderer,
    align: 'center',
    noSort: true
  },
  { id: 'fileName', name: 'templates.fileName', width: 300, noSort: true },
  { id: 'templateName', name: 'templates.templateName', width: 300, noSort: true },
];
