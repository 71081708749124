import { IDictor, IRanges, TDictorFields, TGenderValues } from '@/types/dictors';
import { BackendService } from '@/services/backend-service';
import { ERequestFilterPredicate, IRequestFilterData, IRequestFilterField } from '@/components/filter/i-filter';
import { TChannelNumber } from '@/types/segmentation-types';
import { getColorById } from '@/common/palette';

interface IDictorDto {
  avatar?: string;
  audioRef?: null;
  channel: string;
  correlationId: string;
  description?: null;
  dictorNum: number;
  extId?: string | null;
  gender: TGenderValues;
  id: number;
  metadata?: string;
  modelRef?: string;
  name: string;
  surname: string;
  recordId: number;
  relevance?: number;
  segmentation: string;
  segmentationRef?: null;
  speechDuration: number;
  wordsCount: number;
  defaultName: boolean;
}

class DictorsService {
  getDictorsForRecord = async (id: string): Promise<IDictor[]> => {
    const result: IDictorDto[] = await BackendService.get(`dictors?correlation_id=${id}`);

    return this.dictorDtoToDictorBl(result);
  };

  getDictors = async (): Promise<IDictor[]> => {
    const result: IDictorDto[] = await BackendService.get('dictors');

    return this.dictorDtoToDictorBl(result);
  };

  getByFilter = async (filterData: IRequestFilterData<TDictorFields>) => {
    const result: IDictorDto[] = await BackendService.post('dictors/filter', JSON.stringify(filterData));

    return this.dictorDtoToDictorBl(result);
  };

  updateDictor = async (dictor: IDictor) => {
    const result: IDictorDto = await BackendService.put('dictors', JSON.stringify(dictor));

    return this.dictorDtoToDictorBl([result])[0];
  };

  updateDictorWithGroups = async (dictor: IDictor) => {
    const updateDictor = {
      ...dictor,
      metadata: JSON.stringify(dictor.metadata),
      segmentation: JSON.stringify(dictor.segmentation),
    };
    const result: IDictorDto = await BackendService.put('dictors/with-groups', JSON.stringify(updateDictor));

    return this.dictorDtoToDictorBl([result])[0];
  };

  deleteDictor = async (id: number) => {
    await BackendService.delete(`dictors/${id}`);
  };

  getByRecordIds = async (recordIds: number[]): Promise<IDictor[]> => {
    if (!recordIds.length) {
      return [];
    }

    const filterFields: IRequestFilterField<TDictorFields>[] = [];
    filterFields.push({
      fieldName: 'recordId',
      predicate: ERequestFilterPredicate.EQ,
      targetValues: recordIds,
    });
    const filterData: IRequestFilterData<TDictorFields> = {
      fields: filterFields,
      limit: 1000,
      offset: 0,
      sortByField: { name: 'id', order: 'Dsc' },
    };

    return await dictorsService.getByFilter(filterData);
  };

  private dictorDtoToDictorBl(dictors: IDictorDto[]): IDictor[] {
    return dictors.map((d, idx) => {
      let segmentation: IRanges;
      try {
        segmentation = JSON.parse(d.segmentation);
        if (!segmentation.ranges) {
          segmentation.ranges = [];
        }
      } catch (e) {
        segmentation = { ranges: [] };
      }

      return {
        ...d,
        channel: d.channel ? (d.channel as TChannelNumber) : undefined,
        gender: d.gender || 'undefined',
        metadata: d.metadata ? JSON.parse(d.metadata) : undefined,
        segmentation: segmentation,
        speechDuration: Math.round(d.speechDuration), // Math.round - это важно для корректной работы фильтров

        __color: getColorById(d.id),
      };
    });
  }
}

export const dictorsService = new DictorsService();
