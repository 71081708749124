import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';

import appColors from 'app/app-colors';

import { gAPP_STORE } from '@/app/app-store';

interface ISummaryHistoryItem {
  historyIndex: number;
  isFirst: boolean;
  isCurrent: boolean;
  isLast: boolean;
  autotest: string;
}

const SummaryHistoryItem: React.FC<ISummaryHistoryItem> = ({ historyIndex, isFirst, isCurrent, isLast, autotest }) => {
  const { t } = useTranslation();
  const summaryStore = gAPP_STORE.summaryStore;

  const bgcolor = isFirst ? appColors.green : isCurrent ? appColors.primary : '#ccc';
  const borderColor = isFirst && isCurrent ? appColors.green : isCurrent ? appColors.primary : 'white';

  const textInCircle = isFirst ? 'A' : '';

  return (
    <Box data-autotest={'autotest'} key={historyIndex} display="flex" flexDirection="column" alignItems="center">
      <Box
        width={15}
        height={15}
        m={0.5}
        bgcolor={bgcolor}
        borderRadius={100}
        border={`3px solid ${borderColor}`}
        style={{ cursor: 'pointer', transition: 'all 0.5s', textAlign: 'center', fontSize: '0.9em', color: 'white' }}
        onClick={() => summaryStore.changeHistory(historyIndex)}
        //onMouseEnter={handleMouseEnter}
        //onMouseLeave={handleMouseLeave}
      >
        {textInCircle}
      </Box>
      {!isLast && <Box width="1px" height={30} bgcolor="#ccc"></Box>}
    </Box>
  );
};

export default observer(SummaryHistoryItem);
