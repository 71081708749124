/**
 * Особый список языков.
 * Существует для того, чтобы не расставлять в коде сравнение со строковыми литералами типа if (str === 'en'),
 * когда такое сравнение требуется.
 *
 * Области использования:
 * 1. Список языков, на которые локализовано приложение, для отображения флага
 * 2. Специальная предобработка транскрипций на английском для исправления I`m
 */
export enum ESpecialLanguageCode {
  AR = 'ar',
  DE = 'de',
  EN = 'en',
  ES = 'es',
  KK = 'kk',
  RU = 'ru'
}

export enum EDirection {
  RTL = 'rtl',
  LTR = 'ltr',
}

export interface ILanguage {
  id: number;
  modifyedDate: string;
  name: string;
  icon: string;
  installed: boolean;
}

export class CLanguage implements ILanguage {
  id = -1;
  modifyedDate = '';
  name = '';
  icon = '';
  installed = false;
}
