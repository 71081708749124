export interface IResizeMouseListeners {
  doResize: (event: any) => void;
  endResize: (event: any) => void;
}

export const createResizeMouseListeners = (
  element: any,
  resizeMouseListeners?: IResizeMouseListeners,
): IResizeMouseListeners | undefined => {
  if (element && resizeMouseListeners) {
    console.log('createResizeMouseListeners(), element = ', element);

    // element.addEventListener('mousemove', resizeMouseListeners.doResize);
    // element.addEventListener('mouseup', resizeMouseListeners.endResize);
    // element.addEventListener('mouseleave', resizeMouseListeners.endResize);
    // element.addEventListener('mouseout', resizeMouseListeners.endResize);

    document.addEventListener('mousemove', resizeMouseListeners.doResize);
    document.addEventListener('mouseup', resizeMouseListeners.endResize);
  }

  return resizeMouseListeners;
};

export const removeResizeMouseListeners = (element: any, resizeMouseListeners?: IResizeMouseListeners) => {
  if (element && resizeMouseListeners) {
    console.log('removeResizeMouseListeners(), element = ', element);

    // element.removeEventListener('mousemove', resizeMouseListeners.doResize);
    // element.removeEventListener('mouseup', resizeMouseListeners.endResize);
    // element.removeEventListener('mouseleave', resizeMouseListeners.endResize);
    // element.removeEventListener('mouseout', resizeMouseListeners.endResize);

    document.removeEventListener('mousemove', resizeMouseListeners.doResize);
    document.removeEventListener('mouseup', resizeMouseListeners.endResize);
  }
};
