import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	FormControl,
	TextField,
} from '@material-ui/core';

import { DialogStore } from '../dialog.store';

import { gAPP_STORE } from '@/app/app-store';
import { dialogUploadRecordsRestrictions } from '@/common/constants';


export interface IItemCaseName {
	data: DialogStore;
	mettingTitle: string;
};


export const ItemCaseName: FC<IItemCaseName> = ({ data, mettingTitle }) => {
	const { t } = useTranslation();

	const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		data.setCaseId(e.target.value);
		data.setMettingTitle(e.target.value);
	};

	return (
		<FormControl variant="outlined" fullWidth>
			<TextField
				autoFocus
				label={gAPP_STORE.meetingTitleColumnName === undefined
					? t('records.meetingTitle')
					: (gAPP_STORE.meetingTitleColumnName as string)}
				//variant="outlined" //old
				variant="filled"
				fullWidth
				inputProps={{ maxLength: dialogUploadRecordsRestrictions.MAX_INPUT_TEXT_LENGTH }}
				value={mettingTitle}
				onChange={e => onChange(e)}
			/>
		</FormControl>
	);
};
