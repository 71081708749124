import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormGroup, Box } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';

import { useTranslation } from 'react-i18next';

import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus } from '@uk';

import { CTemplate, ITemplate } from './i-template';

import { templeteRestrictions } from '@/common/constants';

export interface ITemplateDialogResult {
  template: ITemplate;
  closeStatus: ECommonDialogCloseStatus;
}

const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: 5,
  },
}));

interface ITemplateDialogProps {
  open: boolean;
  template: ITemplate | undefined;
  onClose: (dialogResult: ITemplateDialogResult) => void;
  uiStore: CommonDialogUiStore;
}

const TemplateDialog: React.FC<ITemplateDialogProps> = ({ template, onClose, uiStore, open }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);

  const dialogResult: ITemplateDialogResult = useMemo(
    () => ({ template: template ? { ...template } : new CTemplate(), closeStatus: 0 }),
    [template],
  );

  useEffect(() => {
    uiStore.setOkEnabled(!error);
  }, [error, uiStore]);

  const handleClose = useCallback((status: number) => {
    dialogResult.closeStatus = status;

    if (onClose) {
      onClose(dialogResult);
    }
  }, [dialogResult, onClose]);

  const checkNameError = (name: string): boolean => {
    const hasError =
      name.length < templeteRestrictions.MIN_NAME_LENGTH ||
      name.length > templeteRestrictions.MAX_NAME_LENGTH;

    setError(hasError);

    return hasError;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNameChange = useCallback((event: any) => {
    const templateName: string = event.target.value;
    const isError = checkNameError(templateName);

    if (!isError) {
      dialogResult.template.templateName = templateName;
    }

  }, [dialogResult.template]);

  const DialogBody = useMemo(() => {
    return (
      <Box width={400}>
        <FormGroup>
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              label={t('templates.name')}
              variant="outlined"
              fullWidth
              defaultValue={dialogResult.template.templateName}
              onChange={handleNameChange}
              error={error}
              inputProps={{ maxLength: templeteRestrictions.MAX_NAME_LENGTH }}
              helperText={error && t('users.lengthError')}
            />
          </FormControl>
          <Box p={1} />
        </FormGroup>
      </Box>
    );
  }, [classes.formControl, dialogResult.template.templateName, error, handleNameChange, t]);

  return <CommonDialog open={open} title={t('template')} onClose={handleClose} contentComponent={DialogBody} autotestId={'template'} uiStore={uiStore} />;
};

export default TemplateDialog;
