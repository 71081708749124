import { observer } from 'mobx-react';

import { gAPP_STORE } from '@/app/app-store';
import { EditableTextArea } from '@/components/summary/EditableTextArea';

export interface ISummaryDescription {
  text: string;
  id: string;
  autotest: string;
}

export const SummaryDescription = observer(({ text, id, autotest }: ISummaryDescription) => {
  const { updateDescription, isTranslate } = gAPP_STORE.summaryStore;

  return (
    <div
      data-autotest={autotest}
      style={{
        fontSize: '14px',
        fontFamily: 'Inter, sans-serif',
        padding: '12px',
      }}
    >
      <EditableTextArea
        id={id}
        text={text}
        onEdit={updateDescription}
        maxLength={1200}
        placeholder="summary.enterDescription"
        defaultStyle={{
          padding: '10px',
          borderRadius: '8px',
          border: '2px solid rgba(0, 162, 182, 0.24)',
          backgroundColor: 'rgba(0, 162, 182, 0.08)',
        }}
        editStyle={{
          padding: '12px 11px',
        }}
        readOnly={isTranslate}
      />
    </div>
  );
});
