import { Box, Checkbox, Slider, Typography } from '@material-ui/core';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { gAPP_STORE } from 'app/app-store';

import { IHaveDisable } from './types';

export const SentencePauseSelector: FC<IHaveDisable> = observer(props => {
  const { t } = useTranslation();
  const recordTextStore = gAPP_STORE.getRecordTextStore();

  const value = recordTextStore.sentencePauseSec;
  const checked = recordTextStore.useSentencePauseSelector;
  const color = recordTextStore.useSentencePauseSelector ? '#000000' : '#bdbdbd';

  return (
    <Box display="flex">
      <Checkbox
        disabled={props.disabled}
        color="primary"
        checked={checked}
        onChange={event => {
          runInAction(() => recordTextStore.setSentencePauseSelector(event.target.checked as boolean));
        }}
      />
      <Box p={1} pr={2} width={200}>
        <Typography style={{ whiteSpace: 'nowrap', color: color }}>{t('sentencesPauseSec')}</Typography>
      </Box>
      <Box pr={1} mt={0.3} width={150} style={{ paddingTop: 2 }}>
        <Slider
          disabled={!recordTextStore.useSentencePauseSelector || props.disabled}
          value={value}
          onChange={(event, newValue) => recordTextStore.setSentencePauseSec(newValue as number)}
          valueLabelDisplay="off"
          step={0.5}
          marks
          min={0}
          max={5}
        />
      </Box>
      <Box p={1}>
        <Typography style={{ whiteSpace: 'nowrap', color: color }}>
          {value} {t('sec')}
        </Typography>
      </Box>
    </Box>
  );
});
