import moment from 'moment';
// import { convert2Bd } from 'utils/UKFormUtils';
export const WEEK_DAYS = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
export type WeekDay = 'Mo' | 'Tu' | 'We' | 'Th' | 'Fr' | 'Sa' | 'Su';
export type WeekDayNum = 1 | 2 | 3 | 4 | 5 | 6 | 7;
export const strToDate = (d: string): Date | undefined => (d ? new Date(Date.parse(d)) : undefined);

export const strToDateStr = (d: string) => (d ? moment(strToDate(d)).format('YYYY-MM-DD') : '');
export const strToDatetimeStr = (d: string) =>
  d ? moment(strToDate(d)).format('YYYY-MM-DD HH:mm') : '';
export const strToDatetimeWithSecondsStr = (d: string) => {
  const sas = moment(strToDate(d)).format('YYYY-MM-DD HH:mm:ss');
  console.log('timeProblem: was ', d);
  console.log('timeProblem: became ', sas);

  return d ? moment(strToDate(d)).format('YYYY-MM-DD HH:mm:ss') : '';
};

export const checkDate = (str: string): boolean => {
  return moment(str, moment.ISO_8601, true).isValid();
};

export const checkBirthDate = (str: string): boolean => {
  console.log('+++++++ BD', moment(str, moment.ISO_8601, true));
  console.log('++++++ NOW', moment());
  // return moment(str, moment.ISO_8601, true).isValid() && moment(str, moment.ISO_8601, true) < moment()
  return false;
};

export const dateToStrWithoutTime = (date: Date | null) =>
  date ? moment(date).format('YYYY-MM-DDT00:00:00') : '';
export const dateToStrWithTime = (date: Date | null) =>
  date ? moment(date).format('YYYY-MM-DDTHH:mm:ss') : '';
export const datetimeToStr = (date: Date) =>
  date ? moment(date).format('YYYY-MM-DDTHH:mm:ss') : '';

export const getUtcString = (): string => moment.utc().format('YYYY-MM-DDTHH:mm:ss');
export const dateStrToUtc = (s: string): string => {
  const date = strToDate(s);
  date?.setHours(date.getUTCHours());
  return datetimeToStr(date || new Date());
};

export const monthDays = (d: Date) => new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();

export const dateAddDays = (d: Date, days: number) => {
  const newDate = new Date(d);
  newDate.setTime(newDate.getTime() + days * 24 * 60 * 60 * 1000);
  return newDate;
};

// export const bdToStr = (d?: string, m?: string, y?: string) => {
//   const bdDay = d !== undefined ? convert2Bd(d, 2) : '__';
//   const bdMonth = m !== undefined ? convert2Bd(m, 2, true) : '__';
//   const bdYear = y !== undefined ? convert2Bd(y, 4) : '____';
//
//   return `${bdYear}-${bdMonth}-${bdDay}`;
// };

export const fromDayNumToWeekDay = (num: WeekDayNum): WeekDay => {
  switch (num) {
    case 1:
      return 'Mo';
    case 2:
      return 'Tu';
    case 3:
      return 'We';
    case 4:
      return 'Th';
    case 5:
      return 'Fr';
    case 6:
      return 'Sa';
    case 7:
      return 'Su';
  }
};

/**
 * Переводит дату из любого формата в ISO string
 * Если дата не парсится, то возвращается пустая строка
 */
export const dateToISOString = (d: string | Date | number): string => {
  const v = moment(d);

  return v.isValid() ? v.toISOString() : '';
};
