import React from 'react';

import { Box, styled } from '@material-ui/core';

import UploadPanel from '../upload-panel/upload-panel';

import { Filter } from './filter';

interface IFilterPanelProps {}

const FilterPanel: React.FC<IFilterPanelProps> = () => {
  const SBox = styled(Box)({ marginRight: '0.15em' });

  return (
    <SBox id={'FilterPage'} display="flex" flexGrow={1} flexDirection="column" height={1}>
      <Filter />
      <UploadPanel />
    </SBox>
  );
};

export default FilterPanel;
