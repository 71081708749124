const appColors = {
  grey: '#cfd8dc',
  darkGrey: '#999',
  ligthGrey: '#eceff1cc',
  ligthLightGrey: '#eceff166',

  white: '#ffffff',
  red: '#e53935', // '#d32f2f',
  darkRed: '#c51915', // '#d32f2f',
  green: '#078556', // "#689f38",
  blue: '#039be5',
  yellow: '#ffa000',
  yellowLight: '#ffecb3',
  pink: '#e91e63',
  purple: '#9c27b0',
  orange: '#ff5722',
  cyan: '#00acc1',

  primary: '#0b2b48',
  primaryLight: '#00838f',

  appToolbarBackgroundColor: '#0b2b48',
  gridCellBorderColor: '#ddd',

  toolbarBackgroundColor: '#eceff199',
  tabsBackgroundColor: '#eceff1',
  headersBackgroundColor: '#eceff166',

  selectedItem: '#b2ebf2',
};

export default appColors;
