import React, { FC } from 'react';
import { IconButton, Box, TableCell } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

interface IRowActionsProps {
   size: 'small' | 'medium';
}

const RowActions: FC<IRowActionsProps> = ({ size }) => {
   return (
      <TableCell style={{ position: 'sticky', width: 1, right: 0, padding: 0 }}>
         <Box
            className="overlay"
            style={{ opacity: 0 }}
            height="100%"
            top={0}
            right={0}
            bgcolor="transparent"
            position="absolute"
            display="flex"
            alignItems="center"
            pl={1}
            pr={1}>
            <IconButton aria-label="delete" size={size || 'medium'}>
               <MoreVertIcon fontSize="inherit" />
            </IconButton>
         </Box>
      </TableCell>
   );
};

export default RowActions;
