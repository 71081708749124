
export function getRandomIntFromZero(max: number): number {
  return Math.floor(Math.random() * Math.floor(max))
}

export function getRandomIntInRange(min: number, max: number): number {
  return getRandomIntFromZero(max) + min
}

export function getRandomRange(max: number, min = 0): { start: number, end: number } {
  const range = getRandomIntInRange(min, max)
  const start = getRandomIntInRange(min, max - range)
  const result = {
    start: start,
    end: start + range
  }
  console.log("player, min = ", min, " max = ", max , " generated range: ", result)
  return result
}

export function getRandomItem(items: any[]) {
  return items[getRandomIntFromZero(items.length)];
}
