import { dateRenderer, IDataGridCol, TDataGridRenderer } from '@uk';
import i18n from 'i18next';

import { ITranslateSetting } from './types';

const languageRenderer: TDataGridRenderer = (row: ITranslateSetting) => {
  const value = row.value;

  return value ? i18n.getResourceBundle(i18n.language, 'lang')[value.to] : '';
};

export const AutoTranslateGridColumns: IDataGridCol<ITranslateSetting>[] = [
  {
    id: 'modifyedDate',
    name: 'autoTranslate.modifiedDate',
    renderer: dateRenderer,
    width: 200,
    align: 'center',
    noSort: true,
  },
  {
    id: 'value',
    name: 'autoTranslate.name',
    renderer: languageRenderer,
    width: 300,
    noSort: true,
  },
];
