import type { Direction, Theme } from '@material-ui/core';
import { createTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import { createOptions as createBaseOptions } from './base/create-options';
import { createOptions as createLightOptions } from './light/create-options';

declare module '@material-ui/core/styles' {
  export interface INeutralColors {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  }

  interface IPalette {
    neutral: INeutralColors;
  }

  interface IPaletteOptions {
    neutral?: INeutralColors;
  }

  interface IPaletteColor {
    lightest?: string;
    darkest?: string;
    alpha4?: string;
    alpha8?: string;
    alpha12?: string;
    alpha30?: string;
    alpha50?: string;
  }

  interface ITypeBackground {
    paper: string;
    default: string;
  }
}

export type ColorPreset = 'blue' | 'green' | 'indigo' | 'gridsr' | 'purple';

export type Contrast = 'normal' | 'high';

interface IThemeConfig {
  colorPreset?: ColorPreset;
  contrast?: Contrast;
  direction?: Direction;
  paletteMode?: string;
  responsiveFontSizes?: boolean;
}

export const createTheme = (config: IThemeConfig): Theme => {
  let theme = createMuiTheme(
    // Base options available for both dark and light palette modes
    createBaseOptions({
      direction: config.direction,
    }),
    // Options based on selected palette mode, color preset and contrast
    createLightOptions({
      colorPreset: config.colorPreset,
      contrast: config.contrast,
    }),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
