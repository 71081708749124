import React from 'react';
import { Box, BoxProps, Typography } from '@material-ui/core';
import { IToolbarExDescriptorItem } from '@uk/components/toolbarEx';
import ToolbarEx from '@uk/components/toolbarEx/ToolbarEx';
import ukColors from '@uk/ukColors';

interface ITitleBarProps {
  title: string;
  text?: string;
  autotestId?: string;
  actionsDescriptor?: IToolbarExDescriptorItem[];
  hasBottomBorder?: boolean;
}

const TitleBar: React.FC<ITitleBarProps & BoxProps> = ({
  title,
  text,
  autotestId,
  actionsDescriptor,
  hasBottomBorder,
  ...rest
}) => {
  const toolbarDescriptor: IToolbarExDescriptorItem[] = [];

  toolbarDescriptor.push({
    type: 'title',
    text: title,
    pl: 1,
    pr: 1,
    autotestId: autotestId,
  });

  if (actionsDescriptor) {
    toolbarDescriptor.push(...actionsDescriptor);
  }

  return (
    <Box
      flexGrow={0}
      display="flex"
      flexDirection="column"
      style={{ border: autotestId ? undefined : '1px solid red' }}>
      <ToolbarEx
        descriptor={toolbarDescriptor}
        noBorder={!hasBottomBorder}
        autotestId={autotestId}
        {...rest}
      />
      {text && text.length > 0 && (
        <>
          <Box color={ukColors.darkGrey} pl={2} pb={2}>
            <Typography data-autotest={'title-' + autotestId || ''}>{text || ''}</Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TitleBar;
