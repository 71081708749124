import { Box, LinearProgress, Paper, Typography } from '@material-ui/core';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import appColors from '@/app/app-colors';
import { Z_INDEX_PLAYER } from '@/common/constants';

interface IPlayerLoadingPanelProps {
  title: string;
  loaded: number;
}

const PlayerLoadingPanel: FC<IPlayerLoadingPanelProps> = props => {
  const { t } = useTranslation();

  const { title, loaded } = props;

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: Z_INDEX_PLAYER,
        background: appColors.primary,
        backgroundSize: '10px 10px',
        backgroundImage:
          'linear-gradient(to right, #1b3b55 1px, transparent 1px), linear-gradient(to bottom, #1b3b55 1px, transparent 1px)',
      }}
    >
      <Paper>
        <Box p={3}>
          <Typography>{t(title)}</Typography>

          <LinearProgress value={loaded} variant="determinate" />
        </Box>
      </Paper>
    </Box>
  );
};

export default PlayerLoadingPanel;
