import React from 'react';
import { Box } from '@material-ui/core';

import { observer } from 'mobx-react';

import GroupChip from 'components/groups/group-chip';

import { IGroup } from 'components/groups';

import { IRecord } from './types';

const RecordGroups = ({ record }: { record: IRecord }) => {
  if (!Array.isArray(record.groups)) {
    return <></>;
  }

  return (
    <Box display="flex" flexWrap="wrap">
      {record.groups.map((group: IGroup, index) => (
        <Box key={index} m={0.5}>
          <GroupChip group={group} markOwnGroups />
        </Box>
      ))}
    </Box>
  );
};

export default observer(RecordGroups);
