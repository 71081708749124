import { DataGridSettingsStore } from './DataGridSettings.store';
import { ReactNode } from 'react';
import { IFormField } from '../form/types';
import { IColumnLayout } from './DataGridSettings';

export enum EDataGridAlign {
  LEFT,
  CENTER,
  RIGHT,
}

export type TDataGridRenderer = (
  row: any,
  value: any,
  isSelectedRow?: boolean
) => string | ReactNode;
export type TDataGridDetailsRenderer = (row: any, value: any) => TDataGridDetailsRender;
export type TDataGridColumnRenderer<T> = (col: IDataGridCol<T>) => string | ReactNode;
export interface TDataGridDetailsRender {
  value: string | ReactNode;
  direction?: 'row' | 'column';
  hideTitle?: boolean;
}

export type TGridColumnAlign = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export interface IDataGridColBase {
  id?: string;
  name: string;
  width?: number;
  isOverflow?: boolean;
  isHidden?: boolean;
  align?: TGridColumnAlign;
  sort?: boolean;
  renderer?: TDataGridRenderer;
  detailsRenderer?: TDataGridDetailsRenderer;
  filterField?: IFormField;
  disableQuickFilter?: boolean;
  hasCheck?: boolean;
  noSort?: boolean;
  noAllChecked?: boolean;
  fixedWidth?: boolean;
  hideInGrid?: boolean;
  hideInFilter?: boolean;
  hideInDetails?: boolean;
  getData?: (x: any) => any;
  columnRenderer?: TDataGridColumnRenderer<any>;
  autotestId?: string;
}
export interface IDataGridCol<T> extends IDataGridColBase {
  getData?: (x: T) => any;
  columnRenderer?: TDataGridColumnRenderer<T>;
}
export interface IRowActions {
  size: 'small' | 'medium';
  icon: ReactNode;
  isAlwaysVisible?: boolean;
  onClick: (row: any, rowIndex: number) => void;
}
export interface IDataGridProps {
  id?: string;
  cols: IDataGridColBase[];
  gridStore: IDataGridStore<any>;

  noPageNumbers?: boolean;
  isMultiselect?: boolean;
  checkIfSelected?: boolean;
  hideHeader?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  noPaging?: boolean;
  fixedRowHeight?: boolean;
  rowAction?: IRowActions;
  isNeedTooltipForContent?: boolean;

  rowCardRenderer?: (row: any) => string | ReactNode;
  onColumnActions?: () => void;
  onRowDblClick?: (row: any) => void;
}

/////////////////////////////////////////////////////////////////////////////

export enum EDataGridStoreSortMode {
  NONE = -1,
  ASC = 0,
  DSC = 1,
}

export enum IDataGridStorePredicates {
  Between = 0,
  InFilter = 1,
  Contains = 2,
  NotContains = 3,
  StartsWith = 4,
  NotStartsWith = 5,
  EndsWith = 6,
  NotEndsWith = 7,
  Equals = 8,
  NotEquals = 9,
  Less = 10,
  LessOrEqual = 11,
  Greater = 12,
  GreaterOrEqual = 13,
  IsNull = 14,
  IsNotNull = 15,
  In = 16,
  NotIn = 17,
}
export interface IDataGridStoreFilterField {
  key: string;
  predicate: IDataGridStorePredicates;
  targetValues: string[];
  score?: string;
}
export interface IDataGridStoreState {
  offset: number;
  limit?: number;
  sort?: EDataGridStoreSortMode;
  sortField?: string;
  columnFilterMap?: IDataGridStoreFilterField[];
  additionalParams?: IAdditionalParams;
  opaque?: any;
}

export interface IDataGridStoreData<T> {
  data: T[];
  hasNextPage: boolean;
  total?: number;
}

export type TDataProviderResult<T> = Promise<IDataGridStoreData<T>>;
export type TDataProvider<T> = (state: IDataGridStoreState, entity?: T) => TDataProviderResult<T>;
export interface IDataGridStoreDataProvider<T> {
  dataProvider?: TDataProvider<T>;
}

export interface IQueryType {
  kind: 'QueryType';
  queryType: string;
}
export interface ISearchQueryId {
  kind: 'SearchQueryId';
  searchQueryId: string;
}
export interface IWithVoiceModels {
  kind: 'WithVoiceModels';
  withVoiceModels?: boolean;
}
export interface NoAdditionalParams {
  kind: 'NoAdditionalParams';
}

export interface PaginationParams {
  kind: 'PaginationParams';
  currentPage: number;
  rowsOnPare: number;
}

export type IAdditionalParams =
  | IQueryType
  | ISearchQueryId
  | IWithVoiceModels
  | NoAdditionalParams
  | PaginationParams;
// export interface IAdditionalParams {
//   queryType?: string;
//   searchQueryId?: string;
//   withVoiceModels?: boolean
// }

export interface IDataGridStore<T> {
  dataGridId: string;
  cols: IDataGridColBase[];

  data: T[];
  total: number;
  isPending: boolean;
  error?: string;
  state: IDataGridStoreState;
  additionalParams?: IAdditionalParams;
  selectedRowIndexes: number[];
  checkedRows: number[];
  selectedRow: T | undefined;
  rowsOnPage: number;
  currentPage: number;
  storeDataProvider: IDataGridStoreDataProvider<T>;
  skipReload?: boolean;

  selectionChangeHandler?: () => void;

  setSelectedRow: (row: T) => void;
  reload: () => Promise<void>;
  loadData: (state: IDataGridStoreState) => Promise<void>;
  get: (index: number) => T | undefined;
  applyQuickFilterMap: (filterMap: Map<string, string>) => void;
  applyQuickFilter: (filterFields: IDataGridStoreFilterField[]) => void;
  setAdditionalParams: (additionalParams: IAdditionalParams) => void;
  reset: () => void;
  resetSelected: () => void;

  hasPrevPage: boolean;
  hasNextPage: boolean;

  setSelectedRowIndexes: (indexes: number[]) => void;
  setCheckedRows: (indexes: number[]) => void;

  setRowsOnPage: (number: number) => void;
  setCurrentPage: (number: number) => void;
  setSortedColumn: (columnLayout: IColumnLayout | undefined) => void;

  checkCb: ((rowIndex: number, value: boolean, obj: any) => void) | undefined;
  selectCb: ((rowIndex: number, value: boolean, obj: any) => void) | undefined;

  gridSettingsStore: DataGridSettingsStore;

  setCols: (cols: IDataGridColBase[]) => void;

  setDataGridId: (id: string) => void;

  setSelectionChangeHandler: (selectionChangeHandler: () => void) => void;

  setStoreDataProvider: (provider: IDataGridStoreDataProvider<T>) => void;
}

export interface IDataGridPaginationProps<T> {
  gridStore: IDataGridStore<T>;
  noPageNumbers?: boolean;
  tmpAdded?: any[];
  tmpDeleted?: any[];
}

export interface IDataGridCellProps {
  row: any;
  isSelectedRow: boolean;
  fixedRowHeight?: boolean;
  colIndex: number;
  rowIndex: number;
  col: IDataGridColBase;
  gridStore: IDataGridStore<any>;
  handleCheckRow: (row: any, rowIndex: number, e?: any) => void;
  SIZE: 'small' | 'medium';
  isNeedTooltip?: boolean;
}

export interface IDataGridRowProps {
  row: any;
  rowIndex: number;
  gridStore: IDataGridStore<any>;
  fixedRowHeight?: boolean;
  handleCheckRow: (row: any, rowIndex: number, e?: any) => void;
  rowAction?: IRowActions;
  onClick?: (event: any, row: any, rowIndex: number) => void;
  isNeedTooltipForContent?: boolean;

  SIZE: 'small' | 'medium';
}

export interface IDataGridCadProps {
  row: any;
  rowIndex: number;
  gridStore: IDataGridStore<any>;
  onClick?: (event: any, row: any, rowIndex: number) => void;
  rowCardRenderer: (row: any) => string | ReactNode;
}
