import {
  Box,
  BoxProps,
  Checkbox,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import ukColors from '@uk/ukColors';
import ToolbarButton from './ToolbarButton';
import ToolbarSelector from './ToolbarSelector';
import { IToolbarElementProps, IToolbarExDescriptorItem } from './types';

import styles from './toolbarEx.module.scss';
import { useTranslation } from 'react-i18next';

import ReactFileReader from 'react-file-reader';
import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { ToolbarToggleButton } from '@uk/components/toolbarEx/ToolbarToggleButton';

const ToolbarElement: React.FC<IToolbarElementProps & BoxProps> = ({ index, descriptor, itemAutotestId, ...rest }) => {

  const { t } = useTranslation();

  const getText = useCallback((b: IToolbarExDescriptorItem) => t(descriptor.text || ''), [descriptor.text, t]);
  const getTooltip = useCallback((b: IToolbarExDescriptorItem) => t(descriptor.tooltip || ''), [descriptor.tooltip, t]);

  const handleTextFieldKeyDown = useCallback((b: IToolbarExDescriptorItem, event: any) => {
    if (event.key === 'Enter') {
      if (descriptor.onSave) {
        descriptor.onSave();
      }
    }
  }, [descriptor]);


  switch (descriptor.type) {
    case 'button': {
      return (
        <ToolbarButton
          buttonClassName={ descriptor.buttonClassName }
          key={ index }
          tooltip={ getTooltip(descriptor) }
          text={ getText(descriptor) }
          color={ descriptor.color }
          bgcolor={ descriptor.bgcolor }
          icon={ descriptor.icon }
          onClick={ descriptor.onClick }
          pl={ descriptor.pl }
          pr={ descriptor.pr }
          rounded={ descriptor.roundedButton }
          disabled={ descriptor.disabled }
          autotestId={ itemAutotestId }
        />
      );
    }

    case 'checker': {
      return (
        <Box key={ index } pl={ descriptor.pl } pr={ descriptor.pr }>
          <FormControlLabel
            style={ { marginRight: 8 } }
            control={
              <Checkbox color="primary" checked={ descriptor.value } onChange={ descriptor.onChange }/>
            }
            label={ getText(descriptor) }
            data-autotest={ 'chk-' + itemAutotestId }
          />
        </Box>
      );
    }

    case 'divider': {
      return (
        <Box key={ index } pl={ descriptor.pl } pr={ descriptor.pr } className={ styles.divider }>
          <Divider orientation="vertical"/>
        </Box>
      );
    }

    case 'filler': {
      return <Box key={ index } className={ styles.filler }/>;
    }

    case 'switcher': {
      return (
        <Box key={ index } pl={ descriptor.pl } pr={ descriptor.pr }>
          <FormControlLabel
            style={ { marginRight: 8 } }
            control={
              <Switch
                size="small"
                color="primary"
                disabled={ descriptor.disabled }
                checked={ descriptor.value }
                onChange={ descriptor.onChange }
              />
            }
            label={ getText(descriptor) }
            data-autotest={ 'chk-' + itemAutotestId }
          />
        </Box>
      );
    }

    case 'text': {
      return (
        <Box key={ index } pl={ descriptor.pl } pr={ descriptor.pr } overflow={ 'hidden' } maxWidth={ 1 }>
          <Typography className={ styles.text } noWrap={ true }>{ t(getText(descriptor)) }</Typography>
        </Box>
      );
    }

    case 'text-field': {
      return (
        <Box key={ index } pl={ descriptor.pl || 1 } pr={ descriptor.pr || 1 }>
          <Box
            color={ descriptor.color }
            bgcolor={ ukColors.lightLightGrey }
            borderRadius="0.5em"
            p={ 0.5 }
            overflow="hidden"
            display="flex"
            alignItems="center">
            <TextField
              size="small"
              value={ descriptor.value }
              InputProps={ { disableUnderline: true } }
              inputProps={ {
                style: { padding: 6 },
                'data-autotest': 'txt-' + itemAutotestId,
              } }
              onBlur={ descriptor.onSave }
              onChange={ descriptor.onChange }
              onKeyDown={ (event: any) => handleTextFieldKeyDown(descriptor, event) }
            />
          </Box>
        </Box>
      );
    }

    case 'title': {
      return (
        <Box key={ index } display="flex" alignItems="flex-end" pl={ descriptor.pl } pr={ descriptor.pr }>
          <Box
            style={ { fontSize: '1.2em' } }
            pr={ 1 }
            whiteSpace="nowrap"
            data-autotest={ 'title-' + itemAutotestId }
            color={ rest.color }>
            { t(getText(descriptor)) }
          </Box>
          { descriptor.count !== undefined && (
            <Box
              style={ { fontSize: '0.9em', color: ukColors.darkGrey } }
              pb={ 0.2 }
              data-autotest={ 'title-' + itemAutotestId + '_count' }
              color={ rest.color }>
              { descriptor.count }
            </Box>
          ) }
        </Box>
      );
    }

    case 'toggle-button': {
      return (
        <ToolbarToggleButton
          pl={ descriptor.pl }
          pr={ descriptor.pr }
          tooltip={ getTooltip(descriptor) }
          icon={ descriptor.icon }
          color={ descriptor.color }
          disabled={ descriptor.disabled }
          autotestId={ descriptor.autotestId }
          selected={ descriptor.selected }
          setSelected={ descriptor.setSelected }/>
      );
    }

    case 'upload': {
      return (
        <Box key={ index } pl={ descriptor.pl || 1 } pr={ descriptor.pr || 1 }>
          <ReactFileReader
            fileTypes={ descriptor.fileTypes }
            base64={ descriptor.base64 }
            //base64={true}
            multipleFiles={ true }
            handleFiles={ descriptor.onClick }>
            <ToolbarButton
              tooltip={ getTooltip(descriptor) }
              icon={ descriptor.icon }
              text={ getText(descriptor) }
              color={ descriptor.color }
              bgcolor={ descriptor.bgcolor }
              autotestId={ 'btn-upload-' + itemAutotestId }
            />
          </ReactFileReader>

          {/* <Files
              onChange={descriptor.onClick}
              onError={descriptor.onError}
              accepts={descriptor.fileTypes}
              multiple={true}
              maxFiles={descriptor.maxFiles || 100}
              maxFileSize={descriptor.maxFileSize || 1024 * 1024 * 1024}
              minFileSize={1}
              clickable
            >
              <ToolbarButton
                tooltip={getTooltip(b)}
                icon={descriptor.icon}
                text={getText(b)}
                color={descriptor.color}
                bgcolor={descriptor.bgcolor}
                autotestId={'btn-upload-' + itemAutotestId}
              />
            </Files> */ }
        </Box>
      );
    }

    case 'selector': {
      return (
        <Box key={ index } pl={ descriptor.pl } pr={ descriptor.pr }>
          <ToolbarSelector
            title={ getText(descriptor) }
            value={ descriptor.value }
            list={ descriptor.list }
            listNameRenderer={ descriptor.listNameRenderer }
            onChange={ descriptor.onChange }
            selectedObjectsTypesAndChoices={ descriptor.selectedObjectsTypesAndChoices }
          />
        </Box>
      );
    }

    case 'custom': {
      return (
        <Box key={ index } pl={ descriptor.pl } pr={ descriptor.pr } className={ styles.custom }>
          { descriptor.component }
        </Box>
      );
    }

    default: {
      return <Box width={ 20 } height={ 20 } bgcolor="red"/>;
    }
  }

  return <></>
}

export default observer(ToolbarElement);
