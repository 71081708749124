import React, { ChangeEvent, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel, Checkbox, FormGroup, Chip, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';

import { IGroup } from 'components/groups';

import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus, useForceUpdate } from '@uk';

import { IUser } from './i-user';

import { gAPP_STORE } from '@/app/app-store';

import { userSettingsRestrictions } from '@/common/constants';

const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: 5,
  },
}));

const LOGIN_GOOD = 0;
const ERROR_LOGIN_SIZE = 1;
const ERROR_LOGIN_UNIQ = 2;
export interface IUserDialogResult {
  user: IUser;
  oldValue?: IUser;
  newGroups: IGroup[];
  closeStatus: ECommonDialogCloseStatus;
  password?: string;
  passwordConfirm?: string;
}

interface IUserDialogProps {
  open: boolean;
  dialogResult: IUserDialogResult;
  onClose: (dialogResult: IUserDialogResult) => void;
}

const UserDialog: React.FC<IUserDialogProps> = ({ open, dialogResult, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const [nameError, setNameError] = useState(false);
  const [loginError, setLoginError] = useState(0);
  const [passError, setPassError] = useState(false);
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());

  const loginErrorMessage = useMemo(() => ['', t('users.lengthError'), t('users.mustBeUniqueLogin')], [t]);
  const isError = useMemo(() => nameError || loginError > 0 || passError,
    [loginError, nameError, passError]);

  userDlgUiStoreRef.current.setOkEnabled(!isError);

  const handleClose = (status: number) => {
    if (status === ECommonDialogCloseStatus.CANCEL || status === ECommonDialogCloseStatus.OK && !isError) {

      dialogResult.closeStatus = status;

      if (status === ECommonDialogCloseStatus.CANCEL) {
        setNameError(false);
        setLoginError(LOGIN_GOOD);
        setPassError(false);
      } else {
        const hasError =
          checkNameError(dialogResult.user.firstName) ||
          checkLoginError(dialogResult.user.email) ||
          checkPassError(dialogResult.password ?? '');

        if (hasError) {
          return;
        }
      }

      if (onClose) {
        onClose(dialogResult);
      }
    }
  };

  const checkNameError = (name: string): boolean => {
    const hasError =
      name.length < userSettingsRestrictions.MIN_NAME_LENGTH ||
      name.length > userSettingsRestrictions.MAX_NAME_LENGTH;

    setNameError(hasError);

    return hasError;
  };

  const checkLoginError = (login: string): boolean => {
    let result = LOGIN_GOOD;

    if (
      login.length > userSettingsRestrictions.MAX_LOGIN_LENGTH ||
      login.length < userSettingsRestrictions.MIN_LOGIN_LENGTH
    ) {
      result = ERROR_LOGIN_SIZE;
    } else if (
      ((dialogResult.oldValue === undefined) || (dialogResult.oldValue && dialogResult.oldValue.email.toLocaleUpperCase() !== login.toLocaleUpperCase()))
      &&
      gAPP_STORE.getUsersStore().gridStore.data.map(user => user.email.toLocaleUpperCase()).includes(login.toLocaleUpperCase())
    ) {
      result = ERROR_LOGIN_UNIQ;
    }

    setLoginError(result);

    return result > 0;
  };

  const checkPassError = (pass: string): boolean => {
    let hasError = false;

    if (!dialogResult.oldValue) {
      hasError =
        pass.length < userSettingsRestrictions.MIN_PASSWORD_LENGTH ||
        pass.length > userSettingsRestrictions.MAX_PASSWORD_LENGTH;
    } else {
      hasError =
        (pass.length > 0 && pass.length < userSettingsRestrictions.MIN_PASSWORD_LENGTH) ||
        pass.length > userSettingsRestrictions.MAX_PASSWORD_LENGTH;
    }

    setPassError(hasError);

    return hasError;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNameChange = (event: any) => {
    const name: string = event.target.value;
    const isError = checkNameError(name);

    if (!isError) {
      dialogResult.user.firstName = name;
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLoginChange = (event: any) => {
    const login: string = event.target.value;
    const isError = checkLoginError(login);

    if (!isError) {
      dialogResult.user.email = login;
    }

  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleActiveChecked = (event: any) => {
    dialogResult.user.active = event.target.checked;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleIsAdminChecked = (event: any) => {
    dialogResult.user.isAdmin = event.target.checked;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleIsSupervisorChecked = (event: any) => {
    dialogResult.user.isSupervisor = event.target.checked;
  };
  const handleIsEditorChecked = (event: ChangeEvent<HTMLInputElement>) => {
    dialogResult.user.isEditor = event.target.checked;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePasswordChange = (event: any) => {
    const pass: string = event.target.value;
    const isError = checkPassError(pass);

    if (!isError) {
      dialogResult.password = pass;
    }
    ;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePasswordConfirmChange = (event: any) => {
    dialogResult.passwordConfirm = event.target.value;
  };

  const handleChangeGroups = (event: React.ChangeEvent<unknown>, newValue: IGroup[]) => {
    if (newValue.length > userSettingsRestrictions.MAX_GROUPS_COUNT) {
      toast.warn(t('users.maxGroups', { max_groups: userSettingsRestrictions.MAX_GROUPS_COUNT }));
    }
    dialogResult.newGroups = newValue.slice(0, userSettingsRestrictions.MAX_GROUPS_COUNT);
    forceUpdate();
  };

  const DialogBody = () => {
    return (
      <Box width={600}>
        <FormGroup>
          {/* User name */}
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              disabled={gAPP_STORE.loginStore.user?.isAD}
              key="name"
              label={t('users.name')}
              variant="outlined"
              fullWidth
              defaultValue={dialogResult.user.firstName}
              onChange={handleNameChange}
              autoComplete="off"
              error={nameError}
              inputProps={{
                maxLength: userSettingsRestrictions.MAX_NAME_LENGTH
              }}
              helperText={nameError && t('users.lengthError')}
            />
          </FormControl>
          <Box p={3} />

          {/* User login */}
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              disabled={gAPP_STORE.loginStore.user?.isAD}
              key="login"
              label={t('users.login')}
              variant="outlined"
              fullWidth
              defaultValue={dialogResult.user.email}
              onChange={handleLoginChange}
              autoComplete="login"
              inputProps={{ maxLength: userSettingsRestrictions.MAX_LOGIN_LENGTH }}
              error={loginError > 0}
              helperText={loginErrorMessage[loginError]}
            />
          </FormControl>
          <Box p={1} />

          {/* User password */}
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              disabled={gAPP_STORE.loginStore.user?.isAD}
              key="password"
              label={t('password')}
              variant="outlined"
              // type="password"
              fullWidth
              defaultValue={gAPP_STORE.loginStore.user?.isAD ? ' ' : dialogResult.password}
              autoComplete="new-password3"
              inputProps={{ maxLength: 30 }}
              onChange={handlePasswordChange}
              error={passError}
              helperText={passError && t('users.lengthError')}
            />
          </FormControl>
          <Box p={1} />

          {/* <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              key="passwordConfirm"
              label={t("passwordConfirm")}
              variant="outlined"
              // type="password"
              fullWidth
              defaultValue={dialogResult.passwordConfirm}
              autoComplete="new-password"
              onChange={handlePasswordConfirmChange}
            />
          </FormControl> */}

          <Box p={1} />

          {/* User role and status */}
          <Box display="flex" flexDirection="row" justifyContent="flex-start">
            <Box pl={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={gAPP_STORE.loginStore.user?.isAD}
                    color="primary"
                    defaultChecked={dialogResult.user.active}
                    onChange={handleActiveChecked} />
                }
                label={t('users.active')}
              />
            </Box>
          </Box>
          <Box p={1} />

          <Box display="flex" flexDirection="row" justifyContent="flex-start">
            <Box pl={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={gAPP_STORE.loginStore.user?.isAD}
                    color="primary"
                    defaultChecked={dialogResult.user.isAdmin}
                    onChange={handleIsAdminChecked}
                  />
                }
                label={t('users.admin')}
              />
            </Box>
            <Box p={1} />

            <Box pl={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked={dialogResult.user.isSupervisor}
                    onChange={handleIsSupervisorChecked}
                  />
                }
                label={t('users.supervisor')}
              />
            </Box>

            <Box pl={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked={dialogResult.user.isEditor}
                    onChange={handleIsEditorChecked}
                  />
                }
                label={t('users.editor')}
              />
            </Box>
          </Box>

          <Box p={2} />

          {/* Groups */}
          {gAPP_STORE.groups && (
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <Autocomplete
                multiple
                options={gAPP_STORE.getGroupsStore().data.groups}
                clearText={t('uploadForm.clear')}
                noOptionsText={t('uploadForm.noGroups')}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                // defaultValue={dialogResult.user.groups || []}
                value={dialogResult.newGroups || []}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('users.groups')}
                    variant="outlined"
                  // placeholder={t("users.groups")}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      color="primary"
                      // size="small"
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={handleChangeGroups}
              />
            </FormControl>
          )}

          <Box p={1} />
        </FormGroup>
      </Box>
    );
  };

  return (
    <CommonDialog
      title={t('user')}
      open={open}
      onClose={handleClose}
      contentComponent={DialogBody}
      autotestId={'user'}
      uiStore={userDlgUiStoreRef.current}
    />
  );
};

export default observer(UserDialog);
