import lodash from 'lodash';
import { DataGridSettingsStore } from './DataGridSettings.store';
import { IDataGridColBase } from './types';
import { makeAutoObservable } from 'mobx';
import {
  IDataGridStore,
  IDataGridStoreData,
  IDataGridStoreDataProvider,
  IDataGridStoreFilterField,
  IDataGridStorePredicates,
  IDataGridStoreState,
} from './types';
import { IColumnLayout } from './DataGridSettings';
import { EDataGridStoreSortMode, IAdditionalParams } from '@uk';

export class DataGridStore<T> implements IDataGridStore<T> {
  data: T[] = [];
  total: number = 0;
  isPending: boolean = false;
  error = '';
  hasNextPage = false;

  state: IDataGridStoreState = { offset: 0 };
  additionalParams: IAdditionalParams = { kind: 'NoAdditionalParams' };

  selectedRowIndexes: number[] = [];
  checkedRows: number[] = [];
  selectedRow: T | undefined;
  rowsOnPage: number = 20;
  currentPage: number = 0;

  storeDataProvider: IDataGridStoreDataProvider<T>;

  checkCb: ((rowIndex: number, value: boolean, obj: T) => void) | undefined;
  selectCb: ((rowIndex: number, value: boolean, obj: T) => void) | undefined;

  gridSettingsStore: DataGridSettingsStore;

  selectionChangeHandler?: () => void;

  skipReload = true;

  constructor(
    public dataGridId: string,
    public cols: IDataGridColBase[],
    provider: IDataGridStoreDataProvider<T>,
    checkCb?: (rowIndex: number, value: boolean, obj: T) => void,
    selectCb?: (rowIndex: number, value: boolean, obj: T) => void,
    public rowChanged?: (row: T) => void,
    skipReload = true
  ) {
    this.checkCb = checkCb;
    this.selectCb = selectCb;
    this.storeDataProvider = provider;
    this.gridSettingsStore = new DataGridSettingsStore(dataGridId, cols);
    this.state = this.getDefaultState();
    this.skipReload = skipReload;

    if (this.skipReload) {
      this.reload();
    }

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setStoreDataProvider(provider: IDataGridStoreDataProvider<T>) {
    this.storeDataProvider = provider;
  }

  setDataGridId(id: string) {
    this.dataGridId = id;
    this.gridSettingsStore.setDataGridId(id);
  }

  setCols(cols: IDataGridColBase[]) {
    this.cols = cols;
    this.gridSettingsStore.setCols(cols);
  }

  setSelectionChangeHandler(selectionChangeHandler: () => void) {
    this.selectionChangeHandler = selectionChangeHandler;
  }

  setSelectedRow = (row: T | undefined) => {
    if (row === this.selectedRow) {
      return;
    }

    if (!row) {
      this.setSelectedRowIndexes([]);
    } else {
      const ind = this.data.findIndex((val) => val === row);
      this.setSelectedRowIndexes([ind + this.currentPage * this.rowsOnPage]);
      //this.selectedRow = row;
    }

    if (row && this.rowChanged) {
      this.rowChanged(row);
    }

    if (this.selectionChangeHandler) {
      this.selectionChangeHandler();
    }
  };

  reload = async () => {
    await this.loadData({
      ...this.state,
      offset: this.currentPage * this.rowsOnPage,
      limit: this.rowsOnPage,
    });
    this.resetSelected();
  };

  loadData = async (state?: IDataGridStoreState) => {
    try {
      this.setPending(true);
      this.setError('');
      this.setState(state || this.getDefaultState());

      // Добавим дополнительные поля в условие поиска
      this.state.additionalParams = this.additionalParams;

      // const data: T[] = [];
      if (this.storeDataProvider.dataProvider) {
        const state: IDataGridStoreState = lodash.cloneDeep(this.state);
        state.limit = state.limit || this.rowsOnPage;
        const data = await this.storeDataProvider.dataProvider(state);
        this.setData(data);

        if (this.checkedRows.length > 0) {
          this.setCheckedRows([]);
        }
      }
    } catch (error: any) {
      console.error('DataGridStore, loadData(), error: ', error.toString());
      this.setError(error.toString());
    } finally {
      // TODO
      this.setPending(false);
    }
  };

  getDefaultState(): IDataGridStoreState {
    return {
      offset: this.state?.offset || 0,
      limit: this.rowsOnPage || 20,
      sortField: this.gridSettingsStore?.getSortedColumn()?.column.id,
      sort: this.gridSettingsStore?.getSortedColumn()?.sort,
    };
  }

  setRowsOnPage = (number: number) => {
    this.rowsOnPage = number;
    this.currentPage = 0;
    this.checkCurrentPage(true);
  };

  setCurrentPage = (number: number) => {
    this.currentPage = number;
    //this.resetSelected();
    this.reload();
  };

  checkCurrentPage = (reload?: boolean) => {
    const oldCurrentPage = this.currentPage;

    if (reload || oldCurrentPage !== this.currentPage) {
      this.reload();
    }
  };

  get hasPrevPage(): boolean {
    return this.currentPage > 0;
  }

  setSortedColumn(columnLayout: IColumnLayout | undefined) {
    const state = this.state || this.getDefaultState();

    if (columnLayout && columnLayout.sort !== EDataGridStoreSortMode.NONE) {
      state.sort = columnLayout.sort;
      state.sortField = columnLayout.column.id;
    } else {
      state.sort = undefined;
      state.sortField = undefined;
    }

    this.setState(state);
  }

  get = (index: number): T | undefined => {
    const record = (this.data || [])[index] as T;
    // if (!record) {
    //   this.reload();
    // }
    return record;
  };

  // ---------------------------------------------------------
  setSelectedRowIndexes = (indexes: number[]) => {
    if (indexes.toString() !== this.selectedRowIndexes.toString()) {
      this.selectedRowIndexes = indexes;
      if (indexes.length === 1) {
        const dataIndex = indexes[0] - this.currentPage * this.rowsOnPage;
        //this.setSelectedRow(this.data[dataIndex]);
        this.selectedRow = this.data[dataIndex];
      } else {
        this.selectedRow = undefined;
      }
    }
  };

  setCheckedRows = (indexes: number[]) => {
    this.checkedRows = indexes;
  };

  getCheckedRows = (): T[] => {
    const result: T[] = [];
    this.checkedRows.forEach((rowIndex) => {
      const rowIndexOnPage = rowIndex - this.currentPage * this.rowsOnPage;

      const r: T | undefined = this.get(rowIndexOnPage);
      if (r) {
        result.push(r);
      }
    });
    return result;
  };

  getSelectedRows = (): T[] => {
    const result: T[] = [];
    this.selectedRowIndexes.forEach((rowIndex) => {
      const rowIndexOnPage = rowIndex - this.currentPage * this.rowsOnPage;

      const r: T | undefined = this.get(rowIndexOnPage);
      if (r) {
        result.push(r);
      }
    });

    if (result.length === 0 && this.selectedRow) {
      result.push(this.selectedRow);
    }

    return result;
  };
  // ---------------------------------------------------------

  setState = (state: IDataGridStoreState) => {
    this.state = state;
  };

  appendRow = (row: T) => {
    this.data.push(row);
    this.total = this.data.length;
  };

  removeRow = (row: T) => {
    let rowIndex = -1;
    this.data = this.data.filter((i, index) => {
      if (i === row) {
        rowIndex = index;
      }
      return i !== row;
    });
    if (this.selectedRow === row) {
      this.setSelectedRow(undefined);
    }
    this.selectedRowIndexes = this.selectedRowIndexes.filter((i) => i !== rowIndex);
    this.checkedRows = this.checkedRows.filter((i) => i !== rowIndex);
    this.total = this.data.length;
  };

  setData = (data: IDataGridStoreData<T>) => {
    this.data = data.data;
    this.hasNextPage = data.hasNextPage;

    if (data.total) {
      this.total = data.total;
    }

    this.checkCurrentPage();
  };

  setPending = (isPending: boolean) => {
    this.isPending = isPending;
  };

  setError = (error: string) => {
    this.error = error;
  };

  applyQuickFilter = async (filterFields: IDataGridStoreFilterField[]) => {
    this.state.columnFilterMap = [];

    filterFields.forEach((ff) => {
      let notEmpty = true;
      ff.targetValues.forEach((v) => (notEmpty = !!v && v !== ''));

      if (ff.targetValues.length !== 0 && notEmpty) {
        this.state.columnFilterMap?.push(ff);
      }
    });

    this.state.offset = 0;
    this.currentPage = 0;
    this.resetSelected();
    await this.loadData(this.state);
  };

  applyQuickFilterMap = (filterMap: Map<string, string>) => {
    this.state.columnFilterMap = [];
    filterMap.forEach((value, key) => {
      const ff: IDataGridStoreFilterField = {
        key: key,
        predicate: IDataGridStorePredicates.StartsWith,
        targetValues: [value],
      };
      this.state.columnFilterMap?.push(ff);
    });
    this.loadData(this.state);
  };

  setAdditionalParams = (additionalParams: IAdditionalParams) => {
    this.additionalParams = additionalParams;
    // this.loadData(this.state);
  };

  reset = () => {
    this.data = [];
    this.total = 0;
    this.isPending = false;
    this.error = '';
    this.additionalParams = { kind: 'NoAdditionalParams' };
    this.resetSelected();
  };

  resetSelected(): void {
    if (this.selectedRowIndexes.length > 0) {
      this.setSelectedRowIndexes([]);
    }

    if (this.checkedRows.length > 0) {
      this.setCheckedRows([]);
    }

    if (this.selectedRow !== undefined) {
      this.setSelectedRow(undefined);
    }
  }
}

export default DataGridStore;
