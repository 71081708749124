import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, MenuItem } from '@material-ui/core';

import { DialogStore } from '../dialog.store';

import { Stack } from './Stack';

import { gAPP_STORE } from '@/app/app-store';

export interface IItemAlgorithms {
  data: DialogStore;
}

export const ItemAlgorithms: FC<IItemAlgorithms> = ({ data }) => {
  const { t } = useTranslation();
  const [selectedAlgorithm, setAlgorithm] = useState(data.algorithm);

  const hChangeAlgorithm = useCallback(
    (value: string) => {
      data.setAlgorithm(value);
      setAlgorithm(value);
    },
    [data],
  );

  const blockAlgorithms = useMemo(() => {
    if (gAPP_STORE.availableAlgorithms.length < 2 || !data.isSeparated) return null;

    return (
      <Stack direction="row" spacing={2}>
        <TextField
          variant="filled"
          fullWidth
          label={t('uploadForm.algorithmName')}
          select
          onChange={event => hChangeAlgorithm(event.target.value as string)}
          value={selectedAlgorithm}
        >
          {gAPP_STORE.availableAlgorithms.map((algo, index) => (
            <MenuItem key={index} value={algo}>
              {algo}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    );
  }, [data.isSeparated, hChangeAlgorithm, selectedAlgorithm, t]);

  return <>{blockAlgorithms}</>;
};
