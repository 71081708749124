import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { trimStart } from 'lodash';
import { Checkbox, makeStyles, Switch } from '@material-ui/core';

import { FilterSlider } from './filter-slider';
import { FilterFieldList, IFilterFieldListItem } from './filter-field-list';
import FilterPredicate from './filter-predicate';
import { fixFilterValue } from './filter-utils';
import { IFilterData } from './i-filter';
import { IFilterField, TFilterFieldValue, TFilterPredicate } from './i-filter-field';
import { KEYWORDS_DELIMETER, NUMBER_FIELD_VALUES } from './const';

import { date2str } from '@/common/utils';
import FilterFieldDatetime from '@/components/filter/filter-field-datetime';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'stretch',
  },
  predicate: {
    borderRight: '1px solid #ddd',
    flexGrow: 0,
    padding: '4px 10px',
    alignSelf: 'center',
  },
  field: {
    flexGrow: 1,
    paddingTop: 1,
    paddingLeft: 3,
  },

  input: {
    border: 'none',
    outline: 'none',
    width: '100%',
  },
  textField: {
    border: 'none',
    outline: 'none',
  },
});

interface IFilterFieldProps {
  filterData?: IFilterData;
  value?: TFilterFieldValue;
  score?: number;
  predicate?: TFilterPredicate;
  list?: IFilterFieldListItem[];
  descriptor: IFilterField;
  onChange: (descriptorId: string, value: TFilterFieldValue | null | undefined, score?: number) => void;
  onChangePredicate?: (descriptorId: string, value: TFilterPredicate) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  noTranslateValue?: boolean;
  multiple?: boolean;
}

const FilterField: React.FC<IFilterFieldProps> = (props) => {
  const {
    value,
    score,
    predicate,
    list,
    descriptor,
    onChange,
    onChangePredicate,
    onKeyDown,
    noTranslateValue,
    multiple
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCheckboxChange = useCallback((event: any) => {
    if (onChange) {
      onChange(descriptor.id, event.target.checked);
    }
  }, [descriptor.id, onChange]);

  const handleDatetimeChange = useCallback((name: string, date: Date | null) => {
    if (onChange) {
      const sDate = (date !== null && date.toDateString() !== 'Invalid Date') ? date2str(date) : undefined;
      onChange(descriptor.id, sDate);
    }
  }, [descriptor.id, onChange]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
    const key = event.key;
    if (!NUMBER_FIELD_VALUES.includes(key)) {
      event.stopPropagation();
      event.preventDefault();
    }
  }, []);

  const handleListChange = useCallback((name: string, value: string[], score?: number) => {
    // эта обработка нужна для случая, если ищем ключевые слова
    let newValue: string[] = [];
    value.forEach(item => {
      if (item) newValue = [...newValue, ...item.split(KEYWORDS_DELIMETER).map(s => s.trim())];
    });

    if (onChange) {
      onChange(descriptor.id, newValue, score);
    }
  }, [onChange, descriptor.id]);

  const handleNumberListChange = useCallback((name: string, value: string[], score?: number) => {
    const valueFormatted: string[] = value.map(v => v.length > 1 ? trimStart(v, '0') : v);
    handleListChange(name, valueFormatted, score);
  }, [handleListChange]);

  const handleChangePredicate = useCallback((value: TFilterPredicate) => {
    if (onChangePredicate) {
      onChangePredicate(descriptor.id, value);
    }
  }, [onChangePredicate, descriptor.id]);

  const handleSliderChange = useCallback((newValue: number) => {
    onChange(descriptor.id, newValue);
  }, [onChange, descriptor.id]);

  const fieldComponent = useMemo(() => {
    switch (descriptor.type) {

      case 'checkbox':
        return (
          <Checkbox
            color="primary"
            name={descriptor.id.toString()}
            checked={Boolean(value)}
            onChange={handleCheckboxChange}
            onKeyDown={onKeyDown}
          />
        );

      case 'switch':
        return (
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <Switch
              checked={Boolean(value)}
              onChange={handleCheckboxChange}
            />
          </div>
        );

      case 'datetime':
        return (
          <FilterFieldDatetime
            name={descriptor.id.toString()}
            value={(value as string) && (value as string).length > 0 ? (value as string) : null}
            onKeyDown={onKeyDown}
            handleChange={date => handleDatetimeChange(descriptor.id.toString(), date)}
          />
        );

      case 'list':
        return (
          <FilterFieldList
            name={descriptor.id.toString()}
            value={value ? ([value].flat() as string[]) : []}
            score={score}
            list={list}
            onChange={handleListChange}
            noTranslateValue={noTranslateValue}
            multiple={multiple}
          />
        );

      case 'threshold':
        return (
          <FilterSlider defaultValue={value as number} change={handleSliderChange} min={1} max={100} step={1} />
        );

      case 'number':
        return (
          <FilterFieldList
            name={descriptor.id.toString()}
            score={score}
            onKeyDown={handleKeyDown}
            value={fixFilterValue(value as string[])}
            onChange={handleNumberListChange}
            noTranslateValue={true}
            multiple={multiple}
          />
        );

      default:
        return (
          <FilterFieldList
            name={descriptor.id.toString()}
            value={fixFilterValue(value as string[])}
            onChange={handleListChange}
            noTranslateValue={true}
          />
        );
    }

  }, [descriptor, value, handleCheckboxChange, onKeyDown, score, list, handleListChange,
    noTranslateValue, multiple, handleSliderChange, handleKeyDown, handleNumberListChange,
    handleDatetimeChange]);


  return (
    <div className={classes.root}>
      {descriptor.predicates && (
        <div className={classes.predicate}>
          <FilterPredicate
            descriptor={descriptor}
            predicate={predicate}
            onChange={handleChangePredicate}
          />
        </div>
      )}
      <div className={classes.field}>{fieldComponent}</div>
    </div>
  );
};

export default FilterField;
