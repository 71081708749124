import { BackendService } from '../backend-service';

import { TIMEOUT_DICTOR_STAT } from '@/common/constants';

interface IDictorStatIndex {
  recordId: number;
  transcriptionRevision: number;
}

class DictorStatIndexService {

  waitForReloadDictorStatIndex(recordId: number, transcriptionId: number): Promise<IDictorStatIndex> {
    return new Promise(resolve => {
      setTimeout(async function run() {
        const result = await BackendService.get(`dictors_statistics?record_id=${recordId}`);
        if (result.transcriptionRevision === transcriptionId) {
          resolve(result);
        } else {
          setTimeout(run, TIMEOUT_DICTOR_STAT);
        }
      }, TIMEOUT_DICTOR_STAT);
    });
  }

  async reloadDictorStatIndex(recordId: number): Promise<IDictorStatIndex> {
    return await BackendService.get(`dictors_statistics?record_id=${recordId}`);
  }

}

export const dictorStatIndexService = new DictorStatIndexService();
