import clsx from 'clsx';
import { observer } from 'mobx-react';

import { DetailedHTMLProps, HTMLAttributes, useMemo, useRef } from 'react';

import { useStyles } from '../record-text';
import { getPhraseWordText } from '../record-text-utils';
import { IWord } from '../types';

import SelectButton from './select-button';

import { gAPP_STORE } from '@/app/app-store';
import { SelectedByIndexLogic } from '@/stores/selectedByIndexLogic';
import { IDictor } from '@/types/dictors';
import { round2Decimals } from '@/utils/timeStamp';

export interface IPhraseTokenProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  tokenIndex: number;
  text: IWord;
  isTokenFiltered: (t: IWord, tokenIndex: number) => boolean;
  id: string;
  selectionStore: SelectedByIndexLogic;
  dictor?: IDictor;
  hChangeDictor: (dictor: IDictor) => void;
}

const PhraseTokenImpl: React.FC<IPhraseTokenProps> = ({
  tokenIndex,
  text,
  isTokenFiltered,
  id,
  dictor,
  hChangeDictor,
  selectionStore,
  ...rest
}) => {
  const classes = useStyles();

  const isSelected = selectionStore.getSelectedByIndex(tokenIndex);
  const selectionForCopy = selectionStore.getSelectionForCopy();
  const isLast = selectionStore.isLastByIndex(tokenIndex);
  const { wordProbability } = gAPP_STORE.getRecordTextStore();
  const { visibleTimecodes } = gAPP_STORE.summaryStore;

  const ref = useRef<HTMLDivElement>(null);

  const { position } = gAPP_STORE.soundStore;

  const isTokenInSummary = useMemo(() => {
    return visibleTimecodes.findIndex(tc => text.end <= tc.end && tc.begin <= text.begin) >= 0;
  }, [text.begin, text.end, visibleTimecodes]);

  const isTokenPlayed = useMemo(() => {
    const begin = round2Decimals(text.begin);
    const pos = round2Decimals(position);
    const end = round2Decimals(text.end);

    return begin <= pos && pos < end;
  }, [position, text.begin, text.end]);

  const isTokenFilteredVal = useMemo(() => {
    return isTokenFiltered(text, tokenIndex);
    //TODO: Костыль, нужно будет фиксить https://react.dev/learn/removing-effect-dependencies#to-change-the-dependencies-change-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenFiltered, text, tokenIndex, gAPP_STORE.getFilterStore().Data]); //need to recalculate if filter changed

  const isTokenLessProbability = useMemo(() => {
    return text.probability < wordProbability;
  }, [text.probability, wordProbability]);

  const t = useMemo(() => getPhraseWordText(text), [text]);

  return (
    <>
      <div
        {...rest}
        id={id}
        key={tokenIndex}
        ref={ref}
        onClick={e => {
          e.stopPropagation();
        }}
        className={clsx(classes.token, {
          [classes.tokenInSummary]: isTokenInSummary && !isTokenPlayed && !isSelected && !isTokenFilteredVal,
          [classes.tokenFiltered]: isTokenFilteredVal && !isSelected,
          [classes.tokenPlayed]: isTokenPlayed && !isSelected,
          [classes.tokenSelected]: isSelected,
          [classes.tokenSelectedForUpdate]: isSelected && !selectionForCopy,
          [classes.tokenSelectedForCopy]: isSelected && selectionForCopy,
          [classes.tokenLessProbability]: isTokenLessProbability,
        })}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            position: 'relative',
            pointerEvents: 'all',
          }}
        >
          <div style={{ wordBreak: 'break-word' }}>{t}</div>
          {dictor && isLast && !selectionForCopy && <SelectButton dictor={dictor} onChangeDictor={hChangeDictor} />}
        </div>
      </div>
    </>
  );
};

export const PhraseToken = observer(PhraseTokenImpl);
