import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';

import RecordsStatusLabel from '../records/records-status-label';

import { StatisticsRecordsStore } from '../records/statistics-records-store';

export const AppBarRecordsInfo = observer(() => {
  const { t } = useTranslation();

  const statisticStore = useRef(new StatisticsRecordsStore());

  useEffect(() => {
    const statStore = statisticStore.current;
    void statStore.entityChangeEventManager.subscribe();

    return () => void statStore.entityChangeEventManager.unsubscribe();
  }, []);

  return (
    <>
      {statisticStore.current.statistic.totalForAll > 0 && (
        <>
          <Typography style={{ lineHeight: 1.1 }}>{`${t('recordsInDatabase')}:`}</Typography>
          <Box pl={1} />
          <Typography>{statisticStore.current.statistic.totalForAll}</Typography>
        </>
      )}

      {statisticStore.current.statistic.totalPerStatus &&
        statisticStore.current.statistic.totalPerStatus.length > 0 && (
          <>
            <Box display="flex" mx={2}>
              {statisticStore.current.statistic.totalPerStatus
                .slice()
                .sort((a, b) => a.status - b.status)
                .map((tc, index) => (
                  <RecordsStatusLabel key={index} status={tc.status} total={tc.total} />
                ))}
            </Box>
          </>
        )}
    </>
  );
});
