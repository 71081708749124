import React from 'react';
import { observer } from 'mobx-react';

import { ColoredLabel } from '@uk';
import appColors from 'app/app-colors';


import { IGroup } from './types';

import { gAPP_STORE } from '@/app/app-store';

const GroupChip = ({ group, markOwnGroups }: { group: IGroup; markOwnGroups?: boolean }) => {
  const isCurrentUserGroup =
    gAPP_STORE.loginStore.user && gAPP_STORE.loginStore.user.groups && gAPP_STORE.loginStore.user.groups.map(g => g.id).includes(group.id);

  return (
    <ColoredLabel
      needTranslation={false}
      bgcolor={!markOwnGroups || isCurrentUserGroup ? appColors.primary : appColors.red}
      label={group.name}
    />
  );
};

export default observer(GroupChip);
