import { Checkbox, FormControlLabel, Box } from '@material-ui/core';
import List from '@material-ui/core/List';
import { DragHandle, ImportExport } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { EDataGridStoreSortMode, IDataGridColBase, IToolbarExDescriptorItem, ToolbarEx, ukColors } from '@uk';
import { DataGridSettingsStore } from './DataGridSettings.store';

export interface IColumnLayout {
  column: IDataGridColBase;
  visible: boolean;
  sort: EDataGridStoreSortMode;
  width?: number;
  isHidden?: boolean;
}

interface IDataGridSettingsProps {
  dataGridSettingsStore: DataGridSettingsStore;
}

const DataGridSettings: React.FC<IDataGridSettingsProps> = observer(props => {
  const { t } = useTranslation();

  const { dataGridSettingsStore } = props;
  const { colsLayout } = dataGridSettingsStore;

  const toolbarDescriptor: IToolbarExDescriptorItem[] = [];

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const checkBoxCol = colsLayout.find(col => col.column.name === '');
    const colsToReorder = colsLayout.filter(col => col.column.name !== '');
    const items = Array.from(colsToReorder);
    const [reorderedItems] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItems);

    const newOrderCols = checkBoxCol ? [checkBoxCol, ...items] : items;

    dataGridSettingsStore.reorderColumns(newOrderCols);
  }

  function handleOnCheckout(cl: IColumnLayout, event: any) {
    dataGridSettingsStore.setColumnVisible(cl, event.target.checked);
  }

  toolbarDescriptor.push({
    type: 'button',
    text: 'reset',
    onClick: () => dataGridSettingsStore.reset(),
  });

  return (
    <Box>
      <ToolbarEx descriptor={toolbarDescriptor} autotestId="DataGridSettings_Toolbar" noBorder />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId={dataGridSettingsStore.dataGridId}>
          {(provided: any) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {colsLayout
                .filter(cl => !cl.column.hasCheck)
                .map((cl, index) => {
                  if(!cl.isHidden){
                    return (
                      <Draggable key={index} draggableId={index.toString()} index={index}>
                        {(provided: any, snapshot: any) => {
                          return (
                            <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                              <Box px={1} borderRadius={5} bgcolor={snapshot.isDragging ? ukColors.lightGrey : ukColors.white} key={index} display="flex" flexGrow={1}>
                                <Box display="flex" flexGrow={1}>
                                  <Box>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          data-autotest={`${dataGridSettingsStore.dataGridId}_${cl.column.id}_settings_checkbox`}
                                          color="primary"
                                          name={cl.column.id}
                                          checked={cl.visible}
                                          onChange={event => handleOnCheckout(cl, event)}
                                        />
                                      }
                                      label={t(cl.column.name)}
                                    />
                                  </Box>
                                </Box>

                                <Box display="flex" alignItems="center">
                                  {snapshot.isDragging ? <ImportExport /> : <DragHandle />}
                                </Box>
                              </Box>
                            </li>
                          );
                        }}
                      </Draggable>
                    );
                  } else {
                    return null;
                  }
                })}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
});

export default DataGridSettings;
