import {
  Box,
  BoxProps,
  Toolbar,
} from '@material-ui/core';
import React from 'react';
import ukColors from '@uk/ukColors';

import { IToolbarExProps } from './types';
import styles from './toolbarEx.module.scss';

import { transliterate } from '@uk/utils/stringUtils';
import ToolbarElement from './ToolbarElement';

// const  rfr = require('react-file-reader');


/**
 * ToolbarEx
 */

const ToolbarEx: React.FC<IToolbarExProps & BoxProps> = ({
  descriptor,
  // noShadow,
  noBorder,
  noScroll,
  autotestId,
  ...rest
}) => {



  return (
    <Box
      flexGrow={0}
      display="flex"
      flexDirection="row"
      alignItems="center"
      bgcolor={ukColors.toolbarBackgroundColor}
      borderBottom={noBorder ? undefined : '1px solid ' + ukColors.borderColor}
      {...rest}>
      <Box flexGrow={1} className={noScroll ? undefined : styles.toolbarPlace}>
        <Toolbar variant="dense" className={styles.toolbar}>
          {Array.isArray(descriptor) &&
            descriptor.map((b, index) => {
              const itemAutotestId =
                b.text || b.autotestId || autotestId || (b.text && transliterate(b.text)) || '';

              return <ToolbarElement key={index} index={index} descriptor={b} itemAutotestId={itemAutotestId} {...rest}></ToolbarElement>
            })}
        </Toolbar>
      </Box>
    </Box >
  );
};

export default ToolbarEx;
