import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { LoadingPanel } from '@uk';

import { IIssue } from './types';
import { ECollapsePanels, ETabStatus } from './summary-store';
import { IssueItem } from './common/IssueItem';
import { CollapsePanel } from './common/CollapsePanel';

import { gAPP_STORE } from '@/app/app-store';
import { NoDataPanel } from '@/components/summary/NoDataPanel';
import { TranslationErrorPanel } from '@/components/summary/TranslationErrorPanel';

export const DecisionsPanel = observer(() => {
  const { summaryStore, showKeyDecisions, showAmbiguities } = gAPP_STORE;
  const { secondTabStatus, updateDecision, updateAmbiguity, isTranslate, isShow, switchShow } = summaryStore;
  const { decisions, ambiguities, getDecisionOwner } = summaryStore;

  const dopPanel = useMemo(() => {
    switch (secondTabStatus) {
      case ETabStatus.loading:
        return <LoadingPanel />;
      case ETabStatus.noData:
        return <NoDataPanel />;
      case ETabStatus.error:
        if (isTranslate) {
          return <TranslationErrorPanel />;
        } else {
          return <NoDataPanel />;
        }
      default:
        return null;
    }
  }, [isTranslate, secondTabStatus]);

  const decisionsBody = useMemo(
    () => (
      <div
        style={{
          gap: '12px',
          margin: '12px',
        }}
      >
        {decisions.map((item, itemIndex) => (
          // eslint-disable-next-line react/jsx-no-undef
          <IssueItem
            key={`decTopic-${itemIndex}`}
            item={item}
            idx={`decTopic-${itemIndex}`}
            onUpdate={updateDecision}
            isTranslate={summaryStore.isTranslate}
            owner={getDecisionOwner(item.id)}
            autotest={`decision-${itemIndex}`}
          />
        ))}
      </div>
    ),
    [decisions, getDecisionOwner, summaryStore.isTranslate, updateDecision],
  );

  const onUpdateAmbiguities = useCallback(
    (item: IIssue, fields: string[], values: (string | number)[]) => {
      updateAmbiguity(item, fields[0], `${values[0]}`);
    },
    [updateAmbiguity],
  );

  const ambiguitiesBody = useMemo(
    () => (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', margin: '12px' }}>
        {ambiguities.map((item, itemIndex) => (
          <IssueItem
            key={`ambTopic-${itemIndex}`}
            item={item}
            idx={`ambTopic-${itemIndex}`}
            onUpdate={onUpdateAmbiguities}
            isTranslate={summaryStore.isTranslate}
            autotest={`ambiguity-${itemIndex}`}
          />
        ))}
      </div>
    ),
    [ambiguities, onUpdateAmbiguities, summaryStore.isTranslate],
  );

  if (dopPanel !== null) {
    return <>{dopPanel}</>;
  }

  return (
    <>
      {showKeyDecisions && decisions && (
        <CollapsePanel
          id="keyDecisionsPanel"
          title="summary.accordionKeyDecisions"
          showPanel={isShow(ECollapsePanels.tab2Decision)}
          switchShow={() => switchShow(ECollapsePanels.tab2Decision)}
          component={decisionsBody}
          autotest="KeyDecisionsPanel"
        />
      )}
      {showAmbiguities && ambiguities && (
        <CollapsePanel
          id="keyIssuesPanel"
          title="summary.accordionAmbiguities"
          showPanel={isShow(ECollapsePanels.tab2Issues)}
          switchShow={() => switchShow(ECollapsePanels.tab2Issues)}
          component={ambiguitiesBody}
          autotest="AmbiguitiesPanel"
        />
      )}
    </>
  );
});
