import { BackendService } from '../backend-service';

import { ERequestFilterPredicate, IRequestFilterData, IRequestFilterField } from '@/components/filter/i-filter';
import { IGridStorageEntry, TGridStorageFields } from '@/types/grid-storage';

class GridStorageService {
  async getRecordsFiles (correlationIds: string[]): Promise<IGridStorageEntry[]> {
    const filterFields: IRequestFilterField<TGridStorageFields>[] = [];
    filterFields.push({
      fieldName: 'correlationId',
      predicate: ERequestFilterPredicate.EQ,
      targetValues: correlationIds,
    });
    filterFields.push({
      fieldName: 'webSupported',
      predicate: ERequestFilterPredicate.EQ,
      targetValues: [true],
    });
    const filterData: IRequestFilterData<TGridStorageFields> = {
      fields: filterFields,
      limit: 1000,
      offset: 0,
      sortByField: { name: 'id', order: 'Dsc' },
    };

    return await BackendService.post('storage/filter', JSON.stringify(filterData));
  }

  getNginxUrl (recordId: number, storageKey: string) {
    return `/api/v1/storage/stream/nginx/${recordId}/${storageKey}`;
  }

  getNginxUrlParticipants (storageKey: string) {

    return `/api/v1/storage/stream/nginx/${storageKey}`;
  }
}

export const gridStorageService = new GridStorageService();
