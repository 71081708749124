import { FC, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Avatar, Box, Chip, CircularProgress, Typography, useTheme } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogStore } from '../dialog.store';

import { Stack } from './Stack';
import { StyledIconButton } from './StyledIconButton';

import { gridProxyService } from '@/services/grid-proxy/grid-proxy-service';
import { IPersonCardDictor } from '@/components/records/types';
import { gridStorageService } from '@/services/grid-storage/grid-storage-service';

export interface IItemParticipants {
  data: DialogStore;
}

export const ItemParticipants: FC<IItemParticipants> = observer(({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const handleBtn = useCallback(async () => {
    const limit = data.maxMeetingParticipants ?? 5;
    if (data.participants.length >= limit) {
      toast.info(t('uploadForm.participantsLimitExceed'));

      return;
    }
    setLoading(true);
    const dlgFieldValues = await data.saveToUploadResult();

    const result = await gridProxyService.searchCards(limit - data.participants.length, undefined, dlgFieldValues);
    if (result === undefined) setLoading(false);
  }, [data, t]);

  const labelAndButton = useMemo(() => {
    return (
      <Stack direction="row" alignItems="center" spacing={0}>
        <Typography>{t('uploadForm.participants')}</Typography>
        <StyledIconButton
          size="small"
          //color={`${data.isAllFileLoaded ? 'primary' : 'secondary'}`}
          style={{ color: `${data.isAllFileLoaded ? 'rgb(11, 43, 72)' : 'red'}` }}
          onClick={data.isAllFileLoaded ? handleBtn : undefined}
        >
          <Add />
        </StyledIconButton>
        {!data.isAllFileLoaded ? <Typography color="error">{t('uploadForm.filesIsLoading')}</Typography> : <></>}
      </Stack>
    );
  }, [data.isAllFileLoaded, handleBtn, t]);

  const handleExclude = useCallback(
    (index: number) => {
      data.excludeParticipant(index);
    },
    [data],
  );

  const getChipLabel = (dictor: IPersonCardDictor) =>
    dictor.surname ? `${dictor.name} ${dictor.surname}`.trim() : dictor.name;

  const listParticipants = useMemo(() => {
    return (
      <Stack
        flexWrap="wrap"
        direction="row"
        alignItems={'center'}
        p={1}
        border={`1px solid ${theme.palette.grey[200]}`}
        borderRadius={1}
        minHeight={'2.2em'}
        gap={0.5}
      >
        {data.participants.map((dictor, index) => (
          <Chip
            key={index}
            avatar={<Avatar src={gridStorageService.getNginxUrlParticipants(dictor.avatar ? dictor.avatar : '')} />}
            color="primary"
            style={{ backgroundColor: theme.palette.info.main }}
            size="small"
            label={`${getChipLabel(dictor)}`}
            onDelete={() => handleExclude(index)}
          />
        ))}
      </Stack>
    );
  }, [data.participants, handleExclude, theme.palette.grey, theme.palette.info.main]);

  if (loading) {
    return (
      <>
        <Box p={5} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'row'}>
          <CircularProgress />
          <Box mr={3}></Box>
          <Typography variant="body1">{t('uploadForm.participantsLoading')}</Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Stack spacing={0}>
        {labelAndButton}
        {listParticipants}
      </Stack>
    </>
  );
});
