import { runInAction } from 'mobx';
import { IForm, TFormFieldValue } from './types';
import { IFormField, IFormLayout } from '@uk';
import { getVisibleFormFieldIds } from './formUtils';

const checkLengthValidity = (str: TFormFieldValue, minLength?: number, maxLength?: number) => {
  // console.log(`>>> Length (${minLength || '_'}, ${maxLength || '~'}) validation result is:`);
  const result = str === null || str === undefined
    ? true
    : (str?.toString() || '').length <= (maxLength || 10000) && (str?.toString() || '').length >= (minLength || 0);
  // console.log(`descriptor value is ${str?.toString() || ''}. is valid: ${result}`);
  return result;
}

export const formFieldValidator = (descriptor: IFormField): boolean => {
  let isValid = true;

  if (descriptor.validators) {
    descriptor.validators.forEach(validator => {
      switch (validator.type) {
        case 'NotNullable':
          if ((descriptor.value?.toString() || '').length === 0) {
            isValid = false;
            runInAction(() => {
            descriptor.invalidMessage = validator.invalidText || '';
          });
          }
          break;

        case 'Length':
          // console.log('======>>', descriptor.id, ': ', descriptor.value)

          if (Array.isArray(descriptor.value)) {
            if (descriptor.value.length > 0) {
              descriptor.value.forEach(v => {
                const value = (typeof v === 'object' && 'title' in v) ? v.title : v;
                if (!checkLengthValidity(value, validator.minLength, validator.length)) {
                  isValid = false;
                  runInAction(() => {
                  descriptor.invalidMessage = validator.invalidText || '';
                });
                }
              });
            }
          } else {
            if (descriptor.value && !checkLengthValidity(descriptor.value, validator.minLength, validator.length)) {
              isValid = false;
              runInAction(() => {
              descriptor.invalidMessage = validator.invalidText || '';
              });
            }
          }
          break;

        case 'CollectionLength':
          if (
            ((descriptor.value as []) || []).length > (validator.length || 10000) ||
            ((descriptor.value as []) || []).length < (validator.minLength || 0)
          ) {
            isValid = false;
            runInAction(() => {
            descriptor.invalidMessage = validator.invalidText || '';
            });
          }
          break;

        case 'Regexp':
          let r = new RegExp('');
          if (validator.regexp)
            r = new RegExp(validator.regexp);
          // console.log(`>>> Regexp validation result of ${descriptor.id} is:`);
          let value: any[] = []; //= Array.isArray(descriptor.value) ? descriptor.value || [] : [].push(descriptor.value as string);
          if (Array.isArray(descriptor.value)) {
            value = descriptor.value || [];
          } else if (typeof descriptor.value === 'string' || typeof descriptor.value === 'number') {
            value = [descriptor.value]
          }
          value.forEach((v: any) => {
            const t = (v && typeof v === 'object' && 'title' in v) ? v.title || '' : v;

            if (t && !r.test(t)) {
              isValid = false;
              runInAction(() => {
              descriptor.invalidMessage = validator.invalidText || '';
              });
            }
          });
          break;
      }
    });
  }

  if (isValid) {
    runInAction(() => {
    descriptor.invalidMessage = '';
    });
  }

  return isValid;
};

export const formValidator = (descriptor: IForm, descriptorLayout?: IFormLayout): boolean => {
  const visibleFieldIds = getVisibleFormFieldIds(descriptor, descriptorLayout);

  const validValues = descriptor.fields
    .filter(f => f.id && visibleFieldIds.includes(f.id))
    .every(d => {
      let isValid = formFieldValidator(d);
      if (d.refForm) {
        isValid = isValid && formValidator(d.refForm);
      }
      return isValid;
    });

  const validVersions = descriptor.fields
    .filter(f => f.id && visibleFieldIds.includes(f.id))
    .filter(f => f.versions)
    .every(f => f.selectedVersion);

  return validValues && validVersions;
};
