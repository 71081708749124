import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react';

import { FC } from 'react';

import { PlayerStore } from './Player.store';
export interface IPlayerChannelBarProps {
  store: PlayerStore;
}
export const PlayerChannelBar: FC<IPlayerChannelBarProps> = observer(({ store }) => {
  const { wsReady, channels, isErrorPlayer } = store;

  if (!wsReady || isErrorPlayer) return null;

  return (
    <Typography variant="h6" style={{ padding: '10px 0' }}>
      {channels}
    </Typography>
  );
});
