import { FC } from 'react';

import { Box, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { INotification } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { strToDatetimeStr } from '@/react-ui-kit/src/utils/dateUtils';

export interface INotifyErrorMsgProps {
  notification: INotification;
}

export const NotifyErrorMsg: FC<INotifyErrorMsgProps> = ({ notification }) => {
  const { t } = useTranslation();

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography variant="body1" style={{ fontSize: '1.2em', marginBottom: '1.3em' }}>
        {gAPP_STORE.loginStore.user?.isAdmin ? t(`admin_${notification.code}`) : t(notification.code)}
      </Typography>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Typography variant="subtitle2">{strToDatetimeStr(notification.createdDate)}</Typography>
        <Typography variant="subtitle2">{t('ClickToDismiss')}</Typography>
      </Box>
    </Box>
  );
};
