/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { Overrides } from '@material-ui/core/styles/overrides';

interface IConfig {
  palette: PaletteOptions;
}

export const createComponents = ({ palette }: IConfig): Overrides => {
  return {
    MuiAvatar: {
      root: {
        backgroundColor: palette.grey![200],
        color: palette.common?.black,
      },
    },
    // MuiBackdrop: {
    //   root: {
    //     [`&:not(.${backdropClasses.invisible})`]: {
    //       backgroundColor: alpha(palette.grey![900] ?? "grey", 0.75),
    //     },
    //   },
    // },
    // MuiCard: {
    //   root: {
    //     [`&.${paperClasses.elevation1}`]: {
    //       boxShadow:
    //         "0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
    //     },
    //   },
    // },
    MuiChip: {
      icon: {
        color: palette.action!.active,
      },
      root: {
        borderColor: palette.grey![200],
      },
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          color: palette.text!.secondary,
        },
      },
    },
    MuiFilledInput: {
      root: {
        borderColor: palette.grey![200],
        '&:hover': {
          backgroundColor: palette.action!.hover,
        },
        // [`&.${filledInputClasses.disabled}`]: {
        //   backgroundColor: "transparent",
        // },
        // [`&.${filledInputClasses.focused}`]: {
        //   backgroundColor: "transparent",
        //   borderColor: (palette.primary as PaletteColor).main,
        //   boxShadow: `${(palette.primary as PaletteColor).main} 0 0 0 1px`,
        // },
        // [`&.${filledInputClasses.error}`]: {
        //   borderColor: (palette.error as PaletteColor).main,
        //   boxShadow: `${(palette.error as PaletteColor).main} 0 0 0 1px`,
        // },
      },
    },
    MuiOutlinedInput: {
      root: {
        // "&:hover": {
        //   backgroundColor: palette.action!.hover,
        //   [`& .${outlinedInputClasses.notchedOutline}`]: {
        //     borderColor: palette.grey![200],
        //   },
        // },
        // [`&.${outlinedInputClasses.focused}`]: {
        //   backgroundColor: "transparent",
        //   [`& .${outlinedInputClasses.notchedOutline}`]: {
        //     borderColor: (palette.primary as PaletteColor).main,
        //     boxShadow: `${(palette.primary as PaletteColor).main} 0 0 0 2px`,
        //   },
        // },
        // [`&.${filledInputClasses.error}`]: {
        //   [`& .${outlinedInputClasses.notchedOutline}`]: {
        //     borderColor: (palette.error as PaletteColor).main,
        //     boxShadow: `${(palette.error as PaletteColor).main} 0 0 0 2px`,
        //   },
        // },
      },
      notchedOutline: {
        borderColor: palette.grey![200],
      },
    },
    MuiSwitch: {
      switchBase: {
        color: palette.grey![500],
      },
      track: {
        backgroundColor: palette.grey![400],
        opacity: 1,
      },
    },
    MuiTableCell: {
      root: {
        borderBottomColor: palette.divider,
      },
    },
    // MuiTableHead: {
    //   root: {
    //     [`& .${tableCellClasses.root}`]: {
    //       backgroundColor: palette.grey![50],
    //       color: palette.grey![700],
    //     },
    //   },
    // },
    // (@ts-ignore)
    // MuiTimelineConnector: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: palette.divider,
    //     },
    //   },
    // },
    // MuiTooltip: {
    //   tooltip: {
    //     backdropFilter: "blur(6px)",
    //     background: alpha(palette.grey![900], 0.8),
    //   },
    // },
  };
};
