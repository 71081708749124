import { Box } from '@material-ui/core';
import React from 'react';
import { IToolbarExDescriptorItem } from '@uk/components/toolbarEx';
import ToolbarEx from '@uk/components/toolbarEx/ToolbarEx';
import { useTranslation } from 'react-i18next';

export interface RecordInfoProps {
  speechLength: string; // '01:10:15'
  signalNoise: string; // '01:10:15 / 01:10:15'
  reverberation: string; // '0.7'
  quality: string;
  noise: string;
  recordMarkupAlgorithm: string; // polylogue
  //
  // allSegmentLength: string
  lowFrequencyBoundary: string;
  highFrequencyBoundary: string;
  frequencyBoundariesOfSpeechSignalThreshold: string;
  SCOForReverb: string;
  reverbMomentsCount: string;
  // clippedFragmentsLength: string
  maxNotClippedSegmentLength: string;
}

const RecordInfo: React.FC<RecordInfoProps> = ({
  speechLength,
  signalNoise,
  reverberation,
  quality,
  noise,
  recordMarkupAlgorithm,
  // allSegmentLength,
  lowFrequencyBoundary,
  highFrequencyBoundary,
  frequencyBoundariesOfSpeechSignalThreshold,
  SCOForReverb,
  reverbMomentsCount,
  maxNotClippedSegmentLength,
}) => {
  const { t } = useTranslation();

  // const [showDetails, setShowDetails] = useState(false);

  const toolbarInfoDescriptor: IToolbarExDescriptorItem[] = [
    // {
    //   type: 'button',
    //   text: showDetails ? 'collapse' : 'details',
    //   icon: showDetails ? <ExpandLess/> : <ExpandMoreIcon/>,
    //   onClick: () => setShowDetails(!showDetails),
    // },
    // {
    //   type: 'button',
    //   tooltip: 'closePlayer',
    //   icon: <Clear />,
    //   pl: 1,
    //   onClick: () => {
    //     if (onClose) onClose();
    //   },
    // },
  ];

  const InfoBox = ({ title, text }: { title: string; text: string }) => {
    return (
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        width={160}
        p={2}
        boxSizing="border-box">
        <Box color="darkgrey" fontSize="0.9em">
          {title}
        </Box>
        <Box py={0.5}>{text}</Box>
      </Box>
    );
  };

  // const channelsInfo = showDetails ? [0, 1] : [0];

  return (
    <Box borderBottom="1px solid lightgrey" display="flex" alignItems="flex-start">
      <Box>
        <Box key={234212} px={2} py={1.5} display="flex">
          <InfoBox title={t('speechLength')} text={speechLength} />
          <InfoBox title={t('signalNoise')} text={signalNoise} />
          <InfoBox title={t('reverberation')} text={reverberation} />
          <InfoBox title={t('quality')} text={quality} />
          <InfoBox title={t('noise')} text={noise} />
          <InfoBox title={t('recordMarkupAlgorithm')} text={recordMarkupAlgorithm} />
        </Box>
        <Box key={6785} px={2} py={1.5} display="flex">
          <InfoBox title={t('lowFrequencyBoundary')} text={lowFrequencyBoundary} />
          <InfoBox title={t('highFrequencyBoundary')} text={highFrequencyBoundary} />
          <InfoBox
            title={t('frequencyBoundariesOfSpeechSignalThreshold')}
            text={frequencyBoundariesOfSpeechSignalThreshold}
          />
          <InfoBox title={t('SCOForReverb')} text={SCOForReverb} />
          <InfoBox title={t('reverbMomentsCount')} text={reverbMomentsCount} />
          <InfoBox title={t('maxNotClippedSegmentLength')} text={maxNotClippedSegmentLength} />
        </Box>
      </Box>

      <Box flexGrow={1} />

      <ToolbarEx
        descriptor={toolbarInfoDescriptor}
        autotestId="PlayerPanel_Info_ToolbarEx"
        noBorder
        noScroll
      />
    </Box>
  );
};

export default RecordInfo;
