import { toast } from 'react-toastify';
import { DataGridStore, IDataGridStoreData, IDataGridStoreState } from '@uk';
import { makeAutoObservable } from 'mobx';
import i18n from 'i18next';

import { groupsGridColumns } from './groups-grid-columns';
import { IGroup } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { BackendError, HandleErrorTranslatedMessage } from '@/services/types';
import { groupsService } from '@/services/groups/groups-service';

export class GroupsStore {
  data: { groups: IGroup[] } = { groups: [] };
  searchQuery = '';

  gridStore: DataGridStore<IGroup>;
  gridCols = groupsGridColumns;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    const dataProvider = async (state: IDataGridStoreState): Promise<IDataGridStoreData<IGroup>> => {
      await this.getAllImpl();

      return { data: this.data.groups, total: this.data.groups.length, hasNextPage: false };
    };

    this.gridStore = new DataGridStore<IGroup>('GroupsId', this.gridCols, { dataProvider: dataProvider });
  }

  /**
   * Get all groups
   */
  getAll = async () => {
    await this.gridStore.reload();
  };

  getStorageGroupsStore(data: GroupsStore) {
    this.data = data.data;
  }

  private getAllImpl = async () => {
    try {
      let groups = await groupsService.getAllGroups();

      // Update current user groups
      const loggedUser = gAPP_STORE.loginStore.user;
      if (loggedUser) {
        const userGroups = await this.getUserGroupsNoError([loggedUser.id]);
        if (userGroups) {
          loggedUser.groups = userGroups[loggedUser.id];
        }
        // Если текущий пользователь не супервизор, то оставляем только те группы, в которых он состоит
        if (!loggedUser.isSupervisor && !loggedUser.isAdmin) {
          groups = loggedUser.groups || [];
        }
      }

      this.data.groups = groups;
    } catch (error) {
      this.showError(error);
    }
  };

  getCurrentGroups() {
    return gAPP_STORE.loginStore.user?.isSupervisor || gAPP_STORE.loginStore.user?.isAdmin
      ? this.data.groups
      : gAPP_STORE.loginStore.user?.groups || [];
  }

  /**
   * Create new group
   */
  create = async (group: IGroup) => {
    try {
      await groupsService.createGroup(group.name, group.description);
      await this.gridStore.reload();
    } catch (error) {
      if (error instanceof BackendError) {
        if (error.errorInfo.reasonCode && error.errorInfo.reasonCode.length) {
          toast.error(HandleErrorTranslatedMessage(error));
        } else {
          if (error.errorInfo?.description === '###DUPLICATE_KEY_NOT_ALLOWED') {
            toast.error(i18n.t('unicGroup'));
          }
          if (error.errorInfo?.description.includes('Maximum number of groups')) {
            toast.error(error.errorInfo.description);
          }
        }
      } else {
        toast.error(`Server error: ${JSON.stringify(error)}`);
      }
    }
  };

  update = async (group: IGroup, oldValue: IGroup) => {
    try {
      await groupsService.updateGroup(group);
      await this.gridStore.reload();
    } catch (error) {
      this.data.groups = [];
      this.showError(error);
    }
  };

  delete = async (group: IGroup) => {
    try {
      await groupsService.deleteGroupById(group.id);
      this.gridStore.reload();
    } catch (error) {
      this.showError(error);
    }
  };

  /**
   * GROUPS && USERS
   */

  addGroupsToUser = async (userId: number, groupIds: number[], reload = false) => {
    if (groupIds.length === 0) return;
    try {
      await groupsService.addGroupsToUser(userId, groupIds);
      reload && this.gridStore.reload();
    } catch (error) {
      this.showError(error);
    }
  };

  removeGroupsFromUser = async (userId: number, groupIds: number[], reload = false) => {
    if (groupIds.length === 0) return;
    try {
      await groupsService.removeGroupsFromUser(userId, groupIds);
      reload && this.gridStore.reload();
    } catch (error) {
      this.showError(error);
    }
  };

  getUserGroupsNoError = async (userIds: number[]) => {
    return await groupsService.getGroupsByUserIds(userIds);
  };

  updateUserGroups = async (userId: number, deletedGroups: number[], addedGroups: number[]) => {
    try {
      const answer = await groupsService.updateGroupsForUser(userId, deletedGroups, addedGroups);
      answer.failedAdd.length > 0 && console.error(answer.failedAdd.join(', '), ' have not added.');
      answer.failedDelete.length > 0 && console.error(answer.failedDelete.join(', '), ' have not deleted.');
    } catch (error) {
      this.showError(error);
    }
  };

  /**
   * GROUPS && RECORDS
   */
  addGroupsToRecord = async (recordId: number, groupIds: number[]) => {
    try {
      await groupsService.addGroupsToRecord(recordId, groupIds);
      await this.gridStore.reload();
    } catch (error) {
      this.showError(error);
    }
  };

  removeGroupsFromRecord = async (recordId: number, groupIds: number[]) => {
    try {
      await groupsService.removeGroupsFromRecord(recordId, groupIds);
      await this.gridStore.reload();
    } catch (error) {
      this.showError(error);
    }
  };

  getRecordGroups = async (recordIds: number[]) => {
    return recordIds.length > 0 ? await groupsService.getGroupsByRecordIds(recordIds) : undefined;
  };

  getRecordCountWithGroup = async (idGroup: number) => {
    return await groupsService.getRecordCountByGroup(idGroup);
  };

  showError(error: unknown) {
    if (error instanceof BackendError) {
      toast.error(HandleErrorTranslatedMessage(error));
    } else {
      toast.error(`Server error: ${JSON.stringify(error)}`);
    }
  }
}
