import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Box } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import EditIcon from '@material-ui/icons/Edit';

import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react';

import { DataGrid, IToolbarExDescriptorItem, Panel, ToolbarEx } from '@uk';

import GroupDialog, { IGroupDialogResult } from './group-dialog';
import DialogDeleteGroup from './group-dialog-delete';

import { gAPP_STORE } from '@/app/app-store';

const GroupsGrid = () => {
  const { t } = useTranslation();

  const [openCreateGroupDialog, setOpenCreateGroupDialog] = useState(false);
  const [openEditGroupDialog, setOpenEditGroupDialog] = useState(false);
  const [openDeleteGroupDialog, setOpenDeleteGroupDialog] = useState(false);

  const handleCloseGroupDialog = (dialogResult: IGroupDialogResult) => {
    if (dialogResult.closeStatus === 1 && dialogResult.group.name.trim().length === 0) {
      toast.error(t('groups.errorEmptyName'));

      return;
    }
    setOpenCreateGroupDialog(false);
    setOpenEditGroupDialog(false);

    if (dialogResult.closeStatus === 1) {
      if (dialogResult.group.id !== -1 && dialogResult.oldValue) {
        // Update group
        gAPP_STORE.getGroupsStore().update(dialogResult.group, dialogResult.oldValue);
      } else {
        // Create group
        gAPP_STORE.getGroupsStore().create(dialogResult.group);
      }
    }
  };

  const handleCloseGroupDeleteDialog = (dialogResult: IGroupDialogResult) => {
    setOpenDeleteGroupDialog(false);
    if (dialogResult.closeStatus === 1) {
      const selectedGroup = gAPP_STORE.getGroupsStore().gridStore.selectedRow;
      if (selectedGroup) {
        gAPP_STORE.getGroupsStore().delete(selectedGroup);
      }
    }
  };

  const handleCreateGroup = useCallback(() => {
    setOpenCreateGroupDialog(true);
  }, []);

  const handleEditGroup = useCallback(() => {
    if (gAPP_STORE.getGroupsStore().gridStore.selectedRow) {
      setOpenEditGroupDialog(true);
    }
  }, []);

  const handleDeleteGroup = useCallback(() => {
    if (gAPP_STORE.getGroupsStore().gridStore.selectedRow) {
      setOpenDeleteGroupDialog(true);
    }
  }, []);

  const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(
    () => [
      { type: 'text', text: `${t('groups.title')}`, pr: 1 },
      { type: 'divider', pl: 1 },
      {
        type: 'button',
        text: 'create',
        icon: <AddIcon />,
        onClick: handleCreateGroup,
        pl: 1,
      },
      {
        type: 'button',
        text: 'edit',
        icon: <EditIcon />,
        onClick: handleEditGroup,
        disabled: gAPP_STORE.getGroupsStore().gridStore.selectedRow ? false : true,
        pl: 1,
      },
      {
        type: 'button',
        text: 'delete',
        icon: <ClearRoundedIcon />,
        onClick: handleDeleteGroup,
        disabled: gAPP_STORE.getGroupsStore().gridStore.selectedRow ? false : true,
        color: 'red',
        pl: 1,
      },
    ],
    //TODO: Костыль, нужно будет фиксить https://react.dev/learn/removing-effect-dependencies#to-change-the-dependencies-change-the-code
    // оставить задизейблиным чтобы отслеживать выбранные поля в таблице
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gAPP_STORE.getGroupsStore().gridStore.selectedRow, handleCreateGroup, handleDeleteGroup, handleEditGroup, t],
  );

  return (
    <Panel>
      <ToolbarEx descriptor={toolbarDescriptor} />
      <Box display="flex" flexGrow={1}>
        <DataGrid
          id="GroupsGrid"
          cols={gAPP_STORE.getGroupsStore().gridCols}
          gridStore={gAPP_STORE.getGroupsStore().gridStore}
          isNeedTooltipForContent={true}
          noPaging
        />
      </Box>

      <GroupDialog
        open={openCreateGroupDialog || openEditGroupDialog}
        onClose={handleCloseGroupDialog}
        group={openEditGroupDialog ? gAPP_STORE.getGroupsStore().gridStore.selectedRow : undefined}
      />
      <DialogDeleteGroup
        open={openDeleteGroupDialog}
        onClose={handleCloseGroupDeleteDialog}
        group={gAPP_STORE.getGroupsStore().gridStore.selectedRow}
      />
    </Panel>
  );
};

export default observer(GroupsGrid);
