import { IEntityChangeSubscription, ISubscription } from './entity-change-events-service';

import { EntityChangeEventDeserialize } from './entity-change-events-factory';
import { EntityChangeEventTypes } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { IEntityChangeEvent } from '@/types/events';
import { IEntryWithCorrelationID, IEntryWithID } from '@/types/EntryWithId';

export type TEntitySubscriptionEvent<T, TEvent> = (orig: T, event: IEntityChangeEvent<TEvent>) => Promise<T | void>;
export type TEntitySubscriptionEventNotFound<TEvent> = (event: IEntityChangeEvent<TEvent>) => Promise<void>;

export class EntitySubscriptionIdManager<Tid, T extends IEntryWithID<Tid>, TEvent extends IEntryWithID<Tid>> {
  data: Map<Tid, T> = new Map([]);
  dataByCorrelationId: Map<string, T> = new Map([]);
  action: TEntitySubscriptionEvent<T, TEvent>;
  actionIfNone?: TEntitySubscriptionEventNotFound<TEvent>;
  params: IEntityChangeSubscription[];
  private subscriptions: ISubscription[] = [];
  private eventsQueue: MessageEvent<string>[] = [];
  private pollInterval?: NodeJS.Timeout;

  constructor(
    action: TEntitySubscriptionEvent<T, TEvent>,
    params: IEntityChangeSubscription[],
    actionIfNone?: TEntitySubscriptionEventNotFound<TEvent>,
  ) {
    this.action = action;
    this.params = params;
    this.actionIfNone = actionIfNone;
  }

  setData(data: T[]) {
    this.data = new Map(data.map(e => [e.id, e]));
    this.dataByCorrelationId = new Map([]);

    if (data.length > 0) {
      if ('correlationId' in data[0]) {
        this.dataByCorrelationId = new Map(
          data.map(e => [(e as unknown as IEntryWithCorrelationID<Tid>).correlationId, e]),
        );
      }
    }
  }
  private isLabelEvent(typename: string) {
    return (
      typename.includes(EntityChangeEventTypes.LABEL_SHORT) || typename.includes(EntityChangeEventTypes.LABEL_TYPE)
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getRecordIdAsTid(value: any): Tid {
    return (value.recordId ?? -1) as Tid;
  }
  async onEvent() {
    while (this.eventsQueue.length > 0) {
      const e = this.eventsQueue.shift();
      if (e) {
        const eventPayload = EntityChangeEventDeserialize(e) as IEntityChangeEvent<TEvent>;

        const id = this.isLabelEvent(e.type) ? this.getRecordIdAsTid(eventPayload.value) : eventPayload.value.id;
        let target = this.data.get(id);
        if (!target && 'correlationId' in eventPayload.value && this.dataByCorrelationId.size > 0) {
          target = this.dataByCorrelationId.get(
            (eventPayload.value as unknown as IEntryWithCorrelationID<Tid>).correlationId,
          );
        }
        if (target) {
          console.log('Run Action For ID: ', id);
          const res = await this.action(target, eventPayload);
          if (res) {
            this.data.set(id, res);
          }
        } else if (this.actionIfNone) {
          console.log('Run Action For UNKNOWN ID: ', id);
          await this.actionIfNone(eventPayload);
        }
      }
    }
  }

  async subscribe() {
    if (this.subscriptions.length > 0) {
      await this.unsubscribe();
    }
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
    this.pollInterval = setInterval(() => this.onEvent(), 500); //poll queue every 1/2 second

    for (let i = 0; i < this.params.length; i++) {
      const s = await gAPP_STORE.entityChangeEventService.subscribe(
        this.params[i],
        (e: MessageEvent<string>) => this.eventsQueue.push(e),
        true,
      );
      this.subscriptions.push(s);
    }

    await gAPP_STORE.entityChangeEventService.updateForce();
  }

  async unsubscribe() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
      this.pollInterval = undefined;
    }

    for (let i = 0; i < this.subscriptions.length; i++) {
      await this.subscriptions[i].unsubscribe(true);
    }

    this.subscriptions = [];
  }
}
