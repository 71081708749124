import { PLAYER_HEIGHT, TIMELINE_HEIGHT } from './Player.store';

import appColors from '@/app/app-colors';

export const waveFormProps = {
  height: PLAYER_HEIGHT - TIMELINE_HEIGHT,
  barWidth: 2,
  barGap: 1,
  barRadius: 2,
  cursorWidth: 2,

  responsive: true,
  backgroundColor: 'transparent',

  waveColor: appColors.white,
  progressColor: appColors.selectedItem,
  cursorColor: appColors.red,

  barMinHeight: 1,
  hideScrollbar: true,
  splitChannels: true,
};
