import { reaction } from 'mobx';
import { useEffect } from 'react';

import { IWordsData } from '..';

import { gNeedSaveChangesStore } from './needSaveChangesStore';

import { gAPP_STORE } from '@/app/app-store';

export const useNeedSaveChanges = (onChange?: (currentValue: IWordsData | undefined) => void) => {
  const recordTextStore = gAPP_STORE.getRecordTextStore();

  useEffect(() => {
    return reaction(
      () => recordTextStore.currentWordsData,
      () => {
        gNeedSaveChangesStore.setNeedSaveChanges(false);
        onChange && onChange(recordTextStore.currentWordsData);
      },
    );
  }, [onChange, recordTextStore]);

  return {
    needSaveChanges: gNeedSaveChangesStore.needSaveChanges,
    setNeedSaveChanges: (v: boolean) => gNeedSaveChangesStore.setNeedSaveChanges(v),
  };
};
