import { Box } from '@material-ui/core';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';

import { Z_INDEX_PLAYER } from 'common/constants';

import { Resizable, ResizeCallbackData } from 'react-resizable';
import './resizable-styles.css';

import appColors from '@/app/app-colors';

const DEFAULT_HEIGHT = 400;

interface IDrugState {
  activeDrags: number;
  deltaPosition: {
    x: number;
    y: number;
  };
  controlledPosition: {
    x: number;
    y: number;
  };
  size: {
    width: number;
    height: number;
  };
}

interface IDraggableResizableWindowProps {
  id: string;
  children: ReactNode[] | ReactNode;
  ratioCoeff?: number;
}

export const DraggableResizableWindow: FC<IDraggableResizableWindowProps> = props => {
  const { id, children, ratioCoeff = 16 / 9 } = props;

  const [state, setState] = useState<IDrugState | undefined>();
  const isResizing = useRef(false);
  const homeElement = document.getElementById('HomePage');

  useEffect(() => {
    const stateStr = localStorage.getItem(`DraggableResizableWindow[id = ${id}]`);
    if (stateStr) {
      const state: IDrugState = JSON.parse(stateStr);
      setState(state);
    } else {
      setState({
        activeDrags: 0,
        deltaPosition: {
          x: 0,
          y: 0,
        },
        controlledPosition: {
          x: -400,
          y: 200,
        },
        size: {
          height: DEFAULT_HEIGHT,
          width: DEFAULT_HEIGHT * ratioCoeff,
        },
      });
    }
  }, [id, ratioCoeff]);

  const changeState = (newState: IDrugState) => {
    setState(newState);
    localStorage.setItem(`DraggableResizableWindow[id = ${id}]`, JSON.stringify(newState));
  };

  const onStart = (e: DraggableEvent, data: DraggableData) => {
    if (isResizing.current) {
      return false;
    }
    const { x, y } = data;
    if (state) {
      changeState({ ...state, controlledPosition: { x, y }, activeDrags: ++state.activeDrags });
    }
  };

  const onStop = (e: DraggableEvent, data: DraggableData) => {
    const { x, y } = data;
    if (state) {
      changeState({ ...state, controlledPosition: { x, y }, activeDrags: --state.activeDrags });
    }
  };

  const parentWidth = homeElement?.offsetWidth ?? 400;
  const parentHeight = homeElement?.offsetHeight ?? 200;

  const dragProps = {
    onStart,
    onStop,
    bounds: {
      left: -50,
      right: parentWidth - 65 - (state?.size.width ?? 0),
      top: -45,
      bottom: parentHeight - 110 - (state?.size.height ?? 0)
    }
  };

  const onResizeStart = () => {
    isResizing.current = true;
  };
  const onResizeStop = () => {
    isResizing.current = false;
  };

  const onResize = (e: React.SyntheticEvent, data: ResizeCallbackData) => {
    const { size } = data;
    const height = size.height;
    const width = height * ratioCoeff;

    const prevWidth = state?.size.width ?? 0;
    const prevHeight = state?.size.height ?? 0;
    const currentX = state?.controlledPosition.x ?? 0;
    const currentY = state?.controlledPosition.y ?? 0;
    if (width > prevWidth && width + currentX > parentWidth - 65
      || height > prevHeight && height + currentY > parentHeight - 110) {
      return;
    }

    if (state) {
      changeState({ ...state, size: { width, height } });
    }
  };

  const resizeHandlers = { onResizeStart, onResizeStop, onResize };

  if (!state) {
    return null;
  }

  return (
    <Draggable {...dragProps}>
      <Box
        style={{
          position: 'absolute',
          background: appColors.primary,
          boxShadow: '0px 5px 25px rgba(0,0,0,0.8)',
          overflow: 'hidden',
          padding: '0.5em',
          float: 'left',
          zIndex: Z_INDEX_PLAYER,
          cursor: 'pointer',
          top: 100,
          left: 50,
          width: 'auto',
        }}
      >
        <Resizable height={state.size.height} width={state.size.width} minConstraints={[160, 90]} {...resizeHandlers}>
          <Box
            style={{ height: `${state.size.height}px`, width: `${state.size.width}px` }}
            display="flex"
            flexDirection="column"
          >
            {children}
          </Box>
        </Resizable>
      </Box>
    </Draggable>
  );
};
