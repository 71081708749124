/*
        Проработать компонент PlayerErrorMsg, добавить определение ошибки и оповещение по ошибкам.
    */

import React from 'react';
import { Typography, makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import appColors from '../../app/app-colors';

const useStyles = makeStyles({
  root: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: appColors.ligthGrey,
  },
});

const PlayerErrorMsg = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box p={4} className={classes.root}>
      <Typography variant="h6">{t('errorPlayer')}</Typography>
    </Box>
  );
};

export default PlayerErrorMsg;
