import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';

import SummaryHistoryItem from './SummaryHistoryItem';

import { gAPP_STORE } from '@/app/app-store';

//const disabledStyle = ;

interface ISummaryHistory {
  disabled: boolean;
}

export const SummaryHistory: React.FC<ISummaryHistory> = observer(({ disabled }) => {
  const summaryStore = gAPP_STORE.summaryStore;

  return (
    <Box data-autotest={'HistoryBox'} flexGrow={0} display="flex" flexDirection="column">
      {disabled && (
        <Box
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            backgroundColor: '#eceff1cc',
            opacity: 0.7,
          }}
        />
      )}
      <Box p={1} flexGrow={1} style={{ height: 0, overflowY: 'auto' }}>
        {summaryStore.history.map((_, index, arr) => (
          <SummaryHistoryItem
            key={index}
            historyIndex={index}
            isFirst={index === 0}
            isCurrent={index === summaryStore.currentHistory()}
            isLast={index === arr.length - 1}
            autotest={`historyItem-${index}`}
          />
        ))}
      </Box>
    </Box>
  );
});
