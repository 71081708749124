import React from 'react';
import { observer } from 'mobx-react';

import { Box } from '@material-ui/core';

import { IRecord, IHotlist } from './types';
import HotlistChip from './HotlistChip';

import { dialogUploadRecordsRestrictions } from '@/common/constants';

const RecordHotlists = ({ record }: { record: IRecord }) => {
  if (!Array.isArray(record.hotLists)) {
    return <></>;
  }

  return (
    <Box display="flex" flexWrap="wrap">
      {record.hotLists.map((hotlist: IHotlist, index) => (
        <Box key={index} m={0.5}>
          <HotlistChip
            hotlist={hotlist}
            index={index}
            maxLength={dialogUploadRecordsRestrictions.MAX_VISIBLE_HOTLIST_LENGTH}
          />
        </Box>
      ))}
    </Box>
  );
};

export default observer(RecordHotlists);
