import { makeAutoObservable } from 'mobx';

export class CommonDialogUiStore {
  isOkEnabled: boolean;
  isOpened: boolean;
  isPending: boolean;

  constructor() {
    this.isOkEnabled = true;
    this.isOpened = false;
    this.isPending = false;

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setOkEnabled(value: boolean) {
    this.isOkEnabled = value;
  }

  setOpen(value: boolean) {
    this.isOpened = value;
  }

  setPending(value: boolean){
    this.isPending = value;
  }
}
