export enum EStoreStatus {
  EMPTY = 'empty',
  LOADING = 'loading',
  SUCCESS = 'sucess',
  ERROR = 'error',
}

export enum EStatus {
  empty = 'empty',
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export type TStatus = keyof typeof EStatus;
