import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';

import { FilterCommon } from '../common/common-filter';
import { IFilterData, TFilterDataFields } from '../filter/i-filter';
import { IFilterField, TFilterFieldValue, TFilterPredicate } from '../filter/i-filter-field';
import { IFilterFieldListItem, strListToFilterFieldList } from '../filter/filter-field-list';

import { EEventType, EventTypeMap, topicTypes } from './types';
import {
  enumEventsToFilterFieldList,
  enumEventTypeToFilterFieldList,
  enumProcessingMethodsToFilterFieldList
} from './event-log-filter-utils';
import {
  convertFilterFieldToData,
  evenLogDefaultFilterDescriptor,
  getFilterLinkedEventLog
} from './event-log-filter-descriptor';

import { gAPP_STORE } from '@/app/app-store';
import { eventLogs } from '@/common/constants';


const EventLogFilter = observer(() => {
  const [filterFields, setFilterFields] = useState<IFilterField[]>(evenLogDefaultFilterDescriptor);
  const [eventTypus, setEventTypus] = useState<EEventType | undefined>(undefined);

  const resetfilter = useCallback(() => {
    gAPP_STORE.getEventLogStore().setFilterData(convertFilterFieldToData(evenLogDefaultFilterDescriptor));
    setFilterFields(evenLogDefaultFilterDescriptor);
  }, []);

  const setFilterData = useCallback(f => {
    gAPP_STORE.getEventLogStore().setFilterData(f());
  }, []);

  const applyFilter = useCallback(() => {
    gAPP_STORE.getEventLogStore().reload();
  }, []);

  useEffect(() => {
    resetfilter();

    return () => {
      resetfilter();
      applyFilter();
    };
  }, [applyFilter, resetfilter]);

  useEffect(() => {
    const newfilterData = convertFilterFieldToData([...filterFields]);
    const store = gAPP_STORE.getEventLogStore();
    for (const filter in newfilterData) {
      newfilterData[filter as keyof IFilterData] =
        store.filterData[filter as keyof IFilterData] ?? newfilterData[filter as keyof IFilterData];
    }
    store.setFilterData(newfilterData);
  }, [filterFields]);
  const { t } = useTranslation();

  const handleFilterPredicateChange = useCallback(
    (id: string, value: TFilterPredicate) => {
      setFilterData(() => {
        const newFilterData = JSON.parse(JSON.stringify(gAPP_STORE.getEventLogStore().filterData));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newFilterDataField: any = newFilterData[id as TFilterDataFields]
          ? newFilterData[id as TFilterDataFields]
          : {};

        newFilterDataField.predicate = value;

        return newFilterData;
      });
    },
    [setFilterData],
  );

  const getUser = useCallback(() => {
    return gAPP_STORE.getUsersStore()
      .data.users.map(u => ({
        id: u.id.toString(),
        title: `${u.email}`
      }));
  }, []);

  const handleFilterApply = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const handleFilterClear = useCallback(() => {
    resetfilter();
    applyFilter();
  }, [applyFilter, resetfilter]);

  const eventTypes = useMemo(() => enumEventTypeToFilterFieldList('eventType'), []);
  const processingMethods = useMemo(() => enumProcessingMethodsToFilterFieldList(), []);

  const actionTypes = useMemo(() => enumEventsToFilterFieldList(
      gAPP_STORE.getEventLogStore().getEventListByTypus(eventTypus), 'actionType')
    , [eventTypus]);

  const getFilterFieldList = useCallback(
    (descriptor: IFilterField): IFilterFieldListItem[] => {
      let result: IFilterFieldListItem[] = [];

      switch (descriptor.id) {
        case 'actionType':
          result = actionTypes;
          break;

        case 'eventType':
          result = eventTypes;
          break;

        case 'processing_method':
          result = processingMethods;
          break;

        case 'userId':
          result = getUser();
          break;

        case 'format':
          result = strListToFilterFieldList(['pdf', 'docx']);
          break;

        case 'topic_type':
          result = strListToFilterFieldList(topicTypes);
          break;
      }

      return result;
    },
    [actionTypes, eventTypes, getUser],
  );

  const handleFilterChange = useCallback((id: string, value: TFilterFieldValue | null | undefined, score?: number) => {
    setFilterData(() => {
      const newFilterData: IFilterData = JSON.parse(JSON.stringify(gAPP_STORE.getEventLogStore().filterData));
      if ((value === undefined) && ['loadFrom', 'loadTo'].includes('id')) {
        switch (id) {
          case 'loadFrom':
            newFilterData.loadFrom = undefined;
            break;
          case 'loadTo':
            newFilterData.loadTo = undefined;
            break;
        }

        return newFilterData;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newFilterDataField: any = newFilterData[id as TFilterDataFields]
        ? newFilterData[id as TFilterDataFields]
        : {};

      if (Array.isArray(value)) {
        let goodvalues: string[];
        switch (id) {
          case 'eventType':
            goodvalues = Array.from(EventTypeMap.values());
            break;
          case 'userId':
            goodvalues = getUser().map(item => item.id);
            break;
          case 'actionType':
            if (value.length > eventLogs.MAX_ACTIONS) {
              toast.warn(t('actionTypeMaxItems', { actionTypeMaxItems: eventLogs.MAX_ACTIONS }));
            }
            goodvalues = value.slice(0, eventLogs.MAX_ACTIONS);
            break;
          default:
            goodvalues = value;
        }
        newFilterDataField.value = value.filter(v => goodvalues.includes(v));
      } else {
        newFilterDataField.value = value;
      }

      newFilterDataField.score = score;
      if (id === 'eventType') {
        if (value === undefined) {
          setEventTypus(undefined);
        } else if ((value as string[]).length !== 1) {
          setEventTypus(undefined);
        } else setEventTypus((value as string[])[0] === 'error' ? EEventType.error : EEventType.action);
      }

      if (id === 'actionType' && newFilterData.actionType && newFilterData.actionType.value) {
        const paramList = gAPP_STORE.getEventLogStore().getParametres(newFilterData.actionType?.value as string[]);
        const newFilterFileds = [...evenLogDefaultFilterDescriptor, ...getFilterLinkedEventLog(paramList)];

        setFilterFields(newFilterFileds);
      }

      return newFilterData;
    });
  }, [getUser, setFilterData, t]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        applyFilter();
      }
    },
    [applyFilter],
  );

  const getNoTranslateValue = useCallback((descriptor: IFilterField): boolean | undefined => {
    let result = undefined;
    if (descriptor.id === 'dictors' || descriptor.id === 'topic') {
      result = true;
    }

    return result;
  }, []);

  return (
    <>
      <FilterCommon
        getFilterFieldList={getFilterFieldList}
        filterFields={filterFields}
        handleFilterChange={handleFilterChange}
        handleFilterPredicateChange={handleFilterPredicateChange}
        handleKeyDown={handleKeyDown}
        getNoTranslateValue={getNoTranslateValue}
        filterData={gAPP_STORE.getEventLogStore().filterData}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleFilterApply={handleFilterApply}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleFilterClear={handleFilterClear}
      />
    </>
  );
});

export default EventLogFilter;
