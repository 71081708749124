import { makeAutoObservable } from 'mobx';
import { EDataGridStoreSortMode, IDataGridColBase } from '@uk';
import { IColumnLayout } from './DataGridSettings';

const DEFAULT_ROWS_ON_PAGE = 20;

interface ISettings {
  colsLayout: IColumnLayout[];
  showCards: boolean;
  rowsOnPage: number;
}

export class DataGridSettingsStore {
  colsLayout: IColumnLayout[];
  showCards: boolean = true;
  noUserSettings: boolean = false;
  rowsOnPage: number = DEFAULT_ROWS_ON_PAGE;
  defaultHiddenColIds: string[] = [];

  constructor(public dataGridId: string, public cols: IDataGridColBase[]) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.colsLayout = this.getDefaultColsLayout();
    this.readFromLocalStorage();
  }

  setDataGridId(id: string) {
    this.dataGridId = id;
  }

  setCols(cols: IDataGridColBase[]) {
    this.cols = cols;
    this.colsLayout = this.getDefaultColsLayout();
    this.readFromLocalStorage();
  }

  setDefaultHiddenColIds(colIds: string[]) {
    this.defaultHiddenColIds = colIds;
    this.colsLayout = this.getDefaultColsLayout();
    this.readFromLocalStorage();
  }

  getDefaultColsLayout(): IColumnLayout[] {
    return this.cols
      .filter(col => !col.hideInGrid)
      .map(column => {
        const visible = !column.hideInGrid && (this.defaultHiddenColIds.length === 0 || this.defaultHiddenColIds.includes(column.id ?? ''));
        return {
          column,
          visible,
          width: undefined,
          sort: EDataGridStoreSortMode.NONE,
        };
      });
  }

  reset() {
    this.colsLayout = this.getDefaultColsLayout();
    this.rowsOnPage = DEFAULT_ROWS_ON_PAGE;

    this.saveToLocalStorage();
  }

  readFromLocalStorage() {
    if (this.noUserSettings) {
      return;
    }

    const settings: ISettings = JSON.parse(localStorage.getItem(`DataGrid_${this.dataGridId}_settings`) || '{}');
    if (!settings) {
      return;
    }

    // cols
    if (Array.isArray(settings.colsLayout)) {
      const checkBoxColLayout = this.colsLayout.find(cl => cl.column.name === '');
      this.colsLayout = checkBoxColLayout ? [checkBoxColLayout] : [];
      settings.colsLayout.forEach((cl: IColumnLayout) => {
        const col = this.cols.find(c => c.name === cl.column.name);

        if (col && col.name !== '') {
          this.colsLayout.push({
            column: col,
            visible: cl.visible,
            width: cl.width,
            sort: cl.sort,
          });
        }
      });
    }

    // cards mode
    if (settings.showCards !== undefined) {
      this.showCards = settings.showCards;
    }

    // items on page
    if (settings.rowsOnPage) {
      this.rowsOnPage = settings.rowsOnPage;
    }
  }

  saveToLocalStorage() {
    if (this.noUserSettings) {
      return;
    }
    if (this.colsLayout.length > 0) {
      const settings: ISettings = {
        colsLayout: this.colsLayout,
        showCards: this.showCards,
        rowsOnPage: this.rowsOnPage,
      };
      localStorage.setItem(`DataGrid_${this.dataGridId}_settings`, JSON.stringify(settings));
    }
  }

  get colsWithLayout(): IColumnLayout[] {
    return this.colsLayout.filter(cl => cl.visible);
  }

  setColumnVisible(columnLayout: IColumnLayout, visible: boolean) {
    columnLayout.visible = visible;
    this.colsLayout = [...this.colsLayout];
    this.saveToLocalStorage();
  }

  reorderColumns(columnsLayout: IColumnLayout[]) {
    this.colsLayout = columnsLayout;
    this.saveToLocalStorage();
  }

  moveColumnUp(columnLayout: IColumnLayout) {
    const index = this.colsLayout.indexOf(columnLayout) - 1;
    if (index > -1) {
      this.colsLayout = this.colsLayout.filter(cl => cl !== columnLayout);
      this.colsLayout.splice(index, 0, columnLayout);
      this.saveToLocalStorage();
    }
  }

  moveColumnDown(columnLayout: IColumnLayout) {
    const index = this.colsLayout.indexOf(columnLayout) + 1;
    if (index < this.colsLayout.length) {
      this.colsLayout = this.colsLayout.filter(cl => cl !== columnLayout);
      this.colsLayout.splice(index, 0, columnLayout);
      this.saveToLocalStorage();
    }
  }

  // Show cards
  setShowCards(show: boolean) {
    this.showCards = show;
    this.saveToLocalStorage();
  }

  setNoUserSettings(value: boolean) {
    this.noUserSettings = value;
  }

  setRowsOnPage(v: number) {
    this.rowsOnPage = v;
    this.saveToLocalStorage();
  }

  setSortedColumn(columnLayout: IColumnLayout) {
    const prevColumnSort = columnLayout.sort;
    this.colsLayout.forEach(cl => (cl.sort = EDataGridStoreSortMode.NONE));

    switch (prevColumnSort) {
      case EDataGridStoreSortMode.NONE:
        columnLayout.sort = EDataGridStoreSortMode.ASC;
        break;
      case EDataGridStoreSortMode.ASC:
        columnLayout.sort = EDataGridStoreSortMode.DSC;
        break;
      default:
        columnLayout.sort = EDataGridStoreSortMode.NONE;
    }
    this.saveToLocalStorage();
  }

  getSortedColumn(): IColumnLayout | undefined {
    return this.colsLayout.find(cl => cl.sort !== EDataGridStoreSortMode.NONE);
  }

  get needSettings(): boolean {
    return !this.noUserSettings && this.cols.filter(col => !col.hideInGrid && !col.hasCheck).length > 1;
  }
}
