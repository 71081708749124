import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';

import { IRecord, ILabel } from './types';
import { LabelChip } from './dialogs/Labels/LabelChip';

const RecordLabels = ({ record }: { record: IRecord }) => {
  if (!Array.isArray(record.labelLists)) {
    return <></>;
  }

  return (
    <Box display="flex" flexWrap="wrap">
      {record.labelLists.map((label: ILabel, index) => (
        <Box key={index} m={0.5}>
          <LabelChip label={label} />
        </Box>
      ))}
    </Box>
  );
};

export default observer(RecordLabels);
