import { Box, makeStyles, Tooltip } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import { IRecord } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { summaryService } from '@/services/summary/summary-service';

interface ISummaryTopicCountLabel {
  count: number;
  record: IRecord;
}

const useStyles = makeStyles(() => ({
  tooltipContent: {
    gap: '4px',
  },
  divStyle: {
    height: '19px',
    padding: '1px 4px',
    border: '2px solid rgba(17, 25, 39, 1)',
    borderRadius: '4px',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
  },
  tooltipContentRtl1: {
    direction: 'rtl',
  },
}));

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(17, 25, 39, 1)',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.24)',
    borderRadius: '4px',
    padding: '8px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    lineHeight: '18.75px',
  },
}))(Tooltip);

export type TipsCacheType = { [recordId: number]: boolean };

const SummaryTopicCountLabel: React.FC<ISummaryTopicCountLabel> = ({ count, record }) => {
  const { t } = useTranslation();
  const recordLanguage = record.languageResolved;
  const isRTL = gAPP_STORE.isRtlLanguage(recordLanguage);

  const styles = useStyles();
  const recordStore = gAPP_STORE.getRecordsStore();
  const [topicsLoaded, setTopicsLoaded] = useState(recordStore.mapSummaryTopic.has(record.id));

  const hOpen = useCallback(() => {
    const getSummaryTopics = async () => {
      try {
        const summaryDtoRevisions = await summaryService.getBrief(record.id);
        const lastRevision = summaryDtoRevisions.length - 1;
        if (lastRevision < 0) {
          return;
        }

        const topicsDto = await summaryService.getTopics(summaryDtoRevisions[lastRevision].id);

        recordStore.mapSummaryTopic.set(record.id, topicsDto);
        setTopicsLoaded(true);
      } catch (error) {
        console.log('from summaryService ', { recordId: record.id, error });
      }
    };

    if (!topicsLoaded) {
      void getSummaryTopics();
    }
  }, [record.id, recordStore.mapSummaryTopic, topicsLoaded]);

  const title = (tmp?: string[] | string | undefined) => {
    if (Array.isArray(tmp)) {
      return tmp.map((v, index, array) => (
        <li key={`topicsItem-${index}`}>
          {v}
          {index === array.length - 1 ? '.' : isRTL ? <span>&#8271;</span> : ';'}
        </li>
      ));
    } else {
      return tmp;
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        {count === 0 ? (
          <div className={styles.divStyle}>{'0'}</div>
        ) : (
          <StyledTooltip
            onOpen={() => hOpen()}
            onClose={() => setTopicsLoaded(false)}
            title={
              topicsLoaded ? (
                <div className={clsx(styles.tooltipContent, { [styles.tooltipContentRtl1]: isRTL })}>
                  {title(recordStore.getTopicList(record.id))}
                </div>
              ) : (
                t('summary.topicLoading')
              )
            }
          >
            <div className={styles.divStyle}>{count}</div>
          </StyledTooltip>
        )}
      </Box>
    </>
  );
};

export default SummaryTopicCountLabel;
