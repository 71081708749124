import moment from 'moment';
import Docxtemplater from 'docxtemplater';

import { dstr2str } from '@/common/utils';
import { ExportFileExtension } from '@/types/common';
import { IDictor } from '@/types/dictors';
import {
  IExportContent,
  IExportPhrases,
  IExportSummary,
  IPhrase,
  IRecord,
  ITemplateFields,
  ITemplatePhrase,
  ITemplateSpeaker,
  IWord,
  IWordsData,
} from '@/components/records/types';
import { getPhraseWordText, wordsToPhrases } from '@/components/records/record-text-utils';
import { dictorTitleWithDefaultName } from '@/components/dictors/dictor-utils';

export interface IExportReport {
  doc: Docxtemplater;
  record: IRecord;
  summaryToExport: IExportSummary;
  speakers: IDictor[];
  defaultSpeaker: string;
  transcription?: IWordsData;
  translation?: IWordsData;
  translationAuto?: IWordsData;
  isAutoReport: boolean;
  isResolvedRtlLanguage: boolean;
  isTranslateRtlLanguage: boolean;
  showTime: boolean;
  phrasePauseSec: number;
  sentencePause: number | undefined;
  userName: string;
  filename: string;
  fileExtension: ExportFileExtension | undefined;
  fnSave: (blob: Blob, fileName: string, extension?: ExportFileExtension) => void;
}

export const getDictorWordCount = (speaker: IDictor, wordData: IWordsData) => {
  let wordCount = 0;
  wordData.words.forEach(c => {
    if (c.channel === speaker.channel) {
      c.words.forEach(w => {
        if (w.dictor_num === speaker.dictorNum) {
          wordCount += 1;
        }
      });
    }
  });

  return wordCount;
};

const prepareSpeakers = (dictors: IDictor[], defaultDictor: string, wordData?: IWordsData) => {
  const speakers = [...dictors];
  speakers.sort((a, b) => a.id - b.id);
  speakers.forEach((dictor, index) => {
    dictor.__title = dictorTitleWithDefaultName(dictor, index, defaultDictor);
    if (wordData) {
      dictor.wordsCount = getDictorWordCount(dictor, wordData);
    }
  });
  speakers.sort((a, b) => b.wordsCount - a.wordsCount);

  const templateSpeakers: ITemplateSpeaker[] = speakers.map((speaker, index) => {
    const updName = speaker.__title
      ? speaker.surname && !speaker.__title.includes(speaker.surname)
        ? `${speaker.__title} ${speaker.surname}`
        : speaker.__title
      : '';

    speakers[index].__title = updName;

    return {
      name: updName,
      wordsCount: speaker.wordsCount,
      speechDuration: moment.duration(speaker.speechDuration, 'seconds').format('hh:mm:ss', { trim: false }),
    };
  });

  return { speakers, templateSpeakers };
};

const getPharasedToExport = (
  phrasePauseSec: number,
  sentencePause: number | undefined,
  speakers: IDictor[],
  transcription?: IWordsData,
  translation?: IWordsData,
  translationAuto?: IWordsData,
): IExportPhrases => {
  // Строим фразы из диалогов
  const transcriptPhrases: IPhrase[] = wordsToPhrases(transcription, phrasePauseSec, sentencePause, speakers);
  const translatePhrases: IPhrase[] = wordsToPhrases(translation, phrasePauseSec, sentencePause, speakers);
  const translateAutoPhrases: IPhrase[] = wordsToPhrases(translationAuto, phrasePauseSec, sentencePause, speakers);

  return { transcriptPhrases, translatePhrases, translateAutoPhrases };
};

const _shielding = (words: string[], b: string, e: string): string => {
  const ww = [...words];
  const len = ww.length;

  ww.forEach((word, k) => {
    if (k === len - 1) {
      ww[k] = word === '\n' ? `${e}\n` : `${word}${e}`;
    } else {
      if (word === '\n') {
        ww[k] = `${e}\n${b}`;
      } else {
        ww[k] = `${word.replace(/[)(\][}{]/g, match => `\u202e\u200F${match}\u202c`)} `; //ATB::ara-ok
      }
    }
  });

  return `${b}${ww.join('')}`;
};

export const sheldingPhrase = (words: string[]) => {
  //EMBEDDING GOOD внешний(слов в строке) и внутренний(символов в словах) порядок (если нет пробелов в лат. словах)
  const RTL_EMBEDDING = '\u202b';
  const EMBEDDING_END = '\u202c'; // 'POP DIRECTIONAL FORMATING'

  return _shielding(words, RTL_EMBEDDING, EMBEDDING_END);
};

const createFileContent = (recordPhrases: IPhrase[], isRtl: boolean, showTime: boolean): ITemplatePhrase[] => {
  return recordPhrases.map(phrase => {
    const speaker = `${phrase.dictor && phrase.dictor.__title ? phrase.dictor.__title : ''}`;

    const time = showTime
      ? moment.duration(phrase.tokens.at(0)?.begin, 'seconds').format('hh:mm:ss', { trim: false })
      : '';

    const speech = isRtl
      ? sheldingPhrase(phrase.tokens.map((t: IWord) => getPhraseWordText(t)))
      : phrase.tokens.map((t: IWord) => getPhraseWordText(t)).join(' ');

    return {
      speaker,
      time,
      phrase: speech,
    };
  });
};

const getContentToExport = (
  phrasesToExport: IExportPhrases,
  isRTLTranscript: boolean,
  isRTLTranslate: boolean,
  showTime: boolean,
): IExportContent => {
  const transcriptContent = phrasesToExport.transcriptPhrases
    ? createFileContent(phrasesToExport.transcriptPhrases, isRTLTranscript, showTime)
    : [];
  const translateAutoContent = phrasesToExport.translateAutoPhrases
    ? createFileContent(phrasesToExport.translateAutoPhrases, isRTLTranslate, showTime)
    : [];
  const translateContent = phrasesToExport.translatePhrases
    ? createFileContent(phrasesToExport.translatePhrases, isRTLTranslate, showTime)
    : [];

  return { transcriptContent, translateContent, translateAutoContent };
};

const getDocxData = (
  createdBy: string,
  record: IRecord,
  templateSpeakers: ITemplateSpeaker[],
  contentToExport: IExportContent,
  summaryToExport: IExportSummary,
): ITemplateFields => {
  return {
    createdBy,
    createdDate: new Date().toDateString(),
    meetingTitle: record.caseid.toString(),
    meetingDate: dstr2str(record.createdDate),
    fileName: record.fileName,
    loadDate: dstr2str(record.loadDate),
    transcriptionLanguage: record.languageResolved, //t(`lang.${record.languageResolved}`),
    speakersCount: templateSpeakers.length ?? 0,
    speakers: templateSpeakers,
    transcription: contentToExport.transcriptContent,
    translation: contentToExport.translateContent,
    autoTranslation: contentToExport.translateAutoContent,
    chartNumberOfWords: 'chartNumberOfWords',
    chartDuration: 'chartDuration',
    //-~-~-результаты суммаризации-~-~-
    summaryTitle: summaryToExport.title,
    summaryDate: dstr2str(summaryToExport.date),
    summaryDescription: summaryToExport.description,
    summarySpeakers: summaryToExport.summarySpeakers,
    summaryMentions: summaryToExport.summaryMentions,
    summaryKeyDecisions: summaryToExport.keyDecisions,
    summaryAmbiguities: summaryToExport.ambiguities,
    summaryTopics: summaryToExport.summaryTopics,
  };
};

const saveReportToFile = (
  doc: Docxtemplater,
  docData: ITemplateFields,
  fileName: string,
  fnSave: (blob: Blob, fileName: string, ext?: ExportFileExtension) => void,
  ext?: ExportFileExtension,
) => {
  doc.resolveData(docData).then(() => {
    doc.render();
    const outBlob: Blob = doc.getZip().generate({
      type: 'uint8array',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    fnSave(outBlob, fileName, ext);
  });
};

export const createExportReport = (props: IExportReport) => {
  const { speakers, templateSpeakers } = prepareSpeakers(
    props.speakers,
    props.defaultSpeaker,
    props.isAutoReport ? props.transcription : undefined,
  );

  const exportPhrases = getPharasedToExport(
    props.phrasePauseSec,
    props.sentencePause,
    speakers,
    props.transcription,
    props.translation,
    props.translationAuto,
  );

  // Строим содержимое отчета
  const contentToExport = getContentToExport(
    exportPhrases,
    props.isResolvedRtlLanguage,
    props.isTranslateRtlLanguage,
    props.showTime,
  );

  const docData = getDocxData(props.userName, props.record, templateSpeakers, contentToExport, props.summaryToExport);

  saveReportToFile(props.doc, docData, props.filename, props.fnSave, props.fileExtension);
};
