import { action, computed, makeObservable, observable, transaction } from 'mobx';
import Docxtemplater from 'docxtemplater';
import { toast } from 'react-toastify';

import { gAPP_STORE } from 'app/app-store';
import { EStoreStatus } from 'common/store-status';
import { BackendService } from 'services';

import { EDirection, ESpecialLanguageCode } from '../languages/i-language';
import { UNKNOWN_SPEAKER } from '../dictors/const';

import { IFilterPhrase } from '../filter/types';

import { correctIm, isRtlLanguage, sortHistoryWordsData, wordsToSentences } from './record-text-utils';
import { createExportReport } from './docxUtils';
import { IChannelWords, IDocxData, IPhrase, IRecord, IToFileDialogResult, IWord, IWordsData } from './types';

import { exportFileService } from '@/services/export-file-service';
import { ExportFileExtension } from '@/types/common';
import { IDictor } from '@/types/dictors';
import i18n from '@/i18n';

interface ITextStoreState {
  isTranslation: boolean;
}

export class RecordTextStore {
  // выбранная запись
  record?: IRecord;
  originRecord?: IRecord;
  // статус загрузки данных
  status: EStoreStatus = EStoreStatus.EMPTY;
  // статус отправки запроса на переперевод
  retranslationStatus: EStoreStatus = EStoreStatus.EMPTY;
  // флаг - что показываем: транскрипцию или перевод
  isTranslation = false;
  // все ревизии правок транскрипции или перевода, зависит от флага isTranslation
  historyWordsData: IWordsData[] = [];
  phrasePauseSec = 0;
  sentencePauseSec = 1;
  useSentencePauseSelector = true;
  wordProbability = 30;
  // Выбранная в истории ревизия транскрипции
  selectedTranscriptionId?: number = undefined;
  // Выбранная в истории ревизия перевода
  selectedTranslationId?: number = undefined;
  editedPhraseIndex?: number = undefined;

  constructor() {
    makeObservable(this, {
      record: observable,
      originRecord: observable,
      status: observable,
      retranslationStatus: observable,
      historyWordsData: observable,
      phrasePauseSec: observable,
      sentencePauseSec: observable,
      useSentencePauseSelector: observable,
      wordProbability: observable,
      isTranslation: observable,
      selectedTranscriptionId: observable,
      selectedTranslationId: observable,
      editedPhraseIndex: observable,

      canEditCurrentText: computed,
      currentHistoryId: computed,
      currentWordsData: computed,
      currentTextDirection: computed,

      setRecord: action,
      setStatus: action,
      setRetranslationStatus: action,
      setPhrasePauseSec: action,
      setSentencePauseSec: action,
      setSentencePauseSelector: action,
      setWordProbability: action,
      setTranslation: action,
      setHistoryWordsData: action,
      setSelectedTranscriptionId: action,
      setSelectedTranslationId: action,
      setEditedPhraseIndex: action,
    });
    this.restoreState();
  }

  private saveState = () => {
    const state: ITextStoreState = {
      isTranslation: this.isTranslation,
    };

    localStorage.setItem(`${gAPP_STORE.loginStore.user?.id}_TextStoreState`, JSON.stringify(state));
  };

  setEditedPhraseIndex(index?: number) {
    this.editedPhraseIndex = undefined;
    if (this.canEditCurrentText) {
      this.editedPhraseIndex = index;
    }
  }

  restoreState = () => {
    const stateStr = localStorage.getItem(`${gAPP_STORE.loginStore.user?.id}_TextStoreState`) || '{}';
    const state: ITextStoreState = JSON.parse(stateStr);

    this.setTranslation(state.isTranslation && gAPP_STORE.viewTranslate ? state.isTranslation : false);
  };

  changeTranslationMode = async (mode: boolean) => {
    this.setTranslation(mode);
    this.saveState();

    await this.loadRecordHistory();

    if (!mode) {
      //clear translation selection when switch to original view
      this.setSelectedTranslationId(undefined);
    }
  };

  /**
   * Загрузить транскрипции или переводы
   */
  loadRecordHistory = async () => {
    if (!this.record) {
      return;
    }

    try {
      this.setStatus(EStoreStatus.LOADING);

      const body = {
        correlationId: this.record.correlationId,
        limit: 1000,
        offset: 0,
        sortOrder: 'Asc',
      };
      const historyData = await BackendService.post(
        `text/${this.isTranslation && gAPP_STORE.viewTranslate ? 'translate/' : ''}history`,
        JSON.stringify(body),
      );

      const sortedHistory: IWordsData[] = sortHistoryWordsData(historyData || []);
      this.setHistoryWordsData(sortedHistory);
      const histLength = sortedHistory.length;

      let currentWordsData: IWordsData | undefined;
      if (this.isTranslation) {
        if (this.selectedTranslationId) {
          currentWordsData = sortedHistory.find(d => d.id === this.selectedTranslationId);
        } else {
          currentWordsData = histLength > 0 ? sortedHistory[histLength - 1] : undefined;
        }
        this.setSelectedTranslationId(currentWordsData ? currentWordsData.id : undefined);
      } else {
        if (this.selectedTranscriptionId) {
          currentWordsData = sortedHistory.find(d => d.id === this.selectedTranscriptionId);
        } else {
          currentWordsData = histLength > 0 ? sortedHistory[histLength - 1] : undefined;
        }
        this.setSelectedTranscriptionId(currentWordsData ? currentWordsData.id : undefined);
      }

      this.setStatus(EStoreStatus.SUCCESS);
    } catch (error) {
      console.error('TextStore, setRecord(), error = ', error);
      this.clear(EStoreStatus.ERROR);
    }
  };

  /**
   *
   * @param doc Шаблон результурующего документа
   * @param record выбранная запсь
   * @param dialogResult параметры сохранения отчета
   * @param fileExtension формат сохранения отчета
   */
  createExportFile = async (
    doc: Docxtemplater,
    record: IRecord,
    dialogResult: IToFileDialogResult,
    fileExtension: ExportFileExtension,
  ): Promise<void> => {
    //fix 9394
    const dictorsStore = gAPP_STORE.getDictorsStore();
    await dictorsStore.reloadRecordDictors(record);
    const { transcription, translation, translationAuto } = await this.getWordsToExport(record, true);
    const speakers = record ? dictorsStore.getDictorsWithSegmentationForRecord(record.correlationId) : [];
    const defaultSpeaker = gAPP_STORE.unknownDictorInReport ?? i18n.t(UNKNOWN_SPEAKER) ?? '';
    const isResolvedRtlLanguage = isRtlLanguage(record.languageResolved);
    const isTranslateRtlLanguage = isRtlLanguage(record.translateLanguage);
    const summaryToExport = await gAPP_STORE.summaryStore.prepareForReport(
      record,
      speakers,
      defaultSpeaker,
      isResolvedRtlLanguage,
      isTranslateRtlLanguage,
    );
    console.log('@@ got summary ');

    createExportReport({
      doc,
      record,
      summaryToExport,
      speakers,
      defaultSpeaker,
      transcription,
      translation,
      translationAuto,
      isAutoReport: false,
      isResolvedRtlLanguage: isRtlLanguage(record.languageResolved),
      isTranslateRtlLanguage: isRtlLanguage(record.translateLanguage),
      showTime: dialogResult.showTime,
      phrasePauseSec: this.phrasePauseSec,
      sentencePause: gAPP_STORE.getRecordTextStore().useSentencePauseSelector ? this.sentencePauseSec : undefined,
      userName: gAPP_STORE.loginStore.user?.firstName || gAPP_STORE.translationStore.translate('Unknown user'),
      filename: dialogResult.filename,
      fileExtension,
      fnSave: (blob: Blob, fileName: string, extension?: ExportFileExtension) =>
        exportFileService.saveAs({ blob, fileName, extension: extension ?? 'pdf' }),
    });
  };

  clear = (status = EStoreStatus.EMPTY) => {
    this.setHistoryWordsData([]);
    this.setStatus(status);
    this.setRetranslationStatus(status);
    this.setSelectedTranslationId(undefined);
    this.setEditedPhraseIndex(undefined);
    this.setSelectedTranscriptionId(undefined);
    this.setRecord(undefined);
  };

  update = async (channelWords: IChannelWords[] | undefined, dictors?: IDictor[]) => {
    try {
      if (this.record && channelWords) {
        const body = {
          correlationId: this.record.correlationId,
          words: channelWords,
        };

        const data = await BackendService.post(
          `text/${this.isTranslation && gAPP_STORE.viewTranslate ? 'translate/' : ''}update`,
          JSON.stringify(body),
        );
        if (dictors) {
          const dictorsEntryList = dictors.map(dictor => {
            return {
              ...dictor,
              metadata: JSON.stringify(dictor.metadata),
              segmentation:
                dictor.segmentation && dictor.segmentation.ranges.length > 0
                  ? JSON.stringify(dictor.segmentation)
                  : null,
            };
          });

          const body = {
            recordId: this.record.id,
            dictorsEntryList,
          };
          await BackendService.post('text/segmentation/update', JSON.stringify(body));
        }

        if (data?.id) {
          if (this.isTranslation) {
            this.record.translationId = data.id;
            await this.setRecord(this.record, this.selectedTranscriptionId, data.id);
          } else {
            this.record.transcriptionId = data.id;
            await this.setRecord(this.record, data.id, this.selectedTranslationId);
            await gAPP_STORE.getDictorsStore().reloadRecordDictors(this.record);
          }
        }
      }
    } catch (error) {
      this.setStatus(EStoreStatus.ERROR);
    }
  };

  retranslate = () => {
    if (this.record) {
      const body = {
        recordId: this.record.id,
      };
      this.setRetranslationStatus(EStoreStatus.LOADING);
      BackendService.post('text/retranslation', JSON.stringify(body))
        .then(() => {
          this.setRetranslationStatus(EStoreStatus.SUCCESS);
          toast.info(gAPP_STORE.translationStore.translate('records.retranslateSuccess'));
          if (!this.isTranslation) {
            this.resetSelectionId();
          }
        })
        .catch(() => {
          this.setRetranslationStatus(EStoreStatus.ERROR);
          toast.error(gAPP_STORE.translationStore.translate('records.retranslateError'));
        });
    }
  };

  selectHistoryItem = (id: number) => {
    const selectedData = this.getHistoryWordsDataById(id);
    if (this.isTranslation) {
      this.setSelectedTranslationId(selectedData?.id || undefined);
    } else {
      this.setSelectedTranscriptionId(selectedData?.id || undefined);
    }
  };

  getHistoryWordsDataById = (id: number | undefined): IWordsData | undefined => {
    return this.historyWordsData.find(d => d.id === id);
  };

  async setRecord(
    value: IRecord | undefined,
    transcriptionId: number | undefined = undefined,
    translationId: number | undefined = undefined,
  ) {
    if (this.originRecord === undefined || value?.id !== this.originRecord?.id) {
      this.originRecord = value;
    }
    transaction(() => {
      this.record = value;
      this.setSelectedTranscriptionId(transcriptionId);
      this.setSelectedTranslationId(translationId);
    });
    await this.loadRecordHistory();
  }

  setStatus(value: EStoreStatus) {
    this.status = value;
  }

  setRetranslationStatus(value: EStoreStatus) {
    this.retranslationStatus = value;
  }

  setPhrasePauseSec(pauseSec: number) {
    this.phrasePauseSec = pauseSec;
  }

  setSentencePauseSec(pauseSec: number) {
    this.sentencePauseSec = pauseSec;
  }

  setSentencePauseSelector(isUse: boolean) {
    this.useSentencePauseSelector = isUse;
  }

  setWordProbability(wordProbability: number) {
    this.wordProbability = wordProbability;
  }

  setTranslation(value: boolean) {
    this.isTranslation = value;
  }

  setHistoryWordsData(value: IWordsData[]) {
    this.historyWordsData = value;
  }

  setSelectedTranscriptionId(value?: number) {
    this.selectedTranscriptionId = value;
  }

  setSelectedTranslationId(value?: number) {
    this.selectedTranslationId = value;
  }

  resetSelectionId() {
    this.setSelectedTranslationId();
  }

  getDownLoadInform = async (
    fileFormat: string,
    recordName: string | undefined,
    template: string,
    fileName: string,
  ) => {
    const urlParams = {
      format: fileFormat,
      filename: fileName,
      recordName,
      templateName: template,
    };

    await BackendService.post('text/download/inform', JSON.stringify(urlParams));
  };

  getDownLoadTemplate = async (template: string) => {
    const urlParams = {
      templateName: template,
    };

    await BackendService.post('template/download/inform', JSON.stringify(urlParams));
  };

  // идентификатор текущей ревизии текста
  get currentHistoryId() {
    if (this.isTranslation) {
      return this.selectedTranslationId;
    } else {
      return this.selectedTranscriptionId;
    }
  }

  // текущая ревизия текста (отображаемые слова)
  get currentWordsData(): IWordsData | undefined {
    const temp = this.getHistoryWordsDataById(this.currentHistoryId);
    this.markFilteredInWordData(temp);

    return temp ? { ...temp } : undefined;
  }

  // доступен ли режим правки для выбранного текста
  get canEditCurrentText() {
    if (!gAPP_STORE.loginStore.user?.isEditor) return false;

    return (gAPP_STORE.editingASR && !this.isTranslation) || (gAPP_STORE.editingTranslate && this.isTranslation);
  }

  // направление текущего отображаемого текста
  get currentTextDirection(): EDirection {
    const recordLanguage = this.isTranslation ? this.record?.translateLanguage : this.record?.languageResolved;
    const isRTL = gAPP_STORE.isRtlLanguage(recordLanguage);

    return isRTL ? EDirection.RTL : EDirection.LTR;
  }

  /**
   * Получить транскрипцию, автоматический и актуальный переводы транскрипции.
   * @param record
   * @param useCurrentTranscription
   */
  private async getWordsToExport(record: IRecord, useCurrentTranscription: boolean): Promise<IDocxData> {
    let transcriptionData: IWordsData | undefined;
    let translationAutoData: IWordsData | undefined;
    let translationData: IWordsData | undefined;

    if (useCurrentTranscription && !this.isTranslation && !gAPP_STORE.viewTranslate) {
      transcriptionData = this.currentWordsData;
    } else {
      if (record.transcriptionId) {
        transcriptionData = await BackendService.get(`text/${this.selectedTranscriptionId ?? record.transcriptionId}`);
      }
    }

    if (transcriptionData && transcriptionData.words) {
      transcriptionData = this.preprocessWords(transcriptionData, record.languageResolved);

      if (record.translationId !== null) {
        // получаем первый перевод (автоматический), запросив только первую строку из истории
        const autoTranslationRequestBody = {
          correlationId: record.correlationId,
          limit: 1,
          offset: 0,
          sortOrder: 'Asc',
        };
        const autoTranslationRow: IWordsData[] = await BackendService.post(
          'text/translate/history',
          JSON.stringify(autoTranslationRequestBody),
        );
        if (autoTranslationRow && autoTranslationRow.length === 1) {
          translationAutoData = autoTranslationRow[0];

          // если автоматический перевод был изменен, получаем актуальный перевод
          if (useCurrentTranscription && this.selectedTranslationId) {
            translationData = this.isTranslation
              ? this.currentWordsData
              : await BackendService.get(`text/translate/${this.selectedTranslationId}`);
          } else {
            if (translationAutoData?.id !== record.translationId) {
              translationData = await BackendService.get(`text/translate/${record.translationId}`);
            }
          }

          translationAutoData = this.preprocessWords(translationAutoData, record.translateLanguage);

          if (translationData && translationData.words) {
            translationData = this.preprocessWords(translationData, record.translateLanguage);
          }
        }
      }
    }

    return {
      transcription: transcriptionData,
      translationAuto: translationAutoData,
      translation: translationData,
    };
  }

  preprocessWords = (wordsData: IWordsData, wordsLanguage: string): IWordsData => {
    return wordsLanguage === ESpecialLanguageCode.EN ? correctIm(wordsData) : wordsData;
  };

  markFilteredWord(words: IWord[], filterWords: string[]) {
    words.forEach(word => {
      word.isFiltered = undefined; //@@#
      if (filterWords.some(f => word.text.toLowerCase().includes(f))) {
        word.isFiltered = true; //@@#
      }
    });
  }

  markFilteredInPhrase(sentence: IPhrase, filterPhrase: IFilterPhrase[]) {
    let indToken = sentence.tokens.length - 1;
    while (indToken > 0) {
      const curEnd = sentence.tokens[indToken];
      const curEndText = curEnd.text.toLowerCase();
      // eslint-disable-next-line no-loop-func
      const possibleFilter = filterPhrase.filter(ff => {
        return curEndText.startsWith(ff.words.at(-1) ?? '') && ff.words.length <= indToken + 1;
      });
      if (possibleFilter.length === 0) {
        indToken--;
        continue;
      }
      // eslint-disable-next-line no-loop-func
      possibleFilter.forEach(filter => {
        const len = filter.words.length;
        const text = sentence.tokens
          .slice(indToken - len + 1, indToken + 1)
          .map(v => v.text.toLowerCase())
          .join(' ');
        if (!text.includes(filter.text)) return;
        for (let ind = indToken - len + 1; ind < indToken + 1; ind++) {
          sentence.tokens[ind].isFiltered = true;
        }
      });
      indToken--;
    }
  }

  markFilteredInWordData(wordsData: IWordsData | undefined) {
    if (!wordsData) return;
    const { filterWords, filterPhrase } = gAPP_STORE.getFilterStore().getSeparatedFilter(this.isTranslation);
    if (filterWords.length === 0 && filterPhrase.length === 0) return;
    wordsData.words.forEach(channelWords => this.markFilteredWord(channelWords.words, filterWords));

    if (wordsData && filterPhrase.length > 0) {
      wordsData.words.forEach(channelWords => {
        //проходимся по каждому каналу
        const sentences = wordsToSentences(channelWords.words);
        sentences.forEach(sentence => {
          this.markFilteredInPhrase(sentence, filterPhrase);
        });
      });
    }
  }

  get markers(): IWord[] {
    const wd = this.currentWordsData;
    if (wd === undefined) return [];

    const result: IWord[] = [];
    wd.words.forEach(channelWords => {
      const filerWords = channelWords.words.filter(word => word.isFiltered);
      result.push(...filerWords);
    });

    return result;
  }
}
