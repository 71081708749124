import React from 'react';
import { observer } from 'mobx-react';

import { Box } from '@material-ui/core';

import { ImageCacheType } from '../avatar/cached-avatar';
import { DictorChip } from '../dictors/DictorChip';

import { IParticipant } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { Role } from '@/components/summary/Role';

interface IParticipantsPanel {
  participants: IParticipant[];
  editableRole?: boolean;
  avatarCache?: ImageCacheType;
  autotest: string;
}

const ParticipantsPanel: React.FC<IParticipantsPanel> = observer(props => {
  const { participants, editableRole, avatarCache } = props;

  const dictorsStore = gAPP_STORE.getDictorsStore();
  const { updateRole } = gAPP_STORE.summaryStore;

  if (participants.length === 0) {
    return null;
  }

  const roleChangeHandler = (participant: IParticipant, value: string) => {
    void updateRole(participant, value);
  };

  return (
    <Box data-autotest={'ParticipantsPanel'} display="flex" flexWrap="wrap" p={1} style={{ gap: 5 }}>
      {participants.map((participant, index) => {
        return (
          participant.dictor && (
            <Box
              data-autotest={`${props.autotest}-participant-${index}`}
              key={`ParticipantsPanel-${participant.idParticipant}`}
              display="flex"
              flexWrap="nowrap"
              alignItems="center"
              style={{ gap: 4 }}
            >
              <DictorChip key={index} dictor={participant.dictor} cache={dictorsStore.cacheDictorAvatars} />
              {participant.role !== undefined && participant.role !== null && (
                <Role
                  participant={participant}
                  onRoleChanged={value => roleChangeHandler(participant, value)}
                  maxLength={100}
                  editable={editableRole}
                  autotest={`${props.autotest}-participant-${index}`}
                />
              )}
            </Box>
          )
        );
      })}
    </Box>
  );
});

export default ParticipantsPanel;
