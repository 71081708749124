import React from 'react';
import { IDataGridCol, TDataGridRenderer, ColoredLabel } from '@uk';

import appColors from '../../app/app-colors';

import { IUser } from './i-user';

import UserGroups from './user-groups';

const isAdminRenderer: TDataGridRenderer = (row: IUser, value: boolean) => {
  return value ? <ColoredLabel label="users.admin" bgcolor={appColors.blue} /> : '';
};
const isSupervisorRenderer: TDataGridRenderer = (row: IUser, value: boolean) => {
  return value ? <ColoredLabel label="users.supervisor" bgcolor={appColors.blue} /> : '';
};
const isEditorRenderer: TDataGridRenderer = (row: IUser, value: boolean) => {
  return value ? <ColoredLabel label="users.editor" bgcolor={appColors.blue} /> : '';
};
const isActiveRenderer: TDataGridRenderer = (row: IUser, value: boolean) => {
  return value ? (
    <ColoredLabel label="users.active" bgcolor={appColors.green} />
  ) : (
    <ColoredLabel label="users.inactive" bgcolor={appColors.red} />
  );
};

export const userGroupsRenderer: TDataGridRenderer = (row: IUser, colId: string) => {
  return <UserGroups user={row} />;
};

export const usersGridColumns: IDataGridCol<IUser>[] = [
  { id: 'active', name: 'users.active', width: 120, renderer: isActiveRenderer, align: 'center', noSort: true },
  { id: 'id', name: 'id', width: 60, align: 'center', noSort: true },
  { id: 'firstName', name: 'users.name', width: 300, noSort: true },
  { id: 'email', name: 'users.login', width: 150, noSort: true },
  { id: 'isAdmin', name: 'users.admin', width: 150, renderer: isAdminRenderer, align: 'center', noSort: true },
  {
    id: 'isSupervisor',
    name: 'users.supervisor',
    width: 150,
    renderer: isSupervisorRenderer,
    align: 'center',
    noSort: true
  },
  {
    id: 'isEditor',
    name: 'users.editor',
    width: 150,
    renderer: isEditorRenderer,
    align: 'center',
    noSort: true
  },
  { id: 'groups',
    name: 'users.groups',
    width: 500,
    renderer: userGroupsRenderer,
    noSort: true,
    isOverflow: true
  },
];
