import { useMemo } from 'react';
import { observer } from 'mobx-react';

import { Box, Chip, Tooltip, makeStyles } from '@material-ui/core';

import LabelIcon from '@material-ui/icons/Label';
import { Message } from '@material-ui/icons';

import { ILabel } from '../../types';

import { useTextColor } from '@/react-ui-kit/src/hooks/useTextColor';

const useWhite = makeStyles(theme => ({
  white: {
    '& .MuiChip-deleteIcon': {
      color: '#FFFFFF',
    },
  },
}));
const useBlack = makeStyles(theme => ({
  black: {
    '& .MuiChip-deleteIcon': {
      color: '#000',
    },
  },
}));

interface ILabelChipProps {
  label: ILabel;
  index?: number;
  selected?: boolean;
  onDelete?: (index?: number) => void;
  onClick?: (index?: number) => void;
}

export const LabelChip: React.FC<ILabelChipProps> = observer(
  ({ label, index, selected, onDelete, onClick }: ILabelChipProps) => {
    //const isRTL = gAPP_STORE.isRtlLanguage(recordLanguage);
    const chipColor = label.isDeleted ? 'none' : label.color;
    const textColor = label.isDeleted ? 'white' : useTextColor(chipColor);
    const comment = useMemo(() => {
      return label.comment ? (
        <Tooltip
          title={label.comment}
          placement="bottom"
          enterDelay={500}
          enterNextDelay={1000}
          enterTouchDelay={7000}
          leaveTouchDelay={0}
          leaveDelay={0}
          PopperProps={{
            style: {
              fontFamily: 'sans-serif',
              fontWeight: 'lighter',
              fontSize: '0.7rem',
            },
          }}
        >
          <Message htmlColor={textColor} />
        </Tooltip>
      ) : null;
    }, [label.comment, textColor]);

    return (
      <Chip
        className={textColor === 'white' ? useWhite().white : useBlack().black}
        key={index}
        label={
          <Box display={'flex'} alignContent={'center'} alignItems={'center'}>
            <LabelIcon htmlColor={textColor} />
            <Box pl={'4px'} pr={'4px'} pb={'4px'}>
              {label.name}
            </Box>
            {comment}
          </Box>
        }
        onDelete={onDelete ? () => onDelete(index) : undefined}
        onClick={
          onClick
            ? () => {
                onClick(index);
              }
            : undefined
        }
        style={
          label.isDeleted
            ? {
                color: textColor,
                backgroundColor: chipColor,
                border: '1px solid red',
                //direction: isRTL ? 'rtl' : undefined,
              }
            : selected
            ? {
                color: textColor,
                backgroundColor: chipColor,
                border: `3px solid ${textColor}`,
                outline: `2px solid ${chipColor}`,
                //direction: isRTL ? 'rtl' : undefined,
              }
            : {
                color: textColor,
                backgroundColor: chipColor,
                //direction: isRTL ? 'rtl' : undefined,
              }
        }
      />
    );
  },
);
