import React, { useMemo, useState } from 'react';

import { IconButton, makeStyles } from '@material-ui/core';

import { ERecordStatus, IRecord } from 'components/records';

import { IDataGridCol, IToolbarExDescriptorItem, Panel, TDataGridRenderer, ToolbarEx, ukColors } from '@uk';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';

import { observer } from 'mobx-react';

import RecordsStatusLabel from '../records/records-status-label';

import UploadPanelGrid from './upload-panel-grid';

const statusRenderer: TDataGridRenderer = (row: IRecord[], value: ERecordStatus) => {
  return <RecordsStatusLabel status={value} />;
};

export const uncompletedGridColumns: IDataGridCol<IRecord>[] = [
  { id: 'status', name: 'records.status', width: 100, align: 'center', renderer: statusRenderer, noSort: true },
  { id: 'fileName', name: 'records.filename', width: 300, noSort: true },
];

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    borderTop: `1px solid ${ukColors.borderColor}`,
  },

  cell: {
    padding: '0.8em',
    display: 'flex',
    position: 'relative',
  },
  cellContent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: '1px solid green',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  statusCell: {
    width: 1,
  },
}));

interface IFlipIconProps {
  isOpen: boolean;
  onClick: () => void;
}
const FlipIcon = ({ isOpen, onClick }: IFlipIconProps) => {
  return <IconButton onClick={onClick}>{isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>;
};

const UploadPanel = observer(() => {
  const classes = useStyles();
  const [showPanel, setShowPanel] = useState(false);

  const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(
    () => [
      {
        type: 'custom',
        component: <FlipIcon isOpen={showPanel} onClick={() => setShowPanel(v => !v)} />,
      },
      { type: 'text', text: 'records.uploads', pr: 1 },
      { type: 'divider', pl: 1 },
    ],
    [showPanel],
  );

  return (
    <Panel
      display="flex"
      flexDirection="column"
      className={classes.root}
      flexGrow={showPanel ? 1 : 0}
      maxHeight={'calc(100vh/5)'}
    >
      <ToolbarEx descriptor={toolbarDescriptor} />
      {showPanel && <UploadPanelGrid />}
    </Panel>
  );
});

export default UploadPanel;
