// noinspection NonAsciiCharacters

import assert from "assert";

export const toUTF8Array = (str: string) => {
  const utf8 = [];
  for (let i = 0; i < str.length; i++) {
    let charcode = str.charCodeAt(i);
    if (charcode < 0x80) utf8.push(charcode);
    else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(0xe0 | (charcode >> 12), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
    }
    // surrogate pair
    else {
      i++;
      // UTF-16 encodes 0x10000-0x10FFFF by
      // subtracting 0x10000 and splitting the
      // 20 bits of 0x0-0xFFFFF into two halves
      charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f),
      );
    }
  }
  return utf8;
};

export const map2str = (map: any) => {
  return JSON.stringify([...map.entries()]);
};

export const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
export const lowerFirstLetter = (s: string) => s.charAt(0).toLowerCase() + s.slice(1);

export const toTruncatedString = (s: string, maxLength: number) =>
  s.length > maxLength ? s.substring(0, maxLength) + '...' : s;

export const isString = (el: any) => {
  return typeof el === 'string' || el instanceof String;
};

export const pad = (num: number, places: number) => String(num).padStart(places, '0');

export const str2map = (str: any) => {
  return JSON.parse(str).reduce((m: any, [key, val]: [any, any]) => m.set(key, val), new Map());
};

export const parseUrl = (url: string) => {
  const result: string[] = [];
  const regex = /[a-z]+=[%\-a-zA-Zа-яА-Я0-9,_]+/gm;
  // const regex = /[a-z]+=[a-z0-9,]+/gm;
  let m;
  while ((m = regex.exec(url)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match) => {
      const item: any[] = match.split('=');
      result[item[0]] = item[1];
    });
  }
  return result;
};

export const parseSexScore = (value: any) => {
  if (!value) {
    return 0;
  }
  return +value;
};

export const trimChar = (s: string, c: string) => {
  if (c === ']') c = '\\]';
  if (c === '^') c = '\\^';
  if (c === '\\') c = '\\\\';
  return s.replace(new RegExp('^[' + c + ']+|[' + c + ']+$', 'g'), '');
};

const transliterateLetters = {
  Ё: 'YO',
  Й: 'I',
  Ц: 'TS',
  У: 'U',
  К: 'K',
  Е: 'E',
  Н: 'N',
  Г: 'G',
  Ш: 'SH',
  Щ: 'SCH',
  З: 'Z',
  Х: 'H',
  Ъ: "'",
  ё: 'yo',
  й: 'i',
  ц: 'ts',
  у: 'u',
  к: 'k',
  е: 'e',
  н: 'n',
  г: 'g',
  ш: 'sh',
  щ: 'sch',
  з: 'z',
  х: 'h',
  ъ: "'",
  Ф: 'F',
  Ы: 'I',
  В: 'V',
  А: 'a',
  П: 'P',
  Р: 'R',
  О: 'O',
  Л: 'L',
  Д: 'D',
  Ж: 'ZH',
  Э: 'E',
  ф: 'f',
  ы: 'i',
  в: 'v',
  а: 'a',
  п: 'p',
  р: 'r',
  о: 'o',
  л: 'l',
  д: 'd',
  ж: 'zh',
  э: 'e',
  Я: 'Ya',
  Ч: 'CH',
  С: 'S',
  М: 'M',
  И: 'I',
  Т: 'T',
  Ь: "'",
  Б: 'B',
  Ю: 'YU',
  я: 'ya',
  ч: 'ch',
  с: 's',
  м: 'm',
  и: 'i',
  т: 't',
  ь: "'",
  б: 'b',
  ю: 'yu',
};

export const transliterate = (word: string) => {
  return word
    .split('')
    .map(function (char) {
      return (transliterateLetters as any)[char] || char;
    })
    .join('');
};

// TODO gavr to Misha Это какой то ужас, надо бы упростить
export const ellipsisStr = (str: string | undefined, len: number, str2?: string): string => {
  if (str2) {
    return !str
      ? ellipsisStr(str2, Math.ceil(len))
      : str.length + str2.length > len
        ? str.length > len / 2
          ? ellipsisStr(str, len)
          : `${str} ${ellipsisStr(str2, Math.ceil(len / 2))}`
        : `${str} ${str2}`
  } else {
    return !str ? '' : str.length > len ? `${str.substring(0, len - 2)}...` : `${str}`;
  }
}

export function takeLastPartOfPath(path: string): string {
  assert(path.includes("/"),"Путь не содержит слешей '/', путь: " + path)
  const pathSplitted = path.split("/")
  return pathSplitted[pathSplitted.length - 1]
}
