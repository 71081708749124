import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';

import { AppStore } from 'app/index';
import { ERecordStatus } from 'components/records/types';

import RecordTextHistoryItem from './record-text-history-item';
import styles from './record-text-history.module.scss';

interface IRecordTextHistoryProps {
  disabled: boolean;
}

export const RecordTextHistory: React.FC<IRecordTextHistoryProps> = observer((props) => {
  const store  = AppStore.getRecordTextStore();
  useEffect(() => {

    const dispose =  reaction(() => store.originRecord?.translationStatus, (newValue, oldValue) => {
      if((oldValue === ERecordStatus.PROCESSING || oldValue === ERecordStatus.NEW) && newValue === ERecordStatus.PROCESSED ){
        store.loadRecordHistory();
        store.selectHistoryItem( store.historyWordsData.length -1);
      }
    });

    return () => {
      dispose();
    };

  }, [store]);


  return (
    <Box flexGrow={0} display="flex" flexDirection="column">
      {props.disabled && <Box className={styles.disabler}/>}
      <Box p={1} flexGrow={1} style={{ height: 0, overflowY: 'auto' }}>
        {store.historyWordsData.map((wordsData, index, arr) => (
          <RecordTextHistoryItem
            key={index}
            wordsData={wordsData}
            isFirst={index === 0}
            isLast={index === arr.length - 1}
          />
        ))}
      </Box>
    </Box>
  );
});
