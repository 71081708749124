import { Panel, ToolbarEx, DataGrid, IToolbarExDescriptorItem } from '@uk';
import { Box } from '@material-ui/core';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';

import CachedIcon from '@material-ui/icons/Cached';

import EventLogDialog from './event-log-dialog';

import { gAPP_STORE } from '@/app/app-store';

export const EventLogGrid: FC = () => {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const handleEditSetting = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(
    () => [
      { type: 'text', text: `${t('settings.eventLog')}`, pr: 1 },
      { type: 'divider', pl: 1 },
      {
        type: 'button',
        text: 'refresh',
        icon: <CachedIcon />,
        onClick: () => gAPP_STORE.getEventLogStore().reload(),
        color: 'green',
        pl: 1
      },
      {
        type: 'button',
        text: 'saveRecordShort',
        icon: <SaveRoundedIcon />,
        onClick: () => handleEditSetting(),
        disabled: false,
        pl: 1,
      },
    ],
    [handleEditSetting, t],
  );

  return (
    <Panel>
      <ToolbarEx descriptor={toolbarDescriptor} />
      <Box display="flex" flexGrow={1}>
        <DataGrid
          id="EventLogGrid"
          gridStore={gAPP_STORE.getEventLogStore().gridStore}
          cols={gAPP_STORE.getEventLogStore().gridCols}
          noPageNumbers
        />
      </Box>
      <EventLogDialog
        open={openDialog}
        setOpen={setOpenDialog}
      />
    </Panel>
  );
};

export { };
