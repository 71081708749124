import React from 'react';
import { CheckCircle } from '@material-ui/icons';
import appColors from 'app/app-colors';
import { capitalizeFirstLetter } from 'common/utils';

import { dateRenderer, IDataGridCol, TDataGridRenderer } from '@uk';

import { ILanguage } from './i-language';

const languageRenderer: TDataGridRenderer = (row: ILanguage, value: string) => {
  return capitalizeFirstLetter(value);
};

const installedRenderer: TDataGridRenderer = (row: ILanguage, value: boolean) => {

  return value ? <CheckCircle style={{ color: appColors.green }} /> : '';
};


export const languagesGridColumns: IDataGridCol<ILanguage>[] = [
  { id: 'id', name: 'id', width: 60, align: 'center', noSort: true },
  {
    id: 'modifyedDate',
    name: 'templates.modifyedDate',
    renderer: dateRenderer,
    width: 200,
    align: 'center',
    noSort: true
  },
  // { id: 'icon', name: 'languages.icon', renderer: iconRenderer, width: 200, align: EDataGridAlign.CENTER, },
  {
    id: 'name', name: 'languages.name',
    renderer: languageRenderer,
    width: 300,
    noSort: true
  },
  {
    id: 'installed', name: 'languages.installedOnService',
    renderer: installedRenderer,
    width: 300,
    align: 'center',
    noSort: true
  },
];
