import React from 'react';
import { Box } from '@material-ui/core';

import { observer } from 'mobx-react';

import GroupChip from 'components/groups/group-chip';

import { IGroup } from 'components/groups';

import { IUser } from './i-user';

const UserGroups = ({ user }: { user: IUser }) => {
  if (!Array.isArray(user.groups)) {
    return <></>;
  }

  return (
    <Box display="flex">
      {user.groups.map((group: IGroup, index) => (
        <Box key={index} mr={0.5}>
          <GroupChip group={group} markOwnGroups />
        </Box>
      ))}
    </Box>
  );
};

export default observer(UserGroups);
