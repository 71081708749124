import { Direction } from '@material-ui/core';

//import appColors from './app-colors';

import { ColorPreset, Contrast, createTheme } from '@/theme';

export type Layout = 'horizontal' | 'vertical';
export type NavColor = 'blend-in' | 'discreet' | 'evident';

export interface ISettings {
  colorPreset?: ColorPreset;
  contrast?: Contrast;
  direction?: Direction;
  layout?: Layout;
  navColor?: NavColor;
  paletteMode?: string;
  responsiveFontSizes?: boolean;
  stretch?: boolean;
}

const initialSettings: ISettings = {
  colorPreset: 'gridsr',
  contrast: 'high',
  direction: 'ltr',
  layout: 'vertical',
  navColor: 'evident',
  paletteMode: 'light',
  responsiveFontSizes: true,
  stretch: false,
};

const settings = initialSettings;

export const appTheme = createTheme({
  colorPreset: settings.colorPreset,
  contrast: settings.contrast,
  direction: settings.direction,
  paletteMode: settings.paletteMode,
  responsiveFontSizes: settings.responsiveFontSizes,
});

// export const appTheme = createTheme({
//   palette: {
//     primary: {
//       main: appColors.primary,
//     },
//     secondary: {
//       main: appColors.primaryLight,
//     },
//   },

// overrides: {
//   MuiToolbar: {
//     dense: {
//       padding: 0,
//       paddingLeft: '0.8em',
//       paddingRight: '0.8em',
//       flexGrow: 0,
//       color: appColors.primary,
//       textTransform: 'none',
//     },
//   },

//   MuiTabs: {
//     root: {
//       minHeight: 0,
//       background: appColors.tabsBackgroundColor,
//       borderBottom: '1px solid #ddd',
//     },
//     indicator: {
//       background: '#0288d1',
//       height: 0,
//     },
//   },

//   MuiTab: {
//     root: {
//       height: '2.5em',
//       minHeight: '2.5em',
//       fontSize: '1em',
//       fontWeight: 'normal',
//       textTransform: 'none',
//       background: appColors.tabsBackgroundColor,
//       color: '#ddd',
//       borderRight: '1px solid #ddd',
//       '&$selected': {
//         background: 'white',
//         color: 'black',
//         boxShadow: '1px 0 5px rgba(0,0,0,0.1)',
//         zIndex: 100,
//       },
//     },
//   },

//   MuiTooltip: {
//     tooltip: {
//       fontSize: '1.5em',
//     },
//   },
// },
//});
