export const palette = [
  'rgb(235, 117, 50)',
  'rgb(247, 208, 56)',
  'rgb(163, 224, 72)',
  'rgb(73, 218, 154)',
  'rgb(52, 187, 230)',
  'rgb(67, 85, 219)',
  'rgb(210, 59, 231)',
  'rgb(205, 107, 50)',
  'rgb(207, 180, 56)',
  'rgb(103, 204, 202)',
];

export const getColorById = (id: number) => palette.at(id % palette.length);
