import { IGroup } from 'components/groups';

export interface IUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  active: boolean;
  isAdmin: boolean;
  isSupervisor: boolean;
  isEditor: boolean;
  platform: string;
  groups?: IGroup[];
  isAD?: boolean;
}

export class CUser implements IUser {
  id = -1;
  email = '';
  firstName = '';
  lastName = '';
  active = true;
  isAdmin = false;
  isSupervisor = false;
  isEditor = false;
  platform = '';
}
