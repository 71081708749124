import { makeAutoObservable } from 'mobx';

class NeedSaveChangesStoreImpl {
  private needSaveChangesVal = false;

  constructor() {
    makeAutoObservable(this);
  }

  public setNeedSaveChanges(val: boolean) {
    this.needSaveChangesVal = val;
  }

  get needSaveChanges() {
    return this.needSaveChangesVal;
  }
}

export const gNeedSaveChangesStore = new NeedSaveChangesStoreImpl();
