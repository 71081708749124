import { DataGridStore, IDataGridStoreData, IDataGridStoreState } from '@uk';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import i18n from 'i18next';

import { BackendService } from 'services';

import { wordDictionaryGridDescriptor } from './wordDictionary.grid.descriptor';

import { IResponseTopic, IWordDictionary } from './WordDictionary.types';

import { BackendError, HandleErrorTranslatedMessage } from '@/services/types';

export class WordDictionaryStore {
  data: { wordDictionaryList: IWordDictionary[] } = { wordDictionaryList: [] };
  searchQuery = '';

  gridStore: DataGridStore<IWordDictionary>;
  gridCols = wordDictionaryGridDescriptor;

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.gridStore = new DataGridStore<IWordDictionary>('Themse', this.gridCols, { dataProvider: this.dataProvider });
  }

  dataProvider = async (state: IDataGridStoreState): Promise<IDataGridStoreData<IWordDictionary>> => {
    await this.getAllImpl();

    return { data: this.data.wordDictionaryList, total: this.data.wordDictionaryList.length, hasNextPage: false };
  };
  /**
   * Get by id
   */
  get = async (id: string): Promise<IWordDictionary | undefined> => {
    try {
      const data = await BackendService.get(`word_dictionary/${id}`);

      return data;
    } catch (error) {
      return undefined;
    }
  };

  /**
   * Get all wordDictionaryList
   */
  getAll = async () => {
    await this.gridStore.reload();
  };

  private getAllImpl = async () => {
    try {
      // this.wordDictionaryData.wordDictionaryList = [];
      const data = await BackendService.get('word_dictionary/all');
      runInAction(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        () => (this.data.wordDictionaryList = data.sort((a: any, b: any) => (a.id === b.id ? 0 : a.id - b.id))),
      );
    } catch (error) {
      this.data.wordDictionaryList = [];
      if (error instanceof BackendError && error.status === 401) {
        toast.error(i18n.t(error.statusMessage));
      } else {
        toast.error(`Server error: ${JSON.stringify(error)}`);
        throw error;
      }
    }
  };

  /**
   * Create new wordDictionary
   */
  create = async (wordDictionary: IWordDictionary) => {
    try {
      const newWordDictionaryData: IWordDictionary = {
        id: -1,
        language: wordDictionary.language,
        // langId: -1,
        name: wordDictionary.name,
        value: wordDictionary.value,
        isAutoAsr: wordDictionary.isAutoAsr,
        isKws: wordDictionary.isKws,
        createdDate: new Date().toISOString(),
      };

      await BackendService.put('word_dictionary/add', JSON.stringify(newWordDictionaryData));
      await this.gridStore.reload();
    } catch (error) {
      if (error instanceof BackendError) {
        if(error.errorInfo.reasonCode){
          toast.error(HandleErrorTranslatedMessage(error));
        } else {
          toast.error(`Server error: ${JSON.stringify(error)}`);
        };
      } else {
        toast.error(`Server error: ${JSON.stringify(error)}`);
        throw error;
      }
    }
  };

  /**
   * Update wordDictionary
   */
  update = async (newEntry: IWordDictionary, exsisted: IWordDictionary) => {
    try {
      await BackendService.post(`word_dictionary/update/${newEntry.id.toString()}`, JSON.stringify(newEntry));
      await this.gridStore.reload();
    } catch (error) {
      if (error instanceof BackendError) {
        if(error.errorInfo.reasonCode){
          toast.error(HandleErrorTranslatedMessage(error));
        } else {
          toast.error(`Server error: ${JSON.stringify(error)}`);
        };
      } else {
        toast.error(`Server error: ${JSON.stringify(error)}`);
        throw error;
      }
    }
  };

  /**
   * Delete wordDictionary
   */
  delete = async (wordDictionary: IWordDictionary) => {
    try {
      await BackendService.delete(`word_dictionary/${wordDictionary.id.toString()}`);
      await this.gridStore.reload();
    } catch (error) {
      if (error instanceof BackendError) {
        if(error.errorInfo.reasonCode){
          toast.error(HandleErrorTranslatedMessage(error));
        } else {
          toast.error(`Server error: ${JSON.stringify(error)}`);
        };
      } else {
        toast.error(`Server error: ${JSON.stringify(error)}`);
        throw error;
      }
    }
  };

  /**
   * Get record topics
   */
  getRecordTopics = async (recordIds: number[]): Promise<IResponseTopic[]> => {
    try {
      const data: IResponseTopic[] =
        recordIds && recordIds.length > 0
          ? await BackendService.post('word_dictionary/themes', JSON.stringify(recordIds))
          : [];

      return data;
    } catch (error) {
      if (error instanceof BackendError && error.status === 401) {
        toast.error(i18n.t(error.statusMessage));
      }
      throw error;
    }
  };
}
