import en from './en.json';
import ru from './ru.json';
import es from './es.json';
import pt from './pt.json';

const filesMap: Record<string, Record<string, unknown>> = {
  en,
  ru,
  es,
  pt,
};

const filesKeys: string[] = Object.keys(filesMap);

function filesMapRun(): Record<string, string[]> {
  let newMap: Record<string, string[]> = {};

  filesKeys.forEach((fk: string) => {
    const tempMap: Record<string, string[]> = {};
    Object.keys(filesMap[fk]).forEach((tk: string) => {
      tempMap[tk] = [];
    });
    newMap = {
      ...newMap,
      ...tempMap,
    };
  });

  function doFilesParse(inputMap: Record<string, string>, fullMap: Record<string, string[]>) {
    const inputKeys: string[] = Object.keys(fullMap);
    inputKeys.forEach((inputK: string) => {
      if (!newMap.hasOwnProperty(inputK)) {
        newMap[inputK] = [];
      }
      newMap[inputK].push(inputMap[inputK] || ' ');
    });
  }

  filesKeys.forEach((fK: string) => {
    doFilesParse(filesMap[fK] as Record<string, string>, newMap);
  });

  return newMap;
}

export function exporter(): string {
  const mapToParse: Record<string, string[]> = filesMapRun();
  const arrayOfKeys: string[] = Object.keys(mapToParse);
  const arrayOfValues: string[][] = Object.values(mapToParse);

  const arrayToGo: Array<string[]> = [['key', ...filesKeys]];
  arrayOfValues.forEach((valueArray: string[], index: number) => {
    arrayToGo.push([arrayOfKeys[index], ...valueArray]);
  });

  const totalStuff: string[] = [];
  arrayToGo.forEach((element: string[]) => {
    totalStuff.push(element.join(';'));
  });
  const joinedArray: string = totalStuff.join('\n');

  return joinedArray;
}