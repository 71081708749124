import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IToolbarExDescriptorItem, ToolbarEx } from '@uk';
import { observer } from 'mobx-react';
import { Warning } from '@material-ui/icons';
import clsx from 'clsx';

import { gAPP_STORE } from 'app/app-store';

import styles from './needSaveChanges.module.scss';

export interface INeedSaveChangesProps {
  needSaveChanges: boolean;
  isBubbleCuttled: boolean;
  resetChanges: () => void;
  saveChanges: () => void;
}

export const NeedSaveChanges: FC<INeedSaveChangesProps> = observer(props => {
  const { needSaveChanges, isBubbleCuttled, resetChanges, saveChanges } = props;
  const { t } = useTranslation();
  const recordTextStore = gAPP_STORE.getRecordTextStore();
  const { setSegmentationUpdated } = gAPP_STORE.getDictorsStore();

  const handleSave = useCallback(() => {
    setSegmentationUpdated(false);
    saveChanges();
  }, [saveChanges, setSegmentationUpdated]);

  const handleReset = useCallback(() => {
    setSegmentationUpdated(false);
    resetChanges();
  }, [resetChanges, setSegmentationUpdated]);

  const toolbar: IToolbarExDescriptorItem[] = useMemo(() => {
    const result: IToolbarExDescriptorItem[] = [
      {
        type: 'filler',
      },
      {
        type: 'button',
        disabled: !needSaveChanges,
        onClick: handleSave,
        text: 'saveText',
        pl: 1,
      },
      {
        type: 'button',
        disabled: !needSaveChanges,
        onClick: handleReset,
        text: 'reset',
        pl: 1,
      },
    ];

    if (needSaveChanges) {
      result.splice(0, 0, {
        type: 'custom',
        component: (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5x' }}>
            <div className={clsx(styles.text, { [styles.needSaveChanges]: needSaveChanges })}>
              <Warning />
              <div>{t('textChanged')}</div>
            </div>
            {isBubbleCuttled && (
              <div className={clsx(styles.text, { [styles.needSaveChanges]: needSaveChanges })}>
                <Warning />
                <div>{t('textDictorsChanges')}</div>
              </div>
            )}
          </div>
        ),
      });
    }

    return result;
  }, [needSaveChanges, handleSave, handleReset, t, isBubbleCuttled]);

  if (!recordTextStore.canEditCurrentText) {
    return null;
  }

  return <ToolbarEx descriptor={toolbar} />;
});
