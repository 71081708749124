import { Box, Typography, Slider } from '@material-ui/core';
import { runInAction } from 'mobx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { gAPP_STORE } from 'app/app-store';

import { IHaveDisable } from './types';

export const WordProbabilitySelector: FC<IHaveDisable> = (props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(gAPP_STORE.getRecordTextStore().wordProbability);

  return (
    <Box display="flex">
      <Box p={1} minWidth={23}/>
      <Box p={1} pr={2} width={200}>
        <Typography style={{ whiteSpace: 'nowrap' }}>{t('wordProbability')}</Typography>
      </Box>
      <Box pr={1} mt={0.3} width={150} style={{ paddingTop: 2 }}>
        <Slider
          disabled={props.disabled}
          value={value}
          onChange={(event, newValue) => setValue(newValue as number)}
          onChangeCommitted={(event, newValue) => {
            runInAction(() => gAPP_STORE.getRecordTextStore().setWordProbability(newValue as number));
          }}
          valueLabelDisplay="off"
          step={10}
          marks
          min={0}
          max={100}
        />
      </Box>
      <Box p={1}>
        <Typography style={{ whiteSpace: 'nowrap' }}>
          {value} {'%'}
        </Typography>
      </Box>
    </Box>
  );
};