import { v4 as uuidv4 } from 'uuid';

import {
  IAmbiguityDto,
  IKeyDecisionDto,
  IKeyDecisionOwnerDto,
  IKeyDecisionWithOwnerDto,
  IMentionsDto,
  IParticipantDto,
  ISummaryDto,
  ITaskDto,
  ITaskOwnerDto,
  ITaskWithOwnerDto,
  ITopicDto,
} from './types';

import { BackendService } from '@/services/backend-service';

class SummaryService {
  getBrief = async (recordId: number): Promise<ISummaryDto[]> => {
    try {
      const result: ISummaryDto[] = await BackendService.get(`summary/${recordId}`);

      return result;
    } catch (err) {
      console.error('SummaryService.getBrief : ', { recordId, error: err });
      throw new Error('SummaryService');
    }
  };

  updateBrief = async (summaryId: number, summaryDto: ISummaryDto) => {
    try {
      await BackendService.put(`summary/${summaryId}`, JSON.stringify(summaryDto));
    } catch (err) {
      console.error('SummaryService.updateBrief : ', { summaryId, error: err });
      throw new Error('SummaryService');
    }
  };

  getTranslate = async (summaryId: number): Promise<ISummaryDto> => {
    try {
      const result: ISummaryDto = await BackendService.get(`summary/translate/${summaryId}`);

      return result;
    } catch (err) {
      console.error('SummaryService.getTranslate : ', { summaryId, error: err });
      throw new Error('SummaryService');
    }
  };

  getTopics = async (summaryId: number, isTranslate?: boolean): Promise<ITopicDto[]> => {
    try {
      const result: ITopicDto[] = await BackendService.get(
        `summary/topics${isTranslate ? '/translate' : ''}/${summaryId}`,
      );
      result.sort((a, b) => a.id - b.id);
      result.forEach(dto => {
        if (dto.phraseLinks) {
          dto.uuid = uuidv4();
        }
      });

      return result;
    } catch (err) {
      console.error('SummaryService.getTopics : ', { summaryId, error: err });
      //throw new Error('SummaryService');

      return [];
    }
  };

  updateTopic = async (summaryId: number, topicDto: ITopicDto) => {
    try {
      await BackendService.put(`/summary/${summaryId}/topics`, JSON.stringify(topicDto));
    } catch (err) {
      console.error('SummaryService.updateTopic : ', { summaryId, topicId: topicDto.id, error: err });
      throw new Error('SummaryService');
    }
  };

  getTaskOwners = async (summaryId: number): Promise<ITaskOwnerDto[]> => {
    try {
      const result: ITaskOwnerDto[] = await BackendService.get(`summary/task-owners/${summaryId}`);

      return result.sort((a, b) => a.id - b.id);
    } catch (err) {
      console.error('SummaryService.getTaskOwners : ', { summaryId, error: err });
      //throw new Error('SummaryService');

      return [];
    }
  };

  updateTaskWithOwner = async (summaryId: number, taskWithOwnerDto: ITaskWithOwnerDto) => {
    try {
      await BackendService.put(`summary/${summaryId}/task-with-owner/multi`, JSON.stringify(taskWithOwnerDto));
    } catch (err) {
      console.error('SummaryService.updateTaskOwner: ', { summaryId, error: err });
      throw new Error('SummaryService');
    }
  };

  updateDecisionWithOwner = async (summaryId: number, decisionOwner: IKeyDecisionWithOwnerDto) => {
    try {
      await BackendService.put(`summary/${summaryId}/key-decision-with-owner/multi`, JSON.stringify(decisionOwner));
    } catch (err) {
      console.error('SummaryService.updateDecisionOwner : ', { summaryId, error: err });
      throw new Error('SummaryService');
    }
  };

  getTasks = async (topicId: number, isTranslate?: boolean): Promise<ITaskDto[]> => {
    try {
      const result: ITaskDto[] = await BackendService.get(`summary/tasks${isTranslate ? '/translate' : ''}/${topicId}`);

      return result.sort((a, b) => a.id - b.id);
    } catch (err) {
      console.error('SummaryService.getTasks : ', { topicId, error: err });
      //throw new Error('SummaryService');

      return [];
    }
  };

  updateTask = async (summaryId: number, taskDto: ITaskDto) => {
    try {
      await BackendService.put(`summary/${summaryId}/tasks`, JSON.stringify(taskDto));
    } catch (err) {
      console.error('SummaryService.updateTask : ', { summaryId, taskId: taskDto.id, error: err });
      throw new Error('SummaryService');
    }
  };

  getTopicParticipants = async (topicId: number): Promise<IParticipantDto[]> => {
    try {
      const result: IParticipantDto[] = await BackendService.get(`summary/topic/participants/${topicId}`);

      return result.sort((a, b) => a.id - b.id);
    } catch (err) {
      console.error('SummaryService.getTopicRecipinets : ', { topicId, error: err });
      //throw new Error('SummaryService');

      return [];
    }
  };

  updateTopicParticipant = async (summaryId: number, participantDto: IParticipantDto) => {
    try {
      await BackendService.put(`summary/${summaryId}/participants`, JSON.stringify(participantDto));
    } catch (err) {
      console.error('SummaryService.updateTopicParticipant : ', {
        summaryId,
        participantId: participantDto.id,
        error: err,
      });
      throw new Error('SummaryService');
    }
  };

  getTopicMentions = async (topicId: number): Promise<IMentionsDto[]> => {
    try {
      const result: IMentionsDto[] = await BackendService.get(`summary/topic/mentions/${topicId}`);

      return result;
    } catch (err) {
      console.error('SummaryService.getTopicRecipinets : ', { topicId, error: err });
      //throw new Error('SummaryService');

      return [];
    }
  };

  getSummaryRecipinets = async (summarydId: number): Promise<IParticipantDto[]> => {
    try {
      const result: IParticipantDto[] = await BackendService.get(`summary/participants/${summarydId}`);

      return result.sort((a, b) => a.id - b.id);
    } catch (err) {
      console.error('SummaryService.getTopicRecipinets : ', { summarydId, error: err });
      //throw new Error('SummaryService');

      return [];
    }
  };

  getSummaryKeyDecisions = async (summarydId: number, isTranslate?: boolean): Promise<IKeyDecisionDto[]> => {
    try {
      const result: IKeyDecisionDto[] = await BackendService.get(
        `summary/key-decisions${isTranslate ? '/translate' : ''}/${summarydId}`,
      );
      result.sort((a, b) => a.id - b.id);
      result.forEach(dto => {
        if (dto.phraseLinks) {
          dto.uuid = uuidv4();
        }
      });

      return result;
    } catch (err) {
      console.error('SummaryService.getSummaryKeyDecisions : ', { summarydId, error: err });
      //throw new Error('SummaryService');

      return [];
    }
  };

  updateKeyDecision = async (summaryId: number, decision: IKeyDecisionDto) => {
    try {
      await BackendService.put(`summary/${summaryId}/key-decisions`, JSON.stringify(decision));
    } catch (err) {
      console.error('SummaryService.updateKeyDecision : ', { summaryId, decisionId: decision.id, error: err });
      throw new Error('SummaryService');
    }
  };

  getKeyDecisionOwners = async (summaryId: number): Promise<IKeyDecisionOwnerDto[]> => {
    try {
      const result: IKeyDecisionOwnerDto[] = await BackendService.get(`summary/key-decision-owners/${summaryId}`);

      return result.sort((a, b) => a.id - b.id);
    } catch (err) {
      console.error('SummaryService.getKeyDecisionOwners : ', { summaryId, error: err });
      //throw new Error('SummaryService');

      return [];
    }
  };

  getSummaryAmbiguities = async (summarydId: number, isTranslate?: boolean): Promise<IAmbiguityDto[]> => {
    try {
      const result: IAmbiguityDto[] = await BackendService.get(
        `summary/ambiguities${isTranslate ? '/translate' : ''}/${summarydId}`,
      );
      result.sort((a, b) => a.id - b.id);
      result.forEach(dto => {
        if (dto.phraseLinks) {
          dto.uuid = uuidv4();
        }
      });

      return result;
    } catch (err) {
      console.error('SummaryService.getSummaryAmbiguities : ', { summarydId, error: err });
      //throw new Error('SummaryService');

      return [];
    }
  };

  updateAmbiguity = async (summaryId: number, ambiguity: IAmbiguityDto) => {
    try {
      await BackendService.put(`summary/${summaryId}/ambiguities`, JSON.stringify(ambiguity));
    } catch (err) {
      console.error('SummaryService.updateAmbiguity : ', { ambiguity: ambiguity.id, error: err });
      throw new Error('SummaryService');
    }
  };

  resummarize = async (recordId: number): Promise<void> => {
    try {
      await BackendService.post(`summary/retry/${recordId}`, JSON.stringify({}));

      return;
    } catch (err) {
      console.error('SummaryService.resummarize : ', { recordId, error: err });
      throw new Error('SummaryService');
    }
  };

  retranslate = async (recordId: number): Promise<void> => {
    try {
      await BackendService.post(`summary/re-translate/${recordId}`, JSON.stringify({}));

      return;
    } catch (err) {
      console.error('SummaryService.retranslate : ', { recordId, error: err });
      throw new Error('SummaryService');
    }
  };
}

export const summaryService = new SummaryService();
