import React from 'react';
import { Box } from '@material-ui/core';

import { observer } from 'mobx-react';

import WordDictionaryChip from 'components/wordDictionary/WordDictionaryChip';

import { IWordDictionary } from '../wordDictionary/WordDictionary.types';

import { IRecord } from './types';

const RecordTopics = ({ record }: { record: IRecord }) => {
  if (!Array.isArray(record.topics)) {
    return <></>;
  }

  return (
    <Box display="flex" flexWrap="wrap">
      {record.topics.map((topic: IWordDictionary, index) => (
        <Box key={index} m={0.5}>
          <WordDictionaryChip topic={topic} />
        </Box>
      ))}
    </Box>
  );
};

export default observer(RecordTopics);
