import { FC, useMemo } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Box, Chip, FormControl, TextField, Typography, useTheme } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { DialogStore } from '../dialog.store';

import { Stack } from './Stack';

import { IGroup } from '@/components/groups';
import { gAPP_STORE } from '@/app/app-store';
import { dialogUploadRecordsRestrictions } from '@/common/constants';

const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: '0.4rem',
  },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 125,
    border: '1px solid #ccc',
  },
}));

export interface IItemGroups {
  data: DialogStore;
}

export const ItemGroups: FC<IItemGroups> = observer(({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const loggedUser = gAPP_STORE.loginStore.user;

  const blockGroupsWarn = useMemo(
    () =>
      loggedUser &&
      data.newGroups.length !== 0 &&
      !data.newGroups.some(g => (loggedUser.groups || []).map(gr => gr.id).includes(g.id)) ? (
        <>
          <Box p={'0.4rem'} />
          <Alert severity="error" style={{ fontSize: '1rem', whiteSpace: 'pre', paddingBottom: 0, textWrap: 'wrap' }}>
            {t('records.noUserGroupsWarning')}
          </Alert>
        </>
      ) : (
        <></>
      ),
    [data.newGroups, loggedUser, t],
  );

  const blockGroups = (
    <FormControl variant="outlined" fullWidth className={classes.formControl}>
      <Autocomplete
        multiple
        filterSelectedOptions
        clearText={t('uploadForm.clear')}
        noOptionsText={t('uploadForm.noGroups')}
        options={gAPP_STORE.getGroupsStore().getCurrentGroups()}
        getOptionLabel={option => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        value={data.newGroups}
        onChange={(_event, newValue) => {
          if (newValue.length > dialogUploadRecordsRestrictions.MAX_GROUPS_COUNT) {
            toast.warn(t('uploadForm.maxGroups', { max_groups: dialogUploadRecordsRestrictions.MAX_GROUPS_COUNT }));
          }
          data.setGroups(newValue.slice(0, dialogUploadRecordsRestrictions.MAX_GROUPS_COUNT));
        }}
        renderInput={params => <TextField {...params} variant="outlined" />}
        renderTags={(value: IGroup[], getTagProps) => (
          <Box display={'flex'} flexWrap="wrap" flexDirection="row">
            {value.map((group, index) => {
              const isCurrentUserGroup =
                gAPP_STORE.loginStore.user &&
                (gAPP_STORE.loginStore.user.groups || []).map(g => g.id).includes(group.id);

              return (
                <Chip
                  color="primary"
                  style={{
                    backgroundColor: isCurrentUserGroup ? theme.palette.primary.main : theme.palette.error.main,
                  }}
                  size="small"
                  label={group.name}
                  {...getTagProps({ index })}
                />
              );
            })}
          </Box>
        )}
        renderOption={(group: IGroup, props) => {
          const isCurrentUserGroup =
            gAPP_STORE.loginStore.user && (gAPP_STORE.loginStore.user.groups || []).map(g => g.id).includes(group.id);

          return (
            <span
              {...props}
              style={{ color: isCurrentUserGroup ? theme.palette.primary.main : theme.palette.error.main }}
            >
              {group.name}
            </span>
          );
        }}
      />
    </FormControl>
  );

  if (!gAPP_STORE.groups) return <></>;

  return (
    <>
      <Stack spacing={0}>
        <Stack direction="row" alignItems="center" spacing={0}>
          <Typography>{t('uploadForm.groups')}</Typography>
        </Stack>
        {blockGroups}
        {blockGroupsWarn}
      </Stack>
    </>
  );
});
