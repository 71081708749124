
import { useTranslation } from 'react-i18next';

import { dateRenderer, IDataGridCol, TDataGridRenderer } from '@uk';

import { observer } from 'mobx-react';

import { getWordDictionaryTypeLabel, valueToWordsStr } from './wordDictionaryUtils';
import { IWordDictionary } from './WordDictionary.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const langRenderer: TDataGridRenderer = (row: any, value: string) => {

  return <LocalLang lang={value}></LocalLang>;
};

const LocalLang: React.FC<{ lang: string }> = observer(({ lang }) => {
  const { t } = useTranslation('lang');

  return <>{t(lang)}</>;
});

const WordDictionaryUsingType: React.FC<{ wordDictionary: IWordDictionary }> = observer(({ wordDictionary }) => {
  const { t } = useTranslation();

  return <>{t(getWordDictionaryTypeLabel(wordDictionary))}</>;
});

const usingRenderer: TDataGridRenderer = (row: IWordDictionary) => {

  return <WordDictionaryUsingType wordDictionary={row} />;
};

const wordsRenderer: TDataGridRenderer = (row: IWordDictionary, value: string) => {
  return valueToWordsStr(value);
};

export const wordDictionaryGridDescriptor: IDataGridCol<IWordDictionary>[] = [
  { id: 'id', name: 'id', width: 60, align: 'center', noSort: true },
  { id: 'name', name: 'wordDictionary.name', width: 200, noSort: true },
  { id: 'language', name: 'wordDictionary.language', width: 100, renderer: langRenderer, noSort: true },
  { id: 'using', name: 'wordDictionary.using', width: 350, renderer: usingRenderer, noSort: true },
  { id: 'value', name: 'wordDictionary.value', width: 300, renderer: wordsRenderer, noSort: true },
  {
    id: 'createdDate',
    name: 'wordDictionary.createdDate',
    width: 150,
    renderer: dateRenderer,
    align: 'center',
    noSort: true
  },
];
