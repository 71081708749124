/* eslint-disable max-classes-per-file */
/**
 * Regions are visual overlays on the waveform that can be used to mark segments of audio.
 * Regions can be clicked on, dragged and resized.
 * You can set the color and content of each region, as well as their HTML content.
 */

import RegionsPlugin, { RegionsPluginOptions } from 'wavesurfer.js/dist/plugins/regions';
import { createElement } from 'wavesurfer.js/dist/dom';

class RegionsPluginExt extends RegionsPlugin {
  private paddingBottom: number;
  constructor(options?: RegionsPluginOptions, paddingBottom?: number) {
    super(options);

    this.paddingBottom = paddingBottom ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this as any).initRegionsContainer = this.initRegionsContainerExt;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this as any).regionsContainer = (this as any).initRegionsContainer();
  }

  private initRegionsContainerExt(): HTMLElement {
    return createElement('div', {
      style: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        bottom: `${this.paddingBottom}px`,
        zIndex: '3',
        pointerEvents: 'none',
      },
    });
  }
}

export default RegionsPluginExt;
