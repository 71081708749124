import { Box, MenuItem, Select } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ukColors from '@uk/ukColors';
import React from 'react';
import { IDataGridPaginationProps } from './types';
import PaginationControlPages from './PaginationControlPages';
import PaginationControlSteps from './PaginationControlSteps';

// const rowsPerPageList = [10, 15, 20, 30, 50, 100];
const rowsPerPageList = [10, 20, 30, 50, 100];

const DataGridPagination: React.FC<IDataGridPaginationProps<any>> = ({
  gridStore,
  tmpAdded,
  tmpDeleted,
  noPageNumbers,
}) => {
  const { t } = useTranslation();

  const handleChangeRowsPerPage = (event: any) => {
    gridStore.setRowsOnPage(event.target.value);
  };

  return (
    <Box
      borderTop="1px solid lightGrey"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      style={{ boxSizing: 'border-box', margin: '0px', height: '80px', minHeight: '80px' }}>
      <Box px={2}>{t('labelRowsPerPage')}:</Box>

      <Box py={1}>
        <Select
          disableUnderline
          style={{ background: ukColors.lightLightGrey, borderRadius: 5, padding: '0 8px' }}
          value={gridStore.rowsOnPage}
          onChange={handleChangeRowsPerPage}
          data-autotest={gridStore.dataGridId + '_pagination'}>
          {rowsPerPageList.map((v, index) => (
            <MenuItem
              key={index}
              value={v}
              data-autotest={gridStore.dataGridId + '_rowsOnPage_' + index}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {!noPageNumbers && (
        <PaginationControlPages gridStore={gridStore} tmpAdded={tmpAdded} tmpDeleted={tmpDeleted} />
      )}
      {noPageNumbers && (
        <PaginationControlSteps gridStore={gridStore} tmpAdded={tmpAdded} tmpDeleted={tmpDeleted} />
      )}
    </Box>
  );
};

export default observer(DataGridPagination);
