import React, { FC, useEffect, useState } from 'react';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { useTranslation } from 'react-i18next';

import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import ruLocale from 'date-fns/locale/ru';
import ptLocale from 'date-fns/locale/pt';

type LocaleMap = {
  en: Locale;
  es: Locale;
  ru: Locale;
  pt: Locale;
};

const localeMap: LocaleMap = {
  en: enLocale,
  es: esLocale,
  ru: ruLocale,
  pt: ptLocale,
};

interface IFilterFieldDatetime {
  value: string | null;
  name: string;
  handleChange: (date: Date | null) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}

const FilterFieldDatetime: FC<IFilterFieldDatetime> = (props) => {
  const { onKeyDown, handleChange, name, value } = props;
  const [dateTimeValue, setDateTimeValue] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const lang: keyof LocaleMap = localStorage.getItem('language') as keyof LocaleMap || '';
  const selectedLocale: Locale = localeMap[lang];

  const { t } = useTranslation();

  const onDateTimeChange = (date: Date | null): void => {
    setDateTimeValue(date?.toString() || null);
    
    if (date?.toString() !== 'Invalid Date' && !isOpen) {
      handleChange(date);
    }
  };

  const onClosePicker = () => {
    const changedDate = dateTimeValue ? new Date(dateTimeValue) : null;
    handleChange(changedDate);

    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) setDateTimeValue(value);
  }, [isOpen, value]);

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={selectedLocale}
    >
      <KeyboardDateTimePicker
        InputProps={{ disableUnderline: true, style: { paddingLeft: 5 } }}
        KeyboardButtonProps={{ style: { padding: 12, opacity: 0.5 } }}
        variant="inline"
        hideTabs
        ampm={false}
        name={name}
        value={value}
        onChange={onDateTimeChange}
        invalidDateMessage={t('invalidDateMessage')}
        format="yyyy-MM-dd HH:mm"
        onKeyDown={onKeyDown}
        onClose={onClosePicker}
        fullWidth
        onOpen={() => setIsOpen(true)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default FilterFieldDatetime;
