import { IFormLayout, TFormLayoutFieldGroupItem } from '@uk';
import { IForm, IFormFieldListItem } from './types';

const fillDescriptorLayoutFieldIds = (
  descriptorLayoutGroup: (TFormLayoutFieldGroupItem | undefined)[],
  ids: string[],
) => {
  if (descriptorLayoutGroup) {
    descriptorLayoutGroup.forEach(item => {
      if (item) {
        if (typeof item === 'string') {
          ids.push(item);
        } else {
          fillDescriptorLayoutFieldIds(item.group, ids);
        }
      }
    });
  }
};

export const getVisibleFormFieldIds = (descriptor: IForm, descriptorLayout?: IFormLayout) => {
  if (descriptorLayout) {
    const visibleFieldIds: string[] = [];
    fillDescriptorLayoutFieldIds(descriptorLayout.group, visibleFieldIds);

    return descriptor.fields.filter(f => f.id && visibleFieldIds.includes(f.id)).map(f => f.id);
  }
  return descriptor.fields.map(f => f.id);
};

export const convertValueToListItem = (val: any): IFormFieldListItem => {
  let result: IFormFieldListItem | undefined;
  if (typeof val === 'string') {
    result = { id: val, title: val } as IFormFieldListItem;
  } else {
    result = {
      id: val.id,
      title: val.title || val.name || val.text || 'undefined',
      __value: val.__value || val,
    } as IFormFieldListItem;
  }
  return result;
};
