import createTheme from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

import appColors from '@/app/app-colors';

// Used only to create transitions
const muiTheme = createTheme();

export const createComponents = (): Overrides => {
  return {
    // добавлено 
    MuiToolbar: {
      dense: {
        padding: 0,
        paddingLeft: '0.8em',
        paddingRight: '0.8em',
        flexGrow: 0,
        color: appColors.primary,
        textTransform: 'none',
      },
    },

    MuiTabs: {
      root: {
        minHeight: 0,
        background: appColors.tabsBackgroundColor,
        borderBottom: '1px solid #ddd',
      },
      indicator: {
        background: '#0288d1',
        height: 0,
      },
    },

    MuiTab: {
      root: {
        // fontSize: 14, //was katar
        // fontWeight: 500, //was katar
        lineHeight: 1.71,
        minWidth: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
        // textTransform: 'none',
        // '& + &': {
        //   marginLeft: 24,
        // }, //was katar
        // настройки от Сергея
        height: '2.5em',
        minHeight: '2.5em',
        fontSize: '1em',
        fontWeight: 'normal',
        textTransform: 'none',
        background: appColors.tabsBackgroundColor,
        color: '#ddd',
        borderRight: '1px solid #ddd',
        '&$selected': {
          background: 'white',
          color: 'black',
          boxShadow: '1px 0 5px rgba(0,0,0,0.1)',
          zIndex: 100,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1.5em',
      },
    },
    //имеющееся
    MuiAvatar: {
      root: {
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
      },
    },
    MuiButton: {
      root: {
        borderRadius: '12px',
        textTransform: 'none',
      },
      sizeSmall: {
        padding: '6px 16px',
      },
      // sizeMedium: {
      //   padding: "8px 20px",
      // },
      sizeLarge: {
        padding: '11px 24px',
      },
      textSizeSmall: {
        padding: '7px 12px',
      },
      // textSizeMedium: {
      //   padding: "9px 16px",
      // },
      textSizeLarge: {
        padding: '12px 16px',
      },
    },
    MuiCard: {
      root: {
        borderRadius: 20,
      },
    },
    MuiCardContent: {
      root: {
        padding: '32px 24px',
        '&:last-child': {
          paddingBottom: '32px',
        },
      },
    },
    MuiCardHeader: {
      root: {
        padding: '32px 24px 16px',
      },
    },
    // MuiCheckbox: {
    //   defaultProps: {
    //     checkedIcon: <CheckboxCheckedIcon />,
    //     color: 'primary',
    //     icon: <CheckboxIcon />,
    //     indeterminateIcon: <CheckboxIntermediateIcon />
    //   }
    // },
    // MuiDialogTitle: {
    //   root: {
    //     fontWeight: 100,
    //   },
    // },

    MuiChip: {
      root: {
        fontWeight: 500,
      },
    },
    MuiCssBaseline: {
      // "*": {
      //   boxSizing: "border-box",
      // },
      // html: {
      //   MozOsxFontSmoothing: "grayscale",
      //   WebkitFontSmoothing: "antialiased",
      //   display: "flex",
      //   flexDirection: "column",
      //   minHeight: "100%",
      //   width: "100%",
      // },
      // body: {
      //   display: "flex",
      //   flex: "1 1 auto",
      //   flexDirection: "column",
      //   minHeight: "100%",
      //   width: "100%",
      // },
      // "#__next": {
      //   display: "flex",
      //   flex: "1 1 auto",
      //   flexDirection: "column",
      //   height: "100%",
      //   width: "100%",
      // },
      // "#nprogress": {
      //   pointerEvents: "none",
      // },
      // "#nprogress .bar": {
      //   height: 3,
      //   left: 0,
      //   position: "fixed",
      //   top: 0,
      //   width: "100%",
      //   zIndex: 2000,
      // },
    },
    MuiIconButton: {
      sizeSmall: {
        padding: 4,
      },
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
        },
      },
    },
    MuiInput: {
      input: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '24px',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'transparent',
        borderRadius: 8,
        borderStyle: 'solid',
        borderWidth: 1,
        overflow: 'hidden',
        transition: muiTheme.transitions.create(['border-color', 'box-shadow']),
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
      input: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '24px',
      },
    },
    MuiOutlinedInput: {
      input: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '24px',
      },
      notchedOutline: {
        transition: muiTheme.transitions.create(['border-color', 'box-shadow']),
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '14px',
        fontWeight: 500,
        // [`&.${inputLabelClasses.filled}`]: {
        //   transform: "translate(12px, 18px) scale(1)",
        // },
        // [`&.${inputLabelClasses.shrink}`]: {
        //   [`&.${inputLabelClasses.standard}`]: {
        //     transform: "translate(0, -1.5px) scale(0.85)",
        //   },
        //   [`&.${inputLabelClasses.filled}`]: {
        //     transform: "translate(12px, 6px) scale(0.85)",
        //   },
        //   [`&.${inputLabelClasses.outlined}`]: {
        //     transform: "translate(14px, -9px) scale(0.85)",
        //   },
        // },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiLink: {
      // defaultProps: {
      //   underline: "hover",
      // },
    },
    MuiListItemIcon: {
      root: {
        marginRight: '16px',
        minWidth: 'unset',
      },
    },
    MuiPaper: {
      root: {
        backgroundImage: 'none',
      },
    },
    // MuiPopover: {
    //   elevation: 16,
    // },
    // MuiRadio: {
    //   color: "primary",
    // },
    // MuiSwitch: {
    //   color: "primary",
    // },
    MuiTableCell: {
      root: {
        padding: '15px 16px',
      },
    },
    MuiTableHead: {
      root: {
        borderBottom: 'none',
        // [`& .${tableCellClasses.root}`]: {
        //   borderBottom: "none",
        //   fontSize: 12,
        //   fontWeight: 600,
        //   lineHeight: 1,
        //   letterSpacing: 0.5,
        //   textTransform: "uppercase",
        // },
        // [`& .${tableCellClasses.paddingCheckbox}`]: {
        //   paddingTop: 4,
        //   paddingBottom: 4,
        // },
      },
    },
    MuiTextField: {
      root: {
        variant: 'filled',
      },
    },
  };
};
