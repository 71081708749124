import { FilterTranslationStatusList, RecordStatusMap } from 'components/records/types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import React, { useCallback, useEffect, useMemo } from 'react';

import { cloneDeep } from 'lodash';

import { FilterCommon } from '../common/common-filter';

import { getDefaultFilter, getFilterDescriptor } from './filter-descriptor';
import { TFilterDataFields } from './i-filter';
import { IFilterField, TFilterFieldValue, TFilterPredicate } from './i-filter-field';
import { IFilterFieldListItem, strListToFilterFieldList } from './filter-field-list';

import { gAPP_STORE } from '@/app/app-store';
import { ESpecialLanguageCode, ILanguage } from '@/components/languages/i-language';

const defaultLanguagesInFilter: string[] = [
  ESpecialLanguageCode.EN,
  ESpecialLanguageCode.ES,
  ESpecialLanguageCode.RU,
  ESpecialLanguageCode.KK,
];

interface IFilterProps {}

export const Filter: React.FC<IFilterProps> = observer(() => {
  const { t } = useTranslation();
  const dictorsStore = gAPP_STORE.getDictorsStore();
  const langT = useTranslation('lang')['t'];

  useEffect(() => {
    gAPP_STORE.getFilterStore().setFilterData(getDefaultFilter());
  }, []);

  const setFilterData = useCallback(f => {
    gAPP_STORE.getFilterStore().setFilterData(f());
  }, []);

  const applyFilter = useCallback(() => {
    gAPP_STORE.getRecordTextStore().clear();
    gAPP_STORE.getRecordsStore().reload();
    gAPP_STORE.getRecordsStore().gridStore.setCurrentPage(0);
    gAPP_STORE.getRecordsStore().gridStore.resetSelected();
  }, []);

  const handleFilterClear = useCallback(() => {
    setFilterData(() => {
      setFilterData(getDefaultFilter);
      applyFilter();

      return gAPP_STORE.getFilterStore().Data;
    });
  }, [applyFilter, setFilterData]);

  const getOwnersList = useCallback(() => {
    return gAPP_STORE.getUsersStore().data.users.map(u => ({ id: u.id.toString(), title: u.firstName }));
  }, []);
  const getTopicsList = useCallback(() => {
    return gAPP_STORE
      .getWordDictionaryStore()
      .data.wordDictionaryList.filter(wd => wd.isKws)
      .map(wd => ({ id: wd.id.toString(), title: wd.name }));
  }, []);
  const menuInstructions = useMemo(() => {
    const itemYes = t('records.summaryHaveTaskYes');
    const itemNo = t('records.summaryHaveTaskNo');

    return [
      { id: 'summaryHaveTaskYes', title: itemYes },
      { id: 'summaryHaveTaskNo', title: itemNo },
    ];
  }, [t]);
  const getInsructionsList = useCallback(() => {
    return menuInstructions;
  }, [menuInstructions]);
  const getDictorsList = useCallback(() => {
    const dictorNameList = dictorsStore.dictorsForFilter;
    const menuList: IFilterFieldListItem[] = [];
    dictorNameList.forEach((value: string, key: string) => {
      menuList.push({ id: key, title: value });
    });
    menuList.sort((a, b) => a.title.localeCompare(b.title));
    //const map: Map<string, IFilterFieldListItem> = new Map<string, IFilterFieldListItem>();
    // dictorsStore.dictorsForFilter.forEach(dictor => {
    //   const title = getTitle(dictor);

    //   const item: IFilterFieldListItem | undefined = map.get(title);
    //   if (item) {
    //     item.id = `${item.id}${IDS_DELIMETER}${dictor.id.toString()}`;
    //   } else {
    //     map.set(title, { id: dictor.id.toString(), title });
    //   }
    // });

    //return Array.from(map.values());
    return menuList;
  }, [dictorsStore.dictorsForFilter]);

  const localizeLanguagesList = useCallback(
    (list: (string | ILanguage)[]): IFilterFieldListItem[] => {
      return list
        .map(l => {
          const isoLang = gAPP_STORE.tServiceLangNameToISO(typeof l === 'string' ? l : l.name);
          if (isoLang && isoLang.code) {
            return { id: isoLang.code, title: langT(isoLang.code) } as IFilterFieldListItem;
          }

          return { id: '', title: 'error' };
        })
        .filter(f => f.id !== '');
    },
    [langT],
  );

  const getLanguagesList = useCallback(() => {
    let items = localizeLanguagesList(gAPP_STORE.getLanguagesStore().data.languages.filter(l => l.installed));
    if (!items || items.length === 0) {
      console.warn('Attention! T-Service languages undefined! No languages in gAPP_STORE.LanguagesStore.');
      items = localizeLanguagesList(defaultLanguagesInFilter);
    }

    return items;
  }, [localizeLanguagesList]);

  const handleFilterChange = useCallback(
    (id: string, value: TFilterFieldValue | null | undefined, score?: number) => {
      setFilterData(() => {
        const newFilterData = cloneDeep(gAPP_STORE.getFilterStore().Data);

        if (value === undefined && ['createFrom', 'createTo', 'loadFrom', 'loadTo'].includes(id)) {
          //нет времени => нет фильтра
          switch (id) {
            case 'createFrom':
              newFilterData.createFrom = undefined;
              break;
            case 'createTo':
              newFilterData.createTo = undefined;
              break;
            case 'loadFrom':
              newFilterData.loadFrom = undefined;
              break;
            case 'loadTo':
              newFilterData.loadTo = undefined;
              break;
          }

          return newFilterData;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newFilterDataField: any = newFilterData[id as TFilterDataFields]
          ? newFilterData[id as TFilterDataFields]
          : {};

        if (id === 'topic') {
          newFilterData.topic = { value: value ? value : [], score } as IFilterField;

          return newFilterData;
        }

        if (Array.isArray(value)) {
          let goodvalues: string[];
          switch (id) {
            case 'status':
              goodvalues = Array.from(RecordStatusMap.values());
              break;
            case 'translationStatus':
              const arr = Array.from(RecordStatusMap.values());
              goodvalues = arr.splice(1, arr.length - 2);
              break;
            case 'languageResolved':
              goodvalues = getLanguagesList().map(item => item.id);
              break;
            case 'owner':
              goodvalues = getOwnersList().map(item => item.id);
              break;
            case 'dictors':
              goodvalues = getDictorsList().map(item => item.id);
              break;
            case 'summaryText':
              goodvalues = value.slice(0, 20);
              break;
            default:
              goodvalues = value;
          }
          newFilterDataField.value = value.filter(v => goodvalues.includes(v));
        } else {
          newFilterDataField.value = value;
        }
        newFilterDataField.score = score;

        return newFilterData;
      });
    },
    [getDictorsList, getLanguagesList, getOwnersList, setFilterData],
  );

  const handleFilterPredicateChange = useCallback(
    (id: string, value: TFilterPredicate) => {
      setFilterData(() => {
        const newFilterData = gAPP_STORE.getFilterStore().Data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newFilterDataField: any = newFilterData[id as TFilterDataFields]
          ? newFilterData[id as TFilterDataFields]
          : {};

        newFilterDataField.predicate = value;

        return newFilterData;
      });
    },
    [setFilterData],
  );

  const handleFilterApply = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        applyFilter();
      }
    },
    [applyFilter],
  );

  const getNoTranslateValue = useCallback((descriptor: IFilterField): boolean | undefined => {
    let result = undefined;
    if (descriptor.id === 'dictors' || descriptor.id === 'topic') {
      result = true;
    }

    return result;
  }, []);

  const getFilterFieldList = useCallback(
    (descriptor: IFilterField): IFilterFieldListItem[] => {
      let result: IFilterFieldListItem[] = [];

      switch (descriptor.id) {
        case 'status':
          result = strListToFilterFieldList(
            Array.from(RecordStatusMap.values()).filter(v => !['recordStatus.unknown'].includes(v)),
          );
          break;

        case 'translationStatus':
          result = strListToFilterFieldList(
            Array.from(RecordStatusMap.values()).filter(v => FilterTranslationStatusList.includes(v)),
          );
          break;

        case 'languageResolved':
          result = getLanguagesList();
          break;

        case 'owner':
          result = getOwnersList();
          break;

        case 'topic':
          result = getTopicsList();
          break;

        case 'dictors':
          result = getDictorsList();
          break;

        case 'summaryInstructions':
          result = getInsructionsList();
          break;
      }

      return result;
    },
    [getLanguagesList, getOwnersList, getTopicsList, getDictorsList, getInsructionsList],
  );

  const filterFields = useMemo(() => {
    let f = gAPP_STORE.viewTranslate
      ? getFilterDescriptor()
      : getFilterDescriptor().filter(d => d.id !== 'translationStatus' && d.id !== 'translateIdx');
    if (!gAPP_STORE.avocado_topics) {
      f = f.filter(field => !['topic', 'topicThreshold'].includes(field.id));
    }

    if (!gAPP_STORE.gridProxyUrl || gAPP_STORE.gridProxyUrl.length <= 0) {
      f = f.filter(field => !['dictors'].includes(field.id));
    }

    if (!gAPP_STORE.showSummaryAI) {
      f = f.filter(field => !['summaryText', 'summaryInstructions', 'summaryTopics'].includes(field.id));
    }

    return f;
  }, []);

  const filterData = gAPP_STORE.getFilterStore().Data;

  return (
    <>
      <FilterCommon
        getFilterFieldList={getFilterFieldList}
        filterFields={filterFields}
        handleFilterChange={handleFilterChange}
        handleFilterPredicateChange={handleFilterPredicateChange}
        handleKeyDown={handleKeyDown}
        getNoTranslateValue={getNoTranslateValue}
        filterData={filterData}
        handleFilterApply={handleFilterApply}
        handleFilterClear={handleFilterClear}
      />
    </>
  );
});
