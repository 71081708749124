import { FC } from 'react';

import WordDictionaryList from './WordDictionaryList';

import { gAPP_STORE } from '@/app/app-store';

export const WordDictionaryPage: FC = () => {
  return (
    <>
      {gAPP_STORE.loginStore.user?.isAdmin &&
      (gAPP_STORE.avocado_topics || gAPP_STORE.avocado_wordsDictionary) &&
      <WordDictionaryList />}
    </>
  );
};