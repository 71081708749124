import { action, makeObservable } from 'mobx';

import { ISelectedFlag } from './selectedFlagStore';

export class SelectedByIndexLogic {
  private selectedIndexes: ISelectedFlag[]; //observable elements
  private selectionForCopy: boolean;
  private startedIndex?: number;
  private indexTo?: number;
  private len: number;

  private lastSelected?: number;

  constructor(public phraseIndex: number, len: number) {
    this.len = len;
    this.selectionForCopy = false;
    this.selectedIndexes = Array.from(Array(len), x => new ISelectedFlag());
    makeObservable(this);
  }

  getIndexesCount() {
    return this.len;
  }

  getSelectedByIndex(idx: number) {
    return this.selectedIndexes[idx].selected;
  }

  getSelectionForCopy() {
    return this.selectionForCopy;
  }

  isLastByIndex(idx: number) {
    return this.selectedIndexes[idx].last;
  }

  getFromToOrdered() {
    return this.startedIndex !== undefined && this.indexTo !== undefined
      ? [Math.min(this.startedIndex, this.indexTo), Math.max(this.startedIndex, this.indexTo)]
      : undefined;
  }

  setSelectionFinished(f: boolean) {
    const ordered = this.getFromToOrdered();
    if (ordered) {
      this.lastSelected = ordered[1];
      this.selectedIndexes[this.lastSelected].last = true;
    }
  }

  @action
  reset() {
    this.selectedIndexes.forEach((e, i) => this.selectedIndexes[i].reset());
    this.startedIndex = undefined;
    this.indexTo = undefined;
    this.selectionForCopy = false;
  }

  @action
  setSelectedByIndex(idx: number, val: boolean) {
    this.selectedIndexes[idx].selected = val;
  }

  @action
  startSelectFrom(idx: number, forCopy: boolean) {
    this.selectionForCopy = forCopy;
    this.resetLastSelected();
    this.selectedIndexes[idx].selected = true;
    this.startedIndex = idx;
    this.indexTo = idx;
  }

  private resetLastSelected() {
    if (this.lastSelected !== undefined) {
      this.selectedIndexes[this.lastSelected].last = false;
    }
  }

  @action
  selectTo(idx: number) {
    if (this.startedIndex === undefined) return;

    this.resetLastSelected();

    const from = Math.min(this.startedIndex, idx);
    const to = Math.max(this.startedIndex, idx);

    this.indexTo = idx;

    for (let i = 0; i < this.selectedIndexes.length; i++) {
      const v = i >= from && i <= to;
      if (v !== this.selectedIndexes[i].selected) {
        this.selectedIndexes[i].selected = v;
      }
    }
  }
}
