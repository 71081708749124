import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ClickAwayListener } from '@material-ui/core';

import styles from './editableTextArea.module.scss';

import { gAPP_STORE } from '@/app/app-store';
import { EDirection } from '@/components/languages/i-language';

interface IEditableTextArea {
  id: string;
  text: string;
  placeholder?: string;
  emptyValue?: string;
  onEdit: (value: string) => void;
  maxLength?: number;
  prefix?: string;
  defaultStyle?: CSSProperties;
  editStyle?: CSSProperties;
  readOnly?: boolean;
}

export const EditableTextArea = (props: IEditableTextArea) => {
  const { id, text, placeholder, emptyValue, onEdit, maxLength, prefix, defaultStyle, editStyle, readOnly } = props;
  const { currentSummaryRtl: rtl } = gAPP_STORE.summaryStore;
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement | null>(null);
  const prefixRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [value, setValue] = useState<string>('');
  const [newLines, setNewLines] = useState(0);
  const [editMode, setEditMode] = useState(false);

  const clickAwayHandler = useCallback(() => {
    if (!editMode) return;

    void onEdit(value);
    setEditMode(false);
  }, [editMode, onEdit, value]);

  const handleResize = useCallback(() => {
    const container = containerRef.current;
    const height = ref.current?.clientHeight || 0;

    if (container && height) {
      container.style.height = editMode ? `${height + newLines * 16}px` : 'fit-content';
      setNewLines(0);
    }
    //TODO: Костыль, нужно будет фиксить https://react.dev/learn/removing-effect-dependencies#to-change-the-dependencies-change-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, newLines, value]);

  const valueArray = useMemo(() => value.split('\n'), [value]);

  useEffect(() => {
    handleResize();
  }, [editMode, handleResize]);

  useEffect(() => {
    if (value !== text) {
      setValue(text);
    }
    //TODO: что-то не так с депсами, скорее всего, неправильно реализован управляемый компонент, не хватает 'value', компонент ломается при указании этого пропа
  }, [text]);

  const openEditable = () => {
    if (readOnly) return;

    setEditMode(true);
  };

  const editTextAreaStyle: CSSProperties = useMemo(
    () => ({
      direction: rtl,
      textAlign: `${rtl === EDirection.RTL ? 'right' : 'left'}`,
      ...editStyle,
    }),
    [editStyle, rtl],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        setNewLines(prev => prev + 1);
        handleResize();
      }
    },
    [handleResize],
  );

  const prefixEl = useMemo(
    () =>
      prefix ? (
        <div
          ref={prefixRef}
          id={'prefix-element'}
          style={{
            ...defaultStyle,
            display: 'flex',
            backgroundColor: editMode ? '#fff' : 'transparent',
            color: editMode && value === '' ? '#999999' : undefined,
            fontWeight: editMode && value === '' ? '400' : defaultStyle?.fontWeight,
            padding: editMode ? '8px' : '0',
            paddingInlineEnd: editMode ? 0 : '8px',
            zIndex: 5,
          }}
        >
          {prefix}
        </div>
      ) : (
        ''
      ),
    [defaultStyle, editMode, editTextAreaStyle, prefix, value],
  );

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <div
        data-autotest={id}
        id={`editable-${id}`}
        ref={containerRef}
        className={styles.container}
        style={{
          direction: rtl,
          textAlign: 'start',
          borderRadius: '8px',
          border: editMode ? '1px solid rgba(11, 43, 72, 1)' : 'none',
          boxShadow: editMode ? '0 1px 8px 0 rgba(0, 0, 0, 0.24)' : 'none',
        }}
      >
        {prefixEl}
        <div
          ref={ref}
          className={styles.editArea}
          style={{
            opacity: 0,
            zIndex: -1,
            ...editTextAreaStyle,
          }}
        >
          {valueArray.map((part, index) => (
            <div key={index} style={{ display: 'flex', flexGrow: 1 }}>
              <p style={{ margin: 0, padding: 0 }}>{part}</p>
              <br />
            </div>
          ))}
        </div>
        {editMode ? (
          <textarea
            name="description"
            id="description"
            className={styles.editArea}
            style={{
              inset: 0,
              ...editTextAreaStyle,
              insetInlineStart: prefix ? `${prefixRef.current?.clientWidth}px` : 0,
            }}
            autoFocus
            onInput={handleResize}
            maxLength={maxLength}
            onChange={e => setValue(e.target.value)}
            value={value}
            placeholder={t(placeholder || '')}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <div
            className={styles.readArea}
            style={{
              ...defaultStyle,
              cursor: readOnly ? 'default' : 'pointer',
            }}
            onDoubleClick={openEditable}
          >
            {value ? (
              <>
                {value.split('\n').map((s, index, arr) => (
                  <span key={`editAreaWord${index}`}>
                    <span>
                      {s}
                      {index + 1 === arr.length ? '.' : ''}
                    </span>
                    <br />
                  </span>
                ))}
              </>
            ) : (
              emptyValue
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
