import { DataGridStore, IDataGridStoreData, IDataGridStoreState } from '@uk';
import { makeAutoObservable, runInAction } from 'mobx';

import { BackendService } from 'services';

import { toast } from 'react-toastify';

import i18n from 'i18next';

import { templatesGridColumns } from './templates-grid-columns';

import { ITemplate } from './i-template';

import { BackendError } from '@/services/types';

export class TemplatesStore {
  data: { templates: ITemplate[] } = { templates: [] };
  searchQuery = '';

  gridStore: DataGridStore<ITemplate>;
  gridCols = templatesGridColumns;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    const dataProvider = async (state: IDataGridStoreState): Promise<IDataGridStoreData<ITemplate>> => {
      await this.getAllImpl();

      return { data: this.data.templates, total: this.data.templates.length, hasNextPage: false };
    };

    this.gridStore = new DataGridStore<ITemplate>('TemplateSettings', this.gridCols, { dataProvider: dataProvider });
  }

  /**
   * Get all templates
   */
  getAll = async () => {
    await this.gridStore.reload();
  };

  private getAllImpl = async () => {
    try {
      const data = await BackendService.get('template/all');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      runInAction(() => (this.data.templates = data.sort((a: any, b: any) => (a.id === b.id ? 0 : a.id - b.id))));
    } catch (error) {
      this.data.templates = [];
      if (error instanceof BackendError && error.status === 401) {
        toast.error(i18n.t(error.statusMessage));
      } else {
        toast.error(`Server error: ${JSON.stringify(error)}`);
        throw error;
      }
    }
  };

  /**
   * Create new template
   */
  create = async (template: ITemplate) => {
    try {
      const newTemplateData = {
        fileName: template.fileName,
        templateName: template.templateName,
        template_b64: btoa(template.template),
      };

      await BackendService.put('template/add', JSON.stringify(newTemplateData));
      this.gridStore.reload();
    } catch (error) {
      toast.error(`Server error: ${JSON.stringify(error)}`);
      throw error;
    }
  };

  /**
   * Update template
   */
  update = async (template: ITemplate) => {
    try {
      await BackendService.put('template', JSON.stringify(template));
      this.gridStore.reload();
    } catch (error) {
      toast.error(`Server error: ${JSON.stringify(error)}`);
      throw error;
    }
  };

  /**
   * Delete template
   */
  delete = async (template: ITemplate) => {
    try {
      await BackendService.delete(`template/${template.id.toString()}`);
      this.gridStore.reload();
    } catch (error) {
      toast.error(`Server error: ${JSON.stringify(error)}`);
      throw error;
    }
  };
}
