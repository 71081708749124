import { DataTreeNode } from './DataTreeNode';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';


export type TGenericDataTree<T = any> = React.FC<IDataTreeProps<T>>;
export type TGenericDataTreeNode<T = any> = React.FC<IDataTreeNodeLabel<T>>;
export type TGenericTreeNode<T = any> = IDataTreeNode<T>;

export interface IDataTreeProps<T> {
  id?: string;
  treeStore: IDataTreeStore<T>;
  expandIcon?: ReactNode,
  collapseIcon?: ReactNode,
  nodeRenderer?: (node: IDataTreeNode<T>) => ReactNode
}
export interface IDataTreeNodeLabel<T> {
  node: IDataTreeNode<T>;
  nodeRenderer?: (node: IDataTreeNode<T>) => ReactNode
}

// export enum EDataTreeStoreSortMode {
//   NONE = -1,
//   ASC = 0,
//   DSC = 1,
// }

// export interface IDataTreeStoreState {
//   sort?: EDataTreeStoreSortMode;
//   sortField?: string;
// }

export interface IDataTreeNode<T> {
  parentId: string | null;
  children?: IDataTreeNode<T>[];
  isPending: boolean;
  title: string;
  id: string;
  data?: T;
}

export type TTreeDataProvider<T> = (
  node: IDataTreeNode<T>
) => Promise<IDataTreeNode<T>[]>;

export interface IDataTreeStoreDataProvider<T> {
  dataProvider?: TTreeDataProvider<T>;
}

export interface IDataTreeStore<T> {
  getData(): IDataTreeNode<T>;
  error: string | null;
  storeDataProvider: IDataTreeStoreDataProvider<T>;
  getRoot(): IDataTreeNode<T>;
  getSelected(): string[];
  getExpanded(): string[];
  //loadData: (node: IDataTreeNode<T> | null) => Promise<void>;
  reset: () => void;

  setSelectedNode(node: IDataTreeNode<T> | null): void;
  setExpanded(nodes: IDataTreeNode<T>[]): void;
  setCollapseAll(): void;
  setCollapse(node: IDataTreeNode<T>): void;

  appendChild(parentId: string | null, child: IDataTreeNode<T>): void;
  removeChild(parentId: string | null, childId: string): void;

  getNodesByIds(ids: string[]): IDataTreeNode<T>[];
  root: IDataTreeNode<T>;
}

export const ObservedTreeNode = observer(DataTreeNode);