import { Box, BoxProps } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ukColors from '@uk/ukColors';
import { Panel } from '../panel';
import { TitleBar } from '../titleBar';

const AccordionWithStyle = withStyles({
  root: {
    margin: '0 !important',
    boxShadow: 'none',
    borderBottom: '1px solid white',
    border: 'none',
    '&:before': {
      height: 0,
    },
  },
  expanded: {
    border: 'none',
  },
})(MuiAccordion);

const AccordionSummaryWithStyle = withStyles({
  root: {
    minHeight: '0 !important',
    // background: ukColors.ligthLightGrey,
    // background: 'white',
    border: 'none',
    // borderRadius: '0.5em',
    // borderBottom: '1px solid lightGrey',
    // fontSize: '1em',
    fontWeight: 'bold',
    color: ukColors.primary,
  },
  content: {
    // fontWeight: "bold",
    margin: '0 !important',
    border: 'none !important',
  },
})(MuiAccordionSummary);

const AccordionDetailsWithStyle = withStyles({
  root: {
    // margin: '0 !important',
    padding: '8px 1px !important',
    // padding: '8px !important',
    // border: 'none !important',
    // borderTop: "1px solid lightGrey !important",
    // borderBottom: "1px solid lightGrey !important",
    // border: '1px red solid'
  },
})(MuiAccordionDetails);

export interface IAccordionProps {
  title?: string;
  isExpanded?: boolean;
  autotestId?: string;
  isNotExpandable?: boolean;
  children?: ReactNode[] | ReactNode;
}

const Accordion: React.FC<IAccordionProps & BoxProps> = ({
  title,
  isExpanded,
  autotestId,
  isNotExpandable,
  children,
  ...rest
}) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(isExpanded);
  const childNodes = Array.isArray(children) ? children : [children];

  useEffect(() => setExpanded(isExpanded), [isExpanded]);

  return (
    <Panel {...rest}>
      <AccordionWithStyle
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded}
        className={rest.className}
        onChange={(event, isExpanded) => setExpanded(isExpanded)}
      >
        {isNotExpandable ? (
          <TitleBar
            // title={t(title || '') + ` - ${autotestId}`}
            title={t(title || '')}
            // hasBottomBorder
            autotestId={autotestId}
            // bgcolor={ukColors.ligthLightGrey}
            fontWeight="bold"
          />
        ) : (
          <AccordionSummaryWithStyle expandIcon={<ExpandMoreIcon />}>
            <div data-autotest={'acc-title-' + autotestId || ''}>{t(title || '')}</div>
          </AccordionSummaryWithStyle>
        )}

        <AccordionDetailsWithStyle>
          <Box display="flex" flexGrow={1} flexDirection="column">
            {childNodes.map((child, index) => (
              <Box key={index}>{child}</Box>
            ))}
          </Box>
        </AccordionDetailsWithStyle>
      </AccordionWithStyle>
    </Panel>
  );
};

export default Accordion;
