import { observer } from 'mobx-react';

import { Chip, useTheme } from '@material-ui/core';
//import { ColoredLabel } from '@uk';

import { IHotlist } from './types';

const ellipses = '…';
export interface IHotlistChip {
  hotlist: IHotlist;
  index: number;
  onDelete?: (index: number) => void;
  maxLength?: number;
}

const HotlistChip = ({ index, hotlist, maxLength, onDelete }: IHotlistChip) => {
  const theme = useTheme();
  const name =
    !maxLength || maxLength <= 0 || hotlist.GroupName.length <= maxLength
      ? hotlist.GroupName
      : `${hotlist.GroupName.substring(0, maxLength - 1)}${ellipses}`;

  return (
    <Chip
      key={index}
      label={name}
      color="primary"
      style={{ backgroundColor: theme.palette.info.main }}
      size={onDelete ? 'small' : undefined}
      onDelete={onDelete ? () => onDelete(index) : undefined}
    />
  );
};

export default observer(HotlistChip);
