
import { dateRenderer, IDataGridCol } from '@uk';

import { nullValueRenderer } from '../common/column-renderers';

import { IGroup } from './types';

export const groupsGridColumns: IDataGridCol<IGroup>[] = [
  { id: 'id', name: 'id', width: 60, align: 'center', noSort: true },
  { id: 'name', name: 'groups.name', width: 200, noSort: true },
  { id: 'description', name: 'groups.description', width: 300, renderer: nullValueRenderer, noSort: true },
  { id: 'createdDate', name: 'groups.createdDate', width: 150, renderer: dateRenderer, align: 'center', noSort: true },
];
