const SPECIAL_CHARS_ARRAY = [
  '®✉§©☯☭?$£¢₿€₣¥₽¢¤⓿❶❷❸❹❺❻❼❽❾❿⓫⓬⓭⓮⓯⓰⓱⓲⓳⓴⑴⑵⑶⑷⑸⑹⑺⑻⑼⑽⑾⑿⒀⒁⒂⒃⒄⒅⒆⒇①②③④⑤⑥⑦⑧⑨⑩⑪',
  '⑫⑬⑭⑮⑯⑰⑱⑲⑳ⒶⒷⒸⒹⒺⒻⒼⒽⒾⒿⓀⓁⓂⓃⓄⓅⓆⓇⓈⓉⓊⓋⓌⓍⓎⓏ⒈⒉⒊⒋⒌⒍⒎⒏⒐⒑⒒⒓⒔⒕⒖⒗⒘⒙⒚⒛ⓐⓑⓒⓓⓔⓕⓖⓗⓘⓙⓚⓛⓜⓝ',
  'ⓞⓟⓠⓡⓢⓣⓤⓥⓦⓧⓨⓩ⓪⒜⒝⒞⒟⒠⒡⒢⒣⒤⒥⒦⒧⒨⒩⒪⒫⒬⒭⒮⒯⒰⒱⒲⒳⒴⒵◜◝◞◟◠◡◰◱◲◳◴◵◶◷▖▗▘▙▚▛▜▝▞▟■◸◹◺◻◼',
  '◽◿►▻▼▽▾▿◀◁◂▻□▢▣▪▫▬▭▮▯▰▱▤▥▦▧▨▩▲△▴▵▶▷▸▹►▻◢◣◤◥◆◇◈◉◊○◌◍◎●◐◑◒◓◔◕◧◨◩◪◫◖◗◘◙◚◛',
  '◦◬◭◮◯▁▂▃▄▅▆▇█▌▍▎▏▐▀▉▊▋─━│┃└┕┖┗┘┙┚┛┄┅┆┇┈┉┊┋░▒▓▔▕┌┍┎┏┐┑┒┓╭╮╯╰╱╲╳├┝┞┟┠┡┢',
  '┣┤┥┦┧┨┩┪┫╴╵╶╷╸╹╺╻╼╽╾╿┴┵┶┷┸┹┺┻┬┭┮┯┰┱┲┳┼┽┾┿╀╁╂╃╄╅╆╇╈╉╊╋╤╥╦╧╨╩╪╫╬╘╙╚╛╜╝╌',
  '╍╎╏═║╞╟╠╡╢╣╒╓╔╕╖╗⟨⟩⟪⟫⟰⟱❍❏❐❑❒✔✕✖✗✘☀☁☂☃🤘☄★💪☢☣☯☮☣☬☪☆☇☈☉☊☋☌☍☡☢☣☤☥☧☨☩☪☎☏☐☑',
  '☒⟦⟧⟲⟳⟴⟵➘➙➚➛➜➝➞➟➠➡☓☔☕☖☗☘☙☚☛☜☝☞☟☠☫☬✆✇✈✉✌✍✎✏✐✑➲➳➴➵➶➷➸☰☱☲☳☴☵☶☷☭☮☯♮♯♰♱➱➢➣➤',
  '➥➦➧➨➩➪➫➬➭➮➯➔❁❂❃❄❅❆❇❈❉❊❋✁✂✃✄✒✓☦✫✬✭✮✯✰✝✞✟✠✡✢✣✤✥✡〄♨☸⌘✱✲✳✴✵✶✷✸✹✺✻✼✽❀✙✚✛✾✿',
  '✜✦✧✩✪➹➺➻➼➽➾❖❡❢❣❤❥❦❧❘❙❚❛❜❝❞👌➿⟠⟡ⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹⅺⅻⅼⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩⅪⅫⅬ♕♖♗♘♙♚♛♜♝♞♟♠♩',
  '♪♫♬♭♮♯♡♢♣♤♥♦♧ℂ℃℄℅℆ℇ℈℉ℊℋℌℍℎℏℐℑℒℓ℔ℕ℗℘ℙℚℛℜℝ℞℟℠℡™℣ℤ℥Ω℧ℨ℩KÅ№ℬℭ℮ℯℰℱℲℳℴ⅓⅔⅕⅖⅗',
  '⅘⅙⅚⅛⅜⅝⅞⅟ℵℶℷℸℹ℺℻ℽℾℿ⅀⅁⅂⅃⅄ⅅⅆⅇⅈⅉ⅊⅋⅍ⅎⅭⅮⅯ∀∁∂∃∄∅∆∇∈∉∊∋∌∍∎∏∐∑−∓∔∕∖∗∘∙√∛∜∝∞∟∠∡',
  '∢∣∤∥∦∧∨∩∪∫∬∭∮∯∰∱∲∳∴∵∶∷∸∹∺∻∼∽∾∿≀≁≂≃≄≅≆≇≈≉≊≋≌≍≎≏≐≑≒≓≔≕≖≗≘≙≚≛≜≝≞≟≠≡≢≣≤≥≦',
  '≧≨≩≪≫≬≭≮≯≰≱≲≳≴≵≶≷≸≹≺≻≼≽≾≿⊀⊁⊂⊃⊄⊅⊆⊇⊈⊉⊊⊋⊌⊍⊎⋐⋑⋒⋓⋔⋕⋖⋗⋘⋙⋚⋛⋜⋝⋞⋟⋠⋡⋢⋣⋤⋥⋦⋧⋨⋩⋪⋫⋬',
  '⋭⋮⋯⋰⋱⋲⋳⋴⋵⋶⋷⋸⋹⋺⋻⋼⋽⋾⋿⌀ᴀᴁᴂᴃᴄᴅᴆᴇᴈᴉᴊᴋᴌᴍᴎᴏᴐᴑᴒᴓᴔᴕᴖᴗᴘᴙᴚᴛᴜᴝᴞᴟᴠᴡᴢᴣᴤᴥᴦᴧᴨᴩᴪᴫᴬᴭᴮᴯᴰ',
  'ᴱᴲᴳᴴᴵᴶᴷᴸᴹᴺᴻᴼᴽᴾᴿᵀᵁᵂᵃᵄᵅᵆᵇᵈᵉᵊᵋᵌᵍᵎᵏᵐᵑᵒᵓᵔᵕᵖᵗᵘᵙᵚᵛᵜᵝᵞᵟᵠᵡᵢᵣᵤᵥᵦᵧᵨᵩᵪᵫᵬᵭᵮᵯᵰᵱᵲᵳᵴᵵ',
  'ᵶᵷᵸᵹᵺᵻᵼᵽᵾᵿᶀᶁᶂᶃᶄᶅᶆᶇᶈᶉᶊᶋᶌᶍᶎᶏᶐᶑᶒᶓᶔᶕᶖᶗᶘᶙᶚᶛᶜᶝᶞᶟᶠᶡᶢᶣᶤᶥᶦᶧᶨᶩᶪᶫᶬᶭᶮᶯᶰᶱᶲᶳᶴᶵᶶᶷᶸᶹᶺ',
  'ᶻᶼᶽᶾᶿῲῳῴῶῷῸΌῺΏῼ⍳⍴⍵⍶⍷⍸⍹⍺←↑→↓↔↕↖↗↘↙↚↛↜↝↞↟↠↡↢↣↤↥↦↧↨↩↪↫↬↭↮↯↰↱↲↳↴↵↶↷↸↹↺↻↼↽',
  '↾↿⇀⇁⇂⇃⇄⇅⇆⇇⇈⇉⇊⇋⇌⇍⇎⇏⇐⇑⇒⇓⇔⇕⇖⇗⇘⇙⇚⇛⇜⇝⇞⇟⇠⇡⇢⇣⇤⇥⇦⇧⇨⇩⇪⇫⇬⇭⇮⇯⇰⇱⇲⇳⇴⇵⇶⇷⇸⇹⇺⇻⇼⇽⇾⇿⊲⊳⊴',
  '⊵⊶⊷⊸⊹⊺⊻⊼⊽⊾⊿⋀⋁⋂⋃⋄⋅⋆⋇⋈⋉⋊⋋⋌⋍⋎⋏⌁⌂⌃⌄⌅⌆⌇⌈⌉⌊⌋⌌⌍⌎⌏⌐⌑⌒⌓⌔⌕⌖⌗⌘⌙⌚⌛⌜⌝⌞⌟⌠⌡⌢⌣⌤⌥⌦⌧⏎⏏⟶',
  '⟷⟸⟹⟺⟻⟼⟽⟾⟿⤀⤁⤂⤃⤄⤅⤆⤇⤈⤉⤊⤋⤌⤍⤎⤏⤐⤑⤒⤓⤔⤕⤖⤗⤘⤙⤚⤛⤜⤝⤞⤟⤠⤡😀😃😄😁😆😅🤣😂🙂🙃😉😊😇🥰😍🤩😘😗☺',
  '😚😙😋😛😜🤪😝🤑🤗🤭🤫🤔🤐🤨😐😑😶😏😒🙄😬🤥😌😔😪🤤😴😷🤒🤕🤢🤮🤧🥵🥶🥴😵🤯🤠🥳😎🤓🧐😕😟🙁☹',
  '😮😯😲😳🥺😦😧😨😰😥😢😭😱😖😣😞😓😩😫😤😡😠🤬😈👿💀☠💩🤡👹👺👻👽👾🤖😺😸😹😻😼😽🙀😿😾🙈🙉🙊',
  '👋🤚🖐✋🖖👌✌🤞🤟🤘🤙👈👉👆🖕👇☝👍👎✊👊🤛🤜👏🙌👐🤲🤝🙏✍💅🤳💪🦵🦶👂👃🧠🦷🦴👀👁👅👄👶🧒👦👧🧑👱👨🧔',
  '👩🧓👴👵🙍🙎🙅🙆💁🙋🙇🤦🤷👮🕵💂👷🤴👸👳👲🧕🤵👰🤰🤱👼🎅🤶🧙🧚🧛🧜🧝🧞🧟💆💇🚶🏃💃🕺🕴👯🧖🧗🤺🏇⛷🏂🏌🏄',
  '🚣🏊⛹🏋🚴🚵🤸🤼🤽🤾🤹🧘🛀🛌👭👫👬💏💑👪🗣👤👥👣👓🕶🥽🥼👔👕👖🧣🧤🧥🧦👗👘👙👚👛👜👝🛍🎒👞👟🥾🥿👠👡',
  '👢👑👒🎩🎓🧢⛑📿💄💍💎🔇🔈🔉🔊📢📣📯🔔🔕🥁📱📲☎📞📟📠🔋🔌💻🖥🖨⌨🖱🖲💽💾💿📀🧮🎬📷📸🔍🔎🕯💡🔦🏮',
  '📔📕📖📗📘📙📚📓📒📃📜📄📰🗞📑🔖🏷🧾💹✉📧📨📩📤📥📦📫📪📬📭📮🗳✏✒🖋🖊🖌🖍📝💼📁📂🗂📅📆🗒🗓📇📈',
  '📉📊📋📌📍📎🖇📏📐✂🗃🗄🗑🔒🔓🔏🔐🔑🗝🔨⛏⚒🛠🗡⚔🔫🏹🛡🔧🔩⚙🗜⚖🔗⛓🧰🧲⚗🧪🧫🧬🔬🔭📡💉💊🚪🛏🛋',
  '🚽🚿🛁🧴🧷🧹🧺🧻🧼🧽🧯🛒🚬⚰⚱🗿⌛⏳⌚⏰⏱⏲🕰🕛🕧🕐🕜🕑🕝🕒🕞🕓🕟🕔🕠🕕🕡🕖🕢🕗🕣🕘🕤🕙🕥🕚🕦°🌑',
  '🌒🌓🌔🌕🌖🌗🌘🌙🌚🌛🌜🌡☀🌝🌞⭐🌟🌠☁⛅⛈🌤🌥🌦🌧🌨🌩🌪🌫🌬🌀🌈🌂☂☔⛱⚡❄☃⛄☄🔥💧🌊¿¡«»‹›"‘’“”‚„§¶†‡•·—–…√∫∂',
  '∑∏&−±×⋅∗÷⨯⊗⊕≅≈∝≡≠≤≥<>∈∉∩∪⊂⊃⊆⊇∅¬∧∨∃∀⇐⇒⇑⇓⇔←→↑↓↔↕↵¹²³¼½¾°′″∞‰∇ℵℑ℘ℜ∴⊕⊗ªº´',
  '¨˜‾¦◊∠⊥ƒ⁄ˆ★☭♪☂☠☛⇆█░▒▓►◄▼▲▌▄■▀▐￼�ΑΒΓΔΕΖαβγδεζΗΘΙΚΛΜΝηθικλμνΞΟΠΡΣξοπρσς',
  'ΤΥΦΧΨΩτυφχψωÀÁÂÃÄÅÆĄàáâãäåæąÇĆĈČçćĉčÈÉÊËĘĚèéêëęěÌÍÎÏìíîïÐðÑñÒÓÔŒÕÖØòóôœõöøÙÚÛÜùúûüÝŸýÿÞþß',
];

export const PUNCTUATIONS_CHARS = '\\n\\t\\f\\v\\s?,.;:!؟،؛';
export const SPECIAL_CHARS = `${PUNCTUATIONS_CHARS}${SPECIAL_CHARS_ARRAY.join('')}`;
