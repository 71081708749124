import React from 'react';

import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { IFormField } from './types';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

interface IFormFieldCheckboxProps {
  descriptor: IFormField;
  bgcolor?: string;
  fontSize?: string;
  readonly?: boolean;
}

const FormFieldCheckbox: React.FC<IFormFieldCheckboxProps> = ({ descriptor, readonly }) => {
  const { t } = useTranslation();

  //const [localValue, setLocalValue] = useState<TFormFieldValue | undefined>(descriptor.value);

  return (
    <Box pl={2}>
      <FormControlLabel
        control={
          <Checkbox
            data-autotest={'ff-cb-value-' + descriptor.id ? descriptor.id + '-' : '-' + descriptor.autotestId + ''}
            color="primary"
            name={descriptor.id}
            checked={!!descriptor.value}
            onChange={(event: any) => {
              //setLocalValue(event.target.checked);
              runInAction(() => {
                descriptor.value = event.target.checked;
              });
            }}
            disabled={readonly}
          />
        }
        label={t(descriptor.name || '')}
      />
    </Box>
  );
};

export default observer(FormFieldCheckbox);
