import React, { FC, ReactNode } from 'react';
import { Box, TableCell, TableCellProps } from '@material-ui/core';
import styles from './dataGrid.module.scss';
import { ukColors } from '@uk';

interface IRowActionsProps {
  size: 'small' | 'medium';
  icon?: ReactNode;
  isAlwaysVisible?: boolean;
  autotestId?: string;
  onClick?: any;
}

const RowActions: FC<IRowActionsProps & TableCellProps> = ({ size, icon, isAlwaysVisible, onClick, autotestId, ...rest }) => {
  return (
    // <TableCell style={{ position: 'sticky', width: 1, right: 0, padding: 0 }}>
    <TableCell style={{ padding: 0, width: '50px' }} {...rest}>
      <Box className={styles.overlay} style={{ opacity: isAlwaysVisible ? 1 : 0 }}>
        {onClick && (
          <Box
            className={styles.actionButton}
            ml={1.5}
            display="flex"
            alignItems="center"
            color={ukColors.darkGrey}
            data-autotest={autotestId}
            style={{ cursor: 'pointer' }}
            onClick={onClick}
          >
            {icon || <></>}
          </Box>
        )}
      </Box>
    </TableCell>
  );
};

export default RowActions;
