//#region Состояние модели
export enum EModelStatePascalToSnake {
  Normal = 'NORMAL',
  NotEnoughData = 'NOT_ENOUGH_DATA',
  Partial = 'PARTIAL',
  Error = 'ERROR',
  Merged = 'MERGED',
}
export enum EModelStateSnakeToPascal {
  NORMAL = 'Normal',
  NOT_ENOUGH_DATA = 'NotEnoughData',
  PARTIAL = 'Partial',
  ERROR = 'Error',
  MERGED = 'Merged',
}
export type TModelStatePascal = keyof typeof EModelStatePascalToSnake; // Normal, NotEnoughData, Partial, Error, Merged
export type TModelStateSnake = keyof typeof EModelStateSnakeToPascal; // NORMAL, NOT_ENOUGH_DATA, PARTIAL, ERROR, MERGED
//#endregion Состояние модели

//#region algorithm
export enum EAlgorithmPascalToSnake {
  Vad = 'VAD',
  Manual = 'MANUAL',
  Cpd = 'CPD',
  Polylog = 'POLYLOG',
  Xvector = 'XVECTOR',
  AutoEtalon = 'AUTO_ETALON',
}
export enum EAlgorithmSnakeToPascal {
  VAD = 'Vad',
  MANUAL = 'Manual',
  CPD = 'Cpd',
  POLYLOG = 'Polylog',
  XVECTOR = 'Xvector',
  AUTO_ETALON = 'AutoEtalon',
}
export type TAlgorithmPascal = keyof typeof EAlgorithmPascalToSnake; // Vad, Manual, Cpd, Polylog, Xvector, AutoEtalon
export type TAlgorithmSnake = keyof typeof EAlgorithmSnakeToPascal; // VAD, MANUAL, CPD, POLYLOG, XVECTOR, AUTO_ETALON
//#endregion Состояние модели

export enum EChannelNumber {
  Left = 'Left',
  Right = 'Right',
}
export type TChannelNumber = keyof typeof EChannelNumber;

export enum EChannelType {
  MICROPHONE = 'MICROPHONE',
  PHONE = 'PHONE',
}
export type TChannelType = keyof typeof EChannelType;

export interface IMultilevelRanges {
  vad_ranges: number[];
  beep_ranges?: number[];
  music_ranges?: number[];
  glitch_ranges: number[];
  clipping_ranges: number[];
  overload_ranges: number[];
  tonal_noise_ranges: number[];
}

export interface ISampleFormat {
  format_name: string; // "sample_fmt_s16p",
  bits_per_sample: number; // 16,
  bits_per_sample_description: string; // "signed 16 bits, planar"
}

export interface IAudioCharacteristics {
  duration: number; // 74.03102040816327,
  sample_rate: number; // 44100,
  sample_count: number; // 1044725760,
  channel_count: number; // 2,
  sample_format: ISampleFormat;
}

export interface ILanguage {
  fa: number; // 78.70399475097656,
  fr: number; // 0.46181774139404297,
  language: number; // "arabic",
  probability: number; // 5.477506637573242,
  internalScore: number; // -55.01736831665039
}

export interface ISegmentation {
  algorithm: TAlgorithmSnake;
  // Путь к аудио-файлу, по которому делалась предобработка
  audio?: string;
  audio_hash?: string;
  bio_version?: string;
  channel_number: TChannelNumber;
  channel_type: TChannelType;
  dictor_num: number;
  // имя файла, из которого построена сегментация
  fileName?: string;
  fileSize?: number;
  highest_speech_freq_hz?: number;
  lowest_speech_freq_hz?: number;
  // Длительность максимального непрерывного интервала, сек
  max_unclipped_interval?: number;
  // дополнительные уровни разметки
  multilevel_ranges?: IMultilevelRanges;
  original_audio_characteristics?: IAudioCharacteristics;
  // Относительный(относительно storage) путь к файлу с бинарными данными предобработки звука.
  path: string; // "/seg/41/fd/a2/41fda263fb3fa5e3b62fbde3f28061c0d03d9762/1009fbd5337f2d7fb51913de188483d364773403.seg"
  /* Длительность обработанного звука, сек. Большие файлы могут обрабатываться частично, в этом случае длина
    обработанного звука не будет совпадать с длительностью файла. */
  processed_duration?: number;
  /* Признак того, что файл был обработан частично, т.е. длительность обработанного звука меньше, чем длительность
    файла. */
  processed_partially?: boolean;
  // Качество звуковой информации (0 - плохо, 0,5 - средне, 1 - хорошо).
  quality?: number;
  /* Список интервалов эффективной разметки диктора в формате
   [начало 1-го участка, конец 1-го участка,
   начало 2-го участка, конец 2-го участка, ... ,
   начало n-го участка, конец n-го участка], данные в сек. */
  ranges: number[];
  // общая длительность интервалов ranges
  ranges_duration?: number;
  // Длительность реверберации, сек
  rt_aver?: number;
  // Количество реверберационных моментов
  rt_count?: number;
  // RMS реверберации, сек
  rt_rms?: number;
  // Отношение сигнал/шум.
  snr?: number;
  speech_amplitude_threshold_db?: number;
  // Состояние сегментации
  state?: TModelStateSnake;
  // Общая продолжительность отсеченных фрагментов, сек
  time_overload?: number;
  time_speech?: number;
  // Общая длительность тонального шума, сек
  time_tonal_noise?: number;

  ////////////
  // Путь к сохраненному результату предобработки.
  bpa?: string;
  languages?: ILanguage[];
}
