import { UNKNOWN_SPEAKER } from '@/components/dictors/const';
import { IDictor } from '@/types/dictors';
import { gAPP_STORE } from '@/app/app-store';
import { MAX_DICTOR_NAME_LENGTH_CHIP } from '@/common/constants';

import i18n from '@/i18n';

const dictorTitle = (name: string, surname?: string) => {
  return surname ? `${name} ${surname}` : `${name}`;
};

export const dictorTitleForDto = (index: number, name: string, surname?: string) => {
  const isDefaultSpeaker = name.split(' ')[0] === '#Speaker#';

  return dictorTitle(isDefaultSpeaker ? `${i18n.t(UNKNOWN_SPEAKER)} ${index}` : name, surname);
};

const isDefault = (dictor: IDictor) =>
  dictor.defaultName === undefined ? dictor.name.split(' ')[0] === '#Speaker#' : dictor.defaultName;

export const dictorTitleWithDefaultName = (dictor: IDictor, index: number, defaultName: string) =>
  isDefault(dictor) ? dictorTitle(defaultName, `${index}`) : dictorTitle(dictor.name, dictor.surname);

export const getTitle = (dictor: IDictor, report?: boolean): string => {
  const dictorsStore = gAPP_STORE.getDictorsStore();
  const { dictorNumByRecordId } = dictorsStore;

  const unknownSpeakerName = report
    ? gAPP_STORE.unknownDictorInReport ?? i18n.t(UNKNOWN_SPEAKER)
    : i18n.t(UNKNOWN_SPEAKER);

  if (dictor.defaultName && !dictor.disabledForEditing) {
    return `${unknownSpeakerName} ${dictorNumByRecordId(dictor)} ${dictor.surname ?? ''}`.trim();
  } else if (dictor.disabledForEditing) {
    return `${unknownSpeakerName} ${dictorNumByRecordId(dictor)}`.trim();
  }

  return dictor.surname ? `${dictor.name} ${dictor.surname}`.trim() : dictor.name;
};

export const getShortTitleFromIDictor = (dictor: IDictor): string => {
  const dictorName = getTitle(dictor);

  return getShortTitleFromString(dictorName);
};

export const getShortTitleFromString = (dictorName: string, maxLength?: number): string => {
  const maxDictorNameLength = maxLength ?? MAX_DICTOR_NAME_LENGTH_CHIP;
  if (dictorName.length > maxDictorNameLength) {
    dictorName = `${dictorName.slice(0, maxDictorNameLength)}…`;
  }

  return dictorName;
};

export const isRTLLocal = (text: string) => {
  const rtl_count = (text.match(/[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/g) || []).length;
  const ltr_count = (
    text.match(
      /[A-Za-z\u00C0-\u00C0\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF]/g,
    ) || []
  ).length;

  return rtl_count > ltr_count;
};

export const changeSegementationAcordingPhrase = (
  dictors: IDictor[],
  idDictorFrom?: number,
  idDictorTo?: number,
  timeBegin?: number,
  timeEnd?: number,
) => {
  if (!idDictorFrom || !idDictorTo || timeBegin === undefined || timeEnd === undefined) return [];
  const indDictorMinus = dictors.findIndex(d => d.id === idDictorFrom);
  const indDictorPlus = dictors.findIndex(d => d.id === idDictorTo);
  if (indDictorMinus < 0 || indDictorPlus < 0) return [];

  const segmMinus = dictors[indDictorMinus].segmentation.ranges ? [...dictors[indDictorMinus].segmentation.ranges] : [];
  segmMinus.sort((a, b) => +a - +b);
  let ind1 = 0;
  while (ind1 < segmMinus.length && segmMinus[ind1] < timeBegin) {
    ind1 = ind1 + 2;
  }
  if (ind1 >= segmMinus.length) {
    console.error(`segmentation of dictor ${dictors[indDictorMinus].id} is bad`);

    return [];
  }
  let ind2 = ind1 + 1;
  while (ind2 < segmMinus.length && segmMinus[ind2] < timeEnd) {
    ind2 = ind2 + 2;
  }
  if (ind2 >= segmMinus.length) {
    ind2 = segmMinus.length - 1;
  }

  const times = segmMinus.splice(ind1, ind2 - ind1 + 1) ?? [];

  const segmPlus: number[] = dictors[indDictorPlus].segmentation.ranges ?? [];
  segmPlus.sort((a, b) => +a - +b);
  let ind3 = 0;
  while (ind3 < segmPlus.length && segmPlus[ind3] < timeBegin) {
    ind3 += 2;
  }

  dictors[indDictorMinus] = {
    ...dictors[indDictorMinus],
    segmentation: { ...dictors[indDictorMinus].segmentation, ranges: segmMinus },
  };

  let rangesPlus: number[] = [];
  if (ind3 === 0) {
    rangesPlus = [...times, ...segmPlus];
  } else if (ind3 >= segmPlus.length) {
    rangesPlus = [...segmPlus, ...times];
  } else {
    rangesPlus = [...segmPlus.slice(0, ind3), ...times, ...segmPlus.slice(ind3)];
  }

  rangesPlus.sort((a, b) => +a - +b);

  dictors[indDictorPlus] = {
    ...dictors[indDictorPlus],
    segmentation: { ...dictors[indDictorPlus].segmentation, ranges: rangesPlus },
  };

  return dictors;
};
