import { TreeItem } from '@material-ui/lab';
import { reaction } from 'mobx';
import { useEffect } from 'react';
import DataTreeNodeLabel from './DataTreeNodeLabel';
import { ObservedTreeNode, TGenericDataTreeNode } from './types';

export const DataTreeNode: TGenericDataTreeNode = ({ node, nodeRenderer }) => {

  useEffect(() => {
    return reaction(() => node.title, (kids) => {
      console.log(`>>>>>>>>>>>> Changed children at ${node.title}: ${JSON.stringify(node.children)}`);
    });
  });

  return (
    <TreeItem
      key={node.id ? node.id : 'rootTreeNode'}
      nodeId={node.id}
      label={<DataTreeNodeLabel node={node} nodeRenderer={nodeRenderer} />
      }>
      {node.children
        ? node.children.map((n) => {
          return <ObservedTreeNode key={`node-${n.id}`} node={n} nodeRenderer={nodeRenderer} />
        })
        : null}
    </TreeItem>
  );
};
