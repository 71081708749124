export enum NotificationType {
  Alarm = 0,
  Message = 1,
}
export enum NotificationGroup {
  CLEANER_GROUP_NAME = 'CLEANER',
}
export enum NotificationCode {
  CODE_CLEANER_WARN = 'cleanerWarn',
  CODE_CLEANER_FAULT = 'cleanerFault',
}

export interface INotification {
  id: number;
  correlationId: string;
  createdDate: string;
  message: string;
  code: NotificationCode;
  groupName: NotificationGroup;
  mode: NotificationType;
  read: boolean;
}
