import moment from 'moment';

import { IParticipantDto, IMentionsDto, IOwnerDto, IParticipant, IDictor, IOwner } from '@/common/report-index';

export const strToDate = (d: string): Date | undefined => (d ? new Date(Date.parse(d)) : undefined);
export const strToDateStr = (d: string) => (d ? moment(strToDate(d)).format('YYYY-MM-DD') : '');
export const dateToStrWithoutTime = (date: Date | null) => (date ? moment(date).format('YYYY-MM-DDT00:00:00') : '');

export interface IDictorName {
  id: number;
  name: string;
}

export const participantToExportString = (participant: IParticipant, names: IDictorName[]) => {
  const role = !!participant.role ? `(${participant.role})` : '';
  let title = participant.dictor ? '' : participant.summaryName ?? '';
  const ind = names.findIndex(v => v.id === participant.dictor?.id);
  if (ind > 0) {
    title = names[ind].name;
  }

  return `${title} ${role}`;
};

export const getDictorsFromDto = (dictorsDto: IParticipantDto[], recordDictors: IDictor[]) => {
  const participants: IParticipant[] = [];

  dictorsDto.forEach(item => {
    const dictor = recordDictors.find(d => d.id === item.dictorId);
    if (dictor === undefined) {
      console.log('Not found dictor from summary:', { participant: item });
    } else {
      participants.push({
        idParticipant: item.id,
        dictor,
        summaryName: item.name,
        role: item.role ?? undefined,
      });
    }
  });

  return participants;
};

export const getRoleText = (item: IMentionsDto) => {
  const role = item.role ? item.role.trim() : '';

  return role === '' ? item.name : `${item.name} (${role})`;
};

export const getAllMentions = (mentionsDto: IMentionsDto[][]) => {
  const mentions = new Set<string>();
  mentionsDto.forEach(lst =>
    lst.forEach(item => {
      mentions.add(getRoleText(item));
    }),
  );

  return Array.from(mentions.values());
};

export const getOwnerFromDto = (
  participants: IParticipant[],
  owners: IOwnerDto[] | undefined,
  getTitle: (d: IDictor) => string,
): IOwner => {
  const result: IOwner = {
    idOwner: 0,
    name: '',
    editable: true,
  };

  const owner = owners && owners.length > 0 ? owners[0] : undefined;
  if (owner) {
    result.idOwner = owner.id;
    result.name = owner.name;
    if (owner.speakerId) {
      const participant = participants.find(p => p.idParticipant === owner.speakerId);
      if (participant && participant.dictor) {
        result.editable = false;
        result.name = getTitle(participant.dictor);
      }
    }
  }

  return result;
};
