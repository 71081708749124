import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { IFormField, TFormFieldValue } from './types';

interface IFormColorPickerProps {
  descriptor: IFormField;
  rangeIndex?: number;
  readonly?: boolean;
}

const FormColorPicker: React.FC<IFormColorPickerProps> = ({ descriptor, rangeIndex, readonly }) => {
  let value: string = (descriptor.value as string) || '#000000';
  if (rangeIndex !== undefined) {
    value = Array.isArray(descriptor.value) ? (descriptor.value as [])[rangeIndex] : '';
  }

  return (
    <input
      data-autotest={'ff-cp-value-' + descriptor.id ? descriptor.id + '-' : '-' + descriptor.autotestId + ''}
      type="color"
      value={value}
      onChange={e => {
        runInAction(() => {
          if (rangeIndex !== undefined) {
            ((descriptor.value as [])[rangeIndex] as TFormFieldValue) = e.target.value;
          } else {
            descriptor.value = e.target.value;
          }
        });
      }}
      disabled={readonly}
    />
  );
};
export default observer(FormColorPicker);
