import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';

import { IFormField, TFormFieldValue } from './types';

interface IFormFieldNumberProps {
  descriptor: IFormField;
  bgcolor?: string; // TODO bgcolor должен быть юнион типом допустимых цветов приложения
  fontSize?: string;
  autoFocus?: boolean;
  minValue?: number;
  maxValue?: number;
  readonly?: boolean;
  rangeIndex?: number;
  onChange?: (id: string, value: TFormFieldValue) => void;
}

const FormFieldNumber: React.FC<IFormFieldNumberProps> = ({
  descriptor,
  minValue,
  maxValue,
  bgcolor,
  fontSize,
  autoFocus,
  readonly,
  rangeIndex,
  onChange,
}) => {
  // const { t } = useTranslation();

  const fixVal = (value: number) => {
    if (minValue !== undefined && value <= minValue) value = minValue;
    if (maxValue !== undefined && value >= maxValue) value = maxValue;
    return value;
  };

  // const v = () => {
  //   if (descriptor.value && typeof descriptor.value === 'number')
  //     return fixVal(descriptor.value)
  //   return minValue
  // };
  
  let value = descriptor.value || '';
  if (rangeIndex !== undefined) {
    value = Array.isArray(descriptor.value) ? (descriptor.value as [])[rangeIndex] : '';
  }

  const [localValue, setLocalValue] = useState(value);

  const handleChangeValue = (event: any) => {
    const value = event.target.value;
    // const newValue: TFormFieldValue = value;
    const newValue: TFormFieldValue = fixVal(value);


    // const v = fixVal(value);
    // setLocalValue(v);
    setLocalValue(newValue);
    // runInAction(() => {

    //   descriptor.value = v;
    // });

    // runInAction(() => {
      if (rangeIndex !== undefined) {
        if (!descriptor.value) {
          descriptor.value = [''];
        }
        ((descriptor.value as any[])[rangeIndex] as TFormFieldValue) = newValue;
      } else {
        descriptor.value = newValue;
      }
    // });
  };

  // const label = t(descriptor.name || '');

  // const autotestId =
  //   'ff-txt-value-' +
  //   (descriptor.id ?? '') +
  //   '-' +
  //   (descriptor.autotestId || '') +
  //   (rangeIndex !== undefined ? '-' + rangeIndex : '');

  const handleKeyDown = (event: any) => {
    // console.log('key = ', event.key);
    if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
    }
    // if (event.key === 'Escape') {
    //   event.stopPropagation();
    //   event.preventDefault();
    // }
  };

  return (
    <Box flexGrow={1} display="flex" flexDirection="row" alignItems="center">
      <TextField
        type={'number'}
        fullWidth
        InputLabelProps={{ style: { fontSize: fontSize || 'inherit' } }}
        InputProps={{ disableUnderline: true }}
        inputProps={{
          'data-autotest': 'ff-num-value-1',
          style: {
            fontSize: fontSize || 'inherit',
            background: bgcolor || 'inherit',
            // paddingLeft: '0.5em',
            // paddingRight: '0.5em',
            paddingTop: '0.7em',
            paddingBottom: '0.7em',
          },
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        value={localValue}
        onFocus={event => event.target.select()}
        onChange={(event: any) => handleChangeValue(event)}
        onKeyDown={handleKeyDown}
        autoFocus={autoFocus}
        disabled={readonly}
      />
    </Box>
  );
};

export default FormFieldNumber;
