export interface ITemplate {
  id: number;
  modifyedDate: string;
  templateName: string;
  fileName: string;
  template: string;
}

export class CTemplate implements ITemplate {
  id = -1;
  modifyedDate = '';
  templateName = '';
  fileName = '';
  template = '';
}
