/**
 * Minimap is a tiny copy of the main waveform serving as a navigation tool.
 */

import { makeDraggable } from 'wavesurfer.js/dist/draggable';
import WaveSurfer, { WaveSurferOptions } from 'wavesurfer.js';
import { BasePlugin, BasePluginEvents } from 'wavesurfer.js/dist/base-plugin';
import { createElement } from 'wavesurfer.js/dist/dom';

export type MinimapPluginOptions = {
  overlayColor?: string;
  overlayBorderColor?: string;
  insertPosition?: InsertPosition;
} & Partial<WaveSurferOptions>;

const defaultOptions = {
  height: 50,
  overlayColor: 'rgba(100, 100, 100, 0.1)',
  insertPosition: 'afterend',
};

export type MinimapPluginEvents = BasePluginEvents & {
  ready: [];
  interaction: [];
  over: [event: MouseEvent];
  /** Mouse leave */
  leave: [event: MouseEvent];
  click: [event: MouseEvent];
};

class MinimapExtPlugin extends BasePlugin<MinimapPluginEvents, MinimapPluginOptions> {
  protected options: MinimapPluginOptions & typeof defaultOptions;
  private minimapWrapper: HTMLElement;
  private miniWavesurfer: WaveSurfer | null = null;
  private overlay: HTMLElement;
  private container: HTMLElement | null = null;
  private drag: boolean;
  private currentOverlayTime: number;

  constructor(options: MinimapPluginOptions) {
    super(options);
    this.options = Object.assign({}, defaultOptions, options);
    this.drag = true; //switch on/off dragable

    this.currentOverlayTime = 0;

    this.minimapWrapper = this.initMinimapWrapper();
    this.minimapWrapper.setAttribute('data-autotest', 'minimapWrapper');
    this.overlay = this.initOverlay();
    this.initMouseEvents();
  }

  public static create(options: MinimapPluginOptions) {
    return new MinimapExtPlugin(options);
  }

  /** Called by wavesurfer, don't call manually */
  onInit() {
    if (!this.wavesurfer) {
      throw Error('WaveSurfer is not initialized');
    }

    if (this.options.container) {
      if (typeof this.options.container === 'string') {
        this.container = document.querySelector(this.options.container) as HTMLElement;
      } else if (this.options.container instanceof HTMLElement) {
        this.container = this.options.container;
      }
      this.container?.appendChild(this.minimapWrapper);
    } else {
      this.container = this.wavesurfer.getWrapper().parentElement;
      this.container?.insertAdjacentElement(this.options.insertPosition, this.minimapWrapper);
    }

    this.initWaveSurferEvents();
  }

  private initMouseEvents() {
    const { overlay } = this;
    if (!overlay) return;

    // overlay.addEventListener('click', e => this.emit('click', e));
    // overlay.addEventListener('mouseenter', e => this.emit('over', e));
    // overlay.addEventListener('mouseleave', e => this.emit('leave', e));
    // overlay.addEventListener('pointerdown', () => this.toggleCursor(true));
    // overlay.addEventListener('pointerup', () => this.toggleCursor(false));

    overlay.addEventListener('click', e => this.emit('click', e));
    overlay.addEventListener('mouseenter', () => this.toggleCursor(true));
    overlay.addEventListener('mouseleave', () => this.toggleCursor(false));

    // // Drag
    makeDraggable(
      overlay,
      dx => this.onMove(dx),
      () => this.toggleCursor(true),
      () => {
        this.toggleCursor(false);
      },
    );
  }

  private toggleCursor(toggle: boolean) {
    if (!this.overlay?.style) return;
    this.overlay.style.cursor = toggle ? 'grabbing' : 'grab';
  }

  private initMinimapWrapper(): HTMLElement {
    return createElement('div', {
      part: 'minimap',
      style: {
        position: 'relative',
      },
    });
  }

  private initOverlay(): HTMLElement {
    return createElement(
      'div',
      {
        part: 'minimap-overlay',
        style: {
          position: 'absolute',
          zIndex: '999',
          left: '0',
          top: '0',
          bottom: '0',
          margin: '1px',
          // cursor: 'grab',
          //transition: 'left 100ms ease-out',
          pointerEvents: 'all',
          backgroundColor: this.options.overlayColor,
          border: this.options.overlayBorderColor ? `1px solid ${this.options.overlayBorderColor}` : '',
          borderRadius: this.options.overlayBorderColor ? '2px' : '',
        },
      },
      this.minimapWrapper,
    );
  }

  private initMinimap() {
    if (this.miniWavesurfer) {
      this.miniWavesurfer.destroy();
      this.miniWavesurfer = null;
    }

    if (!this.wavesurfer) return;

    const data = this.wavesurfer.getDecodedData();
    const media = this.wavesurfer.getMediaElement();
    if (!data || !media) return;

    const peaks = [];
    for (let i = 0; i < data.numberOfChannels; i++) {
      peaks.push(data.getChannelData(i));
    }

    this.miniWavesurfer = WaveSurfer.create({
      ...this.options,
      container: this.minimapWrapper,
      minPxPerSec: 0,
      fillParent: true,
      media,
      peaks,
      duration: data.duration,
    });

    this.subscriptions.push(
      this.miniWavesurfer.on('ready', () => {
        this.emit('ready');
      }),

      this.miniWavesurfer.on('interaction', () => {
        this.emit('interaction');
      }),
    );
  }

  private getOverlayWidth(): number {
    const waveformWidth = this.wavesurfer?.getWrapper().clientWidth || 1;

    return Math.round((this.minimapWrapper.clientWidth / waveformWidth) * 100);
  }

  private onRedraw() {
    const overlayWidth = this.getOverlayWidth();
    this.overlay.style.width = `${overlayWidth}%`;
    if (overlayWidth >= 99) {
      this.overlay.style.display = 'none';
    } else {
      this.overlay.style.display = 'block';
    }
  }

  private onScroll(startTime: number) {
    if (!this.wavesurfer) return;
    const duration = this.wavesurfer.getDuration();
    this.currentOverlayTime = startTime;
    this.overlay.style.left = `${(this.currentOverlayTime / duration) * 100}%`;
    console.log('onScroll...');
  }

  private onMove(dx: number) {
    //dx in pixels
    if (!this.drag || !this.wavesurfer) return;
    const secPerPixel = this.wavesurfer.getDuration() / this.minimapWrapper.clientWidth;
    this.wavesurfer.setScrollTime(this.currentOverlayTime + dx * secPerPixel);
  }

  private initWaveSurferEvents() {
    if (!this.wavesurfer) return;

    this.subscriptions.push(
      this.wavesurfer.on('decode', () => {
        this.initMinimap();
      }),

      this.wavesurfer.on('scroll', (startTime: number) => {
        this.onScroll(startTime);
      }),

      this.wavesurfer.on('redraw', () => {
        this.onRedraw();
      }),
    );
  }

  /** Unmount */
  public destroy() {
    this.miniWavesurfer?.destroy();
    this.minimapWrapper.remove();
    super.destroy();
  }
}

export default MinimapExtPlugin;
