import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';

import {
    FaEquals,
    FaNotEqual,
    FaGreaterThanEqual,
    FaLessThanEqual
} from 'react-icons/fa';

import { AddCircleOutlineRounded, RemoveCircleOutlineRounded } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';

import appColors from '../../app/app-colors';

import { IFilterField, TFilterPredicate } from './i-filter-field';

export const getPredicateIcon = (descriptor: IFilterField, predicate?: TFilterPredicate) => {
    let icon = undefined;
    switch (predicate || descriptor.predicate) {
        case 'contains':
            // icon = <FontAwesomeIcon icon={IconsR.faDotCircle} />;
            icon = <AddCircleOutlineRounded style={ { fontSize: 12, width: 12, color: appColors.primary } }/>;
            break;
        case 'not_contains':
            // icon = <FontAwesomeIcon icon={IconsR.faCircle} />;
            icon = <RemoveCircleOutlineRounded style={ { fontSize: 12, width: 12, color: appColors.red } }/>;
            break;
        case 'equal':
            icon = <FaEquals style={ { fontSize: 10, width: 12, color: appColors.primary } }/>;
            break;
        case 'not_equal':
            icon = <FaNotEqual style={ { fontSize: 10, width: 12, color: appColors.red } }/>;
            break;
        case 'greater_than_equal':
            icon = <FaGreaterThanEqual style={ { fontSize: 10, width: 12, color: appColors.primary } }/>;
            break;
        case 'less_than_equal':
            icon = <FaLessThanEqual style={ { fontSize: 10, width: 12, color: appColors.primary } }/>;
            break;
    }

    return icon;
};

const useStyles = makeStyles({
    menuItem: {
        display: 'flex',
        alignItems: 'stretch',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 10,
    },
});

interface IFilterPredicateProps {
    descriptor: IFilterField;
    predicate?: TFilterPredicate;
    onChange?: (value: TFilterPredicate) => void;
}

const FilterPredicate: React.FC<IFilterPredicateProps> = ({ descriptor, predicate, onChange }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeMode = (event: any) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const handleSelectMode = (value?: TFilterPredicate) => {
        setMenuAnchorEl(null);
        if (onChange && value) {
            onChange(value);
        }
    };

    return (
        <>
            <Tooltip title={ predicate ? t(predicate).toString() : '' }>
                <IconButton size="small" onClick={ handleChangeMode }>
                    { getPredicateIcon(descriptor, predicate) }
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={ menuAnchorEl }
                transformOrigin={ {
                    vertical: 'top',
                    horizontal: 'left',
                } }
                keepMounted
                open={ Boolean(menuAnchorEl) }
                onClose={ () => handleSelectMode() }
            >
                { descriptor &&
                descriptor.predicates &&
                descriptor.predicates.map((item, index) => (
                    <MenuItem key={ index } className={ classes.menuItem } value={ item }
                              onClick={ () => handleSelectMode(item) }>
                        <div className={ classes.icon }>{ getPredicateIcon(descriptor, item) }</div>
                        { t(item) }
                    </MenuItem>
                )) }
            </Menu>
        </>
    );
};

export default FilterPredicate;
