import { datetimeRenderer, IDataGridCol, TDataGridRenderer } from '@uk';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import i18n from 'i18next';

import appColors from '../../app/app-colors';
import LanguageLabel from '../languages/language-label';

import { ERecordStatus, IRecord, statusToModelStatus } from './types';
import RecordLabels from './record-labels';
import RecordGroups from './record-groups';
import RecordHotlists from './record-hotlists';
import RecordTopics from './record-topics';
import RecordsStatusLabel from './records-status-label';
import SummaryTopicCountLabel from './record-summary-topiccount-label';

import { gAPP_STORE } from '@/app/app-store';
import { RecordsDictorsColumnValue } from '@/components/records/records-dictors-column-value';

const recordPlusModelStatusRenderer: TDataGridRenderer = (row: IRecord, value: ERecordStatus) => {
  let status;
  if (row.voiceModelStatus === value || row.voiceModelStatus === ERecordStatus.NEW) {
    status = value;
  } else {
    status = statusToModelStatus(row.voiceModelStatus);
  }

  const errorCause = [ERecordStatus.FAILED, ERecordStatus.MODEL_REBUILDING_FAILED].includes(status)
    ? row.errorCause
    : '';

  return <RecordsStatusLabel status={status} text={errorCause} />;
};

const translateStatusRenderer: TDataGridRenderer = (row: IRecord, value: ERecordStatus) => {
  const status = value;
  const errorCause = value === ERecordStatus.FAILED ? row.errorCause : '';

  return <RecordsStatusLabel status={status} text={errorCause} />;
};

const summaryStatusRenderer: TDataGridRenderer = (row: IRecord, value: ERecordStatus) => {
  const status = value;
  const errorCause = value === ERecordStatus.FAILED ? row.errorCause : '';

  return <RecordsStatusLabel status={status} text={errorCause} />;
};

const summaryHaveTaskRenderer: TDataGridRenderer = (row: IRecord, value: boolean | undefined) => {
  const hasTask = row.taskQuantity;

  return hasTask === undefined
    ? ''
    : hasTask
    ? i18n.t('records.summaryHaveTaskYes')
    : i18n.t('records.summaryHaveTaskNo');
};

const summaryTopicCountRenderer: TDataGridRenderer = (row: IRecord, value: number | undefined) => {
  return row.topicCount === undefined ? <></> : <SummaryTopicCountLabel record={row} count={row.topicCount} />;
};

const labelsRenderer: TDataGridRenderer = (row: IRecord) => {
  return <RecordLabels record={row} />;
};

const dictorsRenderer: TDataGridRenderer = (row: IRecord, value: ERecordStatus) => {
  const dictors = gAPP_STORE.getDictorsStore().getDictorsByRecord(row.id) || [];

  return <RecordsDictorsColumnValue dictors={dictors} recordStatus={row.status} />;
};

const languagesRenderer: TDataGridRenderer = (row: IRecord, langCode: string) => {
  return <LanguageLabel language={langCode} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transcriptionRenderer: TDataGridRenderer = (row: any[], value: any) => {
  return value ? <CheckCircleIcon style={{ color: appColors.green }} /> : '';
};
const ownerRenderer: TDataGridRenderer = (row: IRecord, value: number) => {
  const userId = value ? +value : -1;

  return userId > -1 ? gAPP_STORE.getUsersStore().data.users.find(user => user.id === userId)?.firstName || '' : '';
};
const topicsRenderer: TDataGridRenderer = (row: IRecord) => {
  return <RecordTopics record={row} />;
};

const hotlistRenderer: TDataGridRenderer = (row: IRecord) => {
  return <RecordHotlists record={row} />;
};

const recordGroupsRenderer: TDataGridRenderer = (row: IRecord) => {
  return <RecordGroups record={row} />;
};

let recordsGridColumnsInnerReplaced = false;

const recordsGridColumnsInner: IDataGridCol<IRecord>[] = [
  { id: 'check', name: '', width: 0, align: 'center', hasCheck: true },
  { id: 'id', name: 'id', width: 50, align: 'center', noSort: true },
  {
    id: 'status',
    name: 'records.status',
    width: 100,
    align: 'center',
    renderer: recordPlusModelStatusRenderer,
    noSort: true,
  },
  {
    id: 'translationStatus',
    name: 'records.translate_status',
    width: 50,
    align: 'center',
    renderer: translateStatusRenderer,
    noSort: true,
  },
  {
    id: 'caseid',
    name: 'records.meetingTitle',
    width: 100,
    align: 'left',
    noSort: true,
  },
  {
    id: 'summaryStatus',
    name: 'records.summaryStatus',
    width: 30,
    align: 'center',
    renderer: summaryStatusRenderer,
    noSort: true,
  },
  {
    id: 'summaryHaveTask',
    name: 'records.summaryHaveTask',
    width: 20,
    renderer: summaryHaveTaskRenderer,
    align: 'center',
    noSort: true,
  },
  {
    id: 'summaryTopicCount',
    name: 'records.summaryTopicCount',
    width: 20,
    renderer: summaryTopicCountRenderer,
    align: 'center',
    noSort: true,
  },
  { id: 'fileName', name: 'records.filename', width: 150, noSort: true },
  { id: 'labels', name: 'records.labels', width: 100, renderer: labelsRenderer, noSort: true },
  { id: 'dictors', name: 'records.meetingParticipants', width: 50, noSort: true, renderer: dictorsRenderer },
  { id: 'loadDate', name: 'records.dateLoad', width: 100, renderer: datetimeRenderer, align: 'center', noSort: true },
  {
    id: 'createdDate',
    name: 'records.dateCreate',
    width: 100,
    renderer: datetimeRenderer,
    align: 'center',
    noSort: true,
  },
  { id: 'languageResolved', name: 'records.language', width: 100, renderer: languagesRenderer, noSort: true },
  {
    id: 'transcriptionId',
    name: 'records.speechRecognition',
    width: 50,
    renderer: transcriptionRenderer,
    align: 'center',
    noSort: true,
  },
  { id: 'topics', name: 'records.topic', width: 300, renderer: topicsRenderer, noSort: true },
  { id: 'hotlists', name: 'records.hotlist', width: 300, renderer: hotlistRenderer, noSort: true },
  { id: 'ownerId', name: 'records.owner', width: 80, renderer: ownerRenderer, noSort: true },
  { id: 'groups', name: 'users.groups', width: 300, renderer: recordGroupsRenderer, noSort: true },
];

export const recordsGridColumns = (): IDataGridCol<IRecord>[] => {
  if (!recordsGridColumnsInnerReplaced && gAPP_STORE.meetingTitleColumnName) {
    const elem = recordsGridColumnsInner.find(e => e.id === 'caseid');
    if (elem) {
      elem.name = gAPP_STORE.meetingTitleColumnName as string;
    }
    recordsGridColumnsInnerReplaced = true;
  }

  const summaryColId = ['summaryTopicCount', 'summaryHaveTask', 'summaryStatus'];
  let result = recordsGridColumnsInner;

  if (!gAPP_STORE.showSummaryAI) {
    result = result.filter(col => !summaryColId.includes(col.id ?? '-'));
  }

  if (!gAPP_STORE.showLabelsColumn) {
    result = result.filter(item => item.id !== 'labels');
  }

  return result;
};
