import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { IParticipant } from './types';
import ParticipantsPanel from './ParticipantsPanel';

export interface IRolesPanel {
  id: string;
  participants: IParticipant[];
  mentions: string;
  divider?: boolean;
  canEdit: boolean;
  autotest: string;
}

export const RolesPanel = observer(({ id, participants, mentions, divider, canEdit, autotest }: IRolesPanel) => {
  const { t } = useTranslation();
  const divide = divider && participants && mentions;

  return (
    <>
      {participants && <ParticipantsPanel participants={participants} editableRole={canEdit} autotest={autotest} />}
      <div
        data-autotest={`${autotest}-mentions`}
        style={{
          font: '14px',
          padding: `${id === 'brief' ? '12px' : '0px'}`,
          borderTop: `${divide ? '1px solid #ddd' : 'none'}`,
        }}
      >
        <span
          style={{
            color: 'rgba(108, 115, 127, 1)',
            fontSize: '14px',
            fontFamily: 'Inter',
            fontWeight: 400,
          }}
        >{`${t('summary.mentions')}: `}</span>
        <span style={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>{mentions ?? ''}</span>
      </div>
    </>
  );
});
