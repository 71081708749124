import { Box, IconButton, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IFormField, TFormFieldValue } from './types';
import { FileCopy, Visibility, VisibilityOff } from '@material-ui/icons';

interface IFormFieldTextProps {
  descriptor: IFormField;
  rangeIndex?: number;
  bgcolor?: string; // TODO bgcolor должен быть юнион типом допустимых цветов приложения
  fontSize?: string;
  autoFocus?: boolean;
  readonly?: boolean;
  onChange?: (id: string, value: TFormFieldValue) => void;
}

const FormFieldText: React.FC<IFormFieldTextProps> = ({
  descriptor,
  rangeIndex,
  bgcolor,
  fontSize,
  autoFocus,
  readonly,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  let localValue = descriptor.value || '';
  if (rangeIndex !== undefined) {
    localValue = Array.isArray(descriptor.value) ? (descriptor.value as [])[rangeIndex] : '';
  }

  const handleChangeValue = (event: any) => {
    const value = event.target.value;

    runInAction(() => {
      if (rangeIndex !== undefined) {
        ((descriptor.value as [])[rangeIndex] as TFormFieldValue) = value;
      } else {
        descriptor.value = value;
      }
    });
  };

  // const label =
  //   t(descriptor.name || '') +
  //   `${rangeIndex !== undefined ? (rangeIndex === 0 ? ' (' + t('from') + ')' : ' (' + t('to') + ')') : ''}`;

  const autotestId =
    'ff-txt-value-' +
    (descriptor.id ?? '') +
    '-' +
    (descriptor.autotestId || '') +
    (rangeIndex !== undefined ? '-' + rangeIndex : '');

  const handleKeyDown = (event: any) => {
    // console.log('key = ', event.key);
    if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
    }
    // if (event.key === 'Escape') {
    //   event.stopPropagation();
    //   event.preventDefault();
    // }
  };

  const isMultiline = (descriptor.textRows || 0) > 1;

  return (
    <Box flexGrow={1} display="flex" flexDirection="row" alignItems="center">
      <TextField
        // label={label}
        // placeholder={descriptor.placeholder}
        // variant="outlined"
        // variant="filled"
        error={!!descriptor.invalidMessage}
        helperText={t(descriptor.invalidMessage || '')}
        type={descriptor.type === 'password' && !showPassword ? 'password' : 'text'}
        multiline={isMultiline}
        rows={descriptor.textRows || 1}
        fullWidth
        InputLabelProps={{
          style: { fontSize: fontSize || 'inherit' },
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment:
            descriptor.type !== 'password' ? null : (
              <InputAdornment position="end">
                <IconButton
                  aria-label="copyPassword"
                  onMouseDown={e => e.preventDefault()}
                  edge="end"
                  onClick={() => navigator.clipboard.writeText(descriptor.value?.toString() || '')}
                >
                  {descriptor.value && <FileCopy />}
                </IconButton>
                <IconButton
                  aria-label="setPasswordVisibility"
                  onMouseDown={e => e.preventDefault()}
                  edge="end"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
        }}
        inputProps={{
          autoComplete: descriptor.type === 'password' ? 'new-password' : 'off',
          'data-autotest': autotestId,
          style: {
            fontSize: fontSize || 'inherit',
            background: bgcolor || 'inherit',
            // paddingLeft: '0.5em',
            // paddingRight: '0.5em',
            paddingTop: isMultiline ? 0 : '0.7em',
            paddingBottom: isMultiline ? 0 : '0.7em',
          },
        }}
        value={localValue}
        onFocus={event => event.target.select()}
        onChange={(event: any) => handleChangeValue(event)}
        onKeyDown={handleKeyDown}
        autoFocus={autoFocus}
        disabled={readonly}
      />
    </Box>
  );
};

export default observer(FormFieldText);
