import appColors from 'app/app-colors';
import { IGroup } from 'components/groups';
import { ITemplate } from 'components/templates/i-template';

import { ECommonDialogCloseStatus } from '@uk';

import { IWordDictionary } from '../wordDictionary/WordDictionary.types';
import { IPersonCard } from '../person-cards/types';
import { FilterStore } from '../filter/filter-store';
import { GroupsStore } from '../groups/groups-store';
import { SummaryStore } from '../summary/summary-store';
import { ISummary } from '../summary/types';

import { RecordsStore } from './records-store';

import { IDictor, TGenderValues } from '@/types/dictors';
import { IEntryWithCorrelationID, IEntryWithID } from '@/types/EntryWithId';

export enum ERecordStatus {
  UNKNOWN = -1,
  NEW = 0,
  LOADED = 1,
  PROCESSING = 2,
  PROCESSED = 3,
  FAILED = 4,
  PREPROCESSING = 5,
  PREPROCESSED = 6,
  MODEL_REBUILDING = 7,
  MODEL_REBUILDING_FAILED = 8,
}

export const statusToModelStatus = (status?: ERecordStatus) => {
  switch (status) {
    case undefined:
    case ERecordStatus.PROCESSING:
    case ERecordStatus.PREPROCESSED:
      return ERecordStatus.MODEL_REBUILDING;
      break;
    case ERecordStatus.FAILED:
      return ERecordStatus.MODEL_REBUILDING_FAILED;
      break;
    default:
      return status;
  }
};

export const RecordStatusMap = new Map<ERecordStatus, string>();
RecordStatusMap.set(ERecordStatus.UNKNOWN, 'recordStatus.unknown');
RecordStatusMap.set(ERecordStatus.NEW, 'recordStatus.new');
RecordStatusMap.set(ERecordStatus.LOADED, 'recordStatus.loaded');
RecordStatusMap.set(ERecordStatus.PROCESSING, 'recordStatus.processing');
RecordStatusMap.set(ERecordStatus.PROCESSED, 'recordStatus.processed');
RecordStatusMap.set(ERecordStatus.FAILED, 'recordStatus.failed');
RecordStatusMap.set(ERecordStatus.PREPROCESSING, 'recordStatus.preprocessing');
RecordStatusMap.set(ERecordStatus.PREPROCESSED, 'recordStatus.preprocessed');
RecordStatusMap.set(ERecordStatus.MODEL_REBUILDING, 'recordStatus.modelRebuilding');
RecordStatusMap.set(ERecordStatus.MODEL_REBUILDING_FAILED, 'recordStatus.modelRebuildingFailed');

export const FilterTranslationStatusList: string[] = [
  'recordStatus.new',
  'recordStatus.processing',
  'recordStatus.processed',
  'recordStatus.failed',
];

export const RecordStatusColorMap = new Map<number, string>();
RecordStatusColorMap.set(ERecordStatus.UNKNOWN, appColors.darkGrey);
RecordStatusColorMap.set(ERecordStatus.NEW, appColors.darkGrey);
RecordStatusColorMap.set(ERecordStatus.LOADED, appColors.blue);
RecordStatusColorMap.set(ERecordStatus.PROCESSING, appColors.yellow);
RecordStatusColorMap.set(ERecordStatus.PROCESSED, appColors.green);
RecordStatusColorMap.set(ERecordStatus.FAILED, appColors.red);
RecordStatusColorMap.set(ERecordStatus.PREPROCESSING, appColors.yellow);
RecordStatusColorMap.set(ERecordStatus.PREPROCESSED, appColors.blue);
RecordStatusColorMap.set(ERecordStatus.MODEL_REBUILDING, appColors.yellow);
RecordStatusColorMap.set(ERecordStatus.MODEL_REBUILDING_FAILED, appColors.red);

export interface IRecord extends IEntryWithCorrelationID<number> {
  status: ERecordStatus;
  caseid: string;
  fileName: string;
  createdDate: string;
  loadDate: string;
  ownerId: number;
  languages?: IRecordChannelLanguage[] | null;
  languageResolved: string;
  transcriptionId: number;
  textIdx?: string;
  groups?: IGroup[];
  topics?: IWordDictionary[];
  hotLists?: IHotlist[];
  translationId: number;
  translateIdx?: string;
  translateLanguage: string;
  translationStatus: ERecordStatus;
  voiceModelStatus: ERecordStatus;
  errorCause?: string;
  labelLists?: ILabel[];
  hotlists?: string;
  summaryStatus?: ERecordStatus;
  summaryTranslationStatus?: ERecordStatus;
  taskQuantity?: boolean;
  topicCount?: number;
  summary?: ISummary;

  // __dictors: IDictor[];
  __audioStorageKey?: string;
  __waveformStorageKey?: string;
  __videoStorageKey?: string;
}

export interface IRecordChannelLanguage {
  languages: ILanguage[];
  channel_number: string;
}

export interface ILanguage {
  fa: number;
  fr: number;
  language: string;
  probability: number;
  internalScore: number;
}

export type TRecordFields = keyof IRecord;

export interface IPersonCardDictor {
  extId: string;
  extIdInstance: string;
  name: string;
  surname?: string;
  gender: TGenderValues;
  modelRef: string;
  avatar?: string;
}

export interface IPreprocessParams {
  mono_params: {
    max_dictors: number;
    min_dictors: number;
    segmentation_type: string;
  };
  stereo_params: {
    max_dictors: number;
    min_dictors: number;
    segmentation_type: string;
  };
  no_speaker_diarization: boolean;
}

export interface IRegisterData {
  caseId: string;
  creationDate: string;
  fileName: string;
  languageStrict?: string;
  additionalWords?: string[];
  preprocessParams?: IPreprocessParams;
  dictors: IPersonCardDictor[];
  hotlists?: string;
}

export interface IRecordRedisFile {
  record: IRecord;
  file: IRedisFile;
}

export interface IWord {
  stop_sign: string | null;
  begin: number;
  dictor_num: number;
  end: number;
  probability: number;
  text: string;
  // additional
  channelNumber?: number;
  channelWords?: IChannelWords;
  isFiltered?: boolean;
}

export interface IChannelWords {
  channel: string;
  quality: number;
  words: IWord[];
}

export interface IGridAnswer {
  ExtraMetadata: string;
  Jwt: string;
  Cards: IPersonCard[];
  HotLists: IHotlist[];
}

export interface ICachedData {
  isCreateDictorCard: boolean;
  filterStore: FilterStore;
  recordsStore: RecordsStore;
  summaryStore: SummaryStore;
  dictors: IDictor[];
  dictor: IDictor;
  groups?: boolean;
  groupsStore?: GroupsStore;
  dialogStore?: IUploadResult2;
}

export interface IShardFile {
  file: File;
  indexRedisFile: number;
  redisIds: string[];
  totalShards: number;
  waitingShards: number;
  byteLoaded: number[];
  xhrs: (XMLHttpRequest | undefined)[];
}

export interface IRedisFile {
  name: string;
  redisIds: string[];
  shards?: IShardFile;
  creationDate: string;
  size: number;
  loading?: number;
  error?: boolean;
  deleted?: boolean;
}

export interface IUploadResult2 {
  maxMeetingParticipants?: number;
  //diarization?: boolean;
  multiLanguageRecognition?: boolean;
  isSeparated?: boolean;
  language?: string; // полное название языка либо 'auto'
  caseId?: string; // название совещания
  mettingTitle?: string; // локальное для инпута название совещания
  algorithm?: string;
  somewords?: string[];
  redisFiles?: IRedisFile[];
  newGroups?: IGroup[];
  newWordDictionaryList?: IWordDictionary[];
  participants?: IPersonCardDictor[];
  hotlists?: IHotlist[];
}

export interface IWordsData {
  id: number;
  correlationId: string;
  createdDate: string;
  creatorId: string;
  words: IChannelWords[];
}

export interface IPhrase {
  tokens: IWord[];
  textLen: number;
  channelNumber?: number;
  speakerNumber?: number;
  channelWords?: IChannelWords;
  dictor?: IDictor;
  begin?: number;
  end?: number;
}

export interface IDocxData {
  /**
   * Транскрипция
   */
  transcription?: IWordsData;
  /**
   * Автоматический перевод (первый в истории)
   */
  translationAuto?: IWordsData;
  /**
   * Последняя версия перевода, если такая есть
   */
  translation?: IWordsData;
}

export interface IExportPhrases {
  transcriptPhrases?: IPhrase[];
  translatePhrases?: IPhrase[];
  translateAutoPhrases?: IPhrase[];
}

export interface IExportContent {
  transcriptContent: ITemplatePhrase[];
  translateContent: ITemplatePhrase[];
  translateAutoContent: ITemplatePhrase[];
}

export interface IExportSummaryTask {
  tasksIssue: string;
  tasksDecision: string;
  tasksOwner: string;
  tasksDeadline: string;
  tasksEstimation: string;
}
export interface IExportSummaryTopic {
  topicsTitle: string;
  topicsDescription: string;
  topicsSpeakers: string;
  topicsMentions: string;
  topicsTasks: IExportSummaryTask[];
}
export interface IExportSummaryKeyDecision {
  KeyDecisionsTitle: string;
  KeyDecisionsDescription: string;
  KeyDecisionsOwner: string;
  KeyDecisionsDeadline: string;
  KeyDecisionsEstimation: string;
}
export interface IExportSummaryAmbiguitie {
  summaryAmbiguitiesTitle: string;
  summaryAmbiguitiesDescription: string;
}
export interface IExportSummary {
  title: string;
  date: string;
  description: string;
  summarySpeakers: string;
  summaryMentions: string;
  keyDecisions: IExportSummaryKeyDecision[];
  ambiguities: IExportSummaryAmbiguitie[];
  summaryTopics: IExportSummaryTopic[];
}

export interface ITemplateSpeaker {
  name: string;
  wordsCount: number;
  speechDuration: string;
}

export interface ITemplateSpeakerWithRole extends ITemplateSpeaker {
  role: string;
}

export interface ITemplatePhrase {
  speaker: string;
  time: string;
  phrase: string;
}

export interface ITemplateFields {
  createdBy: string;
  createdDate: string;
  meetingTitle: string;
  meetingDate: string;
  fileName: string;
  loadDate: string;
  transcriptionLanguage: string;
  speakersCount: number;
  speakers: ITemplateSpeaker[];
  transcription: ITemplatePhrase[];
  translation: ITemplatePhrase[];
  autoTranslation: ITemplatePhrase[];
  chartNumberOfWords: string;
  chartDuration: string;
  //~~~~~~ summary ~~~~~~~~~~~~~~~~~~
  summaryTitle: string;
  summaryDate: string;
  summaryDescription: string;
  summarySpeakers: string;
  summaryMentions: string;
  summaryKeyDecisions: IExportSummaryKeyDecision[];
  summaryAmbiguities: IExportSummaryAmbiguitie[];
  summaryTopics: IExportSummaryTopic[];
}

export interface IToFileDialogResult {
  closeStatus: ECommonDialogCloseStatus;
  template?: ITemplate;
  filename: string;
  showTime: boolean;
}

export interface IKwsIndex extends IEntryWithID<number> {
  recordId?: number;
  dictionaryId?: number;
  kwsThreshold?: number;
  revision?: number;
}

export interface IGroupToRecord extends IEntryWithID<number> {
  recordId?: number;
  groupId?: number;
}

export interface IVirtalListState {
  isScrolling: boolean;
  scrollDirection: string;
  scrollOffset: number;
  scrollUpdateWasRequested: boolean;
}

export interface IRecordsStatistic extends IEntryWithID<number> {
  totalForAll: number;
  totalPerStatus: IRecordsStatusStatistic[];
}

export interface IRecordsStatusStatistic {
  status: number;
  total: number;
}

export interface ILabel extends IEntryWithID<number> {
  recordId?: number;
  typeId: string;
  name: string;
  color: string;
  isDeleted?: boolean;
  comment?: string;
}

export interface IHotlist {
  GroupId: string;
  GroupName: string;
  CardsIds?: string[];
}
