import React, { useCallback, useEffect } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { useTranslation } from 'react-i18next';

import { IFormFieldDatetimeProps, TFormFieldValue } from './types';

import esLocale from 'date-fns/locale/es';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { dateToStrWithoutTime } from '@uk/utils/dateUtils';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { useForceUpdate } from '@uk/common/hooks';

const localeMap: any = {
  en: enLocale,
  es: esLocale,
  ru: ruLocale,
};


const FormFieldDate: React.FC<IFormFieldDatetimeProps> = ({ descriptor, rangeIndex, readonly }) => {
  const { t } = useTranslation();

  const forceUpdate = useForceUpdate();

  let value = descriptor.value || '';
  let otherValue: string | undefined = undefined;
  let maxDateTime: string | undefined = undefined;
  let minDateTime: string | undefined = undefined;

  if (rangeIndex !== undefined) {
    if (Array.isArray(descriptor.value)) {
      value = (descriptor.value as [])[rangeIndex];
      otherValue = (descriptor.value as [])[(rangeIndex + 1) % 2];

      if (rangeIndex > 0) {
        minDateTime = otherValue;
      } else {
        maxDateTime = otherValue;
      }
    } else
      value = '';
  }


  useEffect(() => {
    forceUpdate();
  }, [otherValue, forceUpdate]);


  const handleDatetimeChange = useCallback((d: Date | string | null) => {
    const value = d === 'Invalid Date' ? 'Invalid Date' : dateToStrWithoutTime(d as Date);
    const newValue: TFormFieldValue = value;

    runInAction(() => {
      if (rangeIndex !== undefined) {
        if (!descriptor.value) {
          descriptor.value = ['', ''];
        }
        ((descriptor.value as any[])[rangeIndex] as TFormFieldValue) = newValue;
      } else {
        descriptor.value = newValue;
      }
    });
  }, [descriptor, rangeIndex]);



  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={localeMap[t('dateTimePickerLang')]}
    >
      <KeyboardDatePicker
        autoOk
        maxDateMessage={t('maxDateMessage')}
        minDateMessage={t('minDateMessage')}
        maxDate={maxDateTime}
        minDate={minDateTime}
        inputProps={{
          'data-autotest':
            'ff-dtp-value-' +
            (descriptor.id ?? '') +
            '-' +
            (descriptor.autotestId || '') +
            (rangeIndex !== undefined ? '-' + rangeIndex : ''),
          style: {
            paddingTop: '0.7em',
            paddingBottom: '0.7em',
          },
        }}
        InputProps={{
          disableUnderline: true,
        }}
        InputAdornmentProps={{
          style: {
            padding: 0,
            margin: 0,
          },
        }}

        KeyboardButtonProps={{ style: { padding: 12, opacity: 0.5 } }}
        variant="inline"
        color="primary"
        name={descriptor.id}
        clearable={true}
        value={value && value.toString().length > 0 ? value.toString() : null}
        onChange={date => handleDatetimeChange(date)}
        // error={!!descriptor.invalidMessage}
        // helperText={t(descriptor.invalidMessage || '')}
        onError={console.log}
        invalidDateMessage={t('invalidDateMessage')}
        format="yyyy-MM-dd"
        // onKeyDown={onKeyDown}
        fullWidth
        disabled={readonly}
      />
    </MuiPickersUtilsProvider>
  );
};

export default observer(FormFieldDate);
