import { computed, makeObservable, observable } from 'mobx';

export class ISelectedFlag {
  @observable private isSelected = false;
  @observable private isLastSelected = false;

  constructor() {
    makeObservable(this);
  }

  @computed
  get selected() {
    return this.isSelected;
  }

  set selected(val: boolean) {
    this.isSelected = val;
  }

  @computed
  get last() {
    return this.isLastSelected;
  }

  set last(val: boolean) {
    this.isLastSelected = val;
  }

  reset() {
    this.isLastSelected = false;
    this.isSelected = false;
  }
}
