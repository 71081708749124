import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Fade, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import LanguagesGrid from 'components/languages/languages-grid';
import AutoTranslateGrid from 'components/auto-translate/auto-translate-grid';

import TemplatesGrid from '../templates/templates-grid';
import GroupsGrid from '../groups/groups-grid';
import UsersGrid from '../users/users-grid';

import EventLogPanel from '../eventLog/event-log-panel';

import { gAPP_STORE } from '@/app/app-store';
import { LoadingPanel } from '@/react-ui-kit/src';

interface ITab {
  id: string;
  title: string;
  component: ReactNode;
}

const Settings: FC = observer(() => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);

  const tabs: ITab[] = useMemo(() => {
    const tt: ITab[] = [];
    tt.push({ id: 'userSettings', title: 'settings.userSettings', component: <UsersGrid /> });

    if (!loading && gAPP_STORE.groups && (gAPP_STORE.loginStore.user?.isSupervisor || gAPP_STORE.loginStore.user?.isAdmin)) {
      tt.push({ id: 'groupSettings', title: 'settings.groupSettings', component: <GroupsGrid /> });
    }

    tt.push({ id: 'templateSettings', title: 'settings.templateSettings', component: <TemplatesGrid /> });
    tt.push({ id: 'languageSettings', title: 'settings.languageSettings', component: <LanguagesGrid /> });

    gAPP_STORE.viewTranslate && tt.push({ id: 'autoTranslate', title: 'settings.autoTranslate', component: <AutoTranslateGrid /> });

    if (gAPP_STORE.loginStore.user?.isAdmin) {
      tt.push({ id: 'eventLog', title: 'settings.eventLog', component: <EventLogPanel /> });
    }

    return tt;
  }, [loading]);

  const [selectedTab, setSelectedTab] = useState<number>(parseInt(localStorage.getItem('selectedTab') as string) || 0);

  useEffect(() => {
    const load = async () => {
      const getAll: Promise<void>[] = [];
      getAll.push(gAPP_STORE.getUsersStore().getAll());
      getAll.push(gAPP_STORE.getGroupsStore().getAll());
      getAll.push(gAPP_STORE.getTemplateStore().getAll());
      getAll.push(gAPP_STORE.getLanguagesStore().getAll());
      getAll.push(gAPP_STORE.getWordDictionaryStore().getAll());

      await Promise.allSettled(getAll);

      setLoading(false);
    };

    setLoading(true);
    load();
  }, []);


  if (loading) {
    return <LoadingPanel />;
  }

  return (
    <Fade in>
      <Box display="flex" flexGrow={1} flexDirection="column" style={{ background: '#E5E5E5' }}>
        <Box flexGrow={0}>
          <Tabs
            value={selectedTab} textColor="primary"
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
              localStorage.setItem('selectedTab', newValue);
            }}>
            {tabs.map((tab, index) => (
              <Tab key={`tab-${tab.id}`} label={t(tab.title)} value={index} />
            ))}
          </Tabs>
        </Box>

        {tabs[selectedTab].component}
      </Box>
    </Fade>
  );
});

export const SettingsPage: FC = () => {
  return (
    <>
      {gAPP_STORE.loginStore.user?.isAdmin && <Settings />}
    </>
  );
};
