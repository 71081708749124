import { BackendService, tserviceBackendApiUrl } from 'services';

import { ITranslateSetting, ITranslateSettingValue, ITranslations } from '../types';

import { gAPP_STORE } from '@/app/app-store';

export interface ITranslateSettingLoaded {
  key: string;
  modifyedDate: string;
  value: string;
}

class CAutoTranslateService {
  async getTranslateSettings (): Promise<ITranslateSetting[]> {
    try {
      const settings: ITranslateSettingLoaded[] = await BackendService.get('settings/system/translate');

      return settings.map(loaded => ({ ...loaded, value: JSON.parse(loaded.value) }));
    } catch (error) {
      return [];
    }
  }

  async getTranslateDirections (): Promise<ITranslations> {
    const url = `${tserviceBackendApiUrl}/query/translation/translation-info`;

    const res = (await BackendService.get(url, undefined, true, true)) as ITranslations;

    res.directions.forEach(f => {
      f.from = gAPP_STORE.tServiceLangNameToISO(f.from)?.code ?? f.from;
      f.to = gAPP_STORE.tServiceLangNameToISO(f.to)?.code ?? f.to;
    });

    return res;
  }

  async add (translateSetting: ITranslateSettingValue) {
    return await BackendService.put(
      'settings/system/add',
      JSON.stringify({ key: 'translate', value: JSON.stringify(translateSetting) }),
    );
  }

  async updateTranslate (translateSetting: ITranslateSettingValue) {
    return await BackendService.post(
      'settings/system/update',
      JSON.stringify({ key: 'translate', value: JSON.stringify(translateSetting) }),
    );
  }

  async delete () {
    await BackendService.delete('settings/system/translate');
  }
}

export const AutoTranslateService = new CAutoTranslateService();
