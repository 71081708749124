import React, { FC, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IUKDataGrid2Col } from './types';
import {
   createResizeMouseListeners,
   IResizeMouseListeners,
   removeResizeMouseListeners,
} from './resizeMouseListeners';

interface IColumnResizer {
   col?: IUKDataGrid2Col;
   startX?: number;
   dx?: number;
   elem?: HTMLElement;
}

const relativeCoords = (event: any, element: any) => {
   if (element) {
      const bounds = element.getBoundingClientRect();
      const x = event.clientX - bounds.left;
      const y = event.clientY - bounds.top;
      return { x: x, y: y };
   }
   return { x: 0, y: 0 };
};

const useStyles = makeStyles({
   columnResizer: {
      position: 'absolute',
      height: 'calc(100% - 12px)',
      width: 11,
      right: -4,
      top: 6,
      background: '#ddd',

      boxSizing: 'border-box',
      backgroundClip: 'padding-box',

      borderLeft: '5px solid rgba(255,255,255,0)',
      borderRight: '5px solid rgba(255,255,255,0)',
      margin: '0 0',
      cursor: 'col-resize',
   },
});

interface IColumnSizerProps {
   col: IUKDataGrid2Col;
   rootRef: any;
   columnResizeLineRef: any;
   updateLayout: () => void;
}

const ColumnResizer: FC<IColumnSizerProps> = ({
   col,
   rootRef,
   columnResizeLineRef,
   updateLayout,
}) => {
   const classes = useStyles();

   const columnResizer = useRef<IColumnResizer>({});
   const [resizeMouseListeners, setResizeMouseListeners] = useState<IResizeMouseListeners>();

   const showColumnResizerLine = (event: any) => {
      if (columnResizeLineRef.current) {
         const p = relativeCoords(event, rootRef.current);
         columnResizeLineRef.current.style['left'] = `${p.x}px`;
         columnResizeLineRef.current.style['display'] = 'block';
      }
   };

   const hideColumnResizerLine = () => {
      if (columnResizeLineRef.current) {
         columnResizeLineRef.current.style['display'] = 'none';
      }
   };

   const startResizeColumn = (event: any, col: IUKDataGrid2Col | undefined) => {
      event.preventDefault();
      event.stopPropagation();

      removeResizeMouseListeners(rootRef.current, resizeMouseListeners);
      setResizeMouseListeners(
         createResizeMouseListeners(rootRef.current, {
            doResize: handleResizeColumn,
            endResize: endResizeColumn,
         })
      );

      if (columnResizer.current !== undefined) {
         columnResizer.current.col = col;
         columnResizer.current.startX = event.clientX;
         columnResizer.current.elem = event.target;

         // showColumnResizerLine(event);
      }
   };

   const handleResizeColumn = (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      if (columnResizer.current.col && columnResizer.current.elem) {
         const dx = event.clientX - (columnResizer.current.startX || 0);

         if (columnResizer.current.col.width + dx > 30) {
            columnResizer.current.dx = dx;
            showColumnResizerLine(event);
         }
      }
   };

   const endResizeColumn = (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      if (event.target === rootRef.current || event.type === 'mouseup') {
         removeResizeMouseListeners(rootRef.current, resizeMouseListeners);

         if (columnResizer.current.col && columnResizer.current.elem) {
            columnResizer.current.col.width =
               columnResizer.current.col.width + (columnResizer.current.dx || 0);
         }

         columnResizer.current = {};

         hideColumnResizerLine();
         updateLayout();
      }
   };

   return (
      <div
         className={classes.columnResizer}
         onMouseDown={(event) => startResizeColumn(event, col)}></div>
   );
};

export default ColumnResizer;
