import { action, makeAutoObservable, reaction } from 'mobx';
import ReactPlayer from 'react-player';
import { OnProgressProps, SourceProps } from 'react-player/base';

import { RecordSoundStore } from './record-sound-store';

import { gridStorageService } from '@/services/grid-storage/grid-storage-service';

type TUrlType = string | string[] | SourceProps[] | MediaStream | null;

export class RecordVideoStore {
  soundStore: RecordSoundStore;

  player: ReactPlayer | undefined = undefined;

  url: TUrlType = null;
  pip = false;
  playing = false;
  controls = false;
  light = false;
  volume = 0;
  muted = true;
  played = 0;
  loaded = 0;
  duration = 0;
  playbackRate = 1.0;
  loop = false;
  seeking = false;
  showPlayer = false;
  isError = false;
  isLoading = false;
  isEnded = false;
  ratio = 16 / 9;

  constructor (soundStore: RecordSoundStore) {
    this.soundStore = soundStore;

    makeAutoObservable(this, undefined, { autoBind: true });

    reaction(
      () => this.soundStore.isCycling,
      fTabu => {
        this.showPlayer = false;
      },
    );
  }

  load () {
    const { record } = this.soundStore;
    const { __videoStorageKey: storageKey } = record || {};

    const url = storageKey ? (record?.id ? gridStorageService.getNginxUrl(record.id, storageKey) : '') : '';

    this.url = url;
    this.played = 0;
    this.loaded = 0;
    this.pip = false;
    this.isError = false;
    this.isEnded = false;
    this.isLoading = true;
  }

  play () {
    if (this.isEnded) {
      this.seekTo(0);
    } else {
      this.updatePositionBySound();
    }
    this.playing = true;
    this.isEnded = false;
  }

  pause () {
    this.updatePositionBySound();
    this.playing = false;
  }

  stop () {
    this.url = null;
    this.playing = false;
  }

  togglePlayPause () {
    if (this.isEnded) {
      this.seekTo(0);
    } else {
      this.updatePositionBySound();
    }
    this.playing = !this.playing;
    this.isEnded = false;
  }

  toggleControls () {
    this.controls = !this.controls;
  }

  toggleLight () {
    this.light = !this.light;
  }

  toggleLoop () {
    this.loop = !this.loop;
  }

  setVolume (volume: number) {
    this.volume = volume;
  }

  toggleMuted () {
    this.muted = !this.muted;
  }

  setPlaybackRate (speed: string) {
    this.playbackRate = parseFloat(speed);
  }

  setPIP (pip: boolean) {
    this.pip = pip;
  }

  setSeekMouseDown () {
    this.seeking = true;
  }

  seekTo (position: number) {
    this.seeking = false;
    if (!this.isError && this.player) {
      this.isLoading = true;
      this.player.seekTo(position);
    }
  }

  setEnded () {
    this.playing = false;
    this.isEnded = true;
  }

  setDuration (duration: number) {
    this.duration = duration;
  }

  setPlayer (player: ReactPlayer) {
    this.player = player;
  }

  setRatio (ratio: number) {
    this.ratio = ratio;
  }

  @action
  reset () {
    this.url = null;
    this.pip = false;
    this.playing = false;
    this.controls = false;
    this.light = false;
    this.volume = 0.8;
    this.muted = true;
    this.played = 0;
    this.loaded = 0;
    this.duration = 0;
    this.playbackRate = 1.0;
    this.loop = false;
    this.seeking = false;
    this.showPlayer = false;
    this.isError = false;
    this.isEnded = false;
    this.isLoading = false;
  };

  toggleShowPlayer () {
    this.showPlayer = !this.showPlayer;

    setTimeout(() => this.updatePositionBySound(), 500);
  }

  updatePositionBySound () {
    this.seekTo(this.soundStore.position || 0);
  }

  onReady (player: ReactPlayer) {
    this.isLoading = false;
    this.isError = false;
  }

  onProgress (state: OnProgressProps) {
    this.loaded = state.loaded;
    this.played = state.played;
  }

  onError () {
    this.isLoading = false;
    this.isError = true;
  }
}
