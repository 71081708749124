import { Box, BoxProps } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { MutableRefObject } from 'react';

import styles from './panel.module.scss';

interface IPanelProps {
  children?: ReactNode[] | ReactNode;
  flexGrow?: number;
  style?: Record<string, any>;
  className?: string;
  noShadow?: boolean;
  rounded?: boolean;
  rightBorder?: boolean;
  leftBorder?: boolean;
  topBorder?: boolean;
  verticalScroll?: boolean;
  refObject?: MutableRefObject<any>;
}

const Panel: React.FC<IPanelProps & BoxProps> = ({
  children,
  flexGrow,
  style,
  className,
  noShadow,
  rounded,
  rightBorder,
  leftBorder,
  topBorder,
  verticalScroll,
  refObject,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      flexGrow={flexGrow !== undefined ? flexGrow : 1}
      flexDirection="column"
      className={clsx(className, {
        [styles.shadow]: rounded && !noShadow,
        [styles.rounded]: rounded,
        [styles.rightBorder]: rightBorder,
        [styles.leftBorder]: leftBorder,
        [styles.topBorder]: topBorder,
        [styles.verticalScroll]: verticalScroll,
      })}
      style={style}
      bgcolor={rest.bgcolor || 'white'}
      {...rest}
      {...({ ref: refObject } as any)}
    >
      {children}
    </Box>
  );
};
export default Panel;
