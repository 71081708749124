import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, TextField, Typography } from '@material-ui/core';

import { Stack } from '../UploadFiles/items/Stack';

import { dialogLabelsRestrictions } from '@/common/constants';

export interface IItemComment {
  text: string;
  setComment: (value: string) => void;
  focus?: boolean;
}

export const ItemComment: FC<IItemComment> = ({ text, setComment, focus }) => {
  const { t } = useTranslation();

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setComment(e.target.value as string);
    },
    [setComment],
  );

  const labelOfComment = useMemo(
    () => (
      <Stack direction="row" alignItems="center" spacing={0}>
        <Typography data-autotest="ItemCommentLabel">{t('labelForm.comment')}</Typography>
      </Stack>
    ),
    [t],
  );

  const blockComment = (
    <FormControl variant="outlined" fullWidth>
      <Stack spacing={0}>
        <TextField
          autoFocus={focus}
          label={t('labelForm.commentTips')}
          variant="filled"
          fullWidth
          inputProps={{ maxLength: dialogLabelsRestrictions.MAX_COMMENTS_LENGTH }}
          onChange={e => onChange(e)}
          value={text}
          data-autotest="ItemCommentField"
        />
      </Stack>
    </FormControl>
  );

  return (
    <Stack spacing={1} mb={2} data-autotest="ItemComment">
      {labelOfComment}
      {blockComment}
    </Stack>
  );
};
