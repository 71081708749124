export interface IEventLogEntry {
  id: number;
  loggedDatetime: number | string;
  userId: string;
  eventType: number;
  actionType: number;
  actionMessage: string;
  actionParameters: string;
}

export interface IActionMessages {
  actionType: number;
  eventType: number;
  actionMessage: string;
  actionParameters: string[];
}

export interface IExportEvent {
  eventType: number;
  actionType: number;
  user: string;
  loggedDatetime: string;
  message: string;
}

export type TFields = keyof IEventLogEntry;

export type TEventLogTypeValue = 'action' | 'error';

export enum EEventType {
  action,
  error,
}

export enum EProcessing_method {
  preprocess_audio,
  detect_and_recognize,
  translate,
  voice_model_rebuild,
}

export const EventTypeMap = new Map<EEventType, string>();
(Object.keys(EEventType) as Array<keyof typeof EEventType>).forEach(i => {
  EventTypeMap.set(EEventType[i], i);
});

export interface IEventDescription {
  actionType: number;
  eventType: number;
  actionMessage: string;
  actionParameters: string[];
}

//применяется в фильтре ЖС
//export const topicTypes = ['eventLog.is_kws', 'eventLog.is_auto_asr', 'eventLog.is_auto_asr_and_kws'];
export const topicTypes = ['eventLog.is_kws', 'eventLog.is_auto_asr'];
