import { alpha } from '@material-ui/core';
import { common } from '@material-ui/core/colors';

import { PaletteOptions } from '@material-ui/core/styles/createPalette';

import { error, info, neutral, success, warning } from '../colors';
import type { ColorPreset, Contrast } from '../index';
//import { getPrimary, getSecondary } from '../utils';

import appColors from '@/app/app-colors';

interface IConfig {
  colorPreset?: ColorPreset;
  contrast?: Contrast;
}

export const createPalette = (config: IConfig): PaletteOptions => {
  const { colorPreset, contrast } = config;

  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12),
    },
    background: {
      default: contrast === 'high' ? neutral[50] : common.white,
      paper: common.white,
    },
    divider: '#F2F4F7',
    error,
    info,
    primary: {
      main: appColors.primary, // катар
    },
    secondary: {
      main: appColors.primaryLight, //катар
    },
    // primary: getPrimary(colorPreset), //  настройка Сергея
    // secondary: getSecondary(colorPreset), //  настройка Сергея
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38),
    },
    warning,
    grey: neutral,
  };
};
