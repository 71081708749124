import React from 'react';

import { Box, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { IFormField } from './types';
import { observer } from 'mobx-react';
import { IFormFieldListItem } from '@uk';
import { runInAction } from 'mobx';

interface IFormFieldRadioProps {
  descriptor: IFormField;
  bgcolor?: string;
  fontSize?: string;
  readonly?: boolean;
}

const FormFieldRadio: React.FC<IFormFieldRadioProps> = ({ descriptor, readonly }) => {
  const { t } = useTranslation();

  //const [localValue, setLocalValue] = useState<TFormFieldValue | undefined>(descriptor.value);

  const defaultValue: IFormFieldListItem = Array.isArray(descriptor.value)
    ? (descriptor.value[0] as IFormFieldListItem)
    : (descriptor.value as IFormFieldListItem);

  return (
    <Box pl={2}>
      {/* <FormControlLabel
        control={
          <Checkbox
            data-autotest={'ff-cb-value-' + descriptor.id ?? '' + '-' + descriptor.autotestId + ''}
            color="primary"
            name={descriptor.id}
            checked={!!descriptor.value}
            onChange={(event: any) => {
              runInAction(() => {
                descriptor.value = event.target.checked;
              });
            }}
            disabled={readonly}
          />
        }
        label={t(descriptor.name || '')}
      /> */}

      <RadioGroup
        defaultValue={defaultValue.id}
        onChange={(event: any) => {
          runInAction(() => {
            descriptor.value = [descriptor.list?.find(item => item.id === event.target.value)];
          });
        }}
      >
        {(descriptor.list || []).map(item => {
          return <FormControlLabel value={item.id} control={<Radio />} label={t(item.title)} />;
        })}
      </RadioGroup>
    </Box>
  );
};

export default observer(FormFieldRadio);
