import { ERecordStatus, RecordStatusMap } from '../types';

import { topicTypes } from '@/components/eventLog/types';
import { gAPP_STORE } from '@/app/app-store';
import {
  ERequestFilterPredicate,
  IFilterData,
  IFilterJsonField,
  IRequestFilterDataDictors,
  IRequestFilterField,
  IRequestFilterSummary,
} from '@/components/filter/i-filter';
import { TFilterFieldValue, TFilterPredicate } from '@/components/filter/i-filter-field';

export const convertPredicate = (appPredicate: TFilterPredicate | undefined): ERequestFilterPredicate => {
  switch (appPredicate) {
    case 'contains':
      return ERequestFilterPredicate.CONTAINS;
    case 'not_contains':
      return ERequestFilterPredicate.NOT_EQ;
    case 'equal':
      return ERequestFilterPredicate.EQ;
    case 'not_equal':
      return ERequestFilterPredicate.NOT_EQ;
    case 'greater_than_equal':
      return ERequestFilterPredicate.EQ_GR;
    case 'less_than_equal':
      return ERequestFilterPredicate.EQ_LESS;
    default:
      return ERequestFilterPredicate.CONTAINS;
  }
};

const hasValue = (value?: TFilterFieldValue) => {
  if (!value) return false;

  if (Array.isArray(value)) return value.length > 0;

  return Boolean(value);
};

//~~~ fix when back for filter will ready
export const filterDataToSummaryField = (filterData: IFilterData): IRequestFilterSummary | undefined => {
  const result: IRequestFilterSummary = {};

  if (filterData.summaryText && hasValue(filterData.summaryText.value)) {
    result.text = {
      value: filterData.summaryText.value as string[] || [],
      predicate: convertPredicate(filterData.summaryText.predicate)
    };
  }

  if (filterData.summaryInstructions && hasValue(filterData.summaryInstructions.value)) {
    result.hasActions = filterData.summaryInstructions.value as boolean || false;
  }

  if (filterData.summaryTopics && hasValue(filterData.summaryTopics.value)) {
    result.topics = {
      value: filterData.summaryTopics.value as string[] || [],
      predicate: convertPredicate(filterData.summaryTopics.predicate)

    };
  }

  return Object.keys(result).length > 0 ? result : undefined;
};

export const filterDataToDictorsField = (filterData: IFilterData): IRequestFilterDataDictors | undefined => {
  let result: IRequestFilterDataDictors | undefined = undefined;
  const meetingParticipants = filterData.meetingParticipants?.value || [];
  const meetingParticipantsPre = filterData.meetingParticipants?.predicate;
  const dictorsArray = filterData.dictors?.value || [];
  const speechDuration = filterData.speechDuration?.value;
  const speechDurationPre = filterData.speechDuration?.predicate;
  const wordsCount = filterData.wordsCount?.value;
  const wordsCountPre = filterData.wordsCount?.predicate;
  const isDictors = Array.isArray(dictorsArray) && dictorsArray.length > 0;
  const isSpeechDuration = Array.isArray(speechDuration) && speechDuration.length > 0;
  const isWordsCount = Array.isArray(wordsCount) && wordsCount.length > 0;
  const isMeetingParticipants = Array.isArray(meetingParticipants) && meetingParticipants.length > 0;
  if (isDictors || isSpeechDuration || isWordsCount || isMeetingParticipants) {
    result = {};
    if (isDictors) {
      result.ids = gAPP_STORE.getDictorsStore().filterToDictorIds(dictorsArray);
    }
    if (isSpeechDuration) {
      result.speechDuration = {
        value: +speechDuration[0],
        predicate: convertPredicate(speechDurationPre),
      };
    }
    if (isMeetingParticipants) {
      result.meetingParticipants = {
        value: +meetingParticipants[0],
        predicate: convertPredicate(meetingParticipantsPre),
      };
    }
    if (isWordsCount) {
      result.wordsCount = {
        value: +wordsCount[0],
        predicate: convertPredicate(wordsCountPre),
      };
    }
  }

  return result;
};

const convertStatus = (appStatus: string | undefined): ERecordStatus => {
  const status = Array.from(RecordStatusMap.entries()).find(e => e[1] === appStatus)?.[0];

  return status !== undefined ? status : -1;
};

export const addDateField = <T>(
  dateStr: string,
  fieldName: T,
  predicate: ERequestFilterPredicate,
  fields: IRequestFilterField<T>[],
) => {
  if (dateStr.length > 0) {
    const f: IRequestFilterField<T> = {
      fieldName,
      predicate,
      targetValues: [new Date(dateStr).toISOString()],
    };
    fields.push(f);
  }
};

export const addTextField = <T>(
  textValues: string[],
  fieldName: T,
  predicate: ERequestFilterPredicate,
  fields: IRequestFilterField<T>[],
) => {
  if (textValues.length > 0) {
    const f: IRequestFilterField<T> = {
      fieldName,
      predicate,
      targetValues: textValues,
    };
    fields.push(f);
  }
};

export const addStatusField = <T>(
  statusValues: string[],
  fieldName: T,
  predicate: ERequestFilterPredicate,
  fields: IRequestFilterField<T>[],
) => {
  if (statusValues.length > 0) {
    let statusesInFilter = statusValues.map(status => convertStatus(status));
    const vmStatusValues: ERecordStatus[] = [];
    // если в фильтре выбрали "ошибка перестроения модели", то искать по полю voice_model_status == 4 (Failed)
    if (statusesInFilter.includes(ERecordStatus.MODEL_REBUILDING_FAILED)) {
      statusesInFilter = statusesInFilter.filter(f => f !== ERecordStatus.MODEL_REBUILDING_FAILED);
      vmStatusValues.push(ERecordStatus.FAILED);
    }
    //если выбрали "обработано" , то искать по полю status == 3 AND voice_model_status==3
    if (statusesInFilter.includes(ERecordStatus.PROCESSED)) {
      vmStatusValues.push(ERecordStatus.PROCESSED);
    }
    // если выбрали "перестроение модели", то искать по полю voice_model_status == 6 OR voice_model_status == 2
    if (statusesInFilter.includes(ERecordStatus.MODEL_REBUILDING)) {
      statusesInFilter = statusesInFilter.filter(f => f !== ERecordStatus.MODEL_REBUILDING);
      vmStatusValues.push(ERecordStatus.PROCESSING);
      vmStatusValues.push(ERecordStatus.PREPROCESSED);
    }

    if (statusesInFilter.length > 0) {
      const f: IRequestFilterField<T> = {
        fieldName,
        predicate,
        targetValues: statusesInFilter.map(status => status.toString()),
      };
      fields.push(f);
    }
    if (vmStatusValues.length > 0) {
      const f: IRequestFilterField<T> = {
        fieldName: 'voiceModelStatus' as T,
        predicate,
        targetValues: vmStatusValues.map(status => status.toString()),
      };
      fields.push(f);
    }
  }
};

export const addTranslateStatusField = <T>(
  statusValues: string[],
  fieldName: T,
  predicate: ERequestFilterPredicate,
  fields: IRequestFilterField<T>[],
) => {
  if (statusValues.length > 0) {
    const f: IRequestFilterField<T> = {
      fieldName,
      predicate,
      targetValues: statusValues.map(status => convertStatus(status).toString()),
    };
    fields.push(f);
  }
};

export const addOwnerField = <T>(
  ownersValues: string[],
  fieldName: T,
  predicate: ERequestFilterPredicate,
  fields: IRequestFilterField<T>[],
) => {
  if (ownersValues.length > 0) {
    const f: IRequestFilterField<T> = {
      fieldName,
      predicate,
      targetValues: gAPP_STORE
        .getUsersStore()
        .data.users.filter(user => ownersValues.includes(user.id.toString()))
        .map(user => user.id.toString()),
    };
    fields.push(f);
  }
};

export const addTextFieldJsonField = <T>(
  textValues: string[],
  key: T,
  predicate: ERequestFilterPredicate,
  jsonFields: IFilterJsonField<T>[],
) => {
  if (textValues.length > 0) {
    const f: IFilterJsonField<T> = {
      fieldName: 'actionParameters',
      key,
      predicate,
      targetValues: textValues,
    };
    jsonFields.push(f);
  }
};

export const addTopicTypeFieldJsonField = <T>(
  textValues: string[],
  predicate: ERequestFilterPredicate,
  jsonFields: IFilterJsonField<T>[],
) => {
  textValues.forEach(val => {
    if (topicTypes.includes(val)) {
      const [_, key] = val.split('.');
      const f: IFilterJsonField<T> = {
        fieldName: 'actionParameters',
        key: key as T,
        predicate,
        targetValues: [true],
      };
      jsonFields.push(f);
    }
  });
};
