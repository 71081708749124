import moment from 'moment';
import i18n from 'i18next';
import * as XLSX from 'xlsx';

import { IExportEvent } from './types';

export interface ISerializedGrid {
  csvBody: string[][];
  csvHeader: string[];
  description?: string;
}

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export const i18nT = (localeKey: string, params?: Record<string, string | number>) => i18n.t<string>(localeKey, params);

export const downloadBlob = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

export const downloadString = (data: string, fileName: string, type?: string) => {
  const blob = new Blob([data], { type });

  downloadBlob(blob, fileName);
};

export const dateToStrWithTime = (date: Date | null) => (date ? moment(date).format('YYYY-MM-DDTHH:mm:ss') : '');

export const exportToCSVAndDownload = (filename: string, exportCsv: ISerializedGrid): string => {
  const fName = `${filename}_${dateToStrWithTime(new Date())}`;
  const csvData = [exportCsv.csvHeader, ...exportCsv.csvBody];

  console.log('csvData', csvData);
  if (exportCsv.description) {
    csvData.unshift([i18nT('description'), exportCsv.description]);
  }

  const csvString = csvData.map(x => x.join(',')).join('\n');
  downloadString(csvString, `${fName}.csv`);

  return csvString;
};

export const getXls = (sourceData: IExportEvent[], headers: string[], fileName: string, description: string) => {
  const maxRecordsPerFile = 1000;
  const totalRecords = sourceData.length;

  const createWorksheet = (startIndex: number, endIndex: number) => {
    const ws = XLSX.utils.book_new();
    let headersOrigin = 'A1';
    let tableOrigin = 'A3';

    if (description) {
      headersOrigin = 'A3';
      tableOrigin = 'A5';
      const descriptionText = `${i18nT('description')}: ${description}`;
      XLSX.utils.sheet_add_json(ws, [[descriptionText]], { origin: 'A1', skipHeader: true });
    }

    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: headersOrigin });
    const slicedData = sourceData.slice(startIndex, endIndex);
    XLSX.utils.sheet_add_json(ws, slicedData, { origin: tableOrigin, skipHeader: true });

    return ws;
  };

  const numberOfFiles = Math.ceil(totalRecords / maxRecordsPerFile);
  for (let i = 0; i < numberOfFiles; i++) {
    const startIndex = i * maxRecordsPerFile;
    const endIndex = Math.min((i + 1) * maxRecordsPerFile, totalRecords);
    const ws = createWorksheet(startIndex, endIndex);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const currentFileName = numberOfFiles > 1 ? `${fileName}` : fileName;
    downloadString(excelBuffer, currentFileName, fileType);
  }
};
