import { DownloadLocalesButtonPage } from '@/components/DownloadLocalesButtonPage/DownloadLocalesButtonPage';
import DocsPage from '@/components/docs/docs-page';
import HomePage from '@/components/home/home-page';
import loginPage from '@/components/login/login-page';
import { SettingsPage } from '@/components/settings';
import TestPage from '@/components/test/test-page';
import { WordDictionaryPage } from '@/components/wordDictionary/WordDictionaryPage';

export interface IRoute {
  path: string;
  component: React.ComponentType;
  exact?: boolean;
}

export enum RouteNames {
  LOGIN = '/login',
  HOME = '/',
  WORD_DICTIONARY = '/wordDictionary',
  SETTINGS = '/settings',
  USER_GUIDE = '/userGuide',
  LOCALE = '/locale',
  TEST = '/test',
}

export const publicRoutes: IRoute[] = [
  {
    path: RouteNames.LOGIN,
    exact: false,
    component: loginPage,
  },
];

export const privateRoutes: IRoute[] = [
  {
    path: RouteNames.HOME,
    exact: true,
    component: HomePage,
  },
  {
    path: RouteNames.WORD_DICTIONARY,
    exact: false,
    component: WordDictionaryPage,
  },
  {
    path: RouteNames.SETTINGS,
    exact: false,
    component: SettingsPage,
  },
  {
    path: RouteNames.USER_GUIDE,
    exact: false,
    component: DocsPage,
  },
  {
    path: RouteNames.LOCALE,
    exact: false,
    component: DownloadLocalesButtonPage,
  },
  {
    path: RouteNames.TEST,
    exact: false,
    component: TestPage,
  },
];
