import { toast } from 'react-toastify';

import i18n from 'i18next';

import { BackendService } from '@/services/backend-service';
import { ILabel } from '@/components/records';

export interface ILabelUpdate {
  id: number;
  recordId: number;
  typeId: string;
  comment: string;
}
class LabelService {
  updateRecordsBylabels = async (
    addLabels: ILabelUpdate[],
    deleteLabels: ILabelUpdate[],
    updateLabels: ILabelUpdate[],
  ) => {
    try {
      await BackendService.post('labels/multi-update', JSON.stringify({ deleteLabels, addLabels, updateLabels }));

      return;
    } catch (error) {
      console.error('updateLabels: ', { error });
      toast.error(`${i18n.t('ActionStatusError')}`);

      return;
    }
  };

  getLabelTypes = async (): Promise<ILabel[]> => {
    try {
      const result = await BackendService.get('labels/types');

      return result;
    } catch (error) {
      console.error('get lalbelTypes: ', { error });

      return [];
    }
  };
}

export const labelService = new LabelService();
