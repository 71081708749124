import React from 'react';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IDataGridPaginationProps } from '.';
import { ToolbarButton } from '../toolbarEx';

interface Props extends IDataGridPaginationProps<any> {}

const PaginationControlSteps: FC<Props> = ({ gridStore }) => {
  const { t } = useTranslation();

  const labelDisplayedRows = () => {
    const rowsPerPage = gridStore.rowsOnPage;
    const page = gridStore.currentPage;

    const from = gridStore.data.length > 0 ? page * rowsPerPage + 1 : 0;
    const to = Math.min(rowsPerPage, gridStore.data.length) + from - (from ? 1 : 0);

    return gridStore.isPending ? t('loading') : `${from}-${to}`;
  };

  return (
    <>
      <Box px={2}>{labelDisplayedRows()}</Box>
      <Box px={1} py={1} display="flex">
        <ToolbarButton
          autotestId={`${gridStore.dataGridId}_DataGridPagination_Prev`}
          text={t('prev')}
          disabled={gridStore.isPending || !gridStore.hasPrevPage}
          onClick={() => {
            gridStore.setCurrentPage(gridStore.currentPage - 1)
          }}
        />
        <Box pr={0.5} />
        <ToolbarButton
          autotestId={`${gridStore.dataGridId}_DataGridPagination_Next`}
          text={t('next')}
          disabled={gridStore.isPending || !gridStore.hasNextPage}
          onClick={() => {
            gridStore.setCurrentPage(gridStore.currentPage + 1)
          }}
        />
      </Box>
    </>
  );
};

export default observer(PaginationControlSteps);
