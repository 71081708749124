import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
//import { runInAction } from 'mobx';

import { Button } from '@material-ui/core';

import { ILabel } from '../../types';

import { ItemLabelTypesComboBox } from './itemLabelTypesComboBox';
import { ItemComment } from './itemComment';

export interface IItemLabelAddComment {
  selectedTypeIds: string[];
  isLimitReached: boolean;
  handleAdd: (label: ILabel, comment: string) => void;
}

export const ItemLabelAddComment = observer(({ selectedTypeIds, isLimitReached, handleAdd }: IItemLabelAddComment) => {
  const { t } = useTranslation();

  const [label, setLabel] = useState<ILabel | undefined>(undefined);
  const [comment, setComment] = useState<string>('');

  const hAdd = useCallback(() => {
    if (label) {
      handleAdd(label, comment);
      setLabel(undefined);
      setComment('');
    }
  }, [comment, handleAdd, label]);

  const btnAdd = (
    <Button
      onClick={label ? hAdd : undefined}
      variant="contained"
      color={'primary'}
      disabled={isLimitReached || !label}
      style={{ margin: '1em  0 1em 0' }}
      data-autotest="ItemLabelAndCommentButton"
    >
      {isLimitReached ? t('labelForm.limitReached') : t('labelForm.addLabel')}
    </Button>
  );

  return (
    <div id="ItemLabelAddComment" style={{ width: '100%', padding: '5px' }} data-autotest="ItemLabelAndComment">
      <ItemLabelTypesComboBox defaultLabel={label} setLabel={setLabel} selectedTypeIds={selectedTypeIds} />
      <ItemComment text={comment} setComment={setComment} />
      {btnAdd}
    </div>
  );
});
