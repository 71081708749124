import { FC, useMemo } from 'react';

import { observer } from 'mobx-react';

import { ERecordStatus, statusToModelStatus } from '..';

import { useNeedSaveChanges } from './useNeedSaveChanges';

import { IToolbarButtonProps, ToolbarButton } from '@/react-ui-kit/src';
import { gAPP_STORE } from '@/app/app-store';

export const RecordTextToolbarRebuildModel: FC<IToolbarButtonProps> = observer(props => {
  const { record } = gAPP_STORE.getRecordTextStore();

  const voiceModelStatus = record?.voiceModelStatus;

  const { needSaveChanges } = useNeedSaveChanges();

  const showButtonRebuildModel = useMemo(() => {
    const permitEdit = (gAPP_STORE.loginStore.user?.isEditor ?? false) && gAPP_STORE.editingASR;

    return (
      permitEdit && statusToModelStatus(voiceModelStatus) === ERecordStatus.MODEL_REBUILDING_FAILED && !needSaveChanges
    );
  }, [voiceModelStatus, needSaveChanges]);

  if (!showButtonRebuildModel) return null;

  return <ToolbarButton {...props} />;
});
