import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { IToolbarExDescriptorItem, Panel, ToolbarEx, ukColors } from '@uk';

import { EDirection } from '@/components/languages/i-language';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    borderTop: `1px solid ${ukColors.borderColor}`,
  },
  cell: {
    padding: '0.8em',
    display: 'flex',
    position: 'relative',
  },
  cellContent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: '1px solid green',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  statusCell: {
    width: 1,
  },
}));

interface IFlipIconProps {
  isOpen: boolean;
  onClick: () => void;
}

const FlipIcon = ({ isOpen, onClick }: IFlipIconProps) => {
  return (
    <IconButton onClick={onClick} style={{ padding: '0px' }}>
      {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
    </IconButton>
  );
};

export interface ICollapsePanel {
  id: string;
  title: ReactNode;
  rtl?: EDirection;
  button?: JSX.Element;
  component: JSX.Element;
  showPanel: boolean;
  switchShow: () => void;
  bgcolor?: string;
  autotest: string;
}

export const CollapsePanel = observer((props: ICollapsePanel) => {
  const { id, title, rtl, button, showPanel, switchShow: switchPanel, bgcolor, component } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(() => {
    const descr: IToolbarExDescriptorItem[] = [
      {
        type: 'custom',
        component: <FlipIcon isOpen={showPanel} onClick={() => switchPanel()} />,
        pr: 0,
        pl: 0,
      },
    ];
    if (rtl) {
      descr.push({
        type: 'custom',
        component: (
          <div
            onClick={() => switchPanel()}
            style={{ cursor: 'pointer', direction: rtl, textAlign: `${rtl === EDirection.RTL ? 'right' : 'left'}` }}
          >
            {title}
          </div>
        ),
      });
    } else if (typeof title !== 'string') {
      descr.push({
        type: 'custom',
        component: title,
      });
    } else {
      descr.push({
        type: 'custom',
        component: (
          <div onClick={() => switchPanel()} style={{ cursor: 'pointer' }}>
            {t(title)}
          </div>
        ),
      });
    }

    if (button) {
      descr.push({ type: 'filler' });
      descr.push({ type: 'custom', component: button });
    }

    return descr;
  }, [button, rtl, showPanel, switchPanel, t, title]);

  return (
    <Panel
      data-autotest={`${props.autotest}-Collapspanel`}
      key={id}
      width={'100%'}
      display="flex"
      flexDirection="column"
      className={classes.root}
      flexGrow={showPanel ? 1 : 0}
    >
      <ToolbarEx descriptor={toolbarDescriptor} style={{ backgroundColor: bgcolor }} />
      {showPanel && component}
    </Panel>
  );
});
