import { Box, BoxProps } from '@material-ui/core';
import { FC } from 'react';

interface IStackProps extends BoxProps {
  direction?: string;
  gap?: number;
  spacing?: number;
}

export const Stack: FC<IStackProps> = (props) => {
  const {
    minHeight,
    height,
    width,
    direction, gap, spacing, alignItems, justifyContent, border, borderRadius, padding, margin, p, m } = props;

  let gapValue = undefined;

  if (gap) {
    gapValue = `${gap * 0.5}em`;
  }

  if (spacing) {
    gapValue = `${spacing * 0.5}em`;
  }

  return <Box
    {...props}

    style={{
      minHeight,
      height,
      width,

      display: 'flex',
      flexDirection: direction === 'row' ? 'row' : 'column',
      flexWrap: 'wrap',

      gap: gapValue,

      alignItems,
      justifyContent,

      border,
      borderRadius: `${borderRadius * 0.5}em`,
      padding: `${(padding ?? p) * 0.5}em`,
      margin: `${(margin ?? m) * 0.5}em`,
    }}
  />;
};