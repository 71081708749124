import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ukColors from '@uk/ukColors';
import { objectRenderer } from '@uk';
import { IDataGridColBase, TDataGridDetailsRender, IDataGridStore } from '@uk';
import Panel from '@uk/components/panel/Panel';
import { IToolbarExDescriptorItem } from '@uk/components/toolbarEx';
import ToolbarEx from '@uk/components/toolbarEx/ToolbarEx';

interface ICommonInfoPanelProps {
  autotestId?: string;
  cols: IDataGridColBase[];
  gridStore: IDataGridStore<any>;
  hasLeftBorder?: boolean;
  hideToolbar?: boolean;
  customComponentRender?: (selectedRow: any, onClose?: () => void) => ReactNode;
  onClose?: () => void;
};

const CommonInfoPanel: React.FC<ICommonInfoPanelProps> = ({
  autotestId,
  cols,
  gridStore,
  hasLeftBorder,
  hideToolbar,
  customComponentRender,
  onClose
}) => {
  const { t } = useTranslation();

  let detailsRender: TDataGridDetailsRender;

  const toolbarDescriptor: IToolbarExDescriptorItem[] = [
    { type: 'text', text: 'recordInformation', pl: 1 },
    { type: 'filler' },
  ];

  if (onClose) {
    toolbarDescriptor.push(
      { type: 'divider' },
      {
        type: 'button',
        icon: <CloseIcon />,
        onClick: () => {
          if (onClose) onClose();
        },
        pl: 1,
        autotestId: autotestId,
      }
    );
  }
  // console.log('cols = ', toJS(cols));
  // console.log('row = ', toJS(row));

  if (customComponentRender) {
    return (<>{customComponentRender(gridStore.selectedRow, onClose)}</>);
  }

  return (
    <Panel leftBorder={hasLeftBorder} zIndex={1}>
      {!hideToolbar && (
        <ToolbarEx
          descriptor={toolbarDescriptor}
          autotestId={`${autotestId || 'CommonInfoPanel'}_ToolbarEx`}
        />
      )}
      <Box
        style={{ overflowX: 'hidden', overflowY: 'auto', height: 0 }}
        display="flex"
        flexGrow={1}
        flexDirection="column"
        pl={1}
        pr={1}>
        {/*<Filter filterDescriptor={filterDescriptor} />*/}
        <Box>
          {cols
            .filter((col) => col.name && col.name.length > 0 && !col.hideInDetails)
            .map((col, index) => {
              let value = gridStore.selectedRow && col.getData ? col.getData(gridStore.selectedRow) : '';

              if (col.renderer) {
                value = col.renderer(gridStore.selectedRow, value);
              } else if (col.detailsRenderer) {
                console.log('---=-= row in details', gridStore.selectedRow, value);
                detailsRender = col.detailsRenderer(gridStore.selectedRow, value);
                value = detailsRender.value;
              } else if (typeof value === 'object') {
                // TODO: fix subobjects
                value = objectRenderer(gridStore.selectedRow, value);
                // value = JSON.stringify(value);
              }

              return (
                <Box
                  key={index}
                  display="flex"
                  p={1.5}
                  borderBottom="1px solid lightGrey"
                  border={!autotestId ? '1px solid red' : undefined}>
                  {/*{*/}
                  {/*  !detailsRender?.hideTitle &&*/}
                  {/*  <Box display={flow} flexGrow={1}>*/}
                  <Box
                    color={ukColors.darkGrey}
                    data-autotest={'info-panel-title-' + col.name + '-' + autotestId || ''}>
                    {t(col.name)}{' '}
                  </Box>
                  <Box flexGrow={1} />
                  {/* <Box pl={4} /> */}
                  <Box pl={2} />
                  {/*</Box>*/}
                  {/*}*/}
                  <Box
                    display="flex"
                    color={ukColors.primary}
                    data-autotest={'info-panel-value-' + col.name + '-' + autotestId || ''}>
                    {value}
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Panel>
  );
};

export default observer(CommonInfoPanel);
