import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import { WarningSharp } from '@material-ui/icons';

import { Panel } from '@/react-ui-kit/src/@uk';

export const TranslationErrorPanel: FC = () => {
  const { t } = useTranslation();

  return (
    <Panel
      data-autotest={'ErrorTranslationSummaryPanel'}
      id="blockTranslationError"
      style={{
        height: ' 100%',
        backgroundColor: 'white',
        alignItems: 'center',
        fontFamily: 'Plus Jakarta Sans',
      }}
    >
      <div style={{ marginTop: '144px', alignItems: 'center' }}>
        <WarningSharp style={{ color: '#B3BEC3', fontSize: '96px' }} />
      </div>
      <Typography variant="body1" style={{ color: '#111927', fontWeight: 700 }}>
        {`${t('summary.translationError')}`}
      </Typography>
      <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, .64)', maxWidth: '360px', textAlign: 'center' }}>
        {t('summary.translationErrorText')}
      </Typography>
    </Panel>
  );
};
