import { BoxProps, Chip } from '@material-ui/core';
import { useTextColor } from '@uk/hooks/useTextColor';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';


export interface IColoredLabelProps {
  label: string;
  icon?: ReactElement;
  size?: 'small' | 'medium';
  // переводить ли текст чипса. По умолчанию - да, переводить.
  needTranslation?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const ColoredLabel: React.FC<IColoredLabelProps & BoxProps> = (props) => {
  const { label, icon, size, needTranslation = true, ...rest } = props;
  const { t } = useTranslation();

  const textColor = useTextColor(rest.bgcolor);

  return <Chip size={size}  avatar={icon}  label={needTranslation ? t(label) : label}  style={{ color: textColor, backgroundColor: rest.bgcolor }} />
};
