import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'i18n/en.json';
import ru from 'i18n/ru.json';
import es from 'i18n/es.json';
import pt from 'i18n/pt.json';

import es_lang from 'i18n/es/lang.json';
import pt_lang from 'i18n/pt/lang.json';
import en_lang from 'i18n/en/lang.json';
import ru_lang from 'i18n/ru/lang.json';

import ru_ui_kit from '@uk/i18n/ru.json';
import en_ui_kit from '@uk/i18n/en.json';
import es_ui_kit from '@uk/i18n/es.json';
import pt_ui_kit from '@uk/i18n/pt.json';

import { DEFAULT_LANG } from 'stores/translationStore';

const resources = {
  en: { translation: { ...en_ui_kit, ...en }, lang: en_lang },
  ru: { translation: { ...ru_ui_kit, ...ru }, lang: ru_lang },
  es: { translation: { ...es_ui_kit, ...es }, lang: es_lang },
  pt: { translation: { ...pt_ui_kit, ...pt }, lang: pt_lang },
};

i18n.use(initReactI18next).init({
  ns: ['translation', 'lang'],
  defaultNS: 'translation',
  resources,
  lng: DEFAULT_LANG,
  interpolation: {
    escapeValue: false,
  },
});

//i18n.addResourceBundle('en', 'translation', gridTranslationEn);

export default i18n;
