import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Collapse, FormControlLabel } from '@material-ui/core';

import { DialogStore } from '../dialog.store';

import { ItemAlgorithms } from './itemAlgorithms';
import { ItemParticipants } from './itemParticipants';
import { Stack } from './Stack';

import { gAPP_STORE } from '@/app/app-store';

export interface IItemSeparation {
  data: DialogStore;
}

export const ItemSeparation: FC<IItemSeparation> = ({ data }) => {
  const { t } = useTranslation();
  const [isSeparated, setSeparated] = useState(data.isSeparated);

  const hChangeCheckBox = useCallback(
    (value: boolean) => {
      data.setSeparated(!value);
      setSeparated(!value);
    },
    [data],
  );

  const blockIsSeperated = (
    <FormControlLabel
      label={t('uploadForm.notseparated')}
      control={<Checkbox checked={!isSeparated} onChange={event => hChangeCheckBox(event.target.checked)} />}
    />
  );

  return (
    <Stack>
      {gAPP_STORE.autoSpeakerDetection ? blockIsSeperated : <div style={{ marginTop: '20px' }} />}
      <Collapse in={isSeparated}>
        <Stack spacing={2}>
          <ItemAlgorithms data={data} />
          <Box />
          <Collapse in={(gAPP_STORE.gridProxyUrl && gAPP_STORE.gridProxyUrl.length > 0) || false}>
            <ItemParticipants data={data} />
          </Collapse>
        </Stack>
      </Collapse>
    </Stack>
  );
};
