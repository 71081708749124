import React, { useMemo } from 'react';

import { observer } from 'mobx-react';

import { ETabStatus } from './summary-store';

import { NoDataPanel } from '@/components/summary/NoDataPanel';
import { ITopic } from '@/components/summary/types';
import { TopicCollapsePanel } from '@/components/summary/TopicCollapsePanel';
import { gAPP_STORE } from '@/app/app-store';
import { TranslationErrorPanel } from '@/components/summary/TranslationErrorPanel';
import LoadingPanel from '@/react-ui-kit/src/components/loading/LoadingPanel';

export const ProposalPanel = observer(() => {
  const { summaryStore } = gAPP_STORE;
  const { isTranslate, thirdTabStatus, topics } = summaryStore;

  const dopPanel = useMemo(() => {
    switch (thirdTabStatus) {
      case ETabStatus.loading:
        return <LoadingPanel />;
      case ETabStatus.noData:
        return <NoDataPanel />;
      case ETabStatus.error:
        if (isTranslate) {
          return <TranslationErrorPanel />;
        } else {
          return <NoDataPanel />;
        }
      default:
        return null;
    }
  }, [thirdTabStatus, isTranslate]);

  if (dopPanel !== null) {
    return <>{dopPanel}</>;
  }

  // return (
  //   <div style={{ overflow: 'scroll', flexGrow: 1 }}>
  //     {topics.map((topic: ITopic, indTopic: number) => (
  //       <TopicCollapsePanel key={`topicPanel + ${topic.idTopic}`} indTopic={indTopic} topic={topic} />
  //     ))}
  //   </div>
  // );

  return (
    <>
      {topics.map((topic: ITopic, indTopic: number) => (
        <TopicCollapsePanel key={`topicPanel + ${topic.idTopic}`} indTopic={indTopic} topic={topic} />
      ))}
    </>
  );
});
