import { observer } from 'mobx-react';
import { ReactNode, useMemo } from 'react';

import { Box } from '@material-ui/core';

import { ImageCacheType } from '../avatar/cached-avatar';

import { DictorChip } from './DictorChip';
import { TDictorChipDisableMode } from './types';

import { gAPP_STORE } from '@/app/app-store';

interface IDictorChipsPanelProps {
  disableMode?: TDictorChipDisableMode;
  isHide?: boolean;
  noDictorsInfo?: ReactNode;
  avatarCache?: ImageCacheType;
}

const DictorChipsPanel: React.FC<IDictorChipsPanelProps> = observer(props => {
  const { disableMode, isHide, noDictorsInfo, avatarCache } = props;

  const selectedRecord = gAPP_STORE.getRecordsStore().gridStore.selectedRow;
  const dictorsStore = gAPP_STORE.getDictorsStore();
  const dictors = useMemo(() => {
    return selectedRecord && dictorsStore.isSegmentationLoaded
      ? dictorsStore.getDictorsWithSegmentationForRecord(selectedRecord.correlationId)
      : [];
    //TODO: Костыль, нужно будет фиксить https://react.dev/learn/removing-effect-dependencies#to-change-the-dependencies-change-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictorsStore.isSegmentationLoaded, dictorsStore.dictorsCount, selectedRecord]);

  if (isHide || (!noDictorsInfo && dictors.length === 0)) {
    return null;
  }

  return (
    <Box display="flex" flexWrap="wrap" p={1} style={{ gap: 5 }} borderBottom="1px solid #ddd">
      {dictors.length === 0 && noDictorsInfo}
      {dictors.map((dictor, index) => {
        return <DictorChip key={index} dictor={dictor} disableMode={disableMode} cache={avatarCache} />;
      })}
    </Box>
  );
});

export default DictorChipsPanel;
