import React, { FC, ReactNode, useMemo } from 'react';
import { observer } from 'mobx-react';
import { FaFemale, FaMale } from 'react-icons/fa';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import appColors from '@/app/app-colors';
import { IDictor } from '@/types/dictors';
import { LOADING_GRADIENT } from '@/components/dictors/const';
import { gAPP_STORE } from '@/app/app-store';
import { ERecordStatus } from '@/components/records/types';

export const useStyles = makeStyles(() => ({
  male: {
    color: appColors.blue,
  },
  female: {
    color: appColors.red,
  },
  undefined: {
    color: appColors.darkGrey,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '14px',
  },
}));

export interface IRecordsDictorsColumnValueProps {
  dictors: IDictor[];
  recordStatus: ERecordStatus;
}

export const RecordsDictorsColumnValue: FC<IRecordsDictorsColumnValueProps> = observer(({ dictors = [], recordStatus }) => {
  const classes = useStyles();
  const { colorIdx } = gAPP_STORE.getDictorsStore();

  // сортируем дикторов в кучки по гендеру
  const sortedDictors = useMemo(() => dictors.sort((a, b) => a.gender.localeCompare(b.gender)), [dictors]);

  // флаг статуса загрузки
  const loading = useMemo(
    () => (recordStatus === ERecordStatus.PROCESSED && gAPP_STORE.getDictorsStore().status === 'loading' && dictors.length === 0),
    [recordStatus, dictors]);

  // случайное количество элементов для отображения при загрузке
  const count = useMemo(() => Math.round(Math.random() * 10), []);
  const loadingContent = useMemo(() => {
    let i = 0;
    const result: ReactNode[] = [];
    while (i < count) {
      result.push(<FaMale key={`loading${i}`} color={LOADING_GRADIENT[(colorIdx + i) % LOADING_GRADIENT.length]}/>);
      i++;
    }

    return result.reverse();
  }, [colorIdx, count]);

  // в случае загрузки - отображаем подготовленный случайный контент с анимацией
  if (loading) {
    return (
      <Box className={classes.row}>
        {loadingContent.map(c => c)}
      </Box>
    );
  }

  return (
    <Box className={classes.row}>
      {sortedDictors.map(d => {
        const Icon = d.gender === 'female' ? FaFemale : FaMale;

        return <Icon key={d.id} className={classes[d.gender]}/>;
      })}
      {sortedDictors.length > 0 && <Box>&nbsp;({sortedDictors.length})</Box>}
    </Box>
  );
});
