import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { ApexOptions } from 'apexcharts';

import { Chart } from '../chart/Chart';

interface IDictorStatisticsChart {
  title?: string;
  options: ApexOptions;
  series: number[];
  unknownDictor?: string;
}

const DictorStatisticsChart: React.FC<IDictorStatisticsChart> = props => {
  const { title, options, series } = props;
  const { t } = useTranslation();

  return (
    <Box>
      {title && (
        <Box marginLeft={2}>
          <Typography>{t(title)}</Typography>
        </Box>
      )}
      <Box p={1} />
      <Chart height={200 + series.length * 25} options={options} series={series} type="donut" />
    </Box>
  );
};

export default observer(DictorStatisticsChart);
