import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { FaChartPie } from 'react-icons/fa';
import { IToolbarExDescriptorItem, ToolbarEx } from '@uk';
import { makeStyles } from '@material-ui/core/styles';
import { FlipCameraAndroid, History, Loop, RecordVoiceOver, Settings, Translate } from '@material-ui/icons';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import { EStoreStatus } from 'common/store-status';
import { gAPP_STORE } from 'app/app-store';

import { ERecordStatus, IWordsData } from '../types';

import { RecordTextToolbarRebuildModel } from './recrod-text-toolbar-btn-rebuild-model';

import { IconRetranslate } from '@/components/summary/AIicons';

export const useStyles = makeStyles(() => ({
  iconButtonSquarePants: {
    minWidth: '36px',
    alignItems: 'center',
  },
}));

interface IRecordTextToolbar {
  currentWordsData: IWordsData | undefined;
  editingMode: boolean;
  needSaveChanges: boolean;
  showSpeakerStatistic: boolean;
  showSettings: boolean;
  showHistory: boolean;
  setShowSettings: (value: boolean) => void;
  setShowHistory: (value: boolean) => void;
}

export const RecordTextToolbar: React.FC<IRecordTextToolbar> = observer(props => {
  const {
    currentWordsData,
    editingMode,
    needSaveChanges,
    showSpeakerStatistic,
    showSettings,
    showHistory,
    setShowSettings,
    setShowHistory,
  } = props;

  const { t } = useTranslation();

  const classes = useStyles();
  const recordStore = gAPP_STORE.getRecordsStore();
  const selectRow = recordStore.gridStore.selectedRow;
  const soundStore = gAPP_STORE.soundStore;
  const dictorsStore = gAPP_STORE.getDictorsStore();
  const recordTextStore = gAPP_STORE.getRecordTextStore();

  const handleSwitchTranslate = useCallback(() => {
    recordTextStore.changeTranslationMode(!recordTextStore.isTranslation);
  }, [recordTextStore]);

  const handleRebuildModel = useCallback(() => {
    if (selectRow) {
      selectRow.voiceModelStatus = ERecordStatus.PREPROCESSED;
      recordTextStore.update(currentWordsData?.words, dictorsStore.getTmpDictorsWithSegmentation());
    }
  }, [currentWordsData?.words, dictorsStore, recordTextStore, selectRow]);

  const retranslateDisabled = useMemo(() => {
    return recordTextStore.retranslationStatus === EStoreStatus.LOADING ||
      gAPP_STORE.getRecordsStore().gridStore.isPending ||
      (recordTextStore.historyWordsData.length < 2 && recordStore.getRecordTextTranslationStatus() !== ERecordStatus.FAILED) ||
      editingMode ||
      needSaveChanges;
  }, [editingMode, needSaveChanges, recordStore, recordTextStore.historyWordsData.length, recordTextStore.retranslationStatus]);

  const toolbarExProps = useMemo((): IToolbarExDescriptorItem[] => {
    const toolbarDescriptor: IToolbarExDescriptorItem[] = [];
    
    gAPP_STORE.editingASR &&
    toolbarDescriptor.push({
      pl: -24,
      tooltip: 'records.retranslate',
      type: 'button',
      buttonClassName: classes.iconButtonSquarePants,
      icon: <IconRetranslate disabled={retranslateDisabled} />,
      disabled: retranslateDisabled,
      onClick: recordTextStore.retranslate,
    });

    gAPP_STORE.viewTranslate &&
    toolbarDescriptor.push({
      pl: 0,
      tooltip: recordTextStore.isTranslation ? 'records.translate' : 'records.transcription',
      type: 'toggle-button',
      icon: <Translate />,
      disabled:
        recordTextStore.status !== EStoreStatus.SUCCESS ||
        gAPP_STORE.getRecordsStore().gridStore.isPending ||
        editingMode ||
        needSaveChanges,
      selected: recordTextStore.isTranslation,
      setSelected: handleSwitchTranslate,
    });

    toolbarDescriptor.push({
      type: 'custom',
      component: (
        <RecordTextToolbarRebuildModel
          tooltip={t('records.rebuildingModel')}
          buttonClassName={classes.iconButtonSquarePants}
          icon={<RotateLeftIcon />}
          onClick={handleRebuildModel}
        />
      ),
    });

    toolbarDescriptor.push({
      type: 'filler',
    });

    toolbarDescriptor.push({
      type: 'button',
      tooltip: 'Speaker statistics',
      icon: <FaChartPie />,
      pl: 1,
      disabled: !showSpeakerStatistic,
      onClick: () => {
        gAPP_STORE.getDictorsStore().setOpenDictorsStatisticsDialog(true);
      },
    });

    if (recordTextStore.canEditCurrentText) {
      toolbarDescriptor.push({
        type: 'toggle-button',
        icon: soundStore.isCycling ? <FlipCameraAndroid /> : <Loop />,
        tooltip: 'Cycle Mode',
        disabled: false,
        selected: soundStore.isCycling,
        setSelected: soundStore.setIsCycling,
        pl: 1,
      });
    }

    toolbarDescriptor.push({
      type: 'toggle-button',
      icon: <RecordVoiceOver />,
      tooltip: 'records.dictors',
      disabled:
        !dictorsStore.isSegmentationLoaded ||
        !recordTextStore.record ||
        gAPP_STORE.getRecordsStore().gridStore.isPending,
      selected: dictorsStore.isDictorsPanelVisible,
      setSelected: dictorsStore.setDictorsPanelVisible,
      pl: 1,
    });

    toolbarDescriptor.push({
      type: 'toggle-button',
      icon: <Settings />,
      disabled:
        editingMode || needSaveChanges || !recordTextStore.record || gAPP_STORE.getRecordsStore().gridStore.isPending,
      tooltip: 'records.transcriptionParameters',
      selected: showSettings,
      setSelected: setShowSettings,
      pl: 1,
    });

    toolbarDescriptor.push({
      tooltip: 'history',
      type: 'toggle-button',
      icon: <History />,
      disabled:
        editingMode || needSaveChanges || !recordTextStore.record || gAPP_STORE.getRecordsStore().gridStore.isPending,
      selected: showHistory,
      setSelected: setShowHistory,
      pl: 1,
    });

    return toolbarDescriptor;
  }, [
    recordTextStore.isTranslation,
    recordTextStore.status,
    recordTextStore.retranslationStatus,
    recordTextStore.historyWordsData.length,
    recordTextStore.retranslate,
    recordTextStore.canEditCurrentText,
    recordTextStore.record,
    editingMode,
    needSaveChanges,
    classes.iconButtonSquarePants,
    t,
    handleRebuildModel,
    showSpeakerStatistic,
    dictorsStore.isSegmentationLoaded,
    dictorsStore.isDictorsPanelVisible,
    dictorsStore.setDictorsPanelVisible,
    showSettings,
    setShowSettings,
    showHistory,
    setShowHistory,
    handleSwitchTranslate,
    soundStore.isCycling,
    soundStore.setIsCycling,
  ]);

  return <ToolbarEx descriptor={toolbarExProps} minWidth={350} overflow={'hidden'} />;
});
