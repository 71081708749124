import { PaletteColor } from '@material-ui/core/styles/createPalette';

import type { INeutralColors } from '@material-ui/core/styles';

const withAlphas = (color: PaletteColor): PaletteColor => {
  return {
    ...color,
  };
};

export const neutral: INeutralColors = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#4D5761',
  700: '#2F3746',
  800: '#1C2536',
  900: '#111927',
};

export const gridsr = withAlphas({
  light: '#124473',
  main: '#0b2b48',
  dark: '#061827',
  contrastText: '#FFFFFF',
});

export const success = withAlphas({
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  contrastText: '#FFFFFF',
});

export const info = withAlphas({
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  contrastText: '#FFFFFF',
});

export const warning = withAlphas({
  light: '#FEF0C7',
  main: '#F79009',
  dark: '#B54708',
  contrastText: '#FFFFFF',
});

export const error = withAlphas({
  light: '#FEE4E2',
  main: '#F04438',
  dark: '#B42318',
  contrastText: '#FFFFFF',
});
