import { toast } from 'react-toastify';
import i18n from 'i18next';
import html2canvas from 'html2canvas';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';

const ImageModule = require('docxtemplater-image-module-free');

export const contentBase64ToDoc = (contentBase64: string, linebreaks = false): Docxtemplater | undefined => {
  const regExp = /data:application.*;base64,/gimu;
  const docxmlPrefix: string = contentBase64.match(regExp)?.toString() || '';

  if (docxmlPrefix && docxmlPrefix.length > 0) {
    const content = atob(contentBase64.replace(docxmlPrefix, ''));

    if (content) {
      const zip = new PizZip(content);

      const imageOpts = {
        centered: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getImage: function (tagValue: any, tagName: any) {
          return new Promise(function (resolve, reject) {
            if (!tagValue) {
              reject('Failed to load image - tag value is empty');

              return;
            }
            const currentDiv = document.getElementById(tagValue);
            if (!currentDiv) {
              const img = new Image();
              img.src = '/img/default.svg';
              img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d', { willReadFrequently: true });
                ctx?.drawImage(img, 0, 0);
                canvas.toBlob(blob => {
                  if (!blob) {
                    return reject('Failed to load image');
                  }
                  const reader = new FileReader();
                  reader.onerror = event => {
                    console.log('error reading file', event);
                    toast.error(i18n.t('errorReport'));

                    return reject(event);
                  };
                  reader.onload = event => {
                    const content = event.target?.result;

                    return resolve(content);
                  };
                  reader.readAsBinaryString(blob);
                });
              };
              img.onerror = () => {
                reject('Error loading image');
              };

              return;
            }
            html2canvas(currentDiv, {
              x: 0,
              y: 0,
              width: currentDiv.scrollWidth,
              height: currentDiv.scrollHeight,
              scale: 2,
            }).then(canvas => {
              canvas.toBlob(blob => {
                if (!blob) {
                  return reject('Failed to load image');
                }
                const reader = new FileReader();
                reader.onerror = event => {
                  console.log('error reading file', event);
                  toast.error(i18n.t('errorReport'));

                  return reject(event);
                };
                reader.onload = event => {
                  const content = event.target?.result;

                  return resolve(content);
                };
                reader.readAsBinaryString(blob);
              });
            });
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getSize: function (img: any, tagValue: any, tagName: any) {
          const currentDiv = document.getElementById(tagValue);
          if (currentDiv) {
            return [currentDiv.scrollWidth, currentDiv.scrollHeight];
          }

          return [200, 200];
        },
      };
      const doc = new Docxtemplater(zip, {
        modules: [new ImageModule(imageOpts)],
        linebreaks,
      });

      return doc;
    }
  }

  return undefined;
};
