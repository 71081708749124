import { Box, Slider } from '@material-ui/core';
import { runInAction } from 'mobx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IFilterSlider {
  change: (val: number) => void;
  defaultValue: number;
  min: number;
  max: number;
  step: number;
}

export const FilterSlider = ({ change, defaultValue, min, max, step }: IFilterSlider) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(defaultValue);

  return (
    <Box flexGrow={1} p={1} display="flex" alignItems="center">
      <Slider value={value as number}
        onChange={(event, newValue) => setValue(newValue as number)}
        //          onChange={(event, newValue) => onChange(descriptor.id, newValue as number)}
        onChangeCommitted={(event, newValue) => {
          runInAction(() => change(newValue as number));
        }}
        min={min}
        max={max}
        step={step}
      />
      <Box pl={2}>{value}</Box>
    </Box>

  );
};
