import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ukColors from '@uk/ukColors';

const PlayerErrorMsg = () => {
  const { t } = useTranslation();

  return (
    <Box
      p={4}
      color="red"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={ukColors.lightGrey}>
      <Typography variant="h6">{t('errorPlayer')}</Typography>
    </Box>
  );
};

export default PlayerErrorMsg;
