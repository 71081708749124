import { Box, Chip, CircularProgress, InputAdornment, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete, { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ukColors from '@uk/ukColors';

import FormFieldPredicate from './FormFieldPredicate';
import { IFormFieldListItem, IFormField } from './types';
import { convertValueToListItem } from './formUtils';

import styles from './Form.module.scss';

interface IFormFieldListProps {
  descriptor: IFormField;
  readonly?: boolean;
  onSearch?: (name: string) => void;
}

const FormFieldList: React.FC<IFormFieldListProps> = ({ descriptor, readonly, onSearch }) => {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<IFormFieldListItem[]>(descriptor.list ? descriptor.list : []);

  const onAsyncInputChange = (value: string) => {
    const res = descriptor.listAsync && descriptor.listAsync(descriptor, value);
    if (res) {
      setLoading(true);
      res.then(
        () => {
          setLoading(false);
          setOptions(descriptor.list ? descriptor.list : []);
        },
        error => {
          console.error('Failed to load async options list: ', JSON.stringify(error));
        },
      );
    }
  };

  const renderInput = (params: any) => {
    params.inputProps = {
      ...params.inputProps,
      readOnly: descriptor.noInput,
      'data-autotest': 'ff-lst-value-' + (descriptor.id || '') + '-' + descriptor.autotestId + '',
      style: {
        paddingTop: '0.7em',
        paddingBottom: '0.7em',
      },
    };

    params.InputProps = {
      ...params.InputProps,
      disableUnderline: true,
      startAdornment: descriptor.predicates ? (
        <>
          <InputAdornment position="start">
            <FormFieldPredicate
              descriptor={descriptor}
            />
          </InputAdornment>
          {params.InputProps.startAdornment}
        </>
      ) : (
        params.InputProps.startAdornment
      ),
      // endAdornment: (
      //   <InputAdornment position="end">
      //     <IconButton>
      //       <Search />
      //     </IconButton>
      //   </InputAdornment>
      // )
    };

    if (descriptor.listAsync) {
      params.InputProps = {
        ...params.InputProps,
        'data-autotest': 'ff-lst-async-value-' + descriptor.id ? descriptor.id + '-' : '-' + descriptor.autotestId + '',
      };
      return (

        <TextField
          // label={t(descriptor.name || '')}
          // placeholder={t(descriptor.name || '')}
          // variant="outlined"
          // variant="filled"
          error={!!descriptor.invalidMessage}
          helperText={t(descriptor.invalidMessage || '')}
          onChange={event => onAsyncInputChange(event.target.value)}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: '30px' }} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      );
    }

    // const hasValue = descriptor.value && (descriptor.value as any).length > 0;
    return (
      <>
        <TextField
          // label={hasValue ? t(descriptor.name || '') : undefined}
          // placeholder={!hasValue ? t(descriptor.name || '') : undefined}
          // variant="outlined"
          // variant="filled"
          error={!!descriptor.invalidMessage}
          helperText={t(descriptor.invalidMessage || '')}
          {...params}
        />
      </>
    );
  };

  const renderTags = (val: IFormFieldListItem[], getTagProps: AutocompleteGetTagProps) => {
    const value = val === undefined || Array.isArray(val) ? val : [val];

    // return value.map((v, index) => {
    //   const variant = descriptor.customizeDeep ? descriptor.customizeDeep('variant', v) : undefined;
    //   const size = descriptor.customizeDeep ? descriptor.customizeDeep('size', v) : undefined;
    //   const deleteIcon = descriptor.customizeDeep ? descriptor.customizeDeep('deleteIcon', v) : undefined;
    //   return (
    //     <Chip
    //       key={index}
    //       label={t(v.title)}
    //       // variant="outlined"
    //       variant={variant}
    //       color="primary"
    //       size={size ? size : 'small'}
    //       style={descriptor.listItemStyle ? descriptor.listItemStyle(v) : { marginLeft: '5px' }}
    //       deleteIcon={deleteIcon}
    //       icon={
    //         descriptor.listItemTypesIcons ? (v.type ? descriptor.listItemTypesIcons(v.type) : undefined) : undefined
    //       }
    //       onDelete={() => {
    //         if (descriptor.value) {
    //           runInAction(() => {
    //             descriptor.value = (descriptor.value as IFormFieldListItem[]).filter(e => e.id !== v.id);
    //           });
    //         }
    //       }}
    //       onClick={() => {
    //         if (descriptor.listItemOnClick) {
    //           const res = descriptor.listItemOnClick(v);
    //           runInAction(() => {
    //             descriptor.value = (descriptor.value as IFormFieldListItem[]).map(e => (e.id === res.id ? res : e));
    //           });
    //         }
    //       }}
    //     />
    //   );
    // });

    const onDelete = (descriptor: IFormField, value: IFormFieldListItem) => {
      if (readonly) { return };

      if (descriptor.value) {
        runInAction(() => {
          if (descriptor.value) {
            if (Array.isArray(descriptor.value)) {
              descriptor.value = (descriptor.value as IFormFieldListItem[]).filter(e => e.id !== value.id);
            } else {
              descriptor.value = [];
            }
          }
        });
      }
    };

    return value.map((v, index) => {
      if (descriptor.valueRenderer) {
        return descriptor.valueRenderer(descriptor, v, onDelete);
      }

      return (
        <Chip
          key={index}
          label={(descriptor.translate === undefined) ? v.title : t(v.title)}
          color="primary"
          size={'small'}
          style={{ margin: '0.5em', overflow: 'hidden' }}
          onDelete={() => onDelete(descriptor, v)}
        />
      );
    });
  };

  const scoreValues: number[] = [0];
  for (let i = 0; i < 20; i++) {
    scoreValues.push((i + 1) * 5);
  }

  const getInitialValue = () => {
    let result;

    if (descriptor.value) {
      if (descriptor.type === 'list' && typeof descriptor.value === 'string') {
        result = { id: descriptor.value, title: descriptor.value };
        if (!descriptor.isSingle) {
          result = [result];
        }
      } else if (descriptor.isSingle) {
        result = descriptor.value as IFormFieldListItem;
      } else if (Array.isArray(descriptor.value)) {
        result = descriptor.value as IFormFieldListItem[];
      } else {
        result = [descriptor.value] as IFormFieldListItem[];
      }
    } else if (descriptor.isSingle) {
      result = undefined;
    }

    return descriptor.isSingle ? result : result || [];
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
    }
    // if (event.key === 'Escape') {
    //   event.stopPropagation();
    //   event.preventDefault();
    // }
  };

  function getTranslatedOptions(str: string): string {
    return (descriptor.translate === undefined) ? str : t(str);
  }

  const handleOnValuesChange = (
    event: React.ChangeEvent<{}>,
    newValue: string | IFormFieldListItem | (string | IFormFieldListItem)[] | null,
  ) => {

    if (Array.isArray(newValue)) {
      if (newValue && newValue.length && newValue.length > 0) {
        if (descriptor.listAsync || descriptor.list) {
          newValue = newValue.filter(v => typeof v !== 'string');
        }

        const typedVal: IFormFieldListItem[] = newValue
          .map(v => {
            return convertValueToListItem(v);
          })
          .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index);

        runInAction(() => {
          descriptor.value = typedVal;
        });
      } else {
        runInAction(() => {
          descriptor.value = [];
        });
      }
    } else {
      //single select
      if (newValue) {
        const typedVal = convertValueToListItem(newValue);

        runInAction(() => {
          descriptor.value = typedVal;
        });
      } else {
        runInAction(() => {
          descriptor.value = descriptor.clearToDefault ? descriptor.list ? descriptor.list[0] : null : null;
        });
      }
    }
  };

  const handelNoInput = (event: any) => {
    const noNewChar = event.target.value.slice(0, -1);
    event.target.value = noNewChar;
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {/* <Box style={{ overflowX: "hidden", overflowY: "auto", maxHeight: 200 }}>
        {JSON.stringify(descriptor, null, 2)}
      </Box> */}

      {/* <Box style={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: 200 }}>
        {JSON.stringify(descriptor, null, 2)}
      </Box> */}

      <Autocomplete
        style={{ flexGrow: 1, flexShrink: 1, flexBasis: 'auto', maxWidth: `calc(100% - ${descriptor.score !== undefined ? '90px' : '0px'} - ${descriptor.search ? '24px' : '0px'})` }}
        ChipProps={{
          size: 'small',
          color: 'primary',
          style: {
            marginLeft: 2,
            marginRight: 1,
            paddingTop: '0.5em',
            backgroundColor: ukColors.primaryLight,
          },
        }}
        multiple={!descriptor.isSingle}
        freeSolo={!descriptor.listAsync}
        fullWidth
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onFocus={() => onAsyncInputChange('')}
        loading={loading}
        options={options}
        getOptionLabel={opt => (opt ? getTranslatedOptions(opt.title) : '')}
        getOptionSelected={(option, value) => {
          if (option && value) return option.id === value.id;
          return false;
        }}
        value={getInitialValue()}
        renderInput={renderInput}
        renderTags={renderTags}
        onChange={handleOnValuesChange}
        onKeyDown={handleKeyDown}
        disableClearable={descriptor.clearToDefault !== undefined ? !descriptor.clearToDefault : undefined}

        onInput={descriptor.noInput ? event => handelNoInput(event) : undefined}

        disabled={readonly || descriptor.isReadonly}
        openText={`${t('listOpen')}`}
        loadingText={`${t('listLoading')}`}
        clearText={`${t('listClear')}`}
        closeText={`${t('listClose')}`}
        noOptionsText={`${t('listEmpty')}`}
      />

      {!readonly && descriptor.score && (
        <>
          <Box flexGrow={0} height={20} borderLeft={'0px solid ' + ukColors.grey} width={24} />
          <Box width={'60px'} maxWidth={'60px'} pl={2} pr={1}>
            <Select
              disableUnderline
              MenuProps={{ style: { maxHeight: 500, maxWidth: '60px' } }}
              value={descriptor.score}
              onChange={(event: any) => {
                runInAction(() => {
                  descriptor.score = event.target.value as number;
                });
              }}
            >
              {scoreValues.map((d, index) => (
                <MenuItem key={index} value={d}>
                  {d === 0 ? t('all') : `${d} %`}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </>
      )}

      {!readonly && descriptor.search && (
        <>
          <Box flexGrow={0} height={20} borderLeft={'0px solid ' + ukColors.grey} />
          <Box width={24} display="flex" alignItems="center" className={styles.FormFieldList_SearchIcon} maxWidth={24}>
            <SearchIcon
              data-autotest={(descriptor?.autotestId || '') + (descriptor?.id || '') + '_searchButton'}
              // style={{ cursor: 'pointer', color: '#999' }}

              onClick={() => {
                if (onSearch) onSearch(descriptor.id ? descriptor.id : '');
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default observer(FormFieldList);
