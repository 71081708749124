import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

interface ILanguageProps {
  language: string;
}

const LanguageLabel: React.FC<ILanguageProps> = ({ language }) => {
  const { t } = useTranslation('lang');

  const label = language ? t(language) : '';
  const color = 'black';

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography style={{ color }}>{label}</Typography>
    </Box>
  );
};

export default LanguageLabel;
