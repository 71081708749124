import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Fade } from '@material-ui/core';
import { LoadingPanel, Panel, SplitPaneEx } from '@uk';

import EventLogFilter from './event-log-filter';
import { EventLogGrid } from './event-log-grid';

import { gAPP_STORE } from '@/app/app-store';


const EventLogPanel = observer(() => {

  useEffect(() => {
    gAPP_STORE.getEventLogStore().init();
  }, []);

  if (!gAPP_STORE.getEventLogStore().listEventsLoaded) {
    return <LoadingPanel />;
  }

  return (<Panel width={'100%'}>
    <Fade in>
      <Box id={'EventLogs'}
        display="flex"
        flexGrow={1}
        flexDirection="column" sx={{ width: window.innerWidth }}
        bgcolor="white"
        minWidth={'100%'}
        height="calc(100vh - 94px)">
        <SplitPaneEx
          id="FilterEventPage"
          panes={[
            {
              pane: <EventLogFilter />,
              size: 700,
            },
            {
              pane: <EventLogGrid />,
              minSize: 600,
            },
          ]}
        />
      </Box>
    </Fade>
  </Panel>
  );
});

export default EventLogPanel;
