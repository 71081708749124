import { arrayUniqueValues } from 'common/utils';

import { IWordDictionary, TWordDictionaryTypeValue } from './WordDictionary.types';

export const valueToWords = (value: string): string[] => {
  try {
    const result: string[] = value && value.length > 0 ? JSON.parse(value) || [] : [];

    return arrayUniqueValues(result);
  } catch (ex) {
    return [];
  }
};

export const wordsToWordsStr = (words: string[]): string => {
  return arrayUniqueValues(words).join(' ');
};

export const valueToWordsStr = (value: string): string => {
  return valueToWords(value).join(' ');
};

export const valueToWordsStrWithSeparator = (value: string,separator:string): string => {
  return valueToWords(value).join(separator);
};

export const wordsStrToValue = (str: string): string => {
  try {
    // const reg = /[\s,.;:?!\(\)[\]\\/`'"\r\n\t-=<>|{}]/gm;
    const reg = /[\s,.;:?!()[\]\\/`'"\r\n\t=<>|{}-]/gm;
    const value = JSON.stringify(
      (str.replaceAll(reg, ' ').split(' ') || []).map(s => s.trim()).filter(s => s.length > 0),
    );

    return value;
  } catch (ex) {
    console.error('wordsStrToValue(), ex = ', ex);

    return JSON.stringify([]);
  }
};

export const getWordDictionaryTypeValue = (wordDictionary: IWordDictionary): TWordDictionaryTypeValue => {
  if (wordDictionary.isAutoAsr && wordDictionary.isKws) {
    return 'both';
  }

  return wordDictionary.isAutoAsr ? 'auto' : 'kws';
};

export const getWordDictionaryTypeLabel = (wordDictionary: IWordDictionary): string => {
  if (wordDictionary.isAutoAsr && wordDictionary.isKws) {
    return 'wordDictionary.isAutoAsrAndKws';
  }

  return wordDictionary.isAutoAsr ? 'wordDictionary.isAutoAsr' : 'wordDictionary.isKws';
};
