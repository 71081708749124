import { FC, useMemo } from 'react';

import { observer } from 'mobx-react';

import { Badge, Box, Tooltip } from '@material-ui/core';

import { MdCleaningServices } from 'react-icons/md';

import { TbDatabaseOff } from 'react-icons/tb';

import { useTranslation } from 'react-i18next';

import { NotificationsStore } from './notifications-store';
import { NotificationCode, NotificationGroup } from './types';

import { gAPP_STORE } from '@/app/app-store';

export interface INotificationsPanelProps {
  store: NotificationsStore;
}
const NotificationsPanelImpl: FC<INotificationsPanelProps> = ({ store }) => {
  const { t } = useTranslation();

  const cleanerWarnsCount = useMemo(
    () =>
      store.activeAlarms.filter(
        f => f.groupName === NotificationGroup.CLEANER_GROUP_NAME && f.code === NotificationCode.CODE_CLEANER_WARN,
      ),
    [store.activeAlarms],
  );
  const cleanerFaultsCount = useMemo(
    () =>
      store.activeAlarms.filter(
        f => f.groupName === NotificationGroup.CLEANER_GROUP_NAME && f.code === NotificationCode.CODE_CLEANER_FAULT,
      ),
    [store.activeAlarms],
  );

  return (
    <Box p={1} display={'flex'} flexDirection={'row'}>
      {cleanerWarnsCount.length > 0 && (
        <>
          <Tooltip
            title={
              gAPP_STORE.loginStore.user?.isAdmin
                ? t(`admin_${cleanerWarnsCount[0].code}`)
                : t(cleanerWarnsCount[0].code)
            }
          >
            <Badge badgeContent={cleanerWarnsCount.length} color="error">
              <MdCleaningServices size={'24px'} />
            </Badge>
          </Tooltip>
          {cleanerFaultsCount.length > 0 && <Box mx={2} />}
        </>
      )}
      {cleanerFaultsCount.length > 0 && (
        <Tooltip
          title={
            gAPP_STORE.loginStore.user?.isAdmin
              ? t(`admin_${cleanerFaultsCount[0].code}`)
              : t(cleanerFaultsCount[0].code)
          }
        >
          <Box>
            <TbDatabaseOff size={'24px'} color="red" />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export const NotificationsPanel = observer(NotificationsPanelImpl);
