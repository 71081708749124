import clsx from "clsx"
import { when } from "mobx";
import { useCallback, useEffect, useState } from "react";
import { Panel } from "../panel"

import styles from './dataGrid.module.scss';
import { IDataGridCadProps } from "./types";

export const DataGridCard: React.FC<IDataGridCadProps> = ({ row, rowIndex, gridStore, rowCardRenderer, onClick }) => {

    const getGlobalRowIndex = useCallback((rowIndex: number) => gridStore.currentPage * gridStore.rowsOnPage + rowIndex, [gridStore.currentPage, gridStore.rowsOnPage]);
    const [selected, setSelected] = useState<boolean>(gridStore.selectedRowIndexes.includes(getGlobalRowIndex(rowIndex)));

    useEffect(() => {
        const disposer = when(() => gridStore.selectedRowIndexes.includes(getGlobalRowIndex(rowIndex)) !== selected,
            () => { //CHECK :  calls too many times: semenov-k
                setSelected(gridStore.selectedRowIndexes.includes(getGlobalRowIndex(rowIndex)));
            }
        )

        return function cleanup() {
            disposer();
        }
    });


    return (
        <Panel
            key={rowIndex}
            mb={2}
            mr={2}
            rounded
            flexGrow={0}
            style={{ cursor: 'pointer' }}
            className={clsx(styles.row, { [styles.selected]: gridStore.selectedRowIndexes.includes(getGlobalRowIndex(rowIndex)) })}
            data-autotest={'CardRow-' + rowIndex}
            tabIndex={-1}
            onClick={event => {
                if (onClick) { onClick(event, row, rowIndex); }
            }}
        >
            {rowCardRenderer(row)}
        </Panel>
    )
}