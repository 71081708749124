import React, { useRef, useMemo } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { TUKDataGrid2 } from './types';
import { useForceUpdate } from '@uk/common/hooks';
import RowActions from './RowActions';
import ColumnResizer from './ColumnResizer';
import ColumnResizerLine from './ColumnResizerLine';

const SIZE: 'small' | 'medium' = 'small';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    flexGrow: 0,
    minHeight: 36,
    borderTop: '1px solid #eee',
  },
  headRow: { position: 'relative' },
  row: {
    position: 'relative',
    '&:hover': {
      // background: '#fafafa',
      boxShadow: '0 1px 5px rgba(0,0,0,0.1)',
    },
    '&:hover .overlay': {
      opacity: '1 !important',
    },
  },
  cell: {
    padding: '0.5em',
  },
  selected: {
    background: '#b2ebf2',
  },
});

const UKDataGrid2: TUKDataGrid2 = ({ id, cols, store, isMultiselect }) => {
  const classes = useStyles();

  const rootRef = useRef<any>();
  const columnResizerLineRef = useRef<any>();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
  //const [checkedRowIndexes, setCheckedRowIndexes] = useState<number[]>([]);

  const forceUpdate = useForceUpdate();
  const selectedRows = store.selectedRows;
  const checkedRows = store.checkedRows;

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    store.loadData({
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    store.loadData({
      offset: page * newRowsPerPage,
      limit: newRowsPerPage,
    });
  };

  useMemo(() => {
    store.loadData({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    });
  }, [page, rowsPerPage, store]);

  const handleSelectRow = (row: any, rowIndex: number) => {
    const globalRowIndex = page * rowsPerPage + rowIndex;

    if (isMultiselect && isMultiselect === true) {
      if (selectedRows) {
        let rowIndexes = [];
        if (selectedRows.includes(globalRowIndex)) {
          rowIndexes = selectedRows.filter((i) => i !== globalRowIndex);
        } else {
          rowIndexes = [...selectedRows, globalRowIndex];
        }
        store.setSelectedRows(rowIndexes);
      }
    } else {
      store.setSelectedRows([globalRowIndex]);
    }
  };

  const handleCheckboxChange = (event: any, rowIndex: number) => {
    const globalRowIndex = page * rowsPerPage + rowIndex;

    if (checkedRows) {
      let rowIndexes = [];
      if (checkedRows.includes(globalRowIndex)) {
        rowIndexes = checkedRows.filter((i) => i !== globalRowIndex);
      } else {
        rowIndexes = [...checkedRows, globalRowIndex];
      }
      console.log('rowIndexes = ', rowIndexes);
      store.setCheckedRows(rowIndexes);
    }
  };

  const handleCheckboxOnClick = (event: any) => {
    event.stopPropagation();
    // event.preventDefault();
    // forceUpdate();
  };

  return (
    <div ref={rootRef} className={classes.root}>
      <Box flexGrow={1} style={{ height: 1, overflow: 'auto' }}>
        <Table stickyHeader aria-label="sticky table" size={SIZE} style={{ width: 1 }}>
          <TableHead>
            <TableRow className={classes.row}>
              {cols.map((col, index) => (
                <TableCell
                  key={index}
                  align={col.align}
                  style={{ minWidth: col.width }}
                  className={classes.cell}>
                  {col.name}
                  <ColumnResizer
                    col={col}
                    rootRef={rootRef}
                    columnResizeLineRef={columnResizerLineRef}
                    updateLayout={() => forceUpdate()}
                  />
                </TableCell>
              ))}
              <RowActions size={SIZE} />
            </TableRow>
          </TableHead>

          <TableBody>
            {store.data &&
              store.data.map((row, rowIndex) => {
                return (
                  <TableRow
                    key={rowIndex}
                    className={clsx(classes.row, {
                      [classes.selected]: selectedRows.includes(page * rowsPerPage + rowIndex),
                    })}
                    role="checkbox"
                    tabIndex={-1}
                    onClick={() => handleSelectRow(row, rowIndex)}>
                    {cols.map((col, colIndex) => {
                      if (col.hasCheck) {
                        return (
                          <TableCell key={colIndex} align={col.align} className={classes.cell}>
                            <Checkbox
                              size={SIZE}
                              color="primary"
                              checked={checkedRows.includes(page * rowsPerPage + rowIndex)}
                              onClick={handleCheckboxOnClick}
                              onChange={(event: any) => handleCheckboxChange(event, rowIndex)}
                            />
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={colIndex} align={col.align}>
                          {col.renderer ? col.renderer(row, col.id) : row[col.id]}
                        </TableCell>
                      );
                    })}
                    <RowActions size={SIZE} />
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        size={SIZE}
        className={classes.footer}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={store.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <ColumnResizerLine ref={columnResizerLineRef} />
    </div>
  );
};

export default observer(UKDataGrid2);
