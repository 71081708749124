const oneKb = 1024;
const oneMb = oneKb * oneKb;
const oneGb = oneKb * oneMb;
export const fileSizeToString = (size: number) => {
  let result = '';
  if (size < oneMb) {
    result = `(${(size / oneKb).toFixed(1)}Kb)`;
  } else if (size < oneGb) {
    result = `(${(size / oneMb).toFixed(1)}Mb)`;
  } else {
    result = `(${(size / oneGb).toFixed(1)}Gb)`;
  }

  return result;
};
