import React from 'react';

import { Box, Slider, Tooltip, Typography, ValueLabelProps } from '@material-ui/core';
import { IFormField } from './types';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

interface IFormFieldSliderProps {
  descriptor: IFormField;
  readonly?: boolean;
}

const FormFieldSlider: React.FC<IFormFieldSliderProps> = ({ descriptor, readonly }) => {
  // const { t } = useTranslation();

  //const [localValue, setLocalValue] = useState<TFormFieldValue | undefined>(descriptor.value);
  if (!descriptor.value) {
    descriptor.value = [descriptor.sliderMin !== undefined ? descriptor.sliderMin : 0,
    descriptor.sliderMax !== undefined ? descriptor.sliderMax : 100];
  }

  if (!Array.isArray(descriptor.value)) {
    return (
      <Box style={{ color: 'red' }}>
        Field value must be array value
      </Box>
    );
  }
  const ValueLabelComponent = (props: ValueLabelProps) => {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }
  const handleChange = (event: any, newValue: number | number[]) => {
    runInAction(() => {
      descriptor.value = newValue
    })
  };

  return (
    <Box display={'flex'} alignItems={'center'} height={40} px={2}>
      <Slider
        disabled={readonly || descriptor.isReadonly}
        aria-label={descriptor.name}
        value={descriptor.value as number[]}
        valueLabelDisplay="auto"
        marks
        onChange={handleChange}
        ValueLabelComponent={ValueLabelComponent}
        step={descriptor.sliderStep ? descriptor.sliderStep : 1}
        min={descriptor.sliderMin !== undefined ? descriptor.sliderMin : 0}
        max={descriptor.sliderMax !== undefined ? descriptor.sliderMax : 100}
        style={{ marginRight: '40px' }}
      />
      <Box ml={1} display={'flex'} flexDirection={'row'} width={60} justifyContent={'center'}>
        {descriptor.value && descriptor.value.length > 0 && (
          <Typography> {descriptor.value[0]}</Typography>
        )}
        <Typography> - </Typography>
        {descriptor.value && descriptor.value.length > 1 && (
          <Typography> {descriptor.value[1]}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default observer(FormFieldSlider);
