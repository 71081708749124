import { TDataGridRenderer } from '@uk/components/dataGrid';
import ukColors from '@uk/ukColors';
import { ColoredLabel } from '@uk/components/coloredLabel';
import { SearchQueryStatus } from '@uk/types/dto/search/filtersDto';
// import React from 'react';

export const getStatusColor = (status?: SearchQueryStatus) => {
  switch (status) {
    case SearchQueryStatus.Active:
      return ukColors.yellow;
    case SearchQueryStatus.NotActive: // TODO gavr уточнить что считается завершенным запросом
      return ukColors.green;
    case SearchQueryStatus.Deleted:
      return ukColors.red;
  }
  return ukColors.darkGrey;
};

// interface RenderProps {
//   row: any[];
//   value: any;
// }

export const statusRenderer: TDataGridRenderer = (row: any[], value: any) => {
  // const { t } = useTranslation();

  return <ColoredLabel label={value} bgcolor={getStatusColor(value)} />;
};
