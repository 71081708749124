import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';

import { EDirection } from '../languages/i-language';

import { ITask, ITopic } from './types';
import { DeadlinePaper } from './common/DeadlinePaper';

import { EditableTextArea } from '@/components/summary/EditableTextArea';
import { gAPP_STORE } from '@/app/app-store';

export interface ITaskPanel {
  id: string;
  indTask: number;
  task: ITask;
  rtl: EDirection;
  topic: ITopic;
  autotest: string;
}

export const TaskPanel = observer(({ id, indTask: i, task, rtl, topic, autotest }: ITaskPanel) => {
  const { updateTask, isTranslate, getTaskOwner } = gAPP_STORE.summaryStore;
  const [owner, setOwner] = useState(getTaskOwner(task.idTask));

  useEffect(() => {
    return reaction(
      () => getTaskOwner(task.idTask),
      (arg, prev) => setOwner(arg),
    );
  }, [getTaskOwner, task.idTask]);

  const prefix = useMemo(() => {
    return rtl === EDirection.RTL ? `${i + 1}#` : `#${i + 1}`;
  }, [i, rtl]);

  const handleEdit = (values: (string | number)[], fields: (keyof ITask)[]) => {
    void updateTask(task, fields as string[], values, topic);
  };

  return (
    <div
      data-autotest={autotest}
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px',
        gap: '8px',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderRadius: '8px',
        border: '1px solid rgba(221, 221, 221, 1)',
      }}
    >
      <EditableTextArea
        data-autotest={`${autotest}-issue`}
        id={`${autotest}-issue`}
        text={task.issue || ''}
        onEdit={value => handleEdit([value], ['issue'])}
        prefix={prefix}
        placeholder="summary.enterTitle"
        maxLength={200}
        emptyValue="--"
        defaultStyle={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 600,
        }}
        editStyle={{
          fontWeight: 600,
        }}
        readOnly={isTranslate}
      />
      <EditableTextArea
        id={`${autotest}-decision`}
        text={task.decision || ''}
        onEdit={value => handleEdit([value], ['decision'])}
        placeholder="summary.enterDescription"
        maxLength={1200}
        emptyValue="--"
        defaultStyle={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 400,
        }}
        readOnly={isTranslate}
      />

      <DeadlinePaper
        id={id}
        owner={owner}
        deadline={task.deadline}
        estimation={task.estimation}
        onUpdate={(fields, values) => handleEdit(values, fields as (keyof ITask)[])}
        readOnly={isTranslate}
        autotest={autotest}
      />
    </div>
  );
});
