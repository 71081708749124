import { makeAutoObservable, runInAction } from 'mobx';

import { IDictor } from '@/types/dictors';

export class DictorChipStore {
  anchorEl: HTMLElement | null = null;
  killTimer = false;

  dictor?: IDictor;
  personId?: string;

  openPopper = false;
  openMenu = false;

  constructor(dictor?: IDictor, dictorId?: string) {
    this.dictor = dictor;
    this.personId = dictorId;

    makeAutoObservable(this, {}, { autoBind: true });

    if (!dictor && dictorId) {
      this.loadDictor();
    }
  }

  loadDictor() {
    return {};
  }

  isNoPerson() {
    return !this.dictor && !this.personId;
  }

  setAnchorEl = (elem: HTMLElement | null) => {
    this.anchorEl = elem;
  };

  setOpenPopper = (open: boolean) => {
    this.openPopper = open;
  };

  setOpenMenu = (open: boolean) => {
    this.openMenu = open;

    const closeMenu = () => {
      this.setOpenMenu(false);
    };

    if (open) {
      document.addEventListener('mousewheel', closeMenu);
    } else {
      document.removeEventListener('mousewheel', closeMenu);
    }
  };

  handleMouseEnter = () => {
    this.killTimer = false;
    setTimeout(() => {
      runInAction(() => {
        if (!this.killTimer) {
          this.setOpenPopper(true);
        }
      });
    }, 500);
  };

  handleMouseLeave = () => {
    this.killTimer = true;
    this.setOpenPopper(false);
  };
}
