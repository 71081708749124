export interface ITranslateDirection {
  id: string;
  from: string;
  to: string;
}
export interface ITranslations {
  directions: ITranslateDirection[];
}
export interface ITranslateSettingValue {
  auto: boolean;
  to: string;
}
export class CTranslateSetting implements ITranslateSetting {
  key = '';
  modifyedDate = '';
  value = {
    auto: false,
    to: '',
  };
}
export interface ITranslateSetting {
  key: string;
  modifyedDate: string;
  value: ITranslateSettingValue;
}
