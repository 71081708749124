import { forwardRef, ForwardRefRenderFunction } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { IUKDataGrid2Col } from './types';

const useStyles = makeStyles({
   columnResizerLine: {
      position: 'absolute',
      display: 'none',

      height: '100%',
      width: '1px',

      background: 'red',
      zIndex: 100,
   },
});

interface IColumnResizerLine
   extends ForwardRefRenderFunction<HTMLDivElement, Record<string, any>> {}

const ColumnResizerLine: IColumnResizerLine = (props, ref) => {
   const classes = useStyles();

   return <div className={classes.columnResizerLine} ref={ref} />;
};

export default forwardRef(ColumnResizerLine);
