import { Box, Button, Typography } from '@material-ui/core';

import SaveRoundedIcon from '@material-ui/icons/SaveRounded';

import { FC } from 'react';

import { exporter } from '../../i18n/i18nFilesToCsv';


export const DownloadLocalesButton: FC = () => {

  const handleLocalesImport = () => {
    const data = exporter();
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, data], { type: 'text/csv;charset=utf-8-sig' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'locale-keys.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Box p={4}>
      <Button variant="contained" style={{ width: '25em', height: '5em' }} onClick={handleLocalesImport}>
        <SaveRoundedIcon />
        <Typography style={{ lineHeight: 0.1, marginLeft: '10px' }}>admin.loacalesDownloadBtn</Typography>
      </Button>
    </Box>
  );
};
