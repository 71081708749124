import { makeAutoObservable, toJS } from 'mobx';
import { MutableRefObject } from 'react';
import { IColumnLayout } from './DataGridSettings';
import { createResizeMouseListeners, IResizeMouseListeners, removeResizeMouseListeners } from './resizeMouseListeners';

class ColumnResizerData {
  constructor(
    public colLayout?: IColumnLayout,
    public startX?: number,
    public dx?: number,
    public elem?: HTMLElement,
  ) {}
}

const relativeCoords = (event: any, element: any) => {
  if (element) {
    const bounds = element.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }
  return { x: 0, y: 0 };
};

class ColumnResizerStore {
  columnResizerData?: ColumnResizerData;
  resizeMouseListeners?: IResizeMouseListeners;

  constructor(
    public colLayout: IColumnLayout,
    public rootRef: MutableRefObject<HTMLDivElement | null>,
    public columnResizeLineRef: MutableRefObject<HTMLDivElement | null>,
    public updateLayout: () => void,
  ) {
    this.columnResizerData = new ColumnResizerData(colLayout);

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  startResizeColumn = (event: any, colLayout: IColumnLayout) => {
    event.preventDefault();
    event.stopPropagation();

    console.log('startResizeColumn()');
    console.log('startResizeColumn(), this.rootRef = ', toJS(this.rootRef));

    removeResizeMouseListeners(this.rootRef.current, this.resizeMouseListeners);
    this.resizeMouseListeners = createResizeMouseListeners(this.rootRef.current, {
      doResize: this.handleResizeColumn,
      endResize: this.endResizeColumn,
    });

    if (this.columnResizerData !== undefined) {
      this.columnResizerData.colLayout = colLayout;
      this.columnResizerData.startX = event.clientX;
      this.columnResizerData.elem = event.target;

      console.log('startResizeColumn(), columnResizer.startX = ', this.columnResizerData.startX);

      // showColumnResizerLine(event);
    }
  };

  handleResizeColumn = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    console.log('handleResizeColumn()');

    // console.log('handleResizeColumn(), columnResizer.current.colLayout = ', toJS(columnResizer.current.colLayout));

    if (this.columnResizerData && this.columnResizerData.colLayout && this.columnResizerData.elem) {
      console.log('handleResizeColumn(), columnResizer.current.colLayout = ', toJS(this.columnResizerData.colLayout));

      const dx = event.clientX - (this.columnResizerData.startX || 0);

      if ((this.columnResizerData.colLayout.width || 100) + dx > 30) {
        this.columnResizerData.dx = dx;
        this.showColumnResizerLine(event);
      }
    }
  };

  endResizeColumn = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    console.log('endResizeColumn()');

    removeResizeMouseListeners(this.rootRef.current, this.resizeMouseListeners);

    if (event.target === this.rootRef.current || event.type === 'mouseup') {
      if (this.columnResizerData && this.columnResizerData.colLayout && this.columnResizerData.elem) {
        this.columnResizerData.colLayout.width =
          (this.columnResizerData.colLayout.width || 100) + (this.columnResizerData.dx || 0);

        console.log('endResizeColumn(), columnResizer.current.col.width = ', this.columnResizerData.colLayout.width);
      }

      this.columnResizerData = new ColumnResizerData();

      this.hideColumnResizerLine();
      this.updateLayout();
    }
  };

  showColumnResizerLine = (event: any) => {
    if (this.columnResizeLineRef.current) {
      const p = relativeCoords(event, this.rootRef.current);
      this.columnResizeLineRef.current.style['left'] = `${p.x}px`;
      this.columnResizeLineRef.current.style['display'] = 'block';
    }
  };

  hideColumnResizerLine = () => {
    if (this.columnResizeLineRef.current) {
      this.columnResizeLineRef.current.style['display'] = 'none';
    }
  };
}

export default ColumnResizerStore;
