import { Box, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { AddCircleOutlineRounded, RemoveCircleOutlineRounded } from '@material-ui/icons';
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ukColors from '@uk/ukColors';

import { IFormField, TFormFieldPredicate } from './types';
import { runInAction } from 'mobx';

import {
  FaEquals,
  FaNotEqual
} from 'react-icons/fa'

export interface UkFormCustomIconProviderCB {
  getCustomIcon: (descriptor: IFormField, predicate?: TFormFieldPredicate) => ReactNode | undefined;
}
export const UkFormCustomPredicatesIconCb: UkFormCustomIconProviderCB = {
  getCustomIcon: (descriptor: IFormField, predicate?: TFormFieldPredicate) => {
    return (<>FIXME: Missing Custom ICON</>);
  }
}

export const getPredicateIcon = (descriptor: IFormField, predicate?: TFormFieldPredicate) => {
  let icon = undefined;

  const commonStyle = { fontSize: 14, width: 14 };
  const p = predicate || descriptor.predicate;

  switch (p) {
    case 'contains':
      // icon = <FontAwesomeIcon icon={IconsR.faDotCircle} />;
      icon = (
        <AddCircleOutlineRounded
          style={{ ...commonStyle, color: ukColors.primary }}
          data-autotest={'ff-pred-contains-' + descriptor.id ? descriptor.id + '-' : '-' + descriptor.autotestId + ''}
        />
      );
      break;
    case 'not_contains':
      // icon = <FontAwesomeIcon icon={IconsR.faCircle} />;
      icon = (
        <RemoveCircleOutlineRounded
          style={{ ...commonStyle, color: ukColors.red }}
          data-autotest={'ff-pred-not-contains-' + descriptor.id ? descriptor.id + '-' : '-' + descriptor.autotestId + ''}
        />
      );
      break;
    case 'equal':
      icon = (
        <FaEquals
          style={{ ...commonStyle, color: ukColors.primary }}
          data-autotest={'ff-pred-equal-' + descriptor.id ? descriptor.id + '-' : '-' + descriptor.autotestId + ''}
        />
      );
      break;
    case 'not_equal':
      icon = (
        <FaNotEqual
          style={{ ...commonStyle, color: ukColors.red }}
          data-autotest={'ff-pred-not-equal-' + descriptor.id ? descriptor.id + '-' : '-' + descriptor.autotestId + ''}
        />
      );
      break;
    default:
      return UkFormCustomPredicatesIconCb.getCustomIcon(descriptor, predicate);
  }
  return icon;
};

interface FilterPredicateProps {
  descriptor: IFormField;
}
const FormFieldPredicate: React.FC<FilterPredicateProps> = ({ descriptor }) => {
  const { t } = useTranslation();
  const basePred = useMemo(() => {
    return descriptor.predicate ?? (descriptor.predicates ? descriptor.predicates[0] : 'contains');
  }, [descriptor.predicate, descriptor.predicates]);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const predicate = descriptor.predicate;

  const handleChangeMode = (event: any) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleSelectMode = (value?: TFormFieldPredicate) => {
    setMenuAnchorEl(null);
    runInAction(() => descriptor.predicate = value ? value : basePred);
  };

  return (
    <>
      <Tooltip title={predicate ? t(predicate).toString() : ''}>
        <IconButton size="small" onClick={handleChangeMode}>
          {getPredicateIcon(descriptor)}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuAnchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => handleSelectMode()}
      >
        {descriptor &&
          descriptor.predicates &&
          descriptor.predicates.map((item, index) => (
            <MenuItem
              key={index}
              value={item}
              onClick={() => handleSelectMode(item)}
              style={{
                display: 'flex',
                alignItems: 'stretch',
              }}
            >
              <Box display="flex" alignItems="center" pr={1}>
                {getPredicateIcon(descriptor, item)}
              </Box>
              {t(item)}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default FormFieldPredicate;
