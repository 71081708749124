import { useCallback, useEffect, useState } from 'react';
import { reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { IUploadResult2 } from '../../types';

import { DialogBody } from './dialogBody';
import { DialogStore } from './dialog.store';

import { Z_INDEX_COMMON_DIALOG } from '@/common/constants';
import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus } from '@/react-ui-kit/src';


export interface IDialog {
  isOpen: boolean,
  closeDialog: (status: ECommonDialogCloseStatus) => void
  initValues?: IUploadResult2;
}

export const DialogCreate = observer(({ isOpen, closeDialog, initValues }: IDialog) => {
  const { t } = useTranslation();
  const [uiStore] = useState(new CommonDialogUiStore());
  const [dialogResult] = useState<DialogStore>(new DialogStore(initValues));

  uiStore.setOkEnabled(dialogResult.ready());

  useEffect(() => {
    return reaction(
      () => dialogResult.ready(),
      (newv, oldv) => {
        if ((oldv === newv) && (uiStore.isOkEnabled === newv)) return;
        runInAction(() => {
          uiStore.isOkEnabled = newv;
        });
      },
    );
  }, [dialogResult, dialogResult.ready, uiStore]);


  const handleClose = useCallback(async (status: ECommonDialogCloseStatus) => {
    if (status === ECommonDialogCloseStatus.OK) {
      runInAction(() => {
        uiStore.isOkEnabled = false;
        uiStore.isPending = true;
      });
      const success = await dialogResult.toServerViaRedis();
      if (!success) {
        runInAction(() => {
          uiStore.setOkEnabled(true);
          uiStore.setPending(false);
        });

        return;
      }
    }
    closeDialog(status);
    dialogResult.setInitValues();
    runInAction(() => {
      uiStore.isPending = false;
    });
  }, [closeDialog, dialogResult, uiStore]);

  return (
    <CommonDialog
      bodyProps={{ padding: '0 px', maxHeight: '70vh' }}
      fullheightWithoutScroll
      topPanel2={'10px'}
      style={{ zIndex: Z_INDEX_COMMON_DIALOG, position: 'absolute' }}
      title={'uploadFiles'}
      open={isOpen}
      onClose={handleClose}
      contentComponent={<DialogBody data={dialogResult} />}
      autotestId={'upload2'}
      uiStore={uiStore}
    />
  );
});
