import { Box, Fade } from '@material-ui/core';

import React, { useEffect } from 'react';

import { SplitPaneEx } from '@uk';

import FilterPanel from '../filter/filter-panel';
import { RecordText } from '../records/record-text';
import RecordsGrid from '../records/records-grid';

import { VideoPlayer } from '../video-player/VideoPlayer';

import { gAPP_STORE } from '@/app/app-store';

const HomePage = () => {
  useEffect(() => {
    gAPP_STORE.getUsersStore().getAll();
    gAPP_STORE.getGroupsStore().getAll();
    gAPP_STORE.getTemplateStore().getAll();
    gAPP_STORE.getLanguagesStore().getAll();
    gAPP_STORE.getWordDictionaryStore().getAll();
    gAPP_STORE.initPunctuationModelLanguages();
  }, []);

  return (
    <Fade in>
      <Box id={'HomePage'} display="flex" flexGrow={1} flexDirection="column" sx={{ width: window.innerWidth }}>
        <SplitPaneEx
          id="MainWorkPage"
          panes={[
            {
              pane: <FilterPanel />,
              size: 400,
            },
            {
              pane: <RecordsGrid />,
              minSize: 200,
            },
            {
              pane: <RecordText />,
              size: 600,
            },
          ]}
        />

        <VideoPlayer />
      </Box>
    </Fade>
  );
};

export default HomePage;
