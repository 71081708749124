import { EntityChangeEventTypes } from './types';

import { IGroupToRecord, IKwsIndex, ILabel, IRecord } from '@/components/records';
import { IEntityChangeEvent } from '@/types/events';
import { IGridStorageEntry } from '@/types/grid-storage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function recordToIdProxy<T>(value: any) {
  return new Proxy(value, {
    get(t, prop) {
      if (prop === 'id') {
        return t.recordId;
      }
    },
  });
}

export function EntityChangeEventDeserialize(
  event: MessageEvent<string>,
):
  | IEntityChangeEvent<IKwsIndex>
  | IEntityChangeEvent<IRecord>
  | IEntityChangeEvent<IGroupToRecord>
  | IEntityChangeEvent<IGridStorageEntry>
  | IEntityChangeEvent<ILabel>
  | IEntityChangeEvent<unknown> {
  const entityChangedEvent = JSON.parse(event.data) as IEntityChangeEvent;

  switch (entityChangedEvent.type) {
    case EntityChangeEventTypes.KWS_INDEX:
      entityChangedEvent.value = recordToIdProxy(entityChangedEvent.value);

      return entityChangedEvent as IEntityChangeEvent<IKwsIndex>;

    case EntityChangeEventTypes.GROUP_TO_RECORD:
      entityChangedEvent.value = recordToIdProxy(entityChangedEvent.value);

      return entityChangedEvent as IEntityChangeEvent<IGroupToRecord>;

    case EntityChangeEventTypes.RECORD:
      return entityChangedEvent as IEntityChangeEvent<IRecord>;

    case EntityChangeEventTypes.STORAGE_ENTRY:
      return entityChangedEvent as IEntityChangeEvent<IGridStorageEntry>;

    case EntityChangeEventTypes.LABEL_FULL:
    case EntityChangeEventTypes.LABEL_SHORT:
    case EntityChangeEventTypes.LABEL_TYPE: {
      return entityChangedEvent as IEntityChangeEvent<ILabel>;
    }
  }

  return entityChangedEvent;
}
