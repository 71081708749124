import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tooltip, withStyles } from '@material-ui/core';

import { ColoredLabel } from '@uk';

import appColors from 'app/app-colors';
import { capitalizeFirstLetter } from 'common/utils';

import { ERecordStatus, RecordStatusColorMap, RecordStatusMap } from './types';

export interface IRecordsStatusLabelProps {
  status: ERecordStatus;
  text?: string;
  total?: number;
}

const styles = {
  tooltip: {
    minWidth: '600px',
  }
};

const CustomTooltip = withStyles(styles)(Tooltip);

const RecordsStatusLabel: React.FC<IRecordsStatusLabelProps> = ({ status, text, total }) => {
  const { t } = useTranslation();

  const label = t(RecordStatusMap.get(status) || '');
  const backgroundColor = RecordStatusColorMap.get(status) || appColors.darkGrey;

  return (
    <CustomTooltip title={text || ''} placement="right-end">
      <Box display="flex" flexDirection="row" alignItems="center">
        <ColoredLabel
          label={capitalizeFirstLetter(total ? `${label} (${total})` : label)}
          bgcolor={backgroundColor}
        />
        <Box pl={1} />
        {/* {status === ERecordStatus.NEW && <CircularProgress size={20} style={{ marginTop: 2 }} />} */}
      </Box>
    </CustomTooltip>
  );
};

export default RecordsStatusLabel;
