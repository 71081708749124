import { BackendService } from '../backend-service';

class SettingsService {
  private apiUrl = 'settings';
  private backendService = BackendService;

  async setValue<T>(key: string, value: T): Promise<void> {
    await this.backendService.put(
      this.apiUrl.concat('/add'),
      JSON.stringify({
        key,
        value: JSON.stringify(value),
      }),
    );
  }

  async getValue<T>(key: string): Promise<T | null> {
    try {
      const [resp] = await this.backendService.get(this.apiUrl.concat(`/${key}`));

      return JSON.parse(resp.value) as T;
    } catch (err) {
      console.log('Error while loading setting: ', key);

      return null;
    }
  }
}

export const settingsService = new SettingsService();
