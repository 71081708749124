import { PlayerRegion, IWaveSurfer } from './types';
import { makeAutoObservable } from 'mobx';
import soundStore from '@uk/stores/SoundStore/soundStore';
import { getRandomIntFromZero } from '@uk/utils/intUtils';

export class PlayerStore {
  // TODO добавить gavr необязательный параметр сегментации а также новый метод выставления сегментации, который будет чистить старую

  // может быть хранить хешмапу сегментаций к названиям аудиозаписей, скорее делать это где то выше
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }
  // эта либа без типизации https://github.com/ShiiRochi/wavesurfer-react/issues/24#issuecomment-933276242
  currentWS?: IWaveSurfer;

  private rightWasMarked = false;
  private leftWasMarked = false;
  loading = true;
  isPlaying = false;
  isErrorPlayer = false;

  enableLoading = () => {
    this.loading = true;
  };
  disableLoading = () => {
    this.loading = false;
  };

  enablePlayer = () => {
    this.isPlaying = true;
  };
  disablePlayer = () => {
    this.isPlaying = false;
  };

  showErrorState = (state: boolean) => {
    this.isErrorPlayer = state;
  };

  setAudioWave = (src: string | undefined, blobSrc: Blob | undefined) => {
    if (!this.currentWS) return;
    this.enableLoading();
    this.showErrorState(false);

    if (src) {
      this.currentWS.load(src);
    } else if (blobSrc) {
      this.currentWS.loadBlob(src);
    }

    if (this.currentWS.isPlaying()) {
      this.enablePlayer();
    } else {
      this.disablePlayer();
    }
  };

  setSegmentations(regions: PlayerRegion[]) {
    if (!this.currentWS) return;
    this.currentWS.clearRegions();
    this.addSegmentations(regions);
  }

  addSegmentations(regions: PlayerRegion[]) {
    if (regions.length === 0) {
      console.log(
        'debug1, playerUIStore, пришло ',
        regions.length,
        ' промежутков, ничего не делаем'
      );
      return;
    }
    if (!this.currentWS) return;
    // это фикс множественого ререндера компонента плеера
    // если чистить каждый раз, то вторая дорожка не выделится
    // if (this.leftWasMarked && this.rightWasMarked) {
    //   this.currentWS.clearRegions()
    // }

    for (const region of regions) {
      // Отмечаем были ли уже отметки в правом или левом канале
      // if (x.id === 0) {
      //   this.leftWasMarked = true
      // } else {
      //   this.rightWasMarked = true
      // }

      this.currentWS.addRegion(region);
    }
  }

  handlePlayPause = (event: any) => {
    if (!this.currentWS) return;
    event.preventDefault();
    event.stopPropagation();

    this.currentWS.playPause();
    if (this.currentWS.isPlaying()) {
      this.enablePlayer();
    } else {
      this.disablePlayer();
    }
  };

  clearRegions = () => {
    if (!this.currentWS) return;
    this.currentWS.clearRegions();
  };

  handleAudioProcess = (event: any) => {
    // console.log("Player, handleAudioProcess(), event = ", event);
    // appService.setPlayerPosition(event);
    if (event < soundStore.position || event > soundStore.position + 0.2) {
      // console.log('Player, handleAudioProcess(), event = ', event);
      soundStore.setPosition(event);
    }
  };

  handleWSMount = (waveSurfer: IWaveSurfer) => {
    this.currentWS = waveSurfer;

    if (this.currentWS) {
      const ws: any = this.currentWS;

      // loadCallAudio();

      ws.on('ready', () => this.disableLoading());
      ws.on('finish', () => this.disablePlayer());
      ws.on('pause', () => this.disablePlayer());
      ws.on('play', () => this.enablePlayer());
      ws.on('audioprocess', (event: any) => this.handleAudioProcess(event));
      ws.on('error', (error: any) => {
        console.error('Player, error = ', error.toString());
        if (!error.toString().includes('user aborted')) {
          this.disableLoading();
          this.showErrorState(true);
        }
      });
    }
  };

  handleAddRandomSegmentation() {
    if (this.currentWS) {
      console.log('player handleAddRegions(), ws = ', this.currentWS);

      this.currentWS.clearRegions();

      let start = 1,
        end;
      for (let i = 0; i < 3; i++) {
        end = start + 1 + getRandomIntFromZero(3);
        const region: PlayerRegion = {
          id: 1,
          start: start,
          end: end,
          loop: true,
          drag: true,
          resize: true,
          color: '#99ffff33',
          channelIdx: 0,
        };
        this.currentWS.addRegion(region);
        start = end + 1 + getRandomIntFromZero(3);
      }

      start = 5;
      for (let i = 0; i < 5; i++) {
        end = start + 1 + getRandomIntFromZero(3);
        const region: PlayerRegion = {
          id: 1,
          start: start,
          end: end,
          loop: true,
          drag: true,
          resize: true,
          color: '#ff999966',
          channelIdx: 1,
        };
        this.currentWS.addRegion(region);
        start = end + 1 + getRandomIntFromZero(3);
      }
    }
  }
}
