import { IWord } from '..';
import { ISelectionInfo } from '../text/types';

import { PUNCTUATIONS_CHARS, SPECIAL_CHARS } from './specialCharConstants';

export type IPhraseIndexRanges = { start: number; end: number };

export const isOnlySpecialCharsSelected = (s: string) => {
  return s.replace(new RegExp(`[${SPECIAL_CHARS}]*`), '').length <= 0;
};
const getRangedDelta = (text: string, fromIndex: number, toIndex: number) => {
  let delta = 0;
  const regex = new RegExp(`[${PUNCTUATIONS_CHARS}]`);

  for (let i = fromIndex; i < toIndex; i++) {
    const char = text.charAt(i);

    if (regex.test(char)) {
      delta = delta + 1;
    }
  }

  return delta;
};

const getRealFromIndex = (text: string, fromIndex: number, toIndex: number) => {
  let result = fromIndex;
  const regex = new RegExp(`[${PUNCTUATIONS_CHARS}]`);

  while (result <= toIndex && regex.test(text[result])) {
    result++;
  }

  return result;
};

const getRealToIndex = (text: string, fromIndex: number, toIndex: number) => {
  let result = toIndex;
  const regex = new RegExp(`[${PUNCTUATIONS_CHARS}]`);

  while (result >= fromIndex && regex.test(text[result])) {
    result--;
  }

  return result;
};

export const selectionToTokenIndexes = (selection: ISelectionInfo, tokens: IWord[]): IPhraseIndexRanges => {
  const realSelectionFrom = getRealFromIndex(selection.allText, selection.range.startOffset, selection.range.endOffset);
  const realSelectionTo = getRealToIndex(selection.allText, realSelectionFrom, selection.range.endOffset - 1);

  const punctuationsBeforeStart = getRangedDelta(selection.allText, 0, realSelectionFrom);
  const punctuationsInSelect = getRangedDelta(selection.allText, realSelectionFrom + 1, realSelectionTo - 1);

  const startOffsetInRealChars = realSelectionFrom - punctuationsBeforeStart;
  const endOffsetInRealChars = realSelectionTo - punctuationsBeforeStart - punctuationsInSelect;

  let startTokenIndex = 0;
  let endTokenIndex = tokens.length + 1;
  tokens.reduce((acc, cur, index) => {
    if (startOffsetInRealChars >= acc && startOffsetInRealChars < acc + cur.text.length) {
      startTokenIndex = index;
    }

    if (endOffsetInRealChars >= acc && endOffsetInRealChars < acc + cur.text.length) {
      endTokenIndex = index;
    }

    return acc + cur.text.length;
  }, 0);

  return { start: startTokenIndex, end: endTokenIndex };
};

export const noTextInTokens = (tokens: IWord[], indStart: number, indEnd: number) => {
  if (indEnd < indStart || indEnd >= tokens.length) return false;
  let result = true;
  for (let i = indStart; i <= indEnd; i++) {
    const txt = tokens[i].text.trim();
    result &&= txt.length === 0 || isOnlySpecialCharsSelected(txt);
  }

  return result;
};
