import { useTranslation } from 'react-i18next';
import { Box, ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core';
import { observer } from 'mobx-react';

import appColors from 'app/app-colors';

import React, { useState } from 'react';
import { dstr2str } from 'common/utils';

import { IWordsData } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { Z_INDEX_POPPER } from '@/common/constants';

interface IRecordTextHistoryItemProps {
  wordsData: IWordsData;
  isFirst: boolean;
  isLast: boolean;
}

const RecordTextHistoryItem: React.FC<IRecordTextHistoryItemProps> = ({ wordsData, isFirst, isLast }) => {
  const { t } = useTranslation();
  const { viewTranslate } = gAPP_STORE;
  const recordTextStore = gAPP_STORE.getRecordTextStore();
  const isTranslation = gAPP_STORE.getRecordTextStore().isTranslation;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMouseEnter = (event: any) => {
    const elem = event.currentTarget;
    setAnchorEl(elem);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const isCurrent = wordsData.id === recordTextStore.currentWordsData?.id;
  const bgcolor = isFirst ? appColors.green : isCurrent ? appColors.primary : '#ccc';
  const borderColor = isFirst && isCurrent ? appColors.green : isCurrent ? appColors.primary : 'white';

  const describeText  = isTranslation && viewTranslate ? t('automaticallyTranslated') : t('automaticallyRecognized');
  const textInCircle = wordsData.creatorId ? '' : 'A';

  const textBox = (text1: string, text2: string) => (
    <Box display="flex">
      <Box color="#999">{text1}:</Box>
      <Box p={0.5} />
      <Box color={appColors.primary}>{text2}</Box>
    </Box>
  );


  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          width={15}
          height={15}
          m={0.5}
          bgcolor={bgcolor}
          borderRadius={100}
          border={`3px solid ${borderColor}`}
          style={{ cursor: 'pointer', transition: 'all 0.5s', textAlign: 'center', fontSize: '0.9em', color: 'white' }}
          onClick={() => recordTextStore.selectHistoryItem(wordsData.id)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >{textInCircle}</Box>
        {!isLast && <Box width="1px" height={30} bgcolor="#ccc"></Box>}
      </Box>

      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Popper
          open={anchorEl !== null ? true : false}
          anchorEl={anchorEl}
          placement="right"
          transition
          disablePortal={false}
          style={{ zIndex: Z_INDEX_POPPER }}
        >
          <Grow in={true}>
            <Paper elevation={7} style={{ borderRadius: '0.5em' }}>
              <Box p={2}>
                {textBox(t('textVersionDatetime'), dstr2str(wordsData.createdDate))}
                <Box p={0.5} />
                {wordsData.creatorId ? (
                  textBox(t('textVersionAuthor'), gAPP_STORE.getUsersStore().getUserNameById(+wordsData.creatorId) || '')
                ) : (
                  <Box color={appColors.green}>{describeText}</Box>
                )}
              </Box>
            </Paper>
          </Grow>
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default observer(RecordTextHistoryItem);
