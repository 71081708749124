import { Box, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import { TGenericDataTreeNode } from './types';

const DataTreeNodeLabel: TGenericDataTreeNode = ({ node, nodeRenderer }) => {

  return (

    <Box display="flex" flexDirection="row" key={`nodelbl-${node.id}`}>
      {node.isPending && (
        <Box pr={1} display="flex" alignItems="center">
          <CircularProgress size={15} />
        </Box>
      )}
      <Box>{nodeRenderer ? nodeRenderer(node) : node.title}</Box>
    </Box>

  );

};


export default observer(DataTreeNodeLabel);