import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { gAPP_STORE } from '@/app/app-store';

const AppLogo = () => (
  <Box display={'flex'} flexDirection={'row'} justifyItems={'center'}>
    <div
      style={{
        background: '#ffffff00',
        padding: 4,
        borderRadius: '50%',
        display: 'flex',
        alignSelf: 'center'
      }}
    >
      <img src="img/logo/logo.svg" width="18px" alt="img" />
    </div>
    {
      gAPP_STORE.APP_NAME && gAPP_STORE.APP_NAME.length > 0 &&
      <Typography variant='h6' style={{ marginLeft: '1em', lineHeight: 1.1, alignSelf: 'center' }}>{gAPP_STORE.APP_NAME}</Typography>
    }
    <Box style={{ borderLeft: '1px solid white', marginLeft: '1em' }} />
  </Box>
);

export default AppLogo;
