import { Box, Menu, MenuItem } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IFormFieldValueVersion, TFormFieldType, TFormFieldValue, ukColors } from '@uk';

import { FormColorPicker } from '.';
import FormFieldCheckbox from './FormFieldCheckbox';
import FormFieldDatetime from './FormFieldDatetime';
import FormFieldList from './FormFieldList';
import FormFieldNumber from './FormFieldNumber';
import FormFieldText from './FormFieldText';
import { formFieldValidator } from './FormFieldValidator';
import FormImage from './FormImage';
import { IFormField } from './types';
import { useState } from 'react';
import FormFieldDate from '@uk/components/form/FormFieldDate';
import { runInAction } from 'mobx';
import { strToDateStr, strToDatetimeStr } from '@uk/utils/dateUtils';
import { convertValueToListItem } from './formUtils';
import FormFieldRadio from './FormFieldRadio';

import { pure } from 'recompose';
import FormFieldSlider from './FormFieldSlider';

interface IFilterFieldProps {
  descriptor: IFormField;
  rangeIndex?: number;
  readonly?: boolean;
  onSearch?: (descriptor: IFormField) => void;
}

const FormField: React.FC<IFilterFieldProps> = ({ descriptor, rangeIndex, readonly, onSearch }) => {
  const { t } = useTranslation();

  const [conflictMenuAnchor, setConflictMenuAnchor] = useState<Element | null>(null);


  const fieldComponent = useMemo(() => {
    const handleListSearch = () => {
      if (onSearch) {
        onSearch(descriptor);
      }
    };
    switch (descriptor.type) {
      case 'checkbox':
        return <FormFieldCheckbox descriptor={descriptor} readonly={readonly} />;
        break;

      case 'slider':
        return <FormFieldSlider descriptor={descriptor} readonly={readonly} />;
        break;

      case 'radio':
        return <FormFieldRadio descriptor={descriptor} readonly={readonly} />;
        break;

      case 'datetime':
        return <FormFieldDatetime descriptor={descriptor} rangeIndex={rangeIndex} readonly={readonly} />;
        // isDateType = true;
        break;

      case 'date':
        return <FormFieldDate descriptor={descriptor} rangeIndex={rangeIndex} readonly={readonly} />;
        // isDateType = true;
        break;

      case 'colorpick':
        return <FormColorPicker descriptor={descriptor} rangeIndex={rangeIndex} readonly={readonly} />;
        break;

      case 'image':
        return <FormImage descriptor={descriptor} readonly={readonly} />;
        break;

      case 'list':
        return <FormFieldList descriptor={descriptor} onSearch={handleListSearch} readonly={readonly} />;
        break;

      case 'number':
        return <FormFieldNumber descriptor={descriptor} rangeIndex={rangeIndex} minValue={descriptor.numberMin} maxValue={descriptor.numberMax} readonly={readonly} />;
        break;

      default:
        return descriptor.isSingle ? (
          <FormFieldText descriptor={descriptor} rangeIndex={rangeIndex} readonly={readonly} />
        ) : (
          <FormFieldList descriptor={descriptor} onSearch={handleListSearch} readonly={readonly} />
        );
    }
  }, [descriptor, onSearch, rangeIndex, readonly]);

  const handleOpenConflictMenu = useCallback((event: SyntheticEvent) => {
    setConflictMenuAnchor(event.currentTarget);
  }, []);

  const handleConflictMenuClose = useCallback((v: IFormFieldValueVersion | null) => {
    if (v) {
      runInAction(() => {
        // console.log('handleConflictMenuClose(), v = ', toJS(v));

        let val: any = v.value || '';
        switch (descriptor.type) {
          case 'list':
            val = convertValueToListItem(val);
            break;
        }
        descriptor.value = val;

        descriptor.selectedVersion = v;
      });
    }
    setConflictMenuAnchor(null);
  }, [descriptor]);

  const label = useMemo(() =>
    t(descriptor.name || '') +
    `${rangeIndex !== undefined ? (rangeIndex === 0 ? ' (' + t('from') + ')' : ' (' + t('to') + ')') : ''}`, [descriptor.name, rangeIndex, t]);

  const needBgcolor = useMemo(() => !['image', 'colorpick', 'radio'].includes(descriptor.type), [descriptor.type]);
  const needLabel = useMemo(() => !['image'].includes(descriptor.type) && label?.length > 0, [descriptor.type, label?.length]);
  const isFieldValueValid = formFieldValidator(descriptor);

  let bgColor = needBgcolor ? ukColors.inputFieldBackground : undefined;
  if (!isFieldValueValid) {
    bgColor = ukColors.notValidFieldValueBackground;
  }

  const isConflict = !readonly && descriptor.versions && !descriptor.selectedVersion;
  const border = !isFieldValueValid || isConflict ? `1px solid ${ukColors.red}` : `1px solid transparent`;

  const objectValueToString = useCallback((value: TFormFieldValue, type: TFormFieldType) => {
    if (!value) {
      return t('undefined');
    }
    if (typeof value === 'object') {
      return (value as any).name || (value as any).title || (value as any).text || t('undefined');
    }

    if (type === 'date') {
      return strToDateStr(value as string);
    }
    if (type === 'datetime') {
      return strToDatetimeStr(value as string);
    }

    return value;
  }, [t]);

  if (descriptor.type === 'checkbox') {
    return (
      <Box flexGrow={1} display="flex" alignItems="center">
        <Box width="0em" />
        {fieldComponent}
      </Box>
    );
  }

  return (
    <>
      <Box flexGrow={1} border={!descriptor.autotestId ? '1px solid red' : undefined}>
        {needLabel && (
          <Box
            fontSize="0.9em"
            pb={0.5}
            color={!isFieldValueValid ? ukColors.red : ukColors.darkGrey}
            data-autotest={descriptor.name + '_label'}>
            {label}
          </Box>
        )}

        <Box flexGrow={1} display="flex" alignItems="center">
          <Box
            flexGrow={1}
            bgcolor={bgColor}
            px={needBgcolor ? 1 : 0}
            borderRadius="0.3em"
            overflow="hidden"
            border={border}
          >
            {fieldComponent}
          </Box>
          {isConflict && (
            <Box color={ukColors.red} pl={1} style={{ cursor: 'pointer' }} onClick={handleOpenConflictMenu}>
              <Warning />
            </Box>
          )}
          {descriptor.selectedVersion && (
            <Box color={ukColors.green} pl={1} style={{ cursor: 'pointer' }} onClick={handleOpenConflictMenu}>
              <Warning />
            </Box>
          )}
        </Box>

        {descriptor.selectedVersion && (
          <Box pl={1} pt={0.5} color={ukColors.green} fontSize="0.9em">
            {t('versionFrom')} {strToDatetimeStr(descriptor.selectedVersion.datetime)}
          </Box>
        )}
      </Box>

      <Menu
        anchorEl={conflictMenuAnchor}
        open={conflictMenuAnchor !== null}
        onClose={() => handleConflictMenuClose(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {descriptor.versions?.map((v, index, arr) => {
          const isLast = index === arr.length - 1;
          return (
            <MenuItem
              key={index}
              onClick={() => {
                handleConflictMenuClose(v);
              }}
            >
              <Box p={2} borderBottom={!isLast ? '1px solid #ccc' : undefined}>
                <Box fontSize="1.2em" pb={1}>
                  {v.datetime}
                </Box>
                <Box color="darkGrey" fontSize="0.8em">
                  {t(v.fieldName)}
                </Box>
                <Box>{objectValueToString(v.value, descriptor.type)}</Box>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default pure(observer(FormField));
