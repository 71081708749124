import { BoxProps } from '@material-ui/core';
import { ReactNode } from 'react';
import { CommonDialogUiStore } from './CommonDialogUiStore';



export enum ECommonDialogCloseStatus {
    CANCEL = 0,
    OK = 1,
}


export interface ICommonDialogProps {
    title: string;
    fullSize?: boolean;
    contentComponent: any;
    bodyProps?: BoxProps;
    bodyScroll?: boolean;
    bodyBackground?: boolean;
    open?: boolean;
    okLabel?: string;
    cancelLabel?: string;
    noCancel?: boolean;
    autotestId?: string;
    fullheightWithoutScroll?: boolean;
    topPanel2?:string;
    commonDialogActions?: ReactNode;
    uiStore?: CommonDialogUiStore;
    onClose: (value: any) => void;
}
