import { Box, makeStyles, Slider } from '@material-ui/core';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import LoadingPanel from '@uk/components/loading/LoadingPanel';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerStore } from './Player.store';
import { PlayerProps } from './types';
import { IToolbarExDescriptorItem } from '@uk/components/toolbarEx';
import ToolbarEx from '@uk/components/toolbarEx/ToolbarEx';
import ukColors from '@uk/ukColors';

import PlayerErrorMsg from './PlayerErrorMsg';
import {
  BorderAll,
  BorderClear,
  Clear,
  FastForward,
  FastRewind,
  Repeat,
  Save,
  Speed,
  VolumeDown,
} from '@material-ui/icons';
import RecordInfo from './RecordInfo';

/* eslint-disable @typescript-eslint/no-empty-function */
const NOOP = () => { };

const PLAYER_HEIGHT = 100;
//const TIMELINE_HEIGHT = 25;

export const styles = makeStyles(() => ({
  root: {
    // borderTop: "1px solid #ccc",
    width: '100%',
    height: PLAYER_HEIGHT,
    position: 'relative',

    background: 'green',
    backgroundSize: '40px 40px',
    backgroundImage:
      'linear-gradient(to right, grey 1px, transparent 1px), linear-gradient(to bottom, grey 1px, transparent 1px)',
  },
  button: {
    position: 'absolute',
    top: PLAYER_HEIGHT / 2 - 15,
    right: 20,
    zIndex: 100,
  },
  waveform: {
    width: 'calc(100% - 110px)',
  },
  waveformTimeline: {
    width: 'calc(100% - 110px)',
    height: 40,
  },
}));


const Player: React.FC<PlayerProps> = ({
  srcUrl,
  srcBlob: blobSrc,
  showRecordInfo,
  segmentation,
  recordInfoProps,
  showToolbar
}) => {
  const { t } = useTranslation();
  //const [id] = useState('player_' + uuidv4());
  const [uiStore] = useState(new PlayerStore());

  console.log('debug1 Player перерендерился с ', segmentation?.length, ' сегментациями');
  useEffect(() => {
    if (uiStore.currentWS) {
      uiStore.setAudioWave(srcUrl, blobSrc);
      // console.log("player ОЧИСТИЛИ РЕГИОНЫ")
      // wavesurferRef.current.clearRegions()
      if (segmentation && segmentation.length > 0) {
        console.log('player Добавиляем ', segmentation.length, ' сегментаций');
        uiStore.addSegmentations(segmentation);
      } else {
        console.log('debug1 player сегментаций не пришло');
      }
    }

    // Очищаем регионы при unmount, ставим на паузу
    return () => {
      if (uiStore.currentWS) {
        uiStore.currentWS.clearRegions();
        console.log("debug1 Player Очистили регионы при unmount'e");

        if (uiStore.currentWS && uiStore.isPlaying) {
          uiStore.currentWS.playPause();
        }
        uiStore.disablePlayer();
      }
    };
  }, [blobSrc, srcUrl, uiStore, segmentation]);

  // const plugins = useMemo(() => {
  //   return [
  //     // Regions plugin
  //     // WaveSurfer.regions.create({}),

  //     {
  //       plugin: RegionsPlugin,
  //     },

  //     // TimelinePlugin plugin
  //     timelineVis && {
  //       plugin: TimelinePlugin,
  //       options: {
  //         container: `#TimeLine_${id}`,
  //         height: TIMELINE_HEIGHT,
  //         notchPercentHeight: 50,
  //         primaryColor: ukColors.grey,
  //         secondaryFontColor: ukColors.grey,
  //       },
  //     },
  //   ].filter(Boolean);
  // }, [id, timelineVis]);

  // const loadCallAudio = () => {
  //   setLoading(true);
  //   setErrorPlayer(false);

  //   if (wavesurferRef.current) {
  //     const waveSurfer: any = wavesurferRef.current;
  //     const mediaUrl = `/media/demo-v1.mp3`;
  //     waveSurfer.load(mediaUrl);

  //     setIsPlaying(waveSurfer.isPlaying());
  //   }
  // };

  // useEffect(() => {
  // loadCallAudio();
  // }, [soundStore.record, wavesurferRef.current]);

  // const [waveFormProps] = useState({
  //   container: `WaveForm_${id}`,
  //   height: PLAYER_HEIGHT - TIMELINE_HEIGHT,
  //   // barWidth: 1,
  //   cursorWidth: 2,
  //   // waveColor: ukColors.selectedItem,
  //   // progressColor: "red",
  //   // cursorColor: 'red',
  //   responsive: true,
  //   backgroundColor: 'transparent', // BACKGROUND_COLOR,
  //   barMinHeight: 1,
  //   hideScrollbar: true,
  //   splitChannels: true,

  //   waveColor: 'white',
  //   progressColor: ukColors.selectedItemDark,
  //   cursorColor: ukColors.red,
  //   // barWidth: 1,
  //   // barRadius: 2,
  //   // responsive: true,
  //   // height: 150,
  //   // If true, normalize by the maximum peak instead of 1.0.
  //   normalize: true,
  //   // Use the PeakCache to improve rendering speed of large waveforms.
  //   // partialRender: true,

  //   // splitChannelsOptions: {
  //   //   overlay: false,
  //   //   channelColors: {
  //   //     0: {
  //   //       progressColor: "red",
  //   //       waveColor: ukColors.primaryLight,
  //   //     },
  //   //     1: {
  //   //       progressColor: "green",
  //   //       waveColor: ukColors.primary,
  //   //     },
  //   //   },
  //   // },
  // });

  const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(() => [
    {
      type: 'button',
      icon: <FastRewind />,
    },
    {
      type: 'button',
      icon: uiStore.isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />,
      onClick: (event) => uiStore.handlePlayPause(event),
      pl: 1,
    },
    {
      type: 'button',
      icon: <Repeat />,
      pl: 1,
    },
    {
      type: 'button',
      icon: <FastForward />,
      pl: 1,
    },

    // TODO: выделить компонент управления скоростью
    {
      type: 'custom',
      component: (
        <Box display="flex" alignItems="center" width={150}>
          <Speed />
          <Box p={0.5} />
          <Slider value={50} onChange={NOOP} />
        </Box>
      ),
      pl: 3,
    },

    // TODO: выделить компонент управления громкостью
    {
      type: 'custom',
      component: (
        <Box display="flex" alignItems="center" width={150}>
          <VolumeDown />
          <Box p={0.5} />
          <Slider value={50} onChange={NOOP} />
        </Box>
      ),
      pl: 3,
      pr: 3,
    },

    {
      type: 'filler',
    },

    {
      type: 'text',
      text: 'recordMarkup',
    },
    {
      type: 'button',
      tooltip: 'recordMarkupAddSelection',
      icon: <BorderAll />,
      pl: 1,
      onClick: uiStore.handleAddRandomSegmentation,
    },
    {
      type: 'button',
      tooltip: 'recordMarkupRemoveSelection',
      icon: <BorderClear />,
      pl: 1,
    },
    {
      type: 'button',
      tooltip: 'recordMarkupSave',
      icon: <Save />,
      pl: 1,
    },
    {
      type: 'button',
      tooltip: 'recordMarkupCancel',
      icon: <Clear />,
      pl: 1,
      onClick: () => uiStore.clearRegions(),
    },
  ], [uiStore]);

  // if (1 === 1) {
  //   return (
  //     <Box display="flex" flexGrow={0} flexDirection="column" position="relative" bgcolor="red" height={200}></Box>
  //   );
  // }

  return (
    <Box display="flex" flexGrow={0} flexDirection="column" position="relative">
        WRONG PLAYER!!!
      {uiStore.loading && !uiStore.isErrorPlayer && <LoadingPanel lite title={t('soundLoading')} style={{ color: 'white' }} />}
      {uiStore.isErrorPlayer && <PlayerErrorMsg />}

      {showRecordInfo && recordInfoProps && recordInfoProps.noise !== '?' && (
        <RecordInfo
          speechLength={recordInfoProps.speechLength}
          signalNoise={recordInfoProps.signalNoise}
          reverberation={recordInfoProps.reverberation}
          noise={recordInfoProps.noise}
          quality={recordInfoProps.quality}
          recordMarkupAlgorithm={recordInfoProps.recordMarkupAlgorithm}
          // allSegmentLength={recordInfoProps.allSegmentLength}
          highFrequencyBoundary={recordInfoProps.highFrequencyBoundary}
          lowFrequencyBoundary={recordInfoProps.lowFrequencyBoundary}
          frequencyBoundariesOfSpeechSignalThreshold={
            recordInfoProps.frequencyBoundariesOfSpeechSignalThreshold
          }
          reverbMomentsCount={recordInfoProps.reverbMomentsCount}
          SCOForReverb={recordInfoProps.SCOForReverb}
          maxNotClippedSegmentLength={recordInfoProps.maxNotClippedSegmentLength}
        />
      )}

      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        style={{
          position: 'relative',
          background: ukColors.primary,
          backgroundSize: '10px 10px',
          backgroundImage:
            'linear-gradient(to right, #1b3b55 1px, transparent 1px), linear-gradient(to bottom, #1b3b55 1px, transparent 1px)',
        }}>

      </Box>

      {showToolbar && <ToolbarEx descriptor={toolbarDescriptor} autotestId="PlayerPanel_ToolbarEx" noBorder />}

    
    </Box>
  );
};

export default observer(Player);
