import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormGroup, Box, Typography } from '@material-ui/core';
import { CommonDialog, CommonDialogUiStore, LoadingPanel } from '@uk';

import { CGroup, IGroup } from './types';
import { IGroupDialogResult } from './group-dialog';

import { gAPP_STORE } from '@/app/app-store';


interface IDialogDeleteGroupProps {
  open: boolean;
  group: IGroup | undefined;
  onClose: (dialogResult: IGroupDialogResult) => void;
}

const DialogDeleteGroup: React.FC<IDialogDeleteGroupProps> = ({ open, group, onClose }) => {
  const { t } = useTranslation();
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());
  const [recordCountWithGroup, setrecordCountWithGroup] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (group) {
      gAPP_STORE.getGroupsStore().getRecordCountWithGroup(group.id).then(value => setrecordCountWithGroup(value));
    }
  }, [group]);

  const dialogResult: IGroupDialogResult = useMemo(
    () => ({ group: group ? { ...group } : new CGroup(), closeStatus: 0, oldValue: group }),
    // eslint-disable-next-line
    [group, open],
  );

  const handleClose = (status: number) => {
    dialogResult.closeStatus = status;
    if (onClose) {
      onClose(dialogResult);
    }
  };

  const DialogBody = () => {
    return (
      <Box width={600}>
        {recordCountWithGroup !== undefined
          ? (
            <FormGroup>
              <>
                {recordCountWithGroup > 0 && <Typography style={{ lineHeight: 1.5 }}>{`${t('groups.deleteConfirm2')}`}</Typography>}
                <Typography style={{ lineHeight: 1.1 }}>{`${t('groups.deleteConfirm')}`}</Typography>
                <Typography style={{ padding: '30px' }}>{group?.name}</Typography>
              </>
            </FormGroup>
          ) : (
            <LoadingPanel />
          )
        }
      </Box>
    );
  };

  return (
    <CommonDialog
      title={t('group')}
      open={open}
      onClose={handleClose}
      contentComponent={DialogBody}
      autotestId={'groups'}
      uiStore={userDlgUiStoreRef.current}
    />
  );
};

export default DialogDeleteGroup;
