import { Box, makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ukColors from '@uk/ukColors';
import { INavButtonProps } from './types';

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: '10em',
    padding: '2em',
    '&:hover': {
      background: ukColors.selectedItem,
    },
    cursor: 'pointer',
    transition: 'all 0.5s',
  },
}));

const NavButton: React.FC<INavButtonProps> = ({ title, icon, action }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" onClick={action}>
      <Paper className={classes.paper}>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Box pb={2}>{icon}</Box>
          <Box>{t(title)}</Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default NavButton;
