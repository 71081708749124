import { saveAs as downloadFile } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';

import { backendApiUrl } from './constants';

import { ExportFileExtension } from '@/types/common';

class ExportFileService {
  private async saveAsPdf({ fileName, blob }: { blob: Blob; fileName: string }) {
    const docxFile = new File([blob], `${uuidv4()}.docx`, {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    const resp = await this.convertDocxToPdf(docxFile);
    const pdfBlob = await resp.blob();

    downloadFile(pdfBlob, `${fileName}.pdf`);
  }

  async convertDocxToPdf(file: File) {
    const formData = new FormData();
    formData.append('files', file);

    return await fetch(`${backendApiUrl}/converter/to-pdf`, {
      body: formData,
      method: 'post',
    });
  }

  async saveAs({
    blob,
    extension,
    fileName,
  }: {
    extension: ExportFileExtension;
    blob: Blob;
    fileName: string;
  }): Promise<void> {
    if (extension === 'pdf') return await this.saveAsPdf({ blob, fileName });
    try {
      const docxFile = new File([blob], `${uuidv4()}.docx`, {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      downloadFile(docxFile, `${fileName}.${extension}`);
    } catch (e) {
      console.error('save to docx: ', e);
    }
  }
}

export const exportFileService = new ExportFileService();
