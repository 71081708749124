import React, { FC, useCallback } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';

import { IToolbarTogglButtonProps } from './types';

const Btn: FC<IToolbarTogglButtonProps> = (props) => {
  const {selected, setSelected}  = props;

  const handleClick = useCallback(() => {
    if (selected !== undefined && setSelected !== undefined) {
      setSelected(!selected);
    }
    }, [selected, setSelected]);

  return (
    <ToggleButton
      data-autotest={ 'tbr-toggle-btn-' + (props.autotestId || '') }
      disabled={ props.disabled }
      color={ props.color || 'primary' }
      onClick={ handleClick }
      selected={ selected }
      value={ selected }
      size={ 'small'}
    >
      { props.icon }
    </ToggleButton>
  );
};

export const ToolbarToggleButton: FC<IToolbarTogglButtonProps> = (props) => {

  return (
    <Box pl={ props.pl } pr={ props.pr }>
      { props.tooltip && props.tooltip.length > 0
        ? (
          <Tooltip title={ props.tooltip }>
            <Box>
              <Btn { ...props } />
            </Box>
          </Tooltip>)
        : (
          <Btn { ...props } />
        ) }
    </Box>
  );
};