import { ListItem, ListItemText } from '@material-ui/core';

import { TDataGridRenderer } from '@uk/components/dataGrid';
import { strToDateStr, strToDatetimeStr, strToDatetimeWithSecondsStr } from '@uk/utils/dateUtils';

export const dateRenderer: TDataGridRenderer = (row: any[], value: any) => {
  // const value = row ? (row[colId as any] as string) || "" : "";
  return strToDateStr(value);
};

export const dateRendererWithSecond: TDataGridRenderer = (row: any[], value: any) => {
  return strToDatetimeWithSecondsStr(value);
};

export const datetimeRenderer: TDataGridRenderer = (row: any[], value: any) => {
  const dtstr = strToDatetimeStr(value);

  return (
    <ListItem style={{ padding: 0, width: 'auto' }}>
      <ListItemText
        style={{ margin: 0 }}
        primary={dtstr.substring(0, 10)}
        secondary={dtstr.substring(10, 16)}
      />
    </ListItem>
  );
};

