import { observer } from 'mobx-react';

import { ColoredLabel } from '@uk';
import appColors from 'app/app-colors';

import { IWordDictionary } from './WordDictionary.types';

const WordDictionaryChip = ({ topic }: { topic: IWordDictionary }) => {
  return (
    <ColoredLabel
      bgcolor={appColors.primary}
      label={`${topic.name} (${Math.floor(topic.kwsThreshold || 0)}%)`}
    />
  );
};

export default observer(WordDictionaryChip);
