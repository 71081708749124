import { Avatar, AvatarProps } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
export type ImageCacheType = { [url: string]: string };

export interface ICachedAvatarProps extends AvatarProps {
  imageCache: ImageCacheType;
}
type ImageDataType = string | ArrayBuffer | null;

const toDataURL = (url: string): Promise<ImageDataType> => {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};

export const CachedAvatar: FC<ICachedAvatarProps> = props => {
  const { imageCache, src, ...rest } = props;

  const [data, setData] = useState<string | undefined>(undefined);

  useEffect(() => {
    const doLoad = async () => {
      setData(undefined);
      if (src) {
        let srcCached = imageCache[src];
        if (!srcCached) {
          const dataUrl = await toDataURL(src);
          if (dataUrl) {
            if (dataUrl instanceof ArrayBuffer) {
              srcCached = src;
            } else {
              srcCached = dataUrl;
              imageCache[src] = srcCached;
            }
          }
        }
        setData(srcCached);
      }
    };

    doLoad();
  }, [imageCache, props, src]);

  return (
    <Avatar src={data} {...rest}>
      {props.children}
    </Avatar>
  );
};
