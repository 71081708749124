import { Box, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ukColors from '@uk/ukColors';
import { CommonDialogUiStore } from './CommonDialogUiStore';

export enum ECommonDialogCloseStatus {
  CANCEL = 0,
  OK = 1,
}

interface ICommonDialogActionsProps {
  okLabel?: string;
  cancelLabel?: string;
  noCancel?: boolean;
  autotestId?: string;
  uiStore?: CommonDialogUiStore;
  onClose: (value: any) => void;
}

const CommonDialogActions: React.FC<ICommonDialogActionsProps> = ({
  onClose,
  okLabel,
  cancelLabel,
  noCancel,
  autotestId,
  uiStore,
}) => {
  const { t } = useTranslation();

  okLabel = okLabel ? okLabel : t('OK');
  cancelLabel = cancelLabel ? cancelLabel : t('cancel');

  const handleClick = (status: ECommonDialogCloseStatus) => {
    onClose && onClose(status);
  };

  const isOkEnabled = !uiStore || uiStore.isOkEnabled;
  const isPending = !uiStore || uiStore.isPending;

  return (
    <Box pt={1} pb={1} pr={2} pl={2} borderTop="1px solid lightGrey" bgcolor={ukColors.lightLightGrey}>
      
      <DialogActions>
      {isPending && <CircularProgress style={{marginRight:'auto'}} size={32}/>}
        {!noCancel && (
          <Button
            onClick={() => handleClick(ECommonDialogCloseStatus.CANCEL)}
            variant="contained"
            disabled={isPending}
            data-autotest={'dlg-cancel-btn-' + autotestId || ''}>
            <Box p={0} m={0} minWidth={100}>{cancelLabel}</Box>
          </Button>
        )}
        <Box width={5} />
        <Button
          onClick={() => handleClick(ECommonDialogCloseStatus.OK)}
          variant="contained"
          color="primary"
          disabled={!isOkEnabled || isPending}        
          data-autotest={'dlg-ok-btn-' + autotestId || ''}>
          <Box p={0} m={0} minWidth={100}>{okLabel}</Box>
        </Button>

      </DialogActions>
    </Box>
  );
};

export default observer(CommonDialogActions);
