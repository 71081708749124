import React from 'react';
import { Typography, Paper, Box, TypographyProps, alpha } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingProps } from './types';

const styles = {
  root: {
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: '#00000022',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '1.2em',
  },
};

/**
 * Loading
 */


const LoadingPanel: React.FC<LoadingProps & TypographyProps> = ({ title, lite, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Box style={{ ...styles.root, position: 'absolute' }} >
      {lite && <Box px={4} py={2} borderRadius={10} bgcolor={alpha('#fff', 0.8)}><Typography style={styles.title} {...rest}>{title || t('loading')} </Typography></Box>}
      {!lite && (
        <Paper>
          <Box p={3}>
            <Typography style={styles.title} {...rest}>{title || t('loading')}</Typography>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default LoadingPanel;
