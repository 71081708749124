import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus } from '@uk';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, FormGroup, InputLabel, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

import { IExportEvent } from './types';

import { dateToStrWithTime, exportToCSVAndDownload, getXls } from './event-log-utils';

import { ExportFileExtension } from '@/types/common';
import { EExportFormats, EventLogReportFileRestrictions, exportFileExtensionsCsvXlsx } from '@/common/constants';
import { gAPP_STORE } from '@/app/app-store';


const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: 5,
  },
}));

interface IEventLogDialog {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const EventLogDialog: React.FC<IEventLogDialog> = (props) => {
  const {
    open,
    setOpen
  } = props;

  const [fileFormat, setFileFormat] = useState<string>(exportFileExtensionsCsvXlsx[0]);
  const [name, setName] = useState('events_report');
  const [description, setDescription] = useState('');

  useEffect(() => {
    function getEventLogWithoutLimit() {
      gAPP_STORE.getEventLogStore().getEventLogWithoutLimit();
    }

    if (open) {
      getEventLogWithoutLimit();
    }
  }, [open]);

  const getExportDataFromGrid = (resp: IExportEvent[]): IExportEvent[] => {
    return resp.map(v => ({
        eventType: v.eventType,
        actionType: v.actionType,
        user: v.message,
        loggedDatetime: v.loggedDatetime,
        message: v.user,
      }
    ));
  };

  const exportCsv = (description: string) => {
    const header = gAPP_STORE.getEventLogStore().gridCols
      .filter(c => c.name !== 'eventLog.id' && c.name !== 'eventLog.messageData' && c.name !== 'eventLog.errorCode')
      .map(c => t(c.name));
    const data: IExportEvent[] = getExportDataFromGrid(gAPP_STORE.getEventLogStore().eventData);
    const csvDataArray = data.map<string[]>(x => Object.values(x));

    exportToCSVAndDownload(name, {
      description,
      csvBody: csvDataArray,
      csvHeader: header,
    });
  };

  const exportAs = (format: string) => {
    const descr = description ?? '';
    switch (format) {
      case EExportFormats.csv:
        exportCsv(descr);
        break;
      case EExportFormats.xlsx:
        const res: IExportEvent[] = gAPP_STORE.getEventLogStore().eventData;
        const data = getExportDataFromGrid(res);
        const xlsxName = `${name}_${dateToStrWithTime(new Date())}.xlsx`;

        const headers = gAPP_STORE.getEventLogStore().gridCols
          .filter(c => c.name !== 'eventLog.id' && c.name !== 'eventLog.messageData' && c.name !== 'eventLog.errorCode')
          .map(c => t(c.name));

        if (data.length > 0) {
          getXls(data, headers, xlsxName, descr);
        }

        break;
    }
  };

  const onClose = (status: ECommonDialogCloseStatus) => {
    setOpen(false);
    setDescription('');
    setName('events_report');
    setFileFormat(exportFileExtensionsCsvXlsx[0]);
    if (status === 1) {
      exportAs(fileFormat);
    }
  };

  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());
  const { t } = useTranslation();
  const classes = useStyles();

  const DialogBody = () => {
    return (
      <Box width={600}>
        <FormGroup>
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              key="name"
              label={t('eventLog.filename')}
              variant="outlined"
              fullWidth
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
              inputProps={{ maxLength: EventLogReportFileRestrictions.MAX_FILENAME_LENGTH }}
            />
          </FormControl>
          <Box p={2} />

          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              key="description"
              label={t('eventLog.description')}
              variant="outlined"
              fullWidth
              defaultValue={description}
              onChange={(e) => setDescription(e.target.value)}
              autoComplete="off"
              inputProps={{ maxLength: EventLogReportFileRestrictions.MAX_DESCRIPTION_LENGTH }}
            />
          </FormControl>
          <Box p={2} />

          <FormControl variant="outlined" fullWidth>
            <InputLabel id="select-file-format-label">{t('eventLog.fileExtension')}</InputLabel>
            <Select
              labelId="select-file-format-label"
              value={fileFormat}
              onChange={e => setFileFormat(e.target.value as ExportFileExtension)}
              label={t('eventLog.fileExtension')}
            >
              {exportFileExtensionsCsvXlsx.map(ext => (
                <MenuItem key={ext} className={classes.option} value={ext}>
                  {ext}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      </Box>
    );
  };

  return (
    <>
      <CommonDialog
        title={'eventLog.dialogTitle'}
        open={open}
        onClose={onClose}
        contentComponent={DialogBody}
        uiStore={userDlgUiStoreRef.current}
        autotestId={'event-log'}
      />
    </>
  );
};

export default EventLogDialog;
