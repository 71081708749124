import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { ClickAwayListener, Tooltip } from '@material-ui/core';

import { toast } from 'react-toastify';

import styles from './deadlinePaper.module.scss';

import { checkDate, strToDateStr } from '@/react-ui-kit/src/utils/dateUtils';
import { IOwner } from '@/components/summary/types';

export interface IDeadlinePaper {
  id: string;
  owner: IOwner;
  deadline?: string;
  estimation?: string;
  readOnly?: boolean;
  onUpdate: (fields: string[], values: (string | number)[]) => void;
  autotest: string;
}

interface IDeadlineItem {
  title: string;
  text: string;
  type: keyof IDeadlineValue;
  placeholder?: string;
  onChange: (value: string) => void;
  editMode: boolean;
  divider?: boolean;
  readonly?: boolean;
  hasError?: boolean;
  autotest: string;
}

interface IDeadlineValue {
  ownerRO: string;
  ownerId: number;
  ownerName: string;
  deadline: string;
  estimation: string;
}

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
const initialDeadlineValue: IDeadlineValue = {
  ownerRO: 'true',
  ownerId: 0,
  ownerName: '',
  deadline: '',
  estimation: '',
};

const DeadlineItem = ({
  title,
  text,
  onChange,
  editMode,
  divider,
  placeholder,
  type,
  readonly,
  hasError,
  autotest,
}: IDeadlineItem) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (text !== value) {
      setValue(text);
    }
  }, [text, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={styles.itemWrapper}>
      <span style={{ color: editMode ? '#6C737F' : 'inherit', marginRight: '5px' }}>{title}</span>
      <div className={styles.editableWrapper}>
        {editMode && !readonly ? (
          <div style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            <span className={styles.hiddenElement}>{value.trim() || placeholder}</span>
            <input
              className={styles.input}
              type="text"
              value={editMode ? value : value || '--'}
              onChange={handleChange}
              placeholder={placeholder}
              disabled={!editMode}
              maxLength={type === 'deadline' ? 10 : 100}
              style={{ color: hasError ? '#e53935' : 'inherit' }}
            />
          </div>
        ) : (
          <Tooltip title={value} style={{ backgroundColor: 'white' }}>
            <span style={{ color: editMode && readonly ? '#6C737F' : 'inherit', fontWeight: 600 }}>
              {!value ? '--' : value.length < 21 ? value : `${value.slice(0, 20)}\u2026`}
            </span>
          </Tooltip>
        )}
      </div>
      {divider && (
        <div
          style={{
            borderInlineEnd: editMode ? '1px solid #6C737F' : '1px solid #111927',
            width: '5px',
            height: '16px',
          }}
        />
      )}
    </div>
  );
};

export const DeadlinePaper = observer(
  ({ id, owner, deadline, estimation, readOnly, onUpdate, autotest }: IDeadlinePaper) => {
    const [editMode, setEditMode] = useState(false);
    const [deadlineFieldError, setDeadlineFieldError] = useState(false);
    const [deadlineValue, setDeadlineValue] = useState<IDeadlineValue>(initialDeadlineValue);
    const { t } = useTranslation();

    useEffect(() => {
      setDeadlineValue({
        ownerRO: owner.editable ? 'false' : 'true',
        ownerId: owner.idOwner,
        ownerName: owner.name,
        deadline: deadline ? strToDateStr(deadline) : '',
        estimation: estimation ?? '',
      });
    }, [deadline, estimation, owner]);

    const handleClickAway = useCallback(() => {
      if (!editMode) return;

      const date = deadlineValue.deadline;

      if (date !== '' && (date.length !== 10 || !date?.match(DATE_REGEX) || !checkDate(date))) {
        toast.error(t('summary.errorEnterDeadline', { fieldName: t('summary.taskDeadLine') }));
        setDeadlineFieldError(true);

        return;
      }

      const fields: string[] = [];
      const values: (string | number)[] = [];

      Object.keys(deadlineValue).forEach(key => {
        fields.push(key);
        values.push(deadlineValue[key as keyof IDeadlineValue]);
      });

      onUpdate(fields, values);
      setDeadlineFieldError(false);
      setEditMode(false);
    }, [deadlineValue, editMode, onUpdate, t]);

    const handleUpdateValue = (value: string, key: keyof IDeadlineValue) => {
      setDeadlineValue(prevState => ({
        ...prevState,
        [key]: value,
      }));
    };

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          data-autotest={`${autotest}-DeadlinePaper`}
          className={styles.wrapper}
          onClick={e => e.preventDefault()}
          onDoubleClick={readOnly ? undefined : () => setEditMode(true)}
          style={{
            cursor: readOnly || editMode ? 'default' : 'pointer',
            background: editMode ? '#fff' : 'rgba(0, 162, 182, 0.08)',
            borderColor: editMode ? '#0B2B48' : '#ddd',
            boxShadow: editMode ? '0px 1px 8px 0 rgba(0, 0, 0, 0.24)' : 'none',
          }}
        >
          <DeadlineItem
            type="ownerName"
            title={`${t('summary.taskExecution')}: `}
            onChange={value => handleUpdateValue(value, 'ownerName')}
            text={deadlineValue.ownerName}
            editMode={editMode}
            placeholder={`${t('summary.enterOwner')}`}
            divider
            readonly={!owner.editable}
            autotest={`${autotest}-ownerName`}
          />
          <DeadlineItem
            type="deadline"
            title={`${t('summary.taskDeadLine')}: `}
            onChange={value => {
              handleUpdateValue(value, 'deadline');
              setDeadlineFieldError(false);
            }}
            text={deadlineValue.deadline}
            editMode={editMode}
            placeholder={`${t('summary.enterDeadline')}`}
            hasError={deadlineFieldError}
            divider
            autotest={`${autotest}-deadline`}
          />
          <DeadlineItem
            type="estimation"
            title={`${t('summary.taskGrade')}: `}
            onChange={value => handleUpdateValue(value, 'estimation')}
            text={deadlineValue.estimation}
            editMode={editMode}
            placeholder={`${t('summary.enterEstimation')}`}
            autotest={`${autotest}-estimation`}
          />
        </div>
      </ClickAwayListener>
    );
  },
);
