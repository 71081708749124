import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef } from 'react';

import { Z_INDEX_PLAYER } from 'common/constants';

import { ImageCacheType } from '../avatar/cached-avatar';
import DictorPanel from '../dictors/DictorPanel';

import { gAPP_STORE } from '@/app/app-store';
import { IRanges } from '@/types/dictors';

const BGCOLOR = '#dfd';

interface IPlayerDictorsPanelStyleProps {
  mirrored: boolean;
}

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    visibility: 'visible',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'transparent',
  },

  innerBox: {
    position: 'absolute',
    visibility: 'hidden',
    zIndex: Z_INDEX_PLAYER + 100, // не должен перекрывать плеер
    background: BGCOLOR,
    boxShadow: '0px 10px 30px rgba(0,0,0,0.25)',
    borderRadius: '1em',

    //'&:after': {},
  },
  afterBox: {
    content: '""',
    position: 'absolute',
    bottom: '-30px',
    top: 'calc(100% - 1px)',
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '15px 15px 15px 15px',
    borderColor: `${BGCOLOR} transparent transparent transparent`,
  },
});

const PlayerDictorPanel = observer(({ avatarCache }: { avatarCache: ImageCacheType }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const afterRef = useRef<HTMLDivElement>(null);

  const { soundStore, showDictorInfoCard } = gAPP_STORE;
  const { position, duration, isPlaying, record } = soundStore;

  const classes = useStyles();

  const dictorsStore = gAPP_STORE.getDictorsStore();

  const dictorsToShowSegmentation = useMemo(() => {
    return record && dictorsStore.isSegmentationLoaded
      ? dictorsStore.getDictorsWithSegmentationForRecord(record.correlationId)
      : [];
    //TODO: Костыль, нужно будет фиксить https://react.dev/learn/removing-effect-dependencies#to-change-the-dependencies-change-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictorsStore.isSegmentationLoaded, dictorsStore, record]);

  const dictor = useMemo(() => {
    const dictor = dictorsToShowSegmentation.find(d => {
      const ranges = (d.segmentation as IRanges)?.ranges ?? [];
      for (let i = 0; i < ranges.length; i += 2) {
        if (position >= ranges[i] && position <= ranges[i + 1]) {
          return true;
        }
      }

      return false;
    });

    return dictor;
  }, [dictorsToShowSegmentation, position]);

  useEffect(() => {
    const rootElement = rootRef.current;
    const innerElement = innerRef.current;
    const afterElement = afterRef.current;
    const secondInPixel_ = soundStore.getPlayerStore()?.minPixelPerSec ?? null;

    if (rootElement && innerElement && afterElement && dictor && secondInPixel_ !== null) {
      // if (!isPlaying) {
      //   rootElement.style.visibility = 'hidden';

      //   return;
      // }

      const rootElementRect = rootElement.getBoundingClientRect();
      const innerElementRect = innerElement.getBoundingClientRect();

      const width = rootElement.clientWidth;
      const secondInPixel = secondInPixel_ > 0 ? secondInPixel_ : (width ?? 0) / duration;
      const scrollOffset = soundStore.getPlayerStore()?.scrollPosInPx;
      let positionX = position * secondInPixel - (innerElementRect.width - 30) - (scrollOffset || 0);

      if (positionX < 0) {
        positionX = position * secondInPixel - 30 - (scrollOffset || 0);
        afterElement.style.left = '15px';
        afterElement.style.removeProperty('right');
      } else {
        afterElement.style.removeProperty('left');
        afterElement.style.right = '15px';
      }
      innerElement.style.left = `${positionX}px`;
      innerElement.style.bottom = `${rootElementRect.height + 5}px`;
      innerElement.style.visibility = 'visible';
    }
  }, [dictor, duration, isPlaying, position, soundStore]);

  if (!dictor || !showDictorInfoCard || !isPlaying) {
    return null;
  }

  return (
    <div ref={rootRef} className={classes.root}>
      <div className={classes.innerBox} ref={innerRef}>
        <div className={classes.afterBox} ref={afterRef}></div>
        <DictorPanel dictor={dictor} cache={avatarCache} />
      </div>
    </div>
  );
});

export default PlayerDictorPanel;
