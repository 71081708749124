import { FC } from 'react';

import { DownloadLocalesButton } from './DownloadLocalesButton';

import { gAPP_STORE } from '@/app/app-store';

export const DownloadLocalesButtonPage: FC = () => {

  return (
    <>
      {gAPP_STORE.loginStore.user?.firstName === 'Admin' && <DownloadLocalesButton />}
    </>
  );
};
