import { Box } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TreeView } from '@material-ui/lab';
import { Panel } from '@uk/index';
import ukColors from '@uk/ukColors';
import { observer } from 'mobx-react';
import { useCallback, useEffect } from 'react';
import { ObservedTreeNode, TGenericDataTree } from './types';




const DataTree: TGenericDataTree = ({ treeStore, expandIcon, collapseIcon, nodeRenderer }) => {

  useEffect(() => {
    if (!treeStore.getExpanded().includes(treeStore.getRoot().id)) {
      treeStore.setSelectedNode(treeStore.getRoot())
      treeStore.setExpanded([treeStore.getRoot()]);
    }
  }, [treeStore]);


  const handleSelect = useCallback(async (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    const nodes = treeStore.getNodesByIds(Array.isArray(nodeIds) ? nodeIds : [nodeIds]);
    if (nodes && nodes.length > 0) {
      treeStore.setSelectedNode(nodes[nodes.length - 1]);
    }
  }, [treeStore]);

  const handleToggle = useCallback(async (event: React.ChangeEvent<{}>, nodeIds: string[]) => {

    const nodes = treeStore.getNodesByIds(nodeIds);
    treeStore.setExpanded(nodes);

  }, [treeStore]);


  // const renderTree = useCallback((root: TGenericTreeNode) => {
  //   return (
  //     <TreeItem
  //       key={root.id}
  //       nodeId={root.id}
  //       label={<DataTreeNodeLabel node={root} nodeRenderer={nodeRenderer}/>
  //       }>
  //       {Array.isArray(root.children)
  //         ? root.children.map((node) => {
  //           return renderTree(node);
  //         })
  //         : null}
  //     </TreeItem>
  //   );
  // }, [nodeRenderer]);

  if (treeStore.error) {
    return (
      <Panel>
        <Box display="flex" alignItems="start" flexGrow={1}>
          <Box flexGrow={1}>
            <Box p={3} display="flex" alignItems="center">
              <ErrorIcon style={{ fontSize: '2em', color: ukColors.red }} />
              <Box pl={2} color={ukColors.red}>
                {treeStore.error}
              </Box>
            </Box>
          </Box>
        </Box>
      </Panel>
    );
  }

  if (!treeStore.getData()) {
    return <div>empty!</div>;
  }

  return (
    <>
      <TreeView
        defaultCollapseIcon={expandIcon ? expandIcon : <ExpandMoreIcon />}
        defaultExpandIcon={collapseIcon ? collapseIcon : <ChevronRightIcon />}
        expanded={treeStore.getExpanded()}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        selected={treeStore.getSelected()}
      >
        <ObservedTreeNode key={'rootNode'} node={treeStore.getRoot()} nodeRenderer={nodeRenderer} />
      </TreeView>
    </>
  );
};

export default observer(DataTree);
