export const intersactionOfTwoSets = <T>(A: Set<T>, B: Set<T>) => {
  const result = new Set<T>();
  A.forEach(a => {
    if (B.has(a)) {
      result.add(a);
    }
  });

  return result;
};
