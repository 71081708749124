const queryString = require('query-string');

export const checkCasheKeyUrl = () => {
  const currentURL = window.location.href;
  const queryString = currentURL.split('?')[1];
  const params = new URLSearchParams(queryString);
  const key = params.get('cacheKey') ?? undefined;
  if (key) {
    const tmp = currentURL.split('?')[0];
    window.history.pushState({}, '', tmp);
  }

  return key;
};

export const checkDocKeyUrl = () => {
  if (window.location && window.location.search) {
    const urlParams = queryString.parse(window.location.search);

    return urlParams['doc'] === 'true';
  } else {
    return false;
  }
};

export const checkLangKeyUrl = () => {
  if (window.location && window.location.search) {
    const urlParams = queryString.parse(window.location.search);

    return urlParams['lang'];
  } else {
    return undefined;
  }
};
