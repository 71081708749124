import React from 'react';
import { BoxProps, ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core';

import { observer } from 'mobx-react';

import { Z_INDEX_POPPER } from 'common/constants';

import { ImageCacheType } from '../avatar/cached-avatar';

import { DictorChipStore } from './DictorChipStore';
import DictorPanel from './DictorPanel';

interface IDictorChipProps {
  dictorChipStore: DictorChipStore;
  avatarCache?: ImageCacheType;
  style?: React.CSSProperties;
}

export const DictorPopper: React.FC<IDictorChipProps & BoxProps> = observer(props => {
  const { dictorChipStore, avatarCache, style } = props;

  const { dictor, anchorEl, openPopper, setOpenPopper } = dictorChipStore;

  if (!dictor) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => setOpenPopper(false)}>
      <Popper open={openPopper} anchorEl={anchorEl} placement="top" style={style ? style : { zIndex: Z_INDEX_POPPER }}>
        <Grow in>
          <Paper elevation={7} style={{ borderRadius: '1em' }}>
            <DictorPanel dictor={dictor} cache={avatarCache} />
          </Paper>
        </Grow>
      </Popper>
    </ClickAwayListener>
  );
});
