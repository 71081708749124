import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { ApexOptions } from 'apexcharts';

import { Box } from '@material-ui/core';

import DictorStatisticsChart from './DictorStatisticsChart';
import { getShortTitleFromString, getTitle } from './dictor-utils';

import { gAPP_STORE } from '@/app/app-store';
import { IDictor } from '@/types/dictors';
import appColors from '@/app/app-colors';

interface IDictorDiagramsForReport {
  selectedRecordCorrelationId: string;
}

export const DictorDiagramsForReport: React.FC<IDictorDiagramsForReport> = observer(props => {
  const dictors: IDictor[] = useMemo(() => {
    const result = [
      ...gAPP_STORE.getDictorsStore().getDictorsWithSegmentationForRecord(props.selectedRecordCorrelationId),
    ];
    result.sort((a, b) => b.wordsCount - a.wordsCount);

    return result;
  }, [props.selectedRecordCorrelationId]);

  const { options, seriesWordsCount, seriesSpeechDuration } = useMemo(() => {
    const labels = dictors.map(dictor => getTitle(dictor, true));
    const colors = dictors.map(dictor => (dictor.segmentation.ranges.length > 0 ? dictor.__color : appColors.grey));
    const seriesWordsCount = dictors.map(dictor => dictor.wordsCount);
    const seriesSpeechDuration = dictors.map(dictor => dictor.speechDuration);
    const options: ApexOptions = {
      chart: {
        type: 'donut',
        animations: {
          enabled: false, //no animations
        },
      },
      labels,
      colors,

      plotOptions: {
        pie: {
          donut: {
            size: '50%',
            labels: {
              show: false,
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'left',
        width: 300,
        formatter: (value: string) => getShortTitleFromString(value, 30),
      },
    };

    return { options, seriesWordsCount, seriesSpeechDuration };
  }, [dictors]);

  return (
    <Box display="flex" flexDirection="row" position={'absolute'} left={-9000} top={-9000}>
      <Box width={300} id={'chartNumberOfWords'}>
        <DictorStatisticsChart options={options} series={seriesWordsCount} />
      </Box>
      <Box width={300} id={'chartDuration'}>
        <DictorStatisticsChart options={options} series={seriesSpeechDuration} />
      </Box>
    </Box>
  );
});
