export const lightOrDark = (color: string) => {
  // Check the format of the color, HEX or RGB?
  let r: any;
  let g: any;
  let b: any;

  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    const res = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    if (res) {
      r = res[1];
      g = res[2];
      b = res[3];
    }
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    const res = +color.replace('#', '0x');

    r = res >> 16;
    g = (res >> 8) & 255;
    b = res & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  // if (hsp > 127.5) {
  //   return true;
  // }
  if (hsp > 90) {
    return true;
  }

  return false;
};

const padZero = (str: string, len?: number) => {
  len = len || 2;
  const zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export const invertColor = (hex: string) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}


export const getWhiteOrBlackInverseColor = (bg: string): string => {
  const hsp = colorHsp(bg);

  return hsp < 200 ? 'white' : 'black';
};


export const stringHashCode = (str: string) => {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export const intToRGB = (i: number) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
};

export const colorStrToRgb = (colorStr: string): { r: number; g: number; b: number } => {
  // Check the format of the color, HEX or RGB?
  let r = 0;
  let g = 0;
  let b = 0;

  if (colorStr.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    const res = colorStr.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    if (res) {
      r = +res[1];
      g = +res[2];
      b = +res[3];
    }
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    const res = +colorStr.replace('#', '0x');

    r = res >> 16;
    g = (res >> 8) & 255;
    b = res & 255;
  }

  return { r, g, b };
};


export const colorHsp = (color: string): number => {
  const { r, g, b } = colorStrToRgb(color);

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.9 * (g * g) + 0.114 * (b * b));

  return Math.round(hsp);
};
