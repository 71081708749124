import { FC, memo, useMemo } from 'react';
import { ListChildComponentProps } from 'react-window';

import { IPhrase } from '..';

import { IPhraseProps, Phrase } from './phrase';

import { gAPP_STORE } from '@/app/app-store';

export interface IPhraseWrapperProps extends Omit<IPhraseProps, 'phrase' | 'phraseIndex' | 'wordProbability'> {
  phrases: IPhrase[];
}

export const PhraseWrapper: FC<ListChildComponentProps<IPhraseWrapperProps>> = memo(({ index, style, data }) => {
  const {
    phrases,
    avatarCache,
    stereo,
    onClick,
    handleEditableFocused,
    handleEditComplete,
    handleEditKeyDown,
    onCutBubble,
  } = data;

  const recordTextStore = gAPP_STORE.getRecordTextStore();

  const phraseStyle = useMemo(
    () =>
      recordTextStore.editedPhraseIndex !== undefined && recordTextStore.editedPhraseIndex === index
        ? { ...style, zIndex: 10 }
        : style,
    [index, recordTextStore.editedPhraseIndex, style],
  );

  const phrase = phrases[index];
  const id = useMemo(() => `divPhrase_${index}`, [index]);

  const phraseMemorized = useMemo(
    () => (
      <Phrase
        avatarCache={avatarCache}
        phrase={phrase}
        phraseIndex={index}
        wordProbability={recordTextStore.wordProbability}
        stereo={stereo}
        onClick={onClick}
        handleEditableFocused={() => handleEditableFocused}
        handleEditComplete={(e, pi, cd) => handleEditComplete(e, pi, cd)}
        handleEditKeyDown={(e, pi) => handleEditKeyDown(e, pi)}
        onCutBubble={onCutBubble}
      />
    ),
    [
      avatarCache,
      handleEditComplete,
      handleEditKeyDown,
      handleEditableFocused,
      index,
      onClick,
      onCutBubble,
      phrase,
      recordTextStore.wordProbability,
      stereo,
    ],
  );

  return (
    <div id={id} style={phraseStyle} key={id}>
      {phraseMemorized}
    </div>
  );
});
