import { Box, Button, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { IToolbarButtonProps } from './types';

import styles from './toolbarButton.module.scss';

const ToolbarButton: React.FC<IToolbarButtonProps> = ({
  text,
  icon,
  tooltip,
  color,
  bgcolor,
  onClick,
  pl,
  pr,
  buttonClassName,
  disabled,
  rounded,
  autotestId,
}) => {
  const Btn = () => {
    const buttonStyle = { background: disabled ? undefined : bgcolor, color: disabled ? undefined : color || (bgcolor ? 'white' : undefined) };
    const hasText = text && text.trim().length > 0;
    return (
      <Button
        variant={rounded ? 'contained' : undefined}
        size="small"
        startIcon={icon}
        onClick={(event: any) => {
          if (onClick) {
            event.stopPropagation();
            event.preventDefault();
            onClick(event);
          }
        }}
        data-autotest={'tbr-tooltip-btn-' + (autotestId || '')}
        className={clsx(styles.button, styles.nowrap, buttonClassName, {
          [styles.rounded]: rounded,
          [styles.autotestNoIdWarn]: !autotestId,
        })}
        style={{ ...buttonStyle, paddingRight: hasText ? undefined : 0 }}
        disabled={disabled}
      >
        {text}
      </Button>
    );
  };

  return (
    <Box pl={pl || 0} pr={pr || 0}>
      {tooltip && tooltip.length > 0 ? (
        <Tooltip title={tooltip}>
          <Box>
            <Btn />
          </Box>
        </Tooltip>
      ) : (
        <Btn />
      )}
    </Box>
  );
};

export default ToolbarButton;
