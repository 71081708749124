import { FC, useEffect } from 'react';

import { Box, CircularProgress, LinearProgress, makeStyles } from '@material-ui/core';
import { VideocamOff } from '@material-ui/icons';

import { observer } from 'mobx-react';
import ReactPlayer from 'react-player';

import { SizeMeProps, withSize } from 'react-sizeme';

import { DraggableResizableWindow } from '../draggable-resizable-window/DraggableResizableWindow';

import { RecordVideoStore } from '../records/record-video-store';

import { gAPP_STORE } from '@/app/app-store';

const useStyles = makeStyles({
  linearProgress: {
    backgroundColor: 'transparent',
    '&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
      backgroundColor: 'lightgrey',
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: 'lightgrey',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'red',
    },
  },
});

const RESIZE_DELAY = 100;

const withSizeHOC = withSize({
  monitorWidth: true,
  monitorHeight: false,
  noPlaceholder: true,
  refreshMode: 'debounce',
  refreshRate: RESIZE_DELAY,
});

interface IVideoPlayerComponentProps extends SizeMeProps {
  id: string;
  videoStore: RecordVideoStore;
}

const VideoPlayerComponent = withSizeHOC(
  observer((props: IVideoPlayerComponentProps) => {
    const { id, size, videoStore } = props;

    const {
      url,
      pip,
      playing,
      controls,
      light,
      loop,
      playbackRate,
      volume,
      muted,

      setPlayer,
      onReady,
      onProgress,
      onError,

      setRatio,
    } = videoStore;

    useEffect(() => {
      const elem: HTMLElement | null = document.getElementById(`VideoPlayerComponent_${id}`);
      if (elem) {
        const rect = elem.getBoundingClientRect();
        if (rect) {
          setRatio(rect.width / rect.height);
        }
      }
    }, [id, setRatio, size]);

    if (!url) {
      return null;
    }

    return (
      <Box id={`VideoPlayerComponent_${id}`}>
        <ReactPlayer
          ref={setPlayer}
          url={url}
          pip={pip}
          playing={playing}
          controls={controls}
          light={light}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onReady={onReady}
          onProgress={onProgress}
          onError={onError}
          width="100%"
          height="auto"
        />
      </Box>
    );
  }),
);

export const VideoPlayer: FC = observer(() => {
  const classes = useStyles();

  const { videoStore } = gAPP_STORE;
  const { showPlayer, loaded, played, isError, isLoading, ratio } = videoStore;

  if (!showPlayer) {
    return null;
  }

  const idResizableWindow = videoStore.soundStore.record?.id ? `${videoStore.soundStore.record.id}` : 'VideoPlayer';

  return (
    <DraggableResizableWindow id={idResizableWindow} ratioCoeff={ratio}>
      <Box flexGrow={1} position="relative" color="white">
        <VideoPlayerComponent id="RecordVideoPlayer" videoStore={videoStore} />

        {!isError && (
          <Box position="absolute" bottom={1} width="100%">
            <LinearProgress
              className={classes.linearProgress}
              variant="buffer"
              value={played * 100}
              valueBuffer={loaded * 100}
            />
          </Box>
        )}

        <Box
          style={{
            color: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {isError && <VideocamOff style={{ fontSize: '5em' }} />}
          {isLoading && <CircularProgress style={{ color: 'lightgrey' }} />}
        </Box>
      </Box>
    </DraggableResizableWindow>
  );
});
