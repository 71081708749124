import { makeAutoObservable, runInAction } from 'mobx';

// TODO: быстрый фикс: проверить
// import { IRecord } from '@uk/types/records';
type IRecord = any;

class SoundStore {
  record: IRecord | undefined = undefined;
  position = 0;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setRecord = (f: IRecord) => {
    runInAction(() => {
      this.record = f;
      this.position = 0;
    });
  };

  setPosition = (p: number) => {
    runInAction(() => {
      this.position = p;
    });
  };
}

export default new SoundStore();
