import { FC } from 'react';
import { observer } from 'mobx-react';

import { IIssue, IOwner } from '../types';

import { DeadlinePaper } from '@/components/summary/common/DeadlinePaper';
import { Timecodes } from '@/components/summary/Timecodes';
import { EditableTextArea } from '@/components/summary/EditableTextArea';

interface IIssueItem {
  item: IIssue;
  owner?: IOwner;
  idx: string;
  onUpdate: (item: IIssue, fields: string[], values: (string | number)[]) => void;
  isTranslate?: boolean;
  autotest: string;
}

export const IssueItem: FC<IIssueItem> = observer(({ item, owner, idx, onUpdate, isTranslate, autotest }) => {
  const handleEdit = (values: (string | number)[], fields: string[]) => {
    void onUpdate(item, fields, values);
  };

  return (
    <div
      data-autotest={autotest}
      key={idx}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        justifyContent: 'space-between',
        fontFamily: 'Inter, sans-serif',
      }}
    >
      <div
        key={`itemDecision-${idx}`}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '10px' }}>
          <div
            data-autotest={`${autotest}-title`}
            style={{ fontSize: '16px', fontFamily: 'Plus Jakarta Sans, sans-serif', fontWeight: 700 }}
          >
            <EditableTextArea
              id={'decision-title'}
              text={item.title}
              onEdit={value => handleEdit([value], ['title'])}
              placeholder="summary.enterTitle"
              emptyValue="--"
              maxLength={200}
              readOnly={isTranslate}
            />
          </div>
          <div
            data-autotest={`${autotest}-description`}
            style={{ fontSize: '14px', fontFamily: 'Inter, sans-serif', fontWeight: 400 }}
          >
            <EditableTextArea
              id={'decision-description'}
              text={item.description}
              onEdit={value => handleEdit([value], ['description'])}
              placeholder="summary.enterDescription"
              emptyValue="--"
              maxLength={1200}
              readOnly={isTranslate}
            />
          </div>
        </div>
        <Timecodes
          timecodesItem={item.timecodesItem}
          onShowOnPlayer={() => console.log('click on counter')}
          onClickTimeCode={t => console.log('click on time code: ', t)}
          autotest={autotest}
        />
      </div>
      {item.deadline && owner && (
        <DeadlinePaper
          id={`dlItem-${idx}`}
          owner={owner}
          deadline={item.deadline?.deadline}
          estimation={item.deadline?.estimation}
          onUpdate={(fields, values) => handleEdit(values, fields)}
          readOnly={isTranslate}
          autotest={autotest}
        />
      )}
    </div>
  );
});
