import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppBar, Button, makeStyles, Toolbar, Typography, Box, Tooltip } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react';

import { reaction } from 'mobx';

import AppLogo from './app-logo';

import { AppBarRecordsInfo } from './records-info-bar';

import { AppBarNotify } from './app-notify-bar';

import { LangSelector } from './app-lang-select';

import { gAPP_STORE } from '@/app/app-store';
import { useForceUpdate } from '@/react-ui-kit/src/common/hooks';

const pjson = require('./../../../package.json');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    height: '3em',
    minHeight: '3em',
    padding: '0.5em 1em',
  },
  noShadow: {
    boxShadow: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 0,
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing(2),
  },
  userIcon: {
    fontSize: '2em',
    cursor: 'pointer',
  },
}));

const AppToolbar = () => {
  const classes = useStyles();
  const history = useHistory();

  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { i18n } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    gAPP_STORE.sessionOff();
    history.push('/login');
  };

  useEffect(() => {
    return reaction(
      () => gAPP_STORE.APP_NAME,
      () => forceUpdate(),
    );
  }, [forceUpdate]);

  const toolbarButon = useCallback(
    (sPage: string, title: string) => {
      return (
        <Button
          className={classes.button}
          color="inherit"
          onClick={() => {
            history.push(sPage);
          }}
        >
          {t(title)}
        </Button>
      );
    },
    [classes.button, history, t],
  );

  const labelVersion = useMemo(() => {
    const version = gAPP_STORE.APP_VERSION !== '' ? gAPP_STORE.APP_VERSION : pjson.version;

    return (
      <Box pl={1} fontSize="0.6em">
        {version}
      </Box>
    );
  }, []);

  return (
    <AppBar position="sticky" className={classes.noShadow} style={{ top: 0, marginTop: 0, width: '100%' }}>
      <Toolbar className={classes.toolbar}>
        <AppLogo />

        <Box p={1} />

        {gAPP_STORE.loginStore.isSignedIn() && (
          <>
            {' '}
            {toolbarButon('/', 'main')}
            {gAPP_STORE.loginStore.user?.isAdmin &&
              (gAPP_STORE.avocado_topics || gAPP_STORE.avocado_wordsDictionary) &&
              toolbarButon('/wordDictionary', 'settings.wordDictionaryList')}
            {gAPP_STORE.loginStore.user?.isAdmin && toolbarButon('/settings', 'settings.title')}
            {i18n.language === 'ru' && gAPP_STORE.showDoc && toolbarButon('/userGuide', 'docs')}
            {gAPP_STORE.loginStore.user?.firstName === 'Admin' && toolbarButon('/locale', 'locale')}
          </>
        )}

        <Box className={classes.grow} />

        {gAPP_STORE.loginStore.isSignedIn() && (
          <>
            <AppBarNotify />
            <Box p={2} />
            <AppBarRecordsInfo />
            <Box p={2} />
            <Box pr={2}>
              <Typography>{gAPP_STORE.loginStore.user?.firstName}</Typography>
            </Box>
            <Tooltip title={t('logout') || ''}>
              <AccountCircleIcon className={classes.userIcon} onClick={handleLogout} />
            </Tooltip>
          </>
        )}

        <Tooltip
          title={t('changeLanguage') || ''}
          placement="left"
          enterDelay={500}
          enterNextDelay={1000}
          enterTouchDelay={7000}
          leaveTouchDelay={0}
          leaveDelay={0}
        >
          <div>
            <Button size="small" style={{ color: 'white' }} onClick={handleClick}>
              {i18n.language}
            </Button>
          </div>
        </Tooltip>
        <LangSelector anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        <Box p={1} />
        {labelVersion}
      </Toolbar>
    </AppBar>
  );
};

export default observer(AppToolbar);
