import { Panel } from '@uk';
import { Box, Button, Typography } from '@material-ui/core';
import React, { useCallback, useRef } from 'react';

import { Stack } from '../records/dialogs/UploadFiles/items/Stack';

import { ISubscription } from '@/services/events/entity-change-events-service';
import { gAPP_STORE } from '@/app/app-store';

const TestPage = () => {
  const subscription = useRef<{ s1: ISubscription | null; s2: ISubscription | null }>({ s1: null, s2: null });

  const onClick = useCallback(async () => {
    subscription.current.s1 = await gAPP_STORE.entityChangeEventService.subscribe(
      { action: 'add', type: 'com.indev.quatar.data.dto.repos.objects.RecordEntry' },
      e => {
        console.log('Event: ', e);
      },
    );
  }, []);

  const onClick2 = useCallback(async () => {
    subscription.current.s2 = await gAPP_STORE.entityChangeEventService.subscribe(
      { action: 'upd', type: 'com.indev.quatar.data.dto.repos.objects.KwsIndexEntry' },
      e => {
        console.log('Event2: ', e);
      },
    );
  }, []);

  const onClickOut = useCallback(async () => {
    subscription.current.s1 && (await subscription.current.s1.unsubscribe());
  }, []);

  const onClickOut2 = useCallback(async () => {
    subscription.current.s2 && (await subscription.current.s2.unsubscribe());
  }, []);

  return (
    <Box display="flex" flexGrow={1} flexDirection="column" style={{ background: '#ddd' }}>
      <Panel flexGrow={0} style={{ marginBottom: 0 }}>
        <Box p={2}>
          <Typography>Test page</Typography>
        </Box>
      </Panel>

      <Panel>
        <Stack spacing={2}>
          <Button variant="contained" onClick={onClick}>
            Subscribe
          </Button>
          <Button variant="contained" onClick={onClick2}>
            Subscribe2
          </Button>
          <Button variant="contained" onClick={onClickOut}>
            UnSubscribe
          </Button>
          <Button variant="contained" onClick={onClickOut2}>
            UnSubscribe2
          </Button>
        </Stack>
      </Panel>
    </Box>
  );
};

export default TestPage;
