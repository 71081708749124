import { BoxProps } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleBar from '@uk/components/titleBar/TitleBar';
import CommonDialogActions from './CommonDialogActions';
import { Panel } from '@uk/components/panel';
import ukColors from '@uk/ukColors';
import { ECommonDialogCloseStatus, ICommonDialogProps } from './types';
import { useObserver } from 'mobx-react';


export const CommonDialog: React.FC<ICommonDialogProps & BoxProps> = ({
  title,
  fullSize,
  contentComponent,
  bodyProps,
  bodyScroll,
  bodyBackground,
  open,
  onClose,
  okLabel,
  cancelLabel,
  noCancel,
  autotestId,
  fullheightWithoutScroll,
  topPanel2,
  commonDialogActions,
  uiStore,
  ...rest
}) => {
  const { t } = useTranslation();

  okLabel = okLabel ? okLabel : t('OK');
  cancelLabel = cancelLabel ? cancelLabel : t('cancel');

  const handleClick = (status: ECommonDialogCloseStatus) => {
    // setButtons(status);
    onClose && onClose(status);
  };
  const storeOpenFlag = useObserver(() => uiStore?.isOpened);

  return (
    <Dialog
      open={open !== undefined ? open : storeOpenFlag !== undefined ? storeOpenFlag : true}
      onClose={() => handleClick(0)}
      aria-labelledby="form-dialog-title"
      scroll={fullheightWithoutScroll ? "body" : undefined}
      PaperProps={{
        style: fullSize
          ? {
            minWidth: 'calc(100vw - 20px)',
            minHeight: 'calc(100vh - 20px)',
            backgroundColor: 'white',
          }
          : { maxWidth: 'none' },
      }}
      {...rest}
    >
      <TitleBar
        title={title}
        height="4.5em"
        autotestId={autotestId}
        borderBottom="1px solid lightGrey"
        bgcolor={ukColors.primary}
        color="white"
      />

      <Panel
        verticalScroll={bodyScroll}
        bgcolor={bodyBackground ? ukColors.mainBackground : undefined}
        maxHeight={1}
        // bgcolor={ukColors.mainBackground}
        p={2}
        {...bodyProps}>
        <Panel
          bgcolor="transparent" overflow={'auto'}
          pr={2} pl={2} pb={2} pt={topPanel2 ?? 2}
          maxHeight={fullheightWithoutScroll ? "100%" : 'calc(100vh * 0.7 )'}>
          {contentComponent}
        </Panel>
      </Panel>

      {commonDialogActions || (
        <CommonDialogActions
          uiStore={uiStore}
          okLabel={okLabel}
          cancelLabel={cancelLabel}
          noCancel={noCancel}
          autotestId={autotestId}
          onClose={onClose}
        />
      )}

      {/* <Box pt={1} pb={1} pr={2} borderTop="1px solid lightGrey" bgcolor={ukColors.lightLightGrey}>
        <DialogActions>
          <Button
            onClick={() => handleClick(ECommonDialogCloseStatus.OK)}
            variant="contained"
            color="primary"
            // disabled={okEnabled === false}
            data-autotest={'dlg-ok-btn-' + autotestId || ''}
          >
            {okLabel}
          </Button>

          {!noCancel && (
            <Button
              onClick={() => handleClick(ECommonDialogCloseStatus.CANCEL)}
              variant="contained"
              data-autotest={'dlg-cancel-btn-' + autotestId || ''}
            >
              {cancelLabel}
            </Button>
          )}
        </DialogActions>
      </Box> */}
    </Dialog>
  );
};


