import { IPersonCardDictor } from '../records/types';

import { IPersonCard } from './types';

import { IDictor } from '@/types/dictors';

export const gridCardToDictor = (card: IPersonCard, dictor: IDictor): IDictor => {
  return {
    ...dictor,
    avatar: card.Avatar?.StorageKey,
    defaultName: false,
    extId: card.ObjectId,
    extIdInstance: card.InstanceId,
    gender: card.Gender || 'undefined',
    modelRef: card.VoiceModelKey,
    name: card.FirstName,
    surname: card.LastName,
  };
};

export const gridCardToPersonCardDictor = (card: IPersonCard): IPersonCardDictor => {
  return {
    avatar: card.Avatar?.StorageKey,
    extId: card.ObjectId,
    extIdInstance: card.InstanceId,
    gender: card.Gender || 'undefined',
    modelRef: card.VoiceModelKey,
    name: card.FirstName,
    surname: card.LastName,
  };
};

export const personCardsToDictors = (personCards: IPersonCard[]): IPersonCardDictor[] => {
  const dictors: IPersonCardDictor[] = [];
  personCards.forEach(personCard => {
    dictors.push(gridCardToPersonCardDictor(personCard));
  });

  return dictors;
};
