import React, { forwardRef } from 'react';

interface IColumnResizerLine extends React.ForwardRefRenderFunction<HTMLDivElement, {}> {}

const ColumnResizerLine: IColumnResizerLine = (props, ref) => {
  return (
    <div
      style={{
        position: 'absolute',
        display: 'none',

        height: '100%',
        width: '1px',

        background: 'red',
        zIndex: 100,
      }}
      ref={ref}
    />
  );
};

export default forwardRef(ColumnResizerLine);
