import { action, computed, makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';

import {
  IHotlist,
  IPersonCardDictor,
  IPreprocessParams,
  IRecord,
  IRecordRedisFile,
  IRedisFile,
  IRegisterData,
  IShardFile,
  IUploadResult2,
} from '../../types';

import i18n from '@/i18n';
import { gAPP_STORE } from '@/app/app-store';
import { IGroup } from '@/components/groups';
import { IWordDictionary } from '@/components/wordDictionary/WordDictionary.types';
import { valueToWords } from '@/components/wordDictionary/wordDictionaryUtils';
import { arrayUniqueValues } from '@/common/utils';
import { BackendService } from '@/services/backend-service';
import { BackendError } from '@/services/types';
import { fileSizeToString } from '@/utils/fileSize';
import { createXHR } from '@/services/xhr-service';
import { EventLogService } from '@/components/eventLog/event-log-service';
import {
  DEFAULT_MAX_SPEAKER_COUNT,
  DEFAULT_SEGMANTATION_ALGORITHM,
  dialogUploadRecordsRestrictions,
  SHARDSIZE,
} from '@/common/constants';

const { MAX_VISIBLE_FILENAME_LENGTH } = dialogUploadRecordsRestrictions;

const ellipses = '…';

const cutFileName = (fileName: string, last?: string) => {
  const [name, ext] = fileName.split('.');

  return `${name.substring(0, MAX_VISIBLE_FILENAME_LENGTH - 3 - ext.length - (last?.length || 0))}${ellipses}.${ext} ${
    last || ''
  }`;
};

export class DialogStore implements IUploadResult2 {
  newGroups: IGroup[] = [];
  newWordDictionaryList: IWordDictionary[] = [];
  additionalWords = '';
  caseId = '';
  mettingTitle = '';
  multiLanguageRecognition = true;
  language = ''; // полное название языка либо 'auto'
  isSeparated = false;
  meetingParticipants?: number;
  maxMeetingParticipants = 0;
  maxHotlists = 0;
  participants: IPersonCardDictor[] = [];
  hotlists: IHotlist[] = [];
  redisFiles: IRedisFile[] = [];
  shardsUploading = 0;
  somewords: string[] = [];
  algorithm = '';

  constructor(initValues?: IUploadResult2) {
    this.setInitValues(initValues);

    makeAutoObservable(this);
  }

  @action setInitValues(result?: IUploadResult2) {
    this.setDefault();
    if (!result) return;
    if (result.maxMeetingParticipants) {
      this.maxMeetingParticipants = result.maxMeetingParticipants;
    }
    if (result.multiLanguageRecognition !== undefined) {
      this.multiLanguageRecognition = result.multiLanguageRecognition;
    }
    if (result.isSeparated !== undefined) {
      this.isSeparated = result.isSeparated;
    }
    if (result.language) {
      this.language = result.language;
    }
    if (result.caseId) {
      this.caseId = result.caseId;
    }
    if (result.mettingTitle) {
      this.mettingTitle = result.mettingTitle;
    }
    if (result.algorithm) {
      this.algorithm = result.algorithm;
    }
    if (result.somewords && result.somewords.length > 0) {
      this.somewords = result.somewords;
    }
    if (result.newGroups && result.newGroups.length > 0) {
      this.newGroups = result.newGroups;
    }
    if (result.newWordDictionaryList && result.newWordDictionaryList.length > 0) {
      this.newWordDictionaryList = result.newWordDictionaryList;
    }
    if (result.participants && result.participants.length > 0) {
      this.participants = result.participants;
    }
    if (result.hotlists && result.hotlists.length > 0) {
      this.hotlists = result.hotlists;
    }
    if (result.redisFiles && result.redisFiles.length > 0) {
      this.redisFiles = result.redisFiles;
    }
  }

  private getRedistFilesForUpload() {
    return this.redisFiles.filter(rf => rf.redisIds && !(rf.error || rf.deleted));
  }

  saveToUploadResult(): IUploadResult2 {
    const redisFiles = this.getRedistFilesForUpload();

    return {
      maxMeetingParticipants: this.maxMeetingParticipants,
      multiLanguageRecognition: this.multiLanguageRecognition,
      isSeparated: this.isSeparated,
      language: this.language,
      caseId: this.caseId,
      mettingTitle: this.mettingTitle,
      algorithm: this.algorithm,
      somewords: this.somewords,
      redisFiles,
      newGroups: this.newGroups,
      newWordDictionaryList: this.newWordDictionaryList,
      participants: this.participants,
      hotlists: this.hotlists,
    };
  }

  private setDefault() {
    this.language = gAPP_STORE.autoLanguageDetection ? 'auto' : '';
    this.newGroups =
      gAPP_STORE.loginStore.user && gAPP_STORE.loginStore.user.groups ? [...gAPP_STORE.loginStore.user.groups] : [];
    this.caseId = '';
    this.additionalWords = '';
    this.somewords = [];
    this.newWordDictionaryList = [];
    this.participants = [];
    this.hotlists = [];
    this.redisFiles = [];
    this.multiLanguageRecognition = false;
    this.maxMeetingParticipants = gAPP_STORE.maxSpeakerCount;
    this.maxHotlists = gAPP_STORE.maxHotlistCount;
    this.meetingParticipants = undefined;
    this.isSeparated = true;
    this.algorithm = gAPP_STORE.availableAlgorithms[0];
  }

  @action setSeparated(value: boolean) {
    this.isSeparated = value;
  }

  @action setAlgorithm(value: string) {
    this.algorithm = value;
  }

  @action setCaseId(value: string) {
    this.caseId = value.trim();
  }

  @action setMettingTitle(value: string) {
    this.mettingTitle = value;
  }

  @action setGroups(value: IGroup[]) {
    this.newGroups = value;
  }

  @action clearPersonCards() {
    this.participants = [];
  }

  @action setLanuage(value: string) {
    this.newWordDictionaryList = [];
    this.language = value;
  }

  @action setAutoSpeakerDetection(value: boolean) {
    this.isSeparated = value;
  }

  @action setMultiLanguageRecognition(value: boolean) {
    this.multiLanguageRecognition = value;
  }

  @action setWordDictionaryList(value: IWordDictionary[]) {
    this.newWordDictionaryList = value;
  }

  @action addWord(value: string) {
    if (value === '') return;
    this.somewords = [...this.somewords, value];
  }

  @action excludeWord(index: number) {
    this.somewords = this.somewords.filter((f, i) => i !== index);
  }

  @action changeWords(value: string[]) {
    this.somewords = [...value];
  }

  @action clearWords() {
    this.somewords = [];
  }

  private uploadOneShard(rf: IRedisFile) {
    if (rf.shards === undefined) return;
    this.shardsUploading++;
    const shardIndex = rf.shards.totalShards - rf.shards.waitingShards;
    rf.shards.waitingShards--;
    const start = shardIndex * SHARDSIZE;
    const end = Math.min(rf.shards.file.size, start + SHARDSIZE);
    const shard = rf.shards.file.slice(start, end);
    const file = new File([shard], `${rf.shards.file.name}(${shardIndex + 1}/${rf.shards.totalShards})`);

    const xhr = createXHR(file, (xhr: XMLHttpRequest) => (ev: ProgressEvent<EventTarget>) => {
      const abortAllShardsForFile = () => {
        if (!rf.shards) return;
        this.shardsUploading--;
        rf.shards.xhrs[shardIndex] = undefined;
        rf.shards?.xhrs.forEach(x => {
          if (!x) return;
          x.abort();
          this.shardsUploading--;
        });
        rf.shards = undefined;
        rf.redisIds = [];
      };
      switch (ev.type) {
        case 'abort':
          rf.deleted = true;
          abortAllShardsForFile();
          break;
        case 'error':
          if (!rf.deleted) rf.error = true;
          abortAllShardsForFile();
          break;
        case 'loadend':
          const status = xhr.status;
          if (status === 200 && rf.shards) {
            // The request has been completed successfully
            this.shardsUploading--;
            rf.shards.redisIds[shardIndex] = xhr.responseText;
            rf.shards.xhrs[shardIndex] = undefined;
            if (rf.shards.redisIds.findIndex(id => id === '') < 0) {
              // успешно закачены в редис все части файла
              rf.redisIds = rf.shards.redisIds;
              rf.shards = undefined;
            }
            this.beginRedisUpload();
          } else {
            if (!rf.deleted) {
              // Oh no! There has been an error with the request!
              rf.error = true;
              console.log(`upload file ${rf.name} to redis: (${status}) `, xhr.responseText);
            }
            abortAllShardsForFile();
          }
          break;
        case 'progress':
          if (rf.shards === undefined) return;
          rf.shards.byteLoaded[shardIndex] = ev.loaded;
          const loaded = rf.shards.byteLoaded.reduce((partialSum: number, loaded: number) => partialSum + loaded, 0);
          rf.loading = Math.floor(((loaded ?? 0) * 100) / rf.size);
          break;
        default:
      }
    });
    rf.shards.xhrs[shardIndex] = xhr;
  }

  private beginRedisUpload() {
    // для ограничения количество потоков upload-а
    // if (this.shardsUploading >= MAXsimultaneousSHARDS) return;
    // const fileUpload = this.redisFiles.find(rf => rf.shards && rf.shards.waitingShards > 0);
    // if (fileUpload === undefined) return;
    // this.uploadOneShard(fileUpload);
    // this.beginRedisUpload();
    this.redisFiles.forEach(rf => {
      if (rf.shards && rf.shards.waitingShards > 0) {
        for (let i = rf.shards.waitingShards; i > 0; i--) {
          this.uploadOneShard(rf);
        }
      }
    });
  }

  private makeShards(index: number, file: File) {
    const shardCount = file.size <= SHARDSIZE ? 1 : Math.ceil(file.size / SHARDSIZE); //Total number
    const shards: IShardFile = {
      file,
      indexRedisFile: index,
      redisIds: Array.from({ length: shardCount }, (_, i) => ''),
      totalShards: shardCount,
      waitingShards: shardCount,
      byteLoaded: Array.from({ length: shardCount }, (_, i) => 0),
      xhrs: Array.from({ length: shardCount }, (_, i) => undefined),
    };
    this.redisFiles[index].shards = shards;
  }

  @computed get haveFilesWithError() {
    const filesWithError = this.redisFiles.filter(rf => rf.error && !rf.deleted);

    return filesWithError.length > 0;
  }

  @action addFiles(values: File[]) {
    values.forEach(file => {
      const redisFile: IRedisFile = {
        name: file.name,
        redisIds: [],
        creationDate: new Date(file.lastModified).toISOString(),
        loading: 0,
        size: file.size,
        error: file.size === 0 || file.size > gAPP_STORE.maxFileSize,
        deleted: false,
      };
      this.redisFiles = [...this.redisFiles, redisFile];
      if (redisFile.error) return;
      this.makeShards(this.redisFiles.length - 1, file);
      this.beginRedisUpload();
    });
  }

  @action excludeFile(index: number) {
    this.redisFiles[index].deleted = true;
    this.redisFiles[index].error = false;
    this.redisFiles[index].shards?.xhrs.forEach(x => {
      if (!x) return;
      x.abort();
      this.shardsUploading--;
    });
    this.redisFiles[index].shards = undefined;
    this.redisFiles[index].redisIds = [];
  }

  @action deleteAllFile() {
    this.redisFiles.forEach(rf => rf.shards?.xhrs.forEach(x => x?.abort()));
    this.shardsUploading = 0;
    this.redisFiles = [];
  }

  @computed get fileNames() {
    //colors: [ ready, loading, error ]
    const colors = [0, 1, 2];
    const indexes: number[] = [];
    this.redisFiles.forEach((f, i) => {
      if (!f.deleted) indexes.push(i);
    });

    return indexes.map(fileIndex => {
      const f = this.redisFiles[fileIndex];
      const color = f.redisIds.length > 0 ? colors[0] : f.error ? colors[2] : colors[1];
      if (f.size === 0) {
        return {
          name: f.name.length <= MAX_VISIBLE_FILENAME_LENGTH ? f.name : cutFileName(f.name),
          color,
          fileIndex,
        };
      }
      const z = f.error ? '' : f.redisIds.length === 0 ? `${f.loading}%` : fileSizeToString(f.size);

      return {
        name:
          f.name.length + z.length <= MAX_VISIBLE_FILENAME_LENGTH
            ? `${f.name.substring(0, MAX_VISIBLE_FILENAME_LENGTH - z.length)} ${z}`
            : cutFileName(f.name, z),
        color,
        fileIndex,
      };
    });
  }

  @action excludeHotlist(index: number) {
    this.hotlists = this.hotlists.filter((f, i) => i !== index);
  }

  @computed get participantNames() {
    return this.participants.map(p => (p.surname ? `${p.surname} ${p.name}` : p.name));
  }

  @action excludeParticipant(index: number) {
    this.participants = this.participants.filter((f, i) => i !== index);
    this.meetingParticipants = this.participants.length;
  }

  @action addParticipants(values: IPersonCardDictor[]) {
    const newest = values.filter(dictor => !this.participants.findIndex(p => p.extId === dictor.extId));
    if (newest.length === 0) return;
    this.participants = [...this.participants, ...newest];
    this.meetingParticipants = this.participants.length;
  }

  @computed get isFileCountExceedLimit() {
    const goodFiles = this.redisFiles.filter(rf => !(rf.deleted || rf.error));

    return goodFiles.length > gAPP_STORE.maxFilesToUpload;
  }

  @computed get isAllFileLoaded() {
    const loadingFiles = this.redisFiles.filter(rf => !(rf.deleted || rf.error) && rf.redisIds.length === 0);

    return loadingFiles.length === 0;
  }

  @computed get isFilesOk() {
    return (
      this.isAllFileLoaded &&
      this.redisFiles.filter(rf => rf.error).length === 0 &&
      this.redisFiles.filter(rf => !rf.deleted).length > 0
    );
  }

  @computed ready() {
    return this.caseId.trim() !== '' && this.isFilesOk;
  }

  private getAllWords(): string[] {
    const result: string[] = [...this.somewords];
    this.newWordDictionaryList.forEach(wd => result.push(...valueToWords(wd.value)));

    return arrayUniqueValues(result);
  }

  private getLanguageStrict(): string | undefined {
    if (this.multiLanguageRecognition) return 'multi';
    else if (this.language === 'auto') return undefined;
    else return this.language.toLowerCase();
  }

  private getPreprocessParams(): IPreprocessParams | undefined {
    if (!this.isSeparated) {
      //AutoSpeakerDetection
      return {
        mono_params: { max_dictors: 1, min_dictors: 1, segmentation_type: DEFAULT_SEGMANTATION_ALGORITHM },
        stereo_params: { max_dictors: 1, min_dictors: 1, segmentation_type: DEFAULT_SEGMANTATION_ALGORITHM },
        no_speaker_diarization: !this.isSeparated,
      };
    }
    if (!(gAPP_STORE.gridProxyUrl && gAPP_STORE.gridProxyUrl.length > 0)) return undefined; //use settings config on backend

    /**
     * Если количесиво участников встречи указано, то max_dictors и min_dictors устанавливаем в это значение.
     * Если количество не указано, то min_dictors=1, max_dictors=5 (знчение макс дикторз из настроек)
     */
    const maxDictors =
      this.maxMeetingParticipants === 0
        ? DEFAULT_MAX_SPEAKER_COUNT
        : this.participants.length > 0 && this.participants.length < this.maxMeetingParticipants
        ? this.participants.length
        : this.maxMeetingParticipants;

    const minDictors =
      this.participants.length === 0
        ? 1
        : this.participants.length > 0 && this.participants.length < this.maxMeetingParticipants
        ? this.participants.length
        : this.maxMeetingParticipants;

    return {
      mono_params: {
        max_dictors: maxDictors,
        min_dictors: minDictors,
        segmentation_type: this.algorithm,
      },
      stereo_params: {
        max_dictors: maxDictors,
        min_dictors: minDictors,
        segmentation_type: this.algorithm,
      },
      no_speaker_diarization: !this.isSeparated,
    };
  }

  private prepareDescription() {
    const additionalWords = this.getAllWords();
    const languageStrict = this.getLanguageStrict();
    const preprocessParams = this.getPreprocessParams();

    return { additionalWords, languageStrict, preprocessParams };
  }

  private async tieRecordWithRedisFile(recordId: number, redisIds: string[]): Promise<void> {
    if (redisIds.length === 1) {
      await BackendService.put(`file/redis/upload/${recordId}?key=${redisIds[0]}`, undefined, true);
    } else {
      const body = JSON.stringify(redisIds);
      await BackendService.put(`file/redis/multi/upload/${recordId}`, body, false); //для application/json'
    }
  }

  private async fileRegister(file: IRedisFile, registerData: IRegisterData): Promise<IRecordRedisFile> {
    const record: IRecord = await BackendService.post('file/register', JSON.stringify(registerData));
    await this.tieRecordWithRedisFile(record.id, file.redisIds);

    return { record, file };
  }

  private prepareRegisteringFileData(maxFilesCount: number): Promise<IRecordRedisFile>[] {
    const { additionalWords, languageStrict, preprocessParams } = this.prepareDescription();

    const fileToUpload = this.redisFiles
      .filter(f => !f.shards && !f.deleted && !f.error)
      .slice(0, gAPP_STORE.maxFilesToUpload);

    if (maxFilesCount <= 0 || fileToUpload.length > maxFilesCount) {
      EventLogService.informUploadFilesLimitReached();
      toast.error(`${i18n.t('uploadForm.balanceOfDailyLimit')} ${maxFilesCount > 0 ? maxFilesCount : 0}`);
      // eslint-disable-next-line no-throw-literal
      throw 'fileToUpload.length>maxFilesCount';
    }

    return fileToUpload.map(file =>
      this.fileRegister(file, {
        caseId: this.caseId,
        creationDate: file.creationDate,
        additionalWords,
        languageStrict,
        preprocessParams,
        fileName: file.name,
        dictors: this.participants,
        hotlists: JSON.stringify(this.hotlists),
      }),
    );
  }

  private showError(errorInfo: BackendError) {
    const description = `${gAPP_STORE.loginStore.user?.isAdmin ? 'admin_' : ''}${
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (errorInfo.errorInfo as any).description
    }`;

    toast.error(gAPP_STORE.translationStore.translate(description), { style: { fontSize: '1.2rem' } });
  }

  private async addGroupsOnFile(recordId: number, groupIds: number[]): Promise<void> {
    // add record groups
    await gAPP_STORE.getGroupsStore().addGroupsToRecord(recordId, groupIds);
  }

  private prepareUploadRecords(x: PromiseSettledResult<IRecordRedisFile>[]): Promise<void>[] {
    const results: Promise<void>[] = [];
    const groupIds = this.newGroups.map(g => g.id);
    x.forEach(v => {
      if (v.status === 'rejected') this.showError(v.reason as BackendError);
      else {
        const rrf = v.value as IRecordRedisFile;
        //results.push(this.tieRecordWithRedisFile(rrf.record.id, rrf.file.redisIds));
        if (groupIds.length > 0) {
          results.push(this.addGroupsOnFile(rrf.record.id, groupIds));
        }
      }
    });

    return results;
  }

  async toServerViaRedis() {
    const countLeftDailyUpload = (await BackendService.get('file/daily-upload-count-left')) ?? -1;

    try {
      const registerRequests = this.prepareRegisteringFileData(countLeftDailyUpload);

      const registerResults = await Promise.allSettled(registerRequests);

      await this.fixBug8635(); //http://redmine.indev/issues/8635 //FIXIT: codestyle error

      const uploadRequests: Promise<void>[] = this.prepareUploadRecords(registerResults);
      await Promise.allSettled(uploadRequests);
    } catch {
      return false;
    }

    return true;
  }

  private async fixBug8635() {
    const recordsStore = gAPP_STORE.getRecordsStore();
    //await recordsStore.getStatsData();
    await recordsStore.reload();
  }
}
