export enum EntityChangeEventTypes {
  RECORD = 'com.indev.quatar.data.dto.repos.objects.RecordEntry',
  KWS_INDEX = 'com.indev.quatar.data.dto.repos.objects.KwsIndexEntry',
  GROUP_TO_RECORD = 'com.indev.quatar.data.dto.repos.objects.GroupToRecordEntry',
  STORAGE_ENTRY = 'com.indev.quatar.data.dto.repos.objects.StorageEntry',
  RECORDS_STATS = 'com.indev.quatar.data.dto.repos.objects.RecordStatisticEntry',
  LABEL_FULL = 'com.indev.quatar.data.dto.repos.objects.LabelEntryWithMetadata',
  LABEL_SHORT = 'com.indev.quatar.data.dto.repos.objects.LabelEntry',
  LABEL_TYPE = 'com.indev.quatar.data.dto.repos.objects.LabelTypeEntry',
}

export enum EntityChangeEventActions {
  CREATE = 'add',
  UPDATE = 'upd',
  DELETE = 'del',
}
