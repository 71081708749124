import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Box, Chip, FormControl, TextField } from '@material-ui/core';

import { Alert, Autocomplete } from '@material-ui/lab';

import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus, useForceUpdate } from '@uk';

import appColors from 'app/app-colors';

import { gAPP_STORE } from 'app/app-store';
import { dialogRecordsGroups } from 'common/constants';
import { IGroup } from 'components/groups';

import { IRecord } from './types';

export interface IChangeGroupsDialogResult {
  records: IRecord[];
  oldRecordsGroups: IGroup[];
  newRecordsGroups: IGroup[];
  closeStatus: ECommonDialogCloseStatus;
}

interface IRecordDialogProps {
  open: boolean;
  dialogResult: IChangeGroupsDialogResult;
  onClose: (dialogResult: IChangeGroupsDialogResult) => void;
}

const DialogChangeRecordGroups: React.FC<IRecordDialogProps> = ({ open, dialogResult, onClose }) => {
  const { t } = useTranslation();
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());

  const forceUpdate = useForceUpdate();

  const handleClose = (status: number) => {
    dialogResult.closeStatus = status;

    if (onClose) {
      onClose(dialogResult);
    }
  };

  const handleChangeGroups = (event: React.ChangeEvent<unknown>, newValue: IGroup[]) => {
    if (newValue.length > dialogRecordsGroups.MAX_GROUPS_COUNT) {
      toast.warn(t('records.maxGroups', { max_groups: dialogRecordsGroups.MAX_GROUPS_COUNT }));
    }
    dialogResult.newRecordsGroups = newValue.slice(0, dialogRecordsGroups.MAX_GROUPS_COUNT);
    forceUpdate();
  };

  const loggedUser = gAPP_STORE.loginStore.user;

  const hasUserGroups =
    loggedUser &&
    ((dialogResult.newRecordsGroups || []).some(g => (loggedUser.groups || []).map(gr => gr.id).includes(g.id)) ||
      (dialogResult.newRecordsGroups || []).length === 0);

  const DialogBody = () => {
    return (
      <Box width={600}>
        <Box p={1} />

        {/* Groups */}
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            clearText={t('uploadForm.clear')}
            noOptionsText={t('uploadForm.noGroups')}
            multiple
            options={gAPP_STORE.getGroupsStore().getCurrentGroups()}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            value={dialogResult.newRecordsGroups || []}
            filterSelectedOptions
            renderInput={params => <TextField {...params} label={t('users.groups')} variant="outlined" />}
            renderTags={(value, getTagProps) =>
              value.map((group, index) => {
                const isCurrentUserGroup =
                  gAPP_STORE.loginStore.user &&
                  (gAPP_STORE.loginStore.user.groups || []).map(g => g.id).includes(group.id);

                return (
                  <Chip
                    color="primary"
                    // size="small"
                    label={group.name}
                    {...getTagProps({ index })}
                    style={{ backgroundColor: isCurrentUserGroup ? appColors.primary : appColors.red }}
                  />
                );
              })
            }
            renderOption={(group: IGroup, props) => {
              const isCurrentUserGroup =
                gAPP_STORE.loginStore.user &&
                (gAPP_STORE.loginStore.user.groups || []).map(g => g.id).includes(group.id);

              return (
                <span {...props} style={{ color: isCurrentUserGroup ? appColors.primary : appColors.red }}>
                  {group.name}
                </span>
              );
            }}
            onChange={handleChangeGroups}
          />
        </FormControl>

        <Box p={1} />
        {!hasUserGroups && (
          <Alert severity="error" style={{ fontSize: '1em' }}>
            {t('records.noUserGroupsWarning')}
          </Alert>
        )}
        <Box p={2} />
      </Box>
    );
  };

  return (
    <CommonDialog
      autotestId={'records'}
      title={'recordGroups'}
      contentComponent={DialogBody}
      open={open}
      onClose={handleClose}
      uiStore={userDlgUiStoreRef.current}
    />
  );
};

export default observer(DialogChangeRecordGroups);
