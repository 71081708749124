import React, { FC, useState, MutableRefObject, useEffect } from 'react';
import ColumnResizerStore from './ColumnResizer.store';
import { IColumnLayout } from './DataGridSettings';

const styles = {
  columnResizer: {
    // position: 'absolute',
    // boxSizing: 'border-box',
    // backgroundClip: 'padding-box',

    height: 'calc(100% - 12px)',
    width: 11,
    right: -4,
    top: 6,
    background: 'lightGrey',

    borderLeft: '5px solid rgba(255,255,255,0)',
    borderRight: '5px solid rgba(255,255,255,0)',
    margin: '0 0',
    cursor: 'col-resize',
  },
};

interface IColumnResizerProps {
  colLayout: IColumnLayout;
  rootRef: MutableRefObject<HTMLDivElement | null>;
  columnResizeLineRef: MutableRefObject<HTMLDivElement | null>;
  updateLayout: () => void;
}

const ColumnResizer: FC<IColumnResizerProps> = ({ colLayout, rootRef, columnResizeLineRef, updateLayout }) => {
  const [store, setStore] = useState<ColumnResizerStore | undefined>();

  useEffect(() => {
    setStore(new ColumnResizerStore(colLayout, rootRef, columnResizeLineRef, updateLayout));
  }, [colLayout, rootRef, columnResizeLineRef, updateLayout]);

  return (
    <div
      style={{ ...styles.columnResizer, position: 'absolute', boxSizing: 'border-box', backgroundClip: 'padding-box' }}
      onMouseDown={event => store && store.startResizeColumn(event, colLayout)}
    />
  );
};

export default ColumnResizer;
