import { backendApiUrl } from './constants';

export type ThandleXHR = (xhr: XMLHttpRequest) => (ev: ProgressEvent<EventTarget>) => void;
export function createXHR (file: File, handle: ThandleXHR): XMLHttpRequest {
  const metod = 'POST';
  const url = `${backendApiUrl}/redis/file/set`;
  const xhr = new XMLHttpRequest();

  try {
    xhr.onloadend = handle(xhr); // loadend | xhr.status===200 | xhr.response
    xhr.upload.onprogress = handle(xhr); //progress
    xhr.upload.onerror = handle(xhr);
    xhr.onerror = handle(xhr);
    xhr.onabort = handle(xhr);

    xhr.open(metod, url);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('accept', 'application/json;charset=UTF-8');
    xhr.setRequestHeader('Content-Type', 'application/octet-stream');
    xhr.send(file);
  } catch (e) {
    console.error('createXHR::', e);
  }

  return xhr;
}
