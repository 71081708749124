import { makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { ISplitPaneExProps } from '.';
import './SplitPaneEx.css';


const useStyles = makeStyles({
  splitPane: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'clip',
  },
});


const SplitPaneEx: React.FC<ISplitPaneExProps> = ({ id, split, notSavePosition, panes }) => {
  const classes = useStyles();

  const offsetIndex: number = useMemo(() => {
    let oi = 0;
    for (const pane of panes) {
      if (!pane.hidden) break;
      oi++;
    }

    return oi;
  }, [panes]);

  /**
   * handleResizeEnd
   * @param newSizes
   */
  const handleResizeEnd = useCallback((newSizes: string[]): any => {
    console.log('handleResizeEnd(), newSize = ', newSizes);

    newSizes.forEach((s, index) => {
      const paneIndex = index + offsetIndex;
      const pane = panes[paneIndex];

      console.log('handleResizeEnd(), pane?.size = ', index, pane?.size);
      const paneSize = pane?.size || 0;

      if (paneSize > 0) {
        let pos = s;
        if (pos.includes('px')) {
          pos = (s.match(/(\d+)/g) || '0')[0];
          if (!notSavePosition) {
            localStorage.setItem(`SplitPaneEx.${id}.SplitPane.${paneIndex}.position`, pos);
          }
        }
      }
    });
  }, [id, notSavePosition, offsetIndex, panes]);

  /**
   * getInitialSize
   * @param index
   * @returns
   */
  const getInitialSize = useCallback((index: number) => {
    const initSize = panes[index].size || 0;
    const savedSize = notSavePosition ? undefined : localStorage.getItem(`SplitPaneEx.${id}.SplitPane.${index}.position`);
    const sizeStr = savedSize ? `${savedSize}px` : initSize > 0 ? `${initSize}px` : undefined;
    return sizeStr;
  }, [id, notSavePosition, panes]);

  const orientation = split || 'vertical';

  return (
    <SplitPane
      split={orientation}
      // @ts-ignore
      onResizeEnd={handleResizeEnd}
      style={{ flexGrow: 1 }}
      resizerClassName="resizer"
    >
      {panes.map((pane, paneIndex) => {
        if (pane.hidden) return undefined;

        const minSize = pane.minSize ? `${pane.minSize}px` : undefined;

        return (
          <Pane key={paneIndex} className={classes.splitPane} initialSize={getInitialSize(paneIndex)} minSize={minSize}>
            {pane.pane}
          </Pane>
        );
      })}
    </SplitPane>
  );
};
export default SplitPaneEx;
