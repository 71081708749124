import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus, useForceUpdate } from '@uk';

import { CWordDictionary, IWordDictionary } from './WordDictionary.types';
import { getWordDictionaryTypeValue, valueToWordsStr, wordsStrToValue } from './wordDictionaryUtils';

import { gAPP_STORE } from '@/app/app-store';
import { topicsRestrictions } from '@/common/constants';

const { MAX_TOPIC_NAME, MAX_WORD_LENGTH, MAX_WORDS_COUNT } = topicsRestrictions;

export interface IWordDictionaryDialogResult {
  wordDictionary: IWordDictionary;
  oldValue?: IWordDictionary;
  closeStatus: ECommonDialogCloseStatus;
}

const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: 5,
  },
}));


const useLangList = (useFor: string): string[] => {

  const tsLangs = gAPP_STORE.getLanguagesStore().data.languages.map(l => l.name);

  const commonArray: string[] = [];
  tsLangs.forEach(tsl => {
    const langISO = gAPP_STORE.tServiceLangNameToISO(tsl);
    if (langISO) {
      commonArray.push(langISO.code);
    } else {
      console.error(`Unsupported language from T-Service: ${langISO}`);
    }
  });

  if (gAPP_STORE.viewTranslate && useFor === 'kws') {
    const autoTranslateLangs = gAPP_STORE.getAutoTranslateLanguagesStore().autoTranslateLanguages ?? [];
    commonArray.push(...autoTranslateLangs);
  }

  return Array.from((new Set(commonArray)));
};

export interface IWordDictionaryProps {
  open: boolean;
  wordDictionary: IWordDictionary | undefined;
  onClose: (dialogResult: IWordDictionaryDialogResult) => void;
}

export const WordDictionaryDialog: React.FC<IWordDictionaryProps> = ({ open, wordDictionary, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { t: lang } = useTranslation('lang');
  const forceUpdate = useForceUpdate();
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());
  const [nameError, setNameError] = useState(false);
  const [wordsError, setWordsError] = useState(false);
  const [words, setWords] = useState('');

  const isError = useMemo(() => {

    return nameError || wordsError;
  }, [nameError, wordsError]);

  userDlgUiStoreRef.current.setOkEnabled(!isError);

  const dialogResult: IWordDictionaryDialogResult = useMemo(
    () => {
      const res: IWordDictionaryDialogResult = {
        wordDictionary: wordDictionary ? { ...wordDictionary } : new CWordDictionary(),
        oldValue: wordDictionary,
        closeStatus: 0
      };

      if (!gAPP_STORE.avocado_topics || !gAPP_STORE.avocado_wordsDictionary) {
        res.wordDictionary.isAutoAsr = gAPP_STORE.avocado_wordsDictionary;
        res.wordDictionary.isKws = gAPP_STORE.avocado_topics;
      }

      return res;
    },
    // eslint-disable-next-line
    [wordDictionary, open],
  );

  const [useFor, setUseFor] = useState<string>(getWordDictionaryTypeValue(dialogResult.wordDictionary));
  const langList = useLangList(useFor);

  useEffect(() => {
    setUseFor(getWordDictionaryTypeValue(dialogResult.wordDictionary));
  }, [dialogResult.wordDictionary]);

  const checkNameError = (name: string): boolean => {
    const hasError = name.length > MAX_TOPIC_NAME || name.length < 1;

    setNameError(hasError);

    return hasError;
  };

  const checkWordsError = (name: string): boolean => {
    const hasError = name.length < 1;

    setWordsError(hasError);

    return hasError;
  };

  const handleClose = useCallback((status: number) => {
    dialogResult.closeStatus = status;
    switch (useFor) {
      case 'auto':
        dialogResult.wordDictionary.isAutoAsr = true;
        dialogResult.wordDictionary.isKws = false;
        break;
      case 'kws':
        dialogResult.wordDictionary.isAutoAsr = false;
        dialogResult.wordDictionary.isKws = true;
        break;
      case 'both':
        dialogResult.wordDictionary.isAutoAsr = true;
        dialogResult.wordDictionary.isKws = true;
        break;
    }

    if (status === ECommonDialogCloseStatus.CANCEL || status === ECommonDialogCloseStatus.OK && !isError) {
      dialogResult.closeStatus = status;

      if (status === ECommonDialogCloseStatus.CANCEL) {
        setNameError(false);
        setWordsError(false);
        setWords('');
      } else {
        dialogResult.wordDictionary.name = dialogResult.wordDictionary.name.trim();
        dialogResult.wordDictionary.value = dialogResult.wordDictionary.value.trim();

        const hasError =
          checkNameError(dialogResult.wordDictionary.name) ||
          checkWordsError(dialogResult.wordDictionary.value);

        if (hasError) return;
      }

      if (onClose) {
        setWords('');
        onClose(dialogResult);
      }
    }
  }, [dialogResult, onClose, useFor, isError]);

  const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const name: string = event.target.value;
    checkNameError(name.trim());

    dialogResult.wordDictionary.name = name;
  }, [dialogResult.wordDictionary]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLanguageChange = useCallback((event: any) => {
    dialogResult.wordDictionary.language = event.target.value;
    forceUpdate();
  }, [dialogResult.wordDictionary, forceUpdate]);

  const handleValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const inputWords = event.target.value.trimStart();
    checkWordsError(event.target.value.trim());

    const limitedText = inputWords
      .split(/\s+/)
      .slice(0, MAX_WORDS_COUNT)
      .map(word => word.slice(0, MAX_WORD_LENGTH))
      .join(' '); // Ограничиваем каждое слово до 100 символов
    // const words = inputWords.split(/,+/).slice(0, MAX_WORDS_COUNT); // Ограничиваем до 50 слов
    // const limitedText = words.map(word => word.trim().slice(0, MAX_WORD_LENGTH)).join(SEPARATOR); // Ограничиваем каждое слово до 100 символов
    setWords(limitedText);

    dialogResult.wordDictionary.value = wordsStrToValue(limitedText);
  }, [dialogResult.wordDictionary]);

  const DialogBody = useCallback(() => {

    return (
      <Box width={800}>
        <Box display="flex">
          <Box flexGrow={1}>
            {/* Name */}
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                key="name"
                label={t('wordDictionary.name')}
                variant="outlined"
                fullWidth
                defaultValue={dialogResult.wordDictionary.name}
                onChange={handleNameChange}
                autoComplete="off"
                inputProps={{ maxLength: 40 }}
                error={nameError}
                helperText={nameError && t('users.lengthError')}
              />
            </FormControl>

            {/* Language */}
            <Box p={2} />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-language-label">{t('language')}</InputLabel>
              <Select
                labelId="select-language-label"
                id="select-language"
                value={dialogResult.wordDictionary.language}
                onChange={handleLanguageChange}
                label={t('language')}
              >
                {langList
                  .map((code, index) => (
                    <MenuItem key={index} value={code}>
                      {lang(code)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>


          {gAPP_STORE.avocado_wordsDictionary && gAPP_STORE.avocado_topics && <>
            <Box p={2} />
            <FormControl component="fieldset">
              <RadioGroup
                value={useFor}
                onChange={event => {
                  setUseFor(event.target.value);
                }}
              >
                <FormControlLabel
                  value="auto"
                  control={<Radio color="primary" />}
                  label={t('wordDictionary.isAutoAsr')}
                />
                <FormControlLabel value="kws" control={<Radio color="primary" />} label={t('wordDictionary.isKws')} />
                <FormControlLabel
                  value="both"
                  control={<Radio color="primary" />}
                  label={t('wordDictionary.isAutoAsrAndKws')}
                />
              </RadioGroup>
            </FormControl>
          </>}
        </Box>

        <Box p={1} />

        {/* Words */}
        <Box p={1} />
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <TextField
            key="description"
            label={t('wordDictionary.value')}
            variant="outlined"
            fullWidth
            multiline
            rows={20}
            value={words || valueToWordsStr(dialogResult.wordDictionary.value)}
            onChange={handleValueChange}
            onBlur={() => forceUpdate()}
            helperText={wordsError && t('users.lengthError')}
            error={wordsError}
            autoComplete="off"
          />
        </FormControl>
      </Box>
    );
  }, [classes.formControl,
    dialogResult.wordDictionary.language,
    dialogResult.wordDictionary.name,
    dialogResult.wordDictionary.value,
    forceUpdate, handleLanguageChange,
    handleNameChange, handleValueChange,
    nameError, words, wordsError,
    langList,
    t,
    lang,
    useFor
  ]);

  return (
    <CommonDialog
      title={t('wordDictionary.title')}
      open={open}
      onClose={handleClose}
      contentComponent={DialogBody}
      uiStore={userDlgUiStoreRef.current}
      autotestId={'wordDictEdit'}
    />
  );
};

