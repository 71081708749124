import { colorHsp } from "@uk/utils";

export function useTextColor(backgroundColor: string | undefined) {
  if (backgroundColor) {
    const hsp = colorHsp(backgroundColor);

    const textColor = hsp < 180 ? 'white' : 'black';

    return textColor;
  }

  return 'black';
}
