import React from 'react';
import { Box, Fade } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const DocsPage = () => {
  const { t } = useTranslation();

  return (
    <Fade in>
      <Box display="flex" flexGrow={1} flexDirection="column">
        <iframe title="docs" src="/docs/index.html" frameBorder="0" style={{ flexGrow: 1 }} />
      </Box>
    </Fade>
  );
};

export default DocsPage;
