import { observer } from 'mobx-react';
import React from 'react';
import { Panel } from '../panel';
import { IFormField, TFormFileImageItem } from './types';
import ReactFileReader from 'react-file-reader';
import { useTranslation } from 'react-i18next';
import { runInAction, toJS } from 'mobx';
import { Tooltip } from '@material-ui/core';

interface IFormImageProps {
  descriptor: IFormField;
  readonly?: boolean;
}

class ReactFRFile {
  constructor(
    public name: string,
    public lastModified: number,
    public lastModifiedDate: Date,
    public size: number,
    public type: string,
    public webkitRelativePath: string
  ) { }
}

const FormImage: React.FC<IFormImageProps> = observer(({ descriptor, readonly }) => {
  const { t } = useTranslation();
  console.log('рендер фотки');
  // const fTypes = [EFileFormats.jpeg, EFileFormats.jpg, EFileFormats.png];
  const maxImgSize = 100000000;

  const isFormatValid = (file: ReactFRFile): boolean => {
    return file.type === 'image/png' || file.type === 'image/jpeg'
  }
  
  const handleUploadFile = (file: any) => {
    const filesize = file.fileList[0].size;

    if (filesize > maxImgSize) {
      alert(t('imageFileLimit'));
    }
    else if (!isFormatValid(file.fileList[0])) {
      alert(t('invalidFormat'));
    } else {
      // if (file.base64) {
      runInAction(
        () =>
          (descriptor.value = {
            fileList: file.fileList,
            base64: file.base64,
          } as TFormFileImageItem),
      );
      // }
    }
  };
  console.log('FormImage, descriptor = ', toJS(descriptor));

  const Img = () => (
    <img
      data-autotest={'ff-cp-value-' + descriptor.id ? descriptor.id + '-' : '-' + descriptor.autotestId + ''}
      src={
        (descriptor.value as TFormFileImageItem)?.imgUrl ||
        (descriptor.value as TFormFileImageItem)?.base64?.toString() ||
        descriptor.value?.toString() ||
        '/img/photo-placeholder.png'
      }
      alt="person"
      width={descriptor.width || 200}
      // height={descriptor.height || 200}
    />
  );

  if (readonly) {
    return (
      <Panel justifyContent="center" alignItems="center">
        <Img />
      </Panel>
    );
  }

  return (
    <Panel justifyContent="center" alignItems="center" style={{ cursor: 'pointer' }}>
      {/* <Box p={1}>{JSON.stringify(descriptor)}</Box> */}

      <ReactFileReader
        fileTypes={['.jpg', '.jpeg', '.png', '.svg']}
        base64={true}
        multipleFiles={false}
        handleFiles={handleUploadFile}
      >
        <Tooltip title={t('imageUploadTooltip').toString()} arrow>
          <Img />
        </Tooltip>
      </ReactFileReader>
    </Panel>
  );
});
export default FormImage;
