/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormGroup, Box } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';

import { useTranslation } from 'react-i18next';

import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus } from '@uk';

import { CGroup, IGroup } from './types';

import { groupSettingsRestrictions } from '@/common/constants';

export interface IGroupDialogResult {
  group: IGroup;
  oldValue?: IGroup;
  closeStatus: ECommonDialogCloseStatus;
}

const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: 5,
  },
}));

interface IGroupDialogProps {
  open: boolean;
  group: IGroup | undefined;
  onClose: (dialogResult: IGroupDialogResult) => void;
}

const GroupDialog: React.FC<IGroupDialogProps> = ({ open, group, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());
  const [nameError, setNameError] = useState(false);

  userDlgUiStoreRef.current.setOkEnabled(!nameError);

  const dialogResult: IGroupDialogResult = useMemo(
    () => ({ group: group ? { ...group } : new CGroup(), closeStatus: 0, oldValue: group }),
    // eslint-disable-next-line
    [group, open],
  );

  const handleClose = (status: number) => {
    if (status === ECommonDialogCloseStatus.CANCEL || status === ECommonDialogCloseStatus.OK && !nameError) {
      dialogResult.closeStatus = status;

      if (status === ECommonDialogCloseStatus.CANCEL) {
        setNameError(false);
      } else {
        const hasError = checkNameError(dialogResult.group.name);

        if (hasError) {

          return;
        }
      }
      if (onClose) {
        onClose(dialogResult);
      }
    }
  };

  const checkNameError = (name: string): boolean => {
    const hasError =
      name.length < groupSettingsRestrictions.MIN_NAME_LENGTH ||
      name.length > groupSettingsRestrictions.MAX_NAME_LENGTH;

    setNameError(hasError);

    return hasError;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNameChange = (event: any) => {
    const name: string = event.target.value;
    const isError = checkNameError(name);

    if (!isError) {
      dialogResult.group.name = event.target.value;
    }
  };
  const handleDescriptionChange = (event: any) => {
    dialogResult.group.description = event.target.value;
  };

  const DialogBody = () => {
    return (
      <Box width={600}>
        <FormGroup>
          <>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                key="name"
                label={t('groups.name')}
                variant="outlined"
                fullWidth
                defaultValue={dialogResult.group.name}
                onChange={handleNameChange}
                autoComplete="off"
                error={nameError}
                inputProps={{ maxLength: groupSettingsRestrictions.MAX_NAME_LENGTH }}
                helperText={nameError && t('users.lengthError')}
              />
            </FormControl>
            <Box p={2} />

            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                key="description"
                label={t('groups.description')}
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                inputProps={{ maxLength: groupSettingsRestrictions.MAX_DESCRIPTION_LENGTH }}
                defaultValue={dialogResult.group.description}
                onChange={handleDescriptionChange}
                autoComplete="off"
              />
            </FormControl>
          </>

        </FormGroup>
      </Box>
    );
  };

  return (
    <CommonDialog
      title={t('group')}
      open={open}
      onClose={handleClose}
      contentComponent={DialogBody}
      autotestId={'groups'}
      uiStore={userDlgUiStoreRef.current}
    />
  );
};

export default GroupDialog;
