import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SizeMeProps, withSize } from 'react-sizeme';
import { toast } from 'react-toastify';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
//import { toast } from 'react-toastify';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LoadingPanel, Panel } from '@uk';

import appColors from 'app/app-colors';
import { gAPP_STORE } from 'app/app-store';
import { EStoreStatus } from 'common/store-status';

import DictorChipsPanel from '../dictors/DictorChipsPanels';
import DictorStatisticsDialog from '../dictors/DictorStatisticsDialog';
import { changeSegementationAcordingPhrase } from '../dictors/dictor-utils';

import { NeedSaveChanges } from './NeedSaveChanges';
import { RecordTextHistory } from './record-text-history';
import {
  clearTokens,
  copyPhrasesWithCuttedChange,
  cuttingPhrase,
  getPhraseWordText,
  wordsToPhrases,
} from './record-text-utils';
import { getTokenId } from './text/phrase';
import { PhrasePauseSelector } from './text/phrase-pause-selector';
import { SentencePauseSelector } from './text/sentence-pause-selector';
import { WordProbabilitySelector } from './text/word-probability-selector';
import { RecordTextToolbar } from './toolbar/record-text-toolbar';
import {
  ERecordStatus,
  IChannelWords,
  IPhrase,
  IVirtalListState,
  IWord,
  IWordsData,
  statusToModelStatus,
} from './types';

import { IChangeDictorInfo } from './text/types';

import { isOnlySpecialCharsSelected, noTextInTokens, selectionToTokenIndexes } from './utils/dictorCutUtils';

import { IPhraseWrapperProps, PhraseWrapper } from './text/phrase-wrapper';

import { useNeedSaveChanges } from './toolbar/useNeedSaveChanges';

import { IDictor } from '@/types/dictors';

export const useStyles = makeStyles(() => ({
  iconButtonSquarePants: {
    minWidth: '36px',
    alignItems: 'center',
  },

  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    background: appColors.grey,
  },
  toolbar: {
    flexGrow: 0,
  },
  text: {
    flexGrow: 1,
    height: 0,
    overflowY: 'scroll',
    paddingBottom: 10,
  },

  phraseLine: {
    display: 'flex',
    flexGrow: 1,
    padding: 12,
    paddingBottom: 0,
    pointerEvents: 'auto',
  },
  phraseLine_left: {
    justifyContent: 'flex-start',
    paddingRight: 100,
  },

  phrase_Hidden: {
    opacity: 0.2,
  },

  phraseLine_right: {
    justifyContent: 'flex-end',
    paddingLeft: 100,
  },

  phraseLine_EstContainer: {
    display: 'block',
    width: '100%',
    height: '0px',
    position: 'relative',
  },
  phraseLine_Est: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 100,
    paddingLeft: '12px',
    wordSpacing: '10px',
    overflowY: 'scroll',
    visibility: 'hidden',
    zIndex: -99999,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
  },
  phrase: {
    // overflow: 'hidden',
    flexGrow: 0,
    lineHeight: 1.3,
    textAlign: 'left',
    padding: '0.7em',
    borderRadius: 20,
    width: 'auto',
    boxShadow: '2px 5px 10px rgba(0,0,0,0.2)',
  },
  edited_phrase: {
    //zIndex: 99999,
    lineHeight: 1.3,
    overflow: 'hidden',
    wordSpacing: '6px',
    textAlign: 'left',
    flexGrow: 0,
    wordBreak: 'break-word',
    padding: '0.7em',
    width: 'auto',
    border: '1px solid red !important',
    whiteSpace: 'pre-wrap',
    borderRadius: '20px !important',
  },
  phrase_left: {
    background: appColors.ligthGrey,
  },
  phrase_right: {
    background: appColors.selectedItem,
  },
  phrase_left_tr: {
    background: appColors.ligthGrey,
  },
  phrase_right_tr: {
    background: appColors.selectedItem,
  },
  phrase_rtl: {
    textAlign: 'right',
    direction: 'rtl',
  },
  token: {
    // transition: 'all 0.3s',
    padding: '0px 5px',
    borderRadius: 5,
    display: 'inline-flex',
    cursor: 'text',
  },

  tokenFiltered: {
    background: appColors.red,
    color: 'white !important',
    // cursor: 'pointer',
    borderRadius: 5,
  },
  tokenSelected: {
    transition: 'none !important',
    color: 'black !important',
    borderRadius: 0,
  },
  tokenSelectedForUpdate: {
    background: appColors.orange,
  },
  tokenSelectedForCopy: {
    background: appColors.blue,
  },

  tokenPlayed: {
    background: appColors.green,
    color: 'white !important',
    cursor: 'pointer',
    borderRadius: 5,
  },

  tokenLessProbability: {
    color: appColors.darkGrey,
  },
  tokenInSummary: {
    background: 'rgba(255, 160, 0, 0.4)',
    borderRadius: 0,
  },
}));

const withSizeHOC = withSize({
  monitorWidth: true,
  monitorHeight: false,
  noPlaceholder: true,
  refreshMode: 'debounce',
  refreshRate: 50,
});

export const RecordText = withSizeHOC(
  observer((prop: SizeMeProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const recordTextStore = gAPP_STORE.getRecordTextStore();
    const soundStore = gAPP_STORE.soundStore;
    const dictorsStore = gAPP_STORE.getDictorsStore();
    const { setCycleRange, isPlaying } = soundStore;
    const [dictorsWithNewSegmentation, setDictorsWithNewSegmentation] = useState<IDictor[] | undefined>(undefined);

    const recordStore = gAPP_STORE.getRecordsStore();
    const selectRow = recordStore.gridStore.selectedRow;

    const recordOriginLanguage = recordTextStore.record?.languageResolved;

    useEffect(() => {
      const isPunctuationModelLanguagesIncludesRecordLang = gAPP_STORE.punctuationModelLanguages.some(
        lang => lang === recordOriginLanguage,
      );

      gAPP_STORE.getRecordTextStore().setSentencePauseSelector(!isPunctuationModelLanguagesIncludesRecordLang);
    }, [recordOriginLanguage]);

    const textDivRef = useRef<VariableSizeList>(null);
    const textRulerRef = useRef<HTMLDivElement>(null);

    const [currentWordsData, setCurrentWordsData] = useState<IWordsData | undefined>(recordTextStore.currentWordsData);
    const [phrases, setPhrases] = useState<IPhrase[]>([]);
    const [showSettings, setShowSettings] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    // const [needSaveChanges, setNeedSaveChanges] = useState(false);

    const [lastEditedBubbleIndex, setLastEditedBubbleIndex] = useState<number | undefined>(undefined); //10012
    const [editingMode, setEditingMode] = useState(false);

    const modePhraseEdit = useCallback(
      (phraseIndex: number | undefined) => {
        setCycleRange(
          phraseIndex !== undefined
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              { begin: phrases[phraseIndex].begin!, end: phrases[phraseIndex].end! }
            : undefined,
        );
        recordTextStore.setEditedPhraseIndex(phraseIndex);
      },
      [setCycleRange, phrases, recordTextStore],
    );
    const { needSaveChanges, setNeedSaveChanges } = useNeedSaveChanges(val => {
      setEditingMode(false);
      setCurrentWordsData(val);
    });

    useEffect(() => {
      return reaction(
        () => gAPP_STORE.getFilterStore().Data,
        () => recordTextStore.markFilteredInWordData(currentWordsData),
      );
    }, [currentWordsData, recordTextStore]);

    useEffect(() => {
      if (!recordTextStore.record && showSettings) {
        setShowSettings(false);
      }
    }, [recordTextStore.record, showSettings]);

    const dictorsWithSegmentation = useMemo(() => {
      return selectRow && dictorsStore.isSegmentationLoaded
        ? dictorsStore.getDictorsWithSegmentationForRecord(selectRow.correlationId)
        : [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectRow, dictorsStore.isSegmentationLoaded]);

    const showSpeakerStatistic = useMemo(
      () => dictorsWithSegmentation.some(item => item.wordsCount >= 0 && item.speechDuration >= 0),
      [dictorsWithSegmentation],
    );

    useEffect(() => {
      if (currentWordsData && dictorsStore.isSegmentationLoaded) {
        const ph = wordsToPhrases(
          currentWordsData,
          recordTextStore.phrasePauseSec,
          recordTextStore.useSentencePauseSelector ? recordTextStore.sentencePauseSec : undefined,
          dictorsWithSegmentation,
        );
        setPhrases(ph);
        if (textDivRef.current) {
          textDivRef.current?.resetAfterIndex(0, true); //rerender bubble list
        }
      } else {
        setPhrases([]);
      }
    }, [
      currentWordsData,
      dictorsStore.isSegmentationLoaded,
      dictorsWithSegmentation,
      recordTextStore.phrasePauseSec,
      recordTextStore.sentencePauseSec,
      recordTextStore.useSentencePauseSelector,
    ]);

    useEffect(() => {
      const disp1 = reaction(
        () => {
          return recordTextStore.record?.translationStatus;
        },
        (curr: ERecordStatus | undefined, prev: ERecordStatus | undefined) => {
          if (prev === ERecordStatus.PROCESSING && curr === ERecordStatus.PROCESSED) {
            recordTextStore.loadRecordHistory();
          }
        },
      );

      return () => {
        disp1();
      };
    }, [recordTextStore]);

    const stereo = useMemo(
      () => !!currentWordsData?.words?.length && currentWordsData?.words?.length > 1,
      [currentWordsData?.words?.length],
    );

    const handleClickPhrase = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>, phraseIndex: number) => {
        const dblclick = event.detail === 2;
        event.stopPropagation();
        if (selectRow) {
          soundStore.jumpPlayer(phrases[phraseIndex].begin ?? soundStore.position);
          const canEdit =
            gAPP_STORE.editingASR &&
            (gAPP_STORE.loginStore.user?.isEditor ?? false) &&
            ![ERecordStatus.MODEL_REBUILDING].includes(statusToModelStatus(selectRow.voiceModelStatus));

          if (dblclick && canEdit) {
            modePhraseEdit(phraseIndex);
          }
        }

        return;
      },
      [selectRow, soundStore, phrases, modePhraseEdit],
    );

    const updateChannelWords = useCallback(
      (channelWords: IChannelWords, newTokens: IWord[]) => {
        const temp = currentWordsData ? { ...currentWordsData } : undefined;
        const cw = temp?.words.find(cw => cw === channelWords);
        if (cw) {
          cw.words = newTokens;
          setCurrentWordsData(temp);
        }
      },
      [currentWordsData],
    );

    const handleEditableFocused = useCallback(() => {
      setEditingMode(true);
    }, []);

    const scrollBubbleToPosition = useCallback(
      (ssPosition: number) => {
        const ind = phrases.findIndex(
          (p, i) => p.begin !== undefined && p.end !== undefined && ssPosition >= p.begin && ssPosition <= p.end,
        );
        if (textDivRef.current) {
          if (ind < 0 && (phrases.length ?? 0) > 0 && ssPosition > (phrases[phrases.length - 1].end ?? 0)) {
            //аудио-позиция за пределами текста
            textDivRef.current.scrollToItem(phrases.length - 1, 'end');

            return;
          }
          if (ind >= 0) {
            const curBubble = document.getElementById(`divPhrase_${ind}`);
            const { offsetTop, offsetHeight } = curBubble ?? {};
            const realHeight = offsetHeight ?? 0;
            const panelHeight = Math.floor(+textDivRef.current.props.height);

            if (realHeight === 0) {
              const pnl = document.getElementById('panelText')?.firstElementChild?.firstElementChild;
              if (pnl) {
                pnl.addEventListener('scrollend', () => scrollBubbleToPosition(ssPosition), { once: true });
              }

              textDivRef.current.scrollToItem(ind, 'start');

              return;
            }

            const screenCount = Math.ceil(realHeight / panelHeight);
            const { scrollOffset } = textDivRef.current.state as IVirtalListState;

            if (screenCount <= 1) {
              //бабл "маленький"
              textDivRef.current.scrollToItem(ind, 'center');

              return;
            }

            //бабл длиннее экрана
            const lastInd = phrases[ind].tokens.length - 1;
            const k = phrases[ind].tokens.findIndex((t, k) => ssPosition <= t.end || k === lastInd);
            const el = document.getElementById(getTokenId(ind, k));
            if (el === null) {
              const pnl = document.getElementById('panelText')?.firstElementChild?.firstElementChild;
              if (pnl) {
                pnl.addEventListener('scrollend', () => scrollBubbleToPosition(ssPosition), { once: true });
              }

              textDivRef.current.scrollToItem(ind, 'start');

              return;
            }

            const elPos = Math.floor(el?.offsetTop ?? 0);
            const elHeight = el?.offsetHeight ?? 0;

            const bubleOffset = offsetTop ?? 0;

            if (soundStore.isPlaying && Math.abs(bubleOffset + elPos - scrollOffset) <= elHeight) {
              //на той же строке
              return;
            }
            if (ind === 0 && scrollOffset === 0 && elPos < panelHeight / 2) {
              //в самом начале записи - скролить не куда
              return;
            }
            if (ind === 0 && elPos < panelHeight / 2) {
              //кликнули на начало записи
              textDivRef.current.scrollToItem(ind, 'start');
            }
            if (ind === 0 || scrollOffset !== Math.floor(bubleOffset + elPos)) {
              textDivRef.current.scrollTo(Math.floor(bubleOffset + elPos - panelHeight / 2));
            }

            return;
          }
        }
      },
      [phrases, soundStore],
    );

    useEffect(() => {
      return reaction(
        () => soundStore.position,
        ssPosition => {
          scrollBubbleToPosition(ssPosition);
        },
      );
    }, [editingMode, phrases, scrollBubbleToPosition, soundStore]);

    const saveChanges = useCallback(async () => {
      modePhraseEdit(undefined);
      setNeedSaveChanges(false);
      setEditingMode(false);
      if (selectRow && dictorsWithNewSegmentation) {
        selectRow.voiceModelStatus = ERecordStatus.PREPROCESSED;
      }
      await recordTextStore.update(currentWordsData?.words, dictorsWithNewSegmentation);

      setDictorsWithNewSegmentation(undefined);

      if (!isPlaying) {
        if (lastEditedBubbleIndex !== undefined && textDivRef.current) {
          textDivRef.current.scrollToItem(lastEditedBubbleIndex, 'center');
        }
        setLastEditedBubbleIndex(undefined);
      }
    }, [
      currentWordsData?.words,
      dictorsWithNewSegmentation,
      isPlaying,
      lastEditedBubbleIndex,
      modePhraseEdit,
      recordTextStore,
      selectRow,
      setNeedSaveChanges,
    ]);

    const resetChanges = useCallback(async () => {
      modePhraseEdit(undefined);
      setNeedSaveChanges(false);
      setLastEditedBubbleIndex(undefined);
      setDictorsWithNewSegmentation(undefined);
      setEditingMode(false);
      await recordTextStore.loadRecordHistory();
    }, [modePhraseEdit, recordTextStore, setNeedSaveChanges]);

    const saveToolbar = useMemo(
      () => (
        <NeedSaveChanges
          needSaveChanges={needSaveChanges}
          isBubbleCuttled={dictorsWithNewSegmentation !== undefined}
          resetChanges={resetChanges}
          saveChanges={saveChanges}
        />
      ),
      [dictorsWithNewSegmentation, needSaveChanges, resetChanges, saveChanges],
    );

    const phraseSizeEstimator = useCallback(
      (index: number) => {
        console.log('Estimate size');
        const phrase = phrases[index];
        let size = 0;
        if (textRulerRef.current) {
          textRulerRef.current.innerHTML = phrase.tokens
            .map(t => {
              if (t.stop_sign?.includes('\n')) {
                return t.stop_sign.split('').map(x => '<br />');
              }

              return `<div style="padding-left: 5px; padding-right: 5px; display: inline-block; word-break: break-word">${getPhraseWordText(
                t,
              )}</div>`;
            })
            .join(''); //text.replaceAll('\n', '<br>');

          size = (phrase.dictor ? 40 : 0) + textRulerRef.current.offsetHeight;
        }
        size += 20; //margin 10px

        return size;
      },
      [phrases],
    );

    const handleCutBubble = useCallback(
      (phraseIndex: number, fromIndex: number, uptoIndex: number, dictor: IDictor) => {
        const curPhrase = phrases[phraseIndex];
        if (
          (fromIndex === 0 && uptoIndex + 1 === curPhrase.tokens.length) ||
          noTextInTokens(curPhrase.tokens, 0, fromIndex - 1) ||
          noTextInTokens(curPhrase.tokens, fromIndex, uptoIndex) ||
          noTextInTokens(curPhrase.tokens, uptoIndex + 1, curPhrase.tokens.length - 1)
        ) {
          toast.warn(t('dictorEdit.invalidSelection'));

          return;
        }

        setNeedSaveChanges(true);
        setLastEditedBubbleIndex(phraseIndex);

        const { b1, b2, b3 } = cuttingPhrase(curPhrase, dictor, fromIndex, uptoIndex);

        const ph = copyPhrasesWithCuttedChange(phrases, phraseIndex, b1, b2, b3);

        const tmpDictors = changeSegementationAcordingPhrase(
          dictorsStore.getTmpDictorsWithSegmentation(),
          curPhrase.dictor?.id,
          dictor.id,
          b2.begin,
          b2.end,
        );
        dictorsStore.updateTmpDictors(tmpDictors);
        setDictorsWithNewSegmentation(tmpDictors);

        const temp = currentWordsData ? { ...currentWordsData } : undefined;
        if (temp !== undefined) {
          const tempWords: IWord[] = [];
          ph.forEach(phrase => {
            if (phrase.channelNumber === b2.channelNumber) {
              tempWords.push(...phrase.tokens);
            }
          });
          tempWords.forEach(w => (w.channelWords = undefined));
          temp.words[b2.channelNumber ?? 0].words = tempWords;
        }

        setPhrases(ph);
        setCurrentWordsData(temp);

        setEditingMode(false);
        if (textDivRef.current) {
          textDivRef.current?.resetAfterIndex(phraseIndex, true); //rerender bubble list
        }
      },
      [currentWordsData, dictorsStore, phrases, setNeedSaveChanges, t],
    );

    const handleEditComplete = useCallback(
      (event: React.ChangeEvent<HTMLDivElement>, phraseIndex: number, changeDictor?: IChangeDictorInfo) => {
        setLastEditedBubbleIndex(phraseIndex);
        const editedPhrase: IPhrase = phrases[phraseIndex];

        let newText: string = event.target.innerText.trim();
        modePhraseEdit(undefined);
        if (newText.length === 0 || isOnlySpecialCharsSelected(newText)) {
          toast.warn(t('warnEmptyBubble'));

          return;
        }

        const newPhraseWords: IWord[] = [];
        const phraseOldText = editedPhrase.tokens.map(t => getPhraseWordText(t)).join(' ');

        if (newText !== phraseOldText) {
          const regex = /([\n\r]+)|[\t\f\v ]*([^\s?,.;:!؟،؛]*)[\t\f\v ]*([?,.;:!؟،؛]+)*[\t\f\v ]*/g;
          const x = newText.matchAll(regex);

          //          for (const match of newText.matchAll(regex)) {
          for (const match of x) {
            const newLine = match[1];
            const wordStr = match[2];
            const stopSignStr = match[3];

            if (newLine && newLine.length > 0 && newPhraseWords.length > 0) {
              const w: IWord = {
                begin: 0,
                dictor_num: editedPhrase.speakerNumber ?? 0,
                end: 0,
                probability: 100,
                text: '',
                stop_sign: newLine,
              };

              newPhraseWords.push(w);
            }

            if (wordStr !== null && wordStr !== undefined) {
              const w: IWord = {
                begin: 0,
                dictor_num: editedPhrase.speakerNumber ?? 0,
                end: 0,
                probability: 100,
                text: wordStr,
                stop_sign: null,
              };

              if (stopSignStr) {
                w.stop_sign = stopSignStr;
              }

              if (stopSignStr || wordStr) newPhraseWords.push(w);
            }
          }

          newText = '';
          let hasText = false;

          if (newPhraseWords.length > 0) {
            const minTime = editedPhrase.tokens[0].begin;
            const maxTime = editedPhrase.tokens[editedPhrase.tokens.length - 1].begin;
            const dt = (maxTime - minTime) / newPhraseWords.length;

            newPhraseWords.forEach((w, idx) => {
              newText += idx === 0 ? getPhraseWordText(w) : ` ${getPhraseWordText(w)}`;
              hasText = hasText || w.text.trim().length > 0;

              w.begin = minTime + dt * idx;
              w.end = minTime + dt * idx + 0.5;
            });
          }

          // Если текст изменился, то сохраним его
          if (phraseOldText !== newText) {
            if (!hasText) {
              toast.warn(t('warnEmptyBubble'));

              return;
            }
            const { filterWords, filterPhrase } = gAPP_STORE
              .getFilterStore()
              .getSeparatedFilter(recordTextStore.isTranslation);
            recordTextStore.markFilteredWord(newPhraseWords, filterWords);
            editedPhrase.tokens = newPhraseWords;
            recordTextStore.markFilteredInPhrase(editedPhrase, filterPhrase);

            const channelWords = new Map<IChannelWords, IWord[]>();

            phrases.forEach(phrase => {
              if (phrase.channelWords && phrase.tokens && phrase.tokens.length > 0) {
                let newChannelTokensToUpdate: IWord[];

                if (!channelWords.has(phrase.channelWords)) {
                  newChannelTokensToUpdate = [];
                  channelWords.set(phrase.channelWords, newChannelTokensToUpdate);
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  newChannelTokensToUpdate = channelWords.get(phrase.channelWords)!;
                }

                newChannelTokensToUpdate.push(...phrase.tokens);
              }
            });

            channelWords.forEach((value: IWord[], key: IChannelWords) => {
              updateChannelWords(key, clearTokens(value));
            });

            setNeedSaveChanges(true);
          }
        } else if (needSaveChanges) {
          const ph = wordsToPhrases(
            currentWordsData,
            recordTextStore.phrasePauseSec,
            recordTextStore.useSentencePauseSelector ? recordTextStore.sentencePauseSec : undefined,
            dictorsWithSegmentation,
          );
          setPhrases(ph);
        }
        setEditingMode(false);

        //also cut dictor if requested
        if (changeDictor) {
          const ranges = selectionToTokenIndexes(changeDictor.selection, editedPhrase.tokens);

          handleCutBubble(phraseIndex, ranges.start, ranges.end, changeDictor.dictor);
        } else {
          if (textDivRef.current) {
            textDivRef.current?.resetAfterIndex(0); //rerender bubble list
          }
        }
      },
      //TODO: Костыль, нужно будет фиксить https://react.dev/learn/removing-effect-dependencies#to-change-the-dependencies-change-the-code
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        t,
        phrases,
        modePhraseEdit,
        needSaveChanges,
        updateChannelWords,
        handleCutBubble,
        currentWordsData,
        dictorsWithSegmentation,
        recordTextStore.isTranslation,
        recordTextStore.phrasePauseSec,
        recordTextStore.useSentencePauseSelector,
        recordTextStore.sentencePauseSec,
      ],
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleEditKeyDown = useCallback(
      (event: React.KeyboardEvent, phraseIndex: number) => {
        if (event.key === 'Tab') {
          if (!event.shiftKey && phraseIndex < phrases.length - 1) {
            handleEditComplete(event as unknown as React.ChangeEvent<HTMLDivElement>, phraseIndex);
            modePhraseEdit(phraseIndex + 1);
          } else if (event.shiftKey && phraseIndex > 0) {
            handleEditComplete(event as unknown as React.ChangeEvent<HTMLDivElement>, phraseIndex);
            modePhraseEdit(phraseIndex - 1);
          } else {
            handleEditComplete(event as unknown as React.ChangeEvent<HTMLDivElement>, phraseIndex);
            modePhraseEdit(undefined);
          }

          return;
        }
        if (event.key === 'Enter' && !event.ctrlKey) {
          handleEditComplete(event as unknown as React.ChangeEvent<HTMLDivElement>, phraseIndex);
          recordTextStore.setEditedPhraseIndex(undefined);
        } else if (event.key === 'Enter' && event.ctrlKey) {
          //handleEditComplete(event as unknown as React.ChangeEvent<HTMLDivElement>, phraseIndex);
          recordTextStore.setEditedPhraseIndex(phraseIndex);

          const selection = window.getSelection();
          if (selection) {
            const range = selection.getRangeAt(0);
            const br = document.createElement('br');

            // Проверка, если курсор находится в конце текста
            if (range.endOffset === (range.endContainer.textContent?.length ?? 0)) {
              document.execCommand('insertHTML', false, '<br><br>');
            } else {
              range.insertNode(br);
            }
            range.collapse(false);
          }
        }
      },
      [handleEditComplete, modePhraseEdit, phrases.length, recordTextStore],
    );

    useEffect(() => {
      if (textDivRef.current) {
        textDivRef.current?.resetAfterIndex(0);
      }
    }, [prop.size, recordTextStore.phrasePauseSec, showHistory]);

    const panelHistory = useMemo(
      () => (showHistory ? <RecordTextHistory disabled={editingMode || needSaveChanges} /> : <></>),
      [editingMode, needSaveChanges, showHistory],
    );

    const phraseRenderData = useMemo(() => {
      return {
        phrases: phrases,
        avatarCache: dictorsStore.cacheDictorAvatars,
        stereo: stereo,
        onClick: handleClickPhrase,
        handleEditableFocused: () => handleEditableFocused,
        handleEditComplete: (e, pi, cd) => handleEditComplete(e, pi, cd),
        handleEditKeyDown: (e, pi) => handleEditKeyDown(e, pi),
        onCutBubble: handleCutBubble,
      } as IPhraseWrapperProps;
    }, [
      handleClickPhrase,
      handleCutBubble,
      handleEditComplete,
      handleEditKeyDown,
      handleEditableFocused,
      phrases,
      stereo,
      dictorsStore.cacheDictorAvatars,
    ]);

    return (
      <Panel style={{ marginLeft: '0.15em', overflowX: 'scroll', overflowY: 'hidden', width: '100%' }}>
        <RecordTextToolbar
          currentWordsData={currentWordsData}
          editingMode={editingMode}
          needSaveChanges={needSaveChanges}
          showSpeakerStatistic={showSpeakerStatistic}
          showSettings={showSettings}
          showHistory={showHistory}
          setShowSettings={setShowSettings}
          setShowHistory={setShowHistory}
        />

        {selectRow && (recordTextStore.status === EStoreStatus.LOADING || !dictorsStore.isSegmentationLoaded) && (
          <LoadingPanel />
        )}

        {showSettings && (
          <Box bgcolor={appColors.ligthLightGrey} borderBottom={`1px solid ${appColors.grey}`} p={1}>
            <PhrasePauseSelector disabled={editingMode || needSaveChanges} />
            {gAPP_STORE.punctuation && <SentencePauseSelector disabled={editingMode || needSaveChanges} />}
            <WordProbabilitySelector disabled={editingMode || needSaveChanges} />
          </Box>
        )}

        <DictorChipsPanel
          avatarCache={dictorsStore.cacheDictorAvatars}
          disableMode={'phrases'}
          isHide={!dictorsStore.isDictorsPanelVisible || selectRow === undefined || !dictorsStore.isSegmentationLoaded}
          noDictorsInfo={<div>{t('dictors.noDictors')}</div>}
        />

        {needSaveChanges && saveToolbar}

        <Box minWidth={350} height={1} display={'flex'} flexDirection={'column'}>
          <div className={classes.phraseLine_EstContainer}>
            <div className={classes.phraseLine_Est}>
              <div ref={textRulerRef} className={classes.phrase}></div>
              {showHistory && <div style={{ width: '45px', minWidth: '45px', maxWidth: '45px' }}></div>}
            </div>
          </div>

          {selectRow && recordTextStore.status === EStoreStatus.SUCCESS && dictorsStore.isSegmentationLoaded && (
            <Box display="flex" flexGrow={1} width={1}>
              <Box id="panelText" display="flex" flexGrow={1} flexDirection="column">
                <AutoSizer>
                  {({ height, width }: { height: number; width: number }) => (
                    <VariableSizeList
                      ref={textDivRef}
                      width={width}
                      height={height}
                      itemCount={phrases.length}
                      itemSize={i => phraseSizeEstimator(i)}
                      style={{ overflowY: 'scroll', willChange: 'auto !important' }}
                      itemData={phraseRenderData}
                    >
                      {PhraseWrapper}
                    </VariableSizeList>
                  )}
                </AutoSizer>
              </Box>
              {panelHistory}
            </Box>
          )}
        </Box>
        {!isPlaying && (
          <DictorStatisticsDialog
            open={gAPP_STORE.getDictorsStore().openDictorsPanelVisible}
            onClose={() => gAPP_STORE.getDictorsStore().setOpenDictorsStatisticsDialog(false)}
          />
        )}
      </Panel>
    );
  }),
);
