import { Box } from "@material-ui/core";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { useMemo } from "react";
import FormField from "./FormField";
import { ICustomField, IFormField } from "./types";

const FormFieldRenderer: React.FC<{
    key?: number,
    descriptor: IFormField,
    readonly?: boolean,
    onSearch?: (descriptor: IFormField, parentId?: string) => void,
    customFields?: ICustomField[]
}>
    = ({ descriptor, readonly, onSearch, customFields }) => {

        const customField = useMemo(() => customFields?.find(cf => cf.fieldId === descriptor.id), [customFields, descriptor.id]);

        if (descriptor.type === 'group') {
            return (
                <Box display="flex">
                    {descriptor.children &&
                        descriptor.children.map((child, index) => (
                            <Box key={index} display="flex" alignItems="center">
                                <FormFieldRenderer key={index} descriptor={child} readonly={readonly} onSearch={onSearch} customFields={customFields} />
                            </Box>
                        ))}
                </Box>
            );
        }

        if (descriptor.isRange) {
            if (!descriptor.value || (descriptor.value as any[]).length < 2) {
                runInAction(() => {
                    descriptor.value = descriptor.type === 'datetime' ? null : [undefined, undefined];
                });
            }
            return (
                <Box display="flex" alignItems="center">
                    <FormField descriptor={descriptor} rangeIndex={0} readonly={readonly} onSearch={onSearch} />
                    <Box p={0.5} />
                    <FormField descriptor={descriptor} rangeIndex={1} readonly={readonly} onSearch={onSearch} />
                </Box>
            );
        }

        if (customField) {
            return customField.fieldComponent;
        }

        if ((descriptor.type === 'list') && (descriptor.value === undefined)) {
            runInAction(() => { descriptor.value = [] });
        }

        return <FormField descriptor={descriptor} readonly={readonly} onSearch={onSearch} />;
    };

export default observer(FormFieldRenderer)