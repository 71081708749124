import { EStatus } from '@/common/store-status';
import { IParticipantDto } from '@/services/summary/types';
import { IDictor } from '@/types/dictors';

export const SUMMARY_PANEL_WIDTH = '300px';

export interface ITimecode {
  begin: number;
  end: number;
}

export interface ITimecodesItem {
  uuid: string;
  timecodes: ITimecode[];
}

export interface ISummaryInfoInRecord {
  recordId: number;
  topicCount: number;
  hasTasks: boolean;
}

export enum ETopicStatus {
  loading = 'loading',
  notReady = 'notReady',
  ready = 'Ready',
}

//--using for UI-----------------------------------------------------------------------------

export interface ISummary {
  id: string;
  status: EStatus;
  result?: ISummaryItem | null;
}

export interface IIssue {
  id: number;
  title: string;
  description: string;
  mentions?: string[];
  deadline?: IDeadline;
  timecodesItem?: ITimecodesItem;
}

export interface IDeadline {
  deadline: string;
  estimation: string;
}

export interface ISummaryItem {
  summarydId: number;
  title: string;
  date: string;
  description: string;
}

export interface IParticipant {
  idParticipant: number;
  dictor?: IDictor;
  summaryName: string;
  role?: string | null;
}

export interface IOwner {
  idOwner: number;
  name: string;
  editable: boolean;
}

export interface ITopic {
  idTopic: number;
  title: string;
  description: string;
  participantDto?: IParticipantDto[];
  participants: IParticipant[];
  tasks?: ITask[];
  phraseLinks?: string;
  timecodesItem?: ITimecodesItem;
  status: ETopicStatus;
}

export interface ITask {
  idTask: number;
  issue?: string;
  decision?: string;
  deadline?: string;
  estimation?: string;
}
