import { Box } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { observer } from "mobx-react";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IDataGridPaginationProps } from ".";

const PaginationControlPages: FC<IDataGridPaginationProps<any>> = ({ gridStore, tmpAdded, tmpDeleted }) => {

  const { t } = useTranslation();

  const handleChange = (event: any, value: number) => {
    gridStore.setCurrentPage(value - 1);
  };

  const labelDisplayedRows = useCallback(() => {
    const rowsPerPage = gridStore.rowsOnPage;
    const page = gridStore.currentPage;
    const count = gridStore.total;
    const from = count !== 0 ? rowsPerPage * page + 1 - (tmpDeleted?.length || 0) : 0;
    const to = Math.min(from + rowsPerPage - 1 + (tmpAdded?.length || 0), count + (tmpAdded?.length || 0));
    const total = count !== -1 ? count : `${t('more_than')} ${to}`;
    return `${from}-${to} ${t('of')} ${total}`;
  }, [gridStore.currentPage, gridStore.rowsOnPage, gridStore.total, t, tmpAdded?.length, tmpDeleted?.length]);

  return (
    <>
      <Box px={2}>{labelDisplayedRows()}</Box>
      <Box >
        <Pagination
          color="primary"
          shape="rounded"
          size="small"
          showFirstButton
          showLastButton
          count={Math.ceil(gridStore.total / gridStore.rowsOnPage)}
          page={gridStore.currentPage + 1}
          onChange={handleChange}
        />
      </Box>
    </>
  )
}

export default observer(PaginationControlPages);