import { TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { when } from "mobx";
import { useCallback, useEffect, useMemo, useState } from "react";

import styles from './dataGrid.module.scss';
import DataGridCell from "./DataGridCell";
import RowActions from "./RowActions";
import { IDataGridRowProps } from "./types";


export const DataGridRow: React.FC<IDataGridRowProps> = ({ row, rowIndex, gridStore, rowAction, handleCheckRow, onClick, fixedRowHeight, SIZE, isNeedTooltipForContent }) => {

    const needSettings = useMemo(() => gridStore.gridSettingsStore.cols.filter(col => !col.hideInGrid && !col.hasCheck).length > 1, [gridStore.gridSettingsStore.cols]);

    const getGlobalRowIndex = useCallback((rowIndex: number) => gridStore.currentPage * gridStore.rowsOnPage + rowIndex, [gridStore.currentPage, gridStore.rowsOnPage]);
    const [selected, setSelected] = useState<boolean>(gridStore.selectedRowIndexes.includes(getGlobalRowIndex(rowIndex)));

    useEffect(() => {
        const disposer = when(() => gridStore.selectedRowIndexes.includes(getGlobalRowIndex(rowIndex)) !== selected,
            () => { //CHECK :  calls too many times: semenov-k
                setSelected(gridStore.selectedRowIndexes.includes(getGlobalRowIndex(rowIndex)));
            }
        )

        return function cleanup() {
            disposer();
        }
    });

    return (
        <TableRow
            key={rowIndex}
            className={clsx(styles.row, { [styles.selected]: selected })}
            data-autotest={'TableRow-' + rowIndex}
            role="checkbox"
            tabIndex={-1}
            onClick={(event) => {
                if (onClick) { onClick(event, row, rowIndex); }
            }}
        >
            {needSettings && <TableCell></TableCell>
                // <RowActions 
                // size={SIZE} 
                // icon={<SettingsIcon />} 
                // onClick={() => setOpenSettings(true)} />
            }

            {gridStore.gridSettingsStore.colsWithLayout.map((colLayout, colIndex) =>
                <DataGridCell key={colIndex} row={row} rowIndex={rowIndex} col={colLayout.column} colIndex={colIndex} SIZE={SIZE} fixedRowHeight={fixedRowHeight} handleCheckRow={handleCheckRow} isSelectedRow={selected} gridStore={gridStore} isNeedTooltip={isNeedTooltipForContent} />
            )}
            {rowAction &&
                <RowActions
                    size={rowAction.size}
                    icon={rowAction.icon}
                    isAlwaysVisible={rowAction.isAlwaysVisible}
                    onClick={() => rowAction.onClick(row, rowIndex)}
                />
            }
            {/*{<RowActions size={SIZE} onClick={() => alert('RowActions')} />}*/}
        </TableRow>
    );
};