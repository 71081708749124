import { useState, useCallback } from 'react';

export const useForceUpdate = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, updateState] = useState(false);
  const forceUpdate = useCallback(
    () => updateState((flag: boolean) => !flag),
    [],
  );
  return forceUpdate;
};
