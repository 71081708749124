import React, { useEffect, useState } from 'react';
import { Box, Button, Fade, FormControl, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';

import { gAPP_STORE } from '@/app/app-store';

const LoginPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    gAPP_STORE.clearRecordsStore();
  }, []);

  if (gAPP_STORE.loginStore.isSignedIn()) {
    history.push('/');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLoginChange = (event: any) => {
    setLogin(event.target.value);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };
  const handleLogin = async () => {
    const result = await gAPP_STORE.loginStore.signIn(login, password);
    if (result) {
      history.push('/');
    } else {
      setShowErrorMessage(true);
      setTimeout(() => setShowErrorMessage(false), 1000);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      handleLogin();
    }
  };

  // if (loginStore.status === EStoreStatus.LOADING) {
  //   return <Loading />;
  // }

  return (
    <Fade in>
      <Box display="flex" flexGrow={1} flexDirection="column" alignItems="center" justifyContent="center">
        <Box p={2} width={300} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          {/* <img src="img/customer-logo.svg" style={{ width: 200 }} />
        <Box p={1} /> */}

          <FormControl variant="outlined" fullWidth>
            <TextField
              autoFocus
              label={t('login')}
              variant="outlined"
              fullWidth
              onChange={handleLoginChange}
              onKeyDown={handleKeyDown}
            />
          </FormControl>
          <Box p={1} />

          <FormControl variant="outlined" fullWidth>
            <TextField
              label={t('password')}
              variant="outlined"
              type="password"
              fullWidth
              onChange={handlePasswordChange}
              onKeyDown={handleKeyDown}
            />
          </FormControl>
          <Box p={1} />

          <Box p={1} style={{ width: '100%' }}>
            {showErrorMessage && <Alert severity="error">{t('accessDenied')}</Alert>}
          </Box>

          <Box p={1} />
          <Button onClick={handleLogin} variant="contained" color="primary" fullWidth>
            {t('login')}
          </Button>
        </Box>
        <Box p={5} />
      </Box>
    </Fade>
  );
};

export default observer(LoginPage);
