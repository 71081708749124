export const ukColors = {
  grey: '#cfd8dc',
  darkGrey: '#999',
  lightGrey: 'lightGrey',
  lightLightGrey: '#F1F2F4',

  white: '#ffffff',
  red: '#e53935', // '#d32f2f',
  darkRed: '#c51915', // '#d32f2f',
  green: '#078556', // "#689f38",
  blue: '#039be5',
  yellow: '#ffa000',
  yellowLight: '#ffecb3',
  pink: '#e91e63',
  purple: '#9c27b0',
  orange: '#ff5722',
  cyan: '#00acc1',
  accent: '#10AA70',

  primary: '#0b2b48',
  primaryLight: '#00838f',

  appToolbarBackgroundColor: '#0b2b48',
  gridCellBorderColor: '#e0e0e0',

  toolbarBackgroundColor: '#ffffff',
  tabsBackgroundColor: '#eceff1',
  // headersBackgroundColor: "#f2f8fe",
  headersBackgroundColor: '#ffffff',

  selectedItem: '#EDFAFC',
  selectedItemDark: '#B2EBF2',

  mainBackground: '#E2EAF2',

  buttonBackground: '#F1F2F4',
  inputFieldBackground: '#F1F2F4',
  notValidFieldValueBackground: '#F1E2E4',

  borderColor: '#ddd',
};

export default ukColors;
