import { Badge, Box, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import React from 'react';
import ukColors from '@uk/ukColors';
import { IToolbarSelectorProps } from './types';

const useStyles = makeStyles({
  badge: {
    right: '-15px',
    fontSize: '0.7rem',
  },
  input: {
    display: 'flex',
  },
});

const ToolbarSelector: React.FC<IToolbarSelectorProps> = ({
  pl,
  pr,
  title,
  value,
  list,
  listNameRenderer,
  onChange,
  selectedObjectsTypesAndChoices,
}) => {
  const classes = useStyles();

  return (
    <Box
      pl={pl || 0}
      pr={pr || 0}
      display="flex"
      flexDirection="row"
      alignItems="center"
      zIndex={1000}>
      {title && title.length > 0 && (
        <Box pr={1}>
          <Typography>{title}</Typography>
        </Box>
      )}
      <Select
        disableUnderline
        style={{ background: ukColors.grey, borderRadius: 5, padding: '0 8px' }}
        value={value}
        onChange={onChange}
        classes={{ selectMenu: classes.input }}>
        {list &&
          list.map((t, index) => (
            <MenuItem
              key={index}
              value={t}
              disabled={
                typeof selectedObjectsTypesAndChoices !== 'undefined' &&
                selectedObjectsTypesAndChoices[index] <= 0
              }
              style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'contents' }}>
                <div>{listNameRenderer ? listNameRenderer(t) : JSON.stringify(t)}</div>
                {/*<div> {(selectedObjectsTypesAndChoices?.[index] !== 0) ? selectedObjectsTypesAndChoices?.[index] : null}</div>*/}
                {selectedObjectsTypesAndChoices?.[index] !== 0 ? (
                  <div style={{ minWidth: '15px', paddingLeft: '8px', paddingRight: '8px' }}>
                    <Badge
                      badgeContent={
                        selectedObjectsTypesAndChoices?.[index] === -2
                          ? '-'
                          : selectedObjectsTypesAndChoices?.[index]
                      }
                      color={selectedObjectsTypesAndChoices?.[index] === -2 ? 'error' : 'primary'}
                      classes={{ badge: classes.badge }}
                    />
                  </div>
                ) : null}
              </div>
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
};

export default ToolbarSelector;
