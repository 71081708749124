import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { PiFileTextFill } from 'react-icons/pi';

import { Typography } from '@material-ui/core';

import { Panel, ukColors } from '@/react-ui-kit/src/@uk';

export const NoDataPanel: FC = () => {
  const { t } = useTranslation();

  return (
    <Panel
      data-autotest={'NoDataSummaryPanel'}
      id="blockNoData"
      style={{
        height: ' 100%',
        backgroundColor: 'white',
        alignItems: 'center',
      }}
    >
      <div style={{ marginTop: '144px', alignItems: 'center' }}>
        <PiFileTextFill style={{ color: ukColors.lightGrey, fontSize: '96px' }} />
      </div>
      <Typography variant="body1" style={{ color: ukColors.lightGrey }}>
        {t('noData')}
      </Typography>
    </Panel>
  );
};
