import { action, makeAutoObservable, runInAction } from 'mobx';

import { BackendService } from 'services';

import { DataGridStore, IDataGridStoreState } from '@uk';

import { uncompletedGridColumns } from '../upload-panel/upload-panel';

import { ERecordStatus, IRecord } from './types';

import { EntityChangeGridStoreWrapper } from '@/stores/entityChangeGridStoreWrapper';
import { EntityChangeEventActions, EntityChangeEventTypes } from '@/services/events/types';
import { IGridStorageEntry } from '@/types/grid-storage';
import { IEntityChangeEvent } from '@/types/events';

export class UncompletedRecordsStore {
  data: IRecord[] = [];
  gridStoreEventWrapper: EntityChangeGridStoreWrapper<number, IRecord, IRecord | IGridStorageEntry>;
  uncompletedGridStore: DataGridStore<IRecord>;
  uncompletedStatuses = [
    ERecordStatus.NEW,
    ERecordStatus.LOADED,
    ERecordStatus.PREPROCESSING,
    ERecordStatus.PREPROCESSED,
    ERecordStatus.PROCESSING,
    // ERecordStatus.MODEL_STATUS_REBUILD_WAITING,
    // ERecordStatus.MODEL_STATUS_REBUILDING,
  ];

  constructor() {
    this.uncompletedGridStore = new DataGridStore<IRecord>('uncompletedRecordGrid', uncompletedGridColumns, {
      dataProvider: async (state: IDataGridStoreState) => {
        const d = await this.getUncompleted();

        const result = {
          data: d,
          total: d.length,
          hasNextPage: false,
        };

        return result;
      },
    });

    this.gridStoreEventWrapper = new EntityChangeGridStoreWrapper<number, IRecord, IRecord | IGridStorageEntry>(
      [
        { action: EntityChangeEventActions.UPDATE, type: EntityChangeEventTypes.RECORD },
        { action: EntityChangeEventActions.CREATE, type: EntityChangeEventTypes.RECORD },
        { action: EntityChangeEventActions.DELETE, type: EntityChangeEventTypes.RECORD }, //TODO: on new element append!!!
      ],
      this.uncompletedGridStore,
      async (orig: IRecord, event: IEntityChangeEvent<IRecord | IGridStorageEntry>) => {
        if (event.type === EntityChangeEventTypes.RECORD) {
          if (event.action === EntityChangeEventActions.DELETE) {
            this.uncompletedGridStore.removeRow(orig);
          } else {
            const r = event.value as IRecord;
            if (!this.uncompletedStatuses.includes(r.status)) {
              this.uncompletedGridStore.removeRow(orig);
            } else {
              runInAction(() => {
                Object.assign(orig, event.value);
              });
            }
          }
        }

        return await Promise.resolve();
      },
      async (event: IEntityChangeEvent<IRecord | IGridStorageEntry>) => {
        if (event.type === EntityChangeEventTypes.RECORD) {
          //IRecord
          const r = event.value as IRecord;
          if (this.uncompletedStatuses.includes(r.status)) {
            this.uncompletedGridStore.appendRow(r);
          }
        }

        return await Promise.resolve();
      },
    );

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  reload = async () => {
    await this.uncompletedGridStore.reload();
    //this.gridStore.setSelectedRowIndexes(this.gridStore.selectedRowIndexes);
  };

  @action
  clear = () => {
    this.uncompletedGridStore.reset();
  };

  /**
   * Get uncompleted files
   */
  @action
  getUncompleted = async () => {
    try {
      const dataNew = await this.getRecordsByStatus(this.uncompletedStatuses);

      return [...(dataNew || [])].sort((a, b) => (a.id === b.id ? 0 : b.id - a.id));
    } catch (error) {
      console.error('UncompletedStore.getUncompleted(), error = ', error);

      return [];
    }
  };

  getRecordsByStatus = async (statusArray: ERecordStatus[]) => {
    const filterData = {
      fields: [{ fieldName: 'status', predicate: 'Eq', targetValues: statusArray.map(s => s.toString()) }],
      limit: 30,
      offset: 0,
      sortByField: { name: 'id', order: 'Dsc' },
    };

    return await BackendService.post('file/filter', JSON.stringify(filterData));
  };
}
