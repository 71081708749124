import { IFilterData } from './i-filter';
import { IFilterField, TFilterPredicate } from './i-filter-field';

import { gAPP_STORE } from '@/app/app-store';

const textFieldPredicates: TFilterPredicate[] = ['contains', 'equal', 'not_equal'];
const listFieldPredicates: TFilterPredicate[] = ['equal', 'not_equal'];
const numberFieldPredicatesAll: TFilterPredicate[] = ['equal', 'not_equal', 'greater_than_equal', 'less_than_equal'];
const numberFieldPredicates: TFilterPredicate[] = ['greater_than_equal', 'less_than_equal'];

let filterDescriptor: IFilterField[] | null = null;

export const getFilterDescriptor = () => {
  if (!filterDescriptor) {
    filterDescriptor = [
      {
        id: 'status',
        title: 'records.status',
        type: 'list',
        value: [],
        predicates: listFieldPredicates,
        predicate: 'equal',
      },
      {
        id: 'translationStatus',
        title: 'records.translate_status',
        type: 'list',
        value: [],
        predicates: listFieldPredicates,
        predicate: 'equal',
      },
      {
        id: 'owner',
        title: 'records.owner',
        type: 'list',
        value: [],
        predicates: listFieldPredicates,
        predicate: 'equal',
      },
      {
        id: 'caseid',
        title: !gAPP_STORE.meetingTitleColumnName
          ? 'records.meetingTitle'
          : (gAPP_STORE.meetingTitleColumnName as string),
        type: 'text',
        value: [],
        predicates: textFieldPredicates,
        predicate: 'contains',
      },
      {
        id: 'fileName',
        title: 'records.filename',
        type: 'text',
        value: [],
        predicates: textFieldPredicates,
        predicate: 'contains',
      },
      { id: 'loadFrom', title: 'records.loadFrom', type: 'datetime', value: '' },
      { id: 'loadTo', title: 'records.loadTo', type: 'datetime', value: '' },
      { id: 'createFrom', title: 'records.createFrom', type: 'datetime', value: '' },
      { id: 'createTo', title: 'records.createTo', type: 'datetime', value: '' },
      {
        id: 'languageResolved',
        title: 'records.language',
        type: 'list',
        value: [],
        predicates: listFieldPredicates,
        predicate: 'equal',
      },
      {
        id: 'textIdx',
        title: 'records.text',
        type: 'text',
        value: [],
        predicate: 'contains',
      },
      {
        id: 'translateIdx',
        title: 'records.translateField',
        type: 'text',
        value: [],
        predicate: 'contains',
      },
      {
        id: 'meetingParticipants',
        title: 'records.meetingParticipants',
        type: 'number',
        value: [],
        predicates: numberFieldPredicatesAll,
        predicate: 'equal',
        multiple: false,
      },
      {
        newFilterBlock: true,
        id: 'dictors',
        title: 'records.speakers',
        type: 'list',
        value: [],
        predicate: 'equal',
      },
      {
        id: 'speechDuration',
        title: 'dictors.speechDurationSec',
        type: 'number',
        value: [],
        predicates: numberFieldPredicates,
        predicate: 'greater_than_equal',
        multiple: false,
      },
      {
        id: 'wordsCount',
        title: 'dictors.wordsCount',
        type: 'number',
        value: [],
        predicates: numberFieldPredicates,
        predicate: 'greater_than_equal',
        multiple: false,
      },
      {
        id: 'topic',
        title: 'records.topic',
        type: 'list',
        value: [],
        predicate: 'equal',
      },
      {
        id: 'topicThreshold',
        title: 'records.topicThreshold',
        type: 'threshold',
        value: 50,
        predicate: 'equal',
      },
      //summary
      {
        newFilterBlock: true,
        id: 'summaryText',
        title: 'summary.filterText',
        type: 'text',
        value: [],
        predicate: 'contains',
        predicates: ['contains', 'equal'],
        maxValues: 20
      },
      {
        id: 'summaryInstructions',
        title: 'summary.filterInstructions',
        type: 'switch',
        value: false,
        predicate: 'equal',
        multiple: false,
      },
      {
        id: 'summaryTopics',
        title: 'summary.filterTopics',
        type: 'text',
        value: [],
        predicate: 'contains',
        predicates: ['contains']
      },
    ];
  }

  return filterDescriptor;
};

export const getDefaultFilter = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filter: any = {};
  getFilterDescriptor().forEach(fd => {
    filter[fd.id] = { value: fd.value, score: fd.score, predicate: fd.predicate };
  });

  return filter as IFilterData;
};
