import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus } from '@uk';
import { Box, FormControl, FormGroup, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';


import { CTranslateSetting, ITranslateSetting } from './types';

import { gAPP_STORE } from '@/app/app-store';

export interface IAutoTranslateDialogResult {
  setting: ITranslateSetting;
  oldValue?: ITranslateSetting;
  closeStatus: ECommonDialogCloseStatus;
};

const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: 5,
  },
}));

export interface IAutoTranslateDialogProps {
  translateSettings: ITranslateSetting | undefined;
  onClose: (dialogResult: IAutoTranslateDialogResult) => void;
};

export const AutoTranslateLanguageDialog: React.FC<IAutoTranslateDialogProps> = ({ translateSettings, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation('lang');
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());


  const [value, setValue] = useState();

  const dialogResult: IAutoTranslateDialogResult = useMemo(
    () => ({ setting: translateSettings ? { ...translateSettings } : new CTranslateSetting(), closeStatus: 0, oldValue: translateSettings }),
    [translateSettings],
  );

  const handleClose = useCallback((status: number) => {
    dialogResult.closeStatus = status;
    if (value && status === ECommonDialogCloseStatus.OK) {
      dialogResult.setting.value.to = value;
      dialogResult.setting.value.auto = true;
    }
    if (onClose) {
      onClose(dialogResult);
    }
  }, [dialogResult, onClose, value]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = useCallback((event: any) => {
    setValue(event.target.value);
  }, []);

  const DialogBody = useMemo(() => {

    const { autoTranslateLanguages } = gAPP_STORE.getAutoTranslateLanguagesStore();

    return (
      <Box width={400}>
        <FormGroup>
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <Select value={value ?? translateSettings?.value.to} onChange={onChange}>
              {autoTranslateLanguages
                .map(to => ({ to: to, translate: t(to) }))
                .sort((a, b) => a.translate.localeCompare(b.translate)).map((to, index) => (
                  <MenuItem key={index} className={classes.option} value={to.to}>
                    {to.translate}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Box p={1} />
        </FormGroup>
      </Box>
    );
  }, [classes.formControl, classes.option, onChange, t, translateSettings?.value.to, value]);

  return (
    <CommonDialog
      title={t('language')}
      open={true}
      onClose={handleClose}
      contentComponent={DialogBody}
      uiStore={userDlgUiStoreRef.current}
      autotestId={'autotranslate'}
    />
  );
};

